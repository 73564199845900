<fieldset>
  <h3 class="multisteps-form__title">Receiver Details </h3>
  <div class="multisteps-form__content">
    <form [formGroup]="receiverDetailsForm">
      <div formGroupName="receiver">
        <div class="form-row mt-4">
          <div class="w-100">
            <div class="form-group row">
              <div class="col-md-4 col-sm-12 text-right">
                <label class="control-label" for="inputReceiver">Name <span *ngIf="receiver.get('name')?.errors?.required">*</span></label>
              </div>
              <div class="col-md-4 col-sm-12">
                <input type="text" class="form-control" formControlName="name" id="inputReceiver" name="inputReceiver" placeholder="Name">
              </div>
              <div class="col-md-4 col-sm-12">
                <span *ngIf="receiver.get('name')?.touched && receiver.get('name')?.errors?.required " class="help-block error">Required</span>
              </div>
            </div>
          </div>
        </div>

        <div class="form-row mt-4" *ngIf="showReceiverEmail">
          <div class="form-group row">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label" for="inputClaimantEmail">Email Address <span *ngIf="receiver.get('email')?.errors?.required">*</span></label>
            </div>
            <div class="col-md-4 col-sm-12">
              <input type="email" class="form-control" formControlName="email" id="inputClaimantEmail" name="inputClaimantEmail" placeholder="Email Address">
            </div>
            <div class="col-md-4 col-sm-12">
              <div [ngClass]="{'help-block error' : (receiver.get('email')?.errors?.required && receiver.get('email')?.touched)
                   ||(receiver.get('email')?.errors && receiver.get('email')?.value)}">
                <span *ngIf="receiver.get('email')?.touched && receiver.get('email')?.errors?.required ">Required<br /></span><!--x-->
                <span *ngIf="receiver.get('email')?.errors?.invalidEmailFormat">Email format Invalid <br /> Ex:- "name@domain.com.au"</span>
                <span *ngIf="receiver.get('email')?.errors?.maxlength">Invalid email address max length is 254 characters<br /></span>
                <span *ngIf="showInfoMessage" class="help-block text-info pl-4"><i class="icon-info-circled"></i>The receiver of the goods may need to be contacted as part of the claims process.</span>
              </div>
            </div>
          </div>
        </div>

        <div class="form-row mt-4" *ngIf="showReceiverPhoneNumber">
          <div class="form-group row">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label" for="inputClaimantPhone">Phone Number <span *ngIf="receiver.get('phone')?.errors?.required">*</span></label>
            </div>
            <div class="col-md-4 col-sm-12">
              <input type="text" class="form-control" formControlName="phone" id="inputClaimantPhone" name="inputClaimantPhone" placeholder="Phone Number">
            </div>
            <div class="col-md-4 col-sm-12">
              <div [ngClass]="{'help-block error' : (receiver.get('phone')?.errors?.required && receiver.get('phone')?.touched)
                   ||(receiver.get('phone')?.errors && receiver.get('phone')?.value)}">
                <span *ngIf="receiver.get('phone')?.touched && receiver.get('phone')?.errors?.required">Required<br /></span>
                <span *ngIf="receiver.get('phone')?.errors?.invalidNumber">Phone number must be numeric<br /></span>
                <span *ngIf="receiver.get('phone')?.errors?.maxlength">Phone number must be 10 digits<br /></span>
                <span *ngIf="receiver.get('phone')?.errors?.minlength">Phone number must be 10 digits<br /></span>
                <span *ngIf="showInfoMessage" class="help-block text-info pl-4"><i class="icon-info-circled"></i>The receiver of the goods may need to be contacted as part of the claims process.</span>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row mt-4">
          <div class="w-100">
            <div class="form-group row">
              <div class="col-md-4 col-sm-12 text-right">
                <label class="control-label" for="inputReceiverAddress">Delivery Address <span *ngIf="receiver.get('address')?.errors?.required">*</span></label>
              </div>
              <div class="col-md-4 col-sm-12">
                <input type="text" class="form-control" formControlName="address" autocomplete="off" appGooglePlace (onPlaceChanged)="mapAddressValuesToControls($event)" id="inputReceiverAddress" name="inputReceiverAddress" placeholder="Delivery Address">
              </div>
              <div class="col-md-4 col-sm-12">
                <span *ngIf="receiver.get('address')?.touched && receiver.get('address')?.errors?.required " class="help-block error">Required</span>
                <span *ngIf="receiver.errors?.addressNotSelected" class="help-block error">Address should be selected from the list</span>
                <span *ngIf="showInfoMessage" class="help-block text-info pl-4"><i class="icon-info-circled"></i>Please, search and select the address</span>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row mt-4">
          <div class="w-100">
            <div class="form-group row">
              <div class="col-md-4 col-sm-12 text-right">
                <label class="control-label" for="inputReceiverStreetAddress">Delivery Street <span *ngIf="receiver.get('addressLine1')?.errors?.required">*</span></label>
              </div>
              <div class="col-md-4 col-sm-12">
                <input type="text" class="form-control" formControlName="addressLine1" id="inputReceiverStreetAddress" name="inputReceiverStreetAddress" placeholder="Delivery Street Address">
              </div>
              <div class="col-md-4 col-sm-12">
                <span *ngIf="receiver.get('addressLine1')?.touched && receiver.get('addressLine1')?.errors?.required " class="help-block error">Required</span>
              </div>
            </div>
          </div>
        </div>

        <div class="form-row mt-4">
          <div class="w-100">
            <div class="form-group row">
              <div class="col-md-4 col-sm-12 text-right">
                <label class="control-label" for="inputReceiverCity">Delivery City <span *ngIf="receiver.get('city')?.errors?.required">*</span></label>
              </div>
              <div class="col-md-4 col-sm-12">
                <input type="text" class="form-control" formControlName="city" id="inputReceiverCity" name="inputReceiverCity" placeholder="Delivery City">
              </div>
              <div class="col-md-4 col-sm-12">
                <span *ngIf="receiver.get('city')?.touched && receiver.get('city')?.errors?.required " class="help-block error">Required</span>
              </div>
            </div>
          </div>
        </div>

        <div class="form-row mt-4">
          <div class="w-100">
            <div class="form-group row">
              <div class="col-md-4 col-sm-12 text-right">
                <label class="control-label" for="dropReceiverState">Delivery State <span *ngIf="receiver.get('state')?.errors?.required">*</span></label>
              </div>
              <div class="col-md-4 col-sm-12">
                <select class="form-control" formControlName="state" id="dropReceiverState" name="dropReceiverState">
                  <option disabled selected value="">Please Choose</option>
                  <option *ngFor="let state of states" [ngValue]="state.value">{{state.name}}</option>
                </select>
              </div>
              <div class="col-md-4 col-sm-12">
                <span *ngIf="receiver.get('state')?.touched && receiver.get('state')?.errors?.required" class="help-block error">Required</span>
                <span *ngIf="isInternationalState" class="help-block text-info pl-4"><i class="icon-info-circled"></i>State will be set automatically, for international addresses</span>
              </div>
            </div>
          </div>
        </div>

        <div class="form-row mt-4">
          <div class="w-100">
            <div class="form-group row">
              <div class="col-md-4 col-sm-12 text-right">
                <label class="control-label" for="inputReceiverPostCode">Delivery Postcode <span *ngIf="receiver.get('postalCode')?.errors?.required">*</span></label>
              </div>
              <div class="col-md-4 col-sm-12">
                <input type="text" class="form-control" formControlName="postalCode" id="inputReceiverPostCode" name="inputReceiverPostCode" placeholder="Delivery Postcode">
              </div>
              <div class="col-md-4 col-sm-12">
                <span *ngIf="receiver.get('postalCode')?.touched && receiver.get('postalCode')?.errors?.required " class="help-block error">Required</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</fieldset>
