<div class="form-row">
  <fieldset>
    <h3 class="multisteps-form__title">Bank Details</h3>
    <div class="multisteps-form__content">
      <form [formGroup]="bankInformationForm">

        <div class="form-row mt-4" *ngIf="showIsInternationalClaiamnt">
          <div class="form-group row">
            <div class="col-md-4 col-sm-12 text-right">
              <label for="isInternationalClaim" class="control-label">Are you an International Claimant? <span *ngIf="myForm.isInternationalClaimant?.errors?.required">*</span></label>
            </div>
            <div class="col-md-4 col-sm-12">
              <div>
                <label class="input-radio">
                  Yes
                  <input type="radio" formControlName="isInternationalClaimant" value="true" name="isInternationalClaimant">
                  <span class="checkmark"></span>
                </label>
                <label class="input-radio">
                  No
                  <input type="radio" formControlName="isInternationalClaimant" value="false" name="isInternationalClaimant">
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
          </div>
        </div>


        <div class="form-row mt-4">
          <div class="form-group row">
            <div class="col-md-4 col-sm-12 text-right">
              <label for="txtBankName" class="control-label">Bank Name <span *ngIf=" myForm.nameOfBank?.errors?.required">*</span></label>
            </div>
            <div class="col-md-4 col-sm-12">
              <input type="text" class="form-control" formControlName="nameOfBank" id="inputBankName" name="inputBankName" placeholder="Bank Name" rows="4" maxlength="1000">
            </div>
            <div class="col-md-4 col-sm-12">
              <span *ngIf="myForm.nameOfBank?.touched &&  myForm.nameOfBank?.errors?.required" class="help-block error">Required</span>
            </div>
          </div>
        </div>
        <div class="form-row mt-4">
          <div class="form-group row">
            <div class="col-md-4 col-sm-12 text-right">
              <label for="inputAccountName" class="control-label">Account Name <span *ngIf=" myForm.accountName?.errors?.required">*</span></label>
            </div>
            <div class="col-md-4 col-sm-12">
              <input type="text" class="form-control" formControlName="accountName" id="inputAccountName" name="inputAccountName" placeholder="Account Name" rows="4" maxlength="1000">
            </div>
            <div class="col-md-4 col-sm-12">
              <span *ngIf="myForm.accountName?.touched &&  myForm.accountName?.errors?.required" class="help-block error">Required</span>
            </div>
          </div>
        </div>
        <div class="form-row mt-4" *ngIf="!showInternationalBankDetails">
          <div class="form-group row">
            <div class="col-md-4 col-sm-12 text-right">
              <label for="txtBSBNumber" class="control-label">BSB Number <span *ngIf="myForm.bsbNumber.errors?.required">*</span></label>
            </div>
            <div class="col-md-4 col-sm-12">
              <div>
                <input type="text" class="form-control" formControlName="bsbNumber" id="inputBSBNumber" name="inputBSBNumber" placeholder="BSB Number" (change)="onBsbNumberChanged(myForm.bsbNumber.value,true)" />
                <span>
                  <i (click)="openBSBInformation()" *ngIf="showbsbInforModalButton" class="bsb-status icon- completed" aria-hidden="true"></i>
                  <i (click)="openBSBInformation()" *ngIf="!showbsbInforModalButton" class="bsb-status icon- issue" aria-hidden="true"></i>
                </span>
              </div>
            </div>
            <div class="col-md-4 col-sm-12">
              <div [ngClass]="{'help-block error' : ((myForm.bsbNumber.dirty ||  myForm.bsbNumber.value || myForm.bsbNumber.touched) && myForm.bsbNumber.errors)}">
                <span *ngIf="(!myForm.bsbNumber.pristine || myForm.bsbNumber.touched ) && myForm.bsbNumber.errors?.required">Required</span>
                <span *ngIf="myForm.bsbNumber.errors?.invalidNumber">BSB should be numeric<br /></span>
                <span *ngIf="myForm.bsbNumber.value && myForm.bsbNumber.touched &&  myForm.bsbNumber.errors?.invalidBSB">BSB number is invalid<br /></span>
                <span *ngIf="myForm.bsbNumber.errors?.minlength || myForm.bsbNumber.errors?.maxlength">BSB number is 6 digits<br /></span>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row mt-4">
          <div class="form-group row">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label" for="inputAccountNumber">Bank Account Number <span *ngIf="myForm.accountNumber.errors?.required">*</span></label>
            </div>
            <div class="col-md-4 col-sm-12">
              <input type="text" class="form-control" formControlName="accountNumber" id="inputAccountNumber" name="inputAccountNumber" placeholder="Account Number" />
            </div>
            <div class="col-md-4 col-sm-12">
              <div [ngClass]="{'help-block error' : ((myForm.accountNumber.dirty || myForm.accountNumber.value || myForm.accountNumber.touched) && myForm.accountNumber.errors)}">
                <span *ngIf="myForm.accountNumber.touched &&  myForm.accountNumber.errors?.required">Required</span>
                <span *ngIf="myForm.accountNumber.errors?.maxlength"> Account number should be only 10 digits<br /></span>
                <span *ngIf="myForm.accountNumber.errors?.minlength"> Account number should be 6 digits minimum<br /></span>
                <span *ngIf="myForm.accountNumber.errors?.invalidNumber">Account number should be numeric</span>
              </div>
            </div>
          </div>
        </div>


        <ng-container *ngIf="showInternationalBankDetails">
          <div class="form-row mt-4">
            <div class="form-group row">
              <div class="col-md-4 col-sm-12 text-right">
                <label for="inputBankAddress" class="control-label">Bank Address <span *ngIf="myForm.bankAddress?.errors?.required">*</span></label>
              </div>
              <div class="col-md-4 col-sm-12">
                <input type="text" class="form-control" formControlName="bankAddress" id="inputBankAddress" name="inputBankAddress" placeholder="Bank Address" />

              </div>
              <div class="col-md-4 col-sm-12">
                <span *ngIf="myForm.bankAddress?.touched &&  myForm.bankAddress?.errors?.required" class="help-block error">Required</span>
              </div>
            </div>
          </div>
          <div class="form-row mt-4">
            <div class="form-group row">
              <div class="col-md-4 col-sm-12 text-right">
                <label for="inputAccountAddress" class="control-label">Account Address <span *ngIf="myForm.accountAddress?.errors?.required">*</span></label>
              </div>
              <div class="col-md-4 col-sm-12">
                <input type="text" class="form-control" formControlName="accountAddress" id="inputAccountAddress" name="inputAccountAddress" placeholder="Account Address" />

              </div>
              <div class="col-md-4 col-sm-12">
                <span *ngIf="myForm.accountAddress?.touched &&  myForm.accountAddress?.errors?.required" class="help-block error">Required</span>
              </div>
            </div>
          </div>
          <div class="form-row mt-4">
            <div class="form-group row">
              <div class="col-md-4 col-sm-12 text-right">
                <label for="inputBankABA" class="control-label">Bank ABA/Routing <span *ngIf=" myForm.bankABA.errors?.required">*</span></label>
              </div>
              <div class="col-md-4 col-sm-12">
                <input type="text" class="form-control" formControlName="bankABA" id="inputBankABA" name="inputBankABA" placeholder="Bank ABA/Routing" />

              </div>
              <div class="col-md-4 col-sm-12">
                <div [ngClass]="{'help-block error' : ((myForm.bankABA.dirty || myForm.bankABA.value || myForm.bankABA.touched) && myForm.bankABA.errors)}">
                  <span *ngIf="myForm.bankABA.touched && myForm.bankABA.errors?.required">Required</span>
                  <span *ngIf="myForm.bankABA.errors?.invalidNumber">Bank ABA/Routing should be numeric<br /></span>
                  <span *ngIf="myForm.bankABA.errors?.minlength">Too short</span>
                  <span *ngIf="myForm.bankABA.errors?.maxlength">Too long </span>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row mt-4">
            <div class="form-group row">
              <div class="col-md-4 col-sm-12 text-right">
                <label for="inputBankSwift" class="control-label">Bank SWIFT <span *ngIf=" myForm.bankSwift.errors?.required">*</span></label>
              </div>
              <div class="col-md-4 col-sm-12">
                <input type="text" class="form-control" formControlName="bankSwift" id="inputBankSwift" name="inputBankSwift" placeholder="Bank SWIFT" />
              </div>
              <div class="col-md-4 col-sm-12">
                <div [ngClass]="{'help-block error' : ((myForm.bankSwift.dirty || myForm.bankSwift.value || myForm.bankSwift.touched) && myForm.bankSwift.errors)}">
                  <span *ngIf="myForm.bankSwift.touched &&  myForm.bankSwift.errors?.required">Required</span>
                  <span *ngIf="myForm.bankSwift.errors?.invalidCharacter">Special characters are not allowed as Bank SWIFT<br /></span>
                  <span *ngIf="myForm.bankSwift.errors?.minlength">Too short</span>
                  <span *ngIf="myForm.bankSwift.errors?.maxlength">Too long</span>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row mt-4">
            <div class="form-group row">
              <div class="col-md-4 col-sm-12 text-right">
                <label for="selectAccountCurrancy" class="control-label">Currency <span *ngIf=" myForm.accountCurrency?.errors?.required">*</span></label>
              </div>
              <div class="col-md-4 col-sm-12">
                <select class="form-control" formControlName="accountCurrency" id="selectAccountCurrancy" name="selectAccountCurrancy">
                  <option selected disabled value="">Please Choose</option>
                  <option *ngFor="let currancy of currancies" [ngValue]="currancy" [title]="currancy.name">
                    {{currancy?.code}}
                  </option>

                </select>
              </div>
              <div class="col-md-4 col-sm-12">
                <span *ngIf="myForm.accountCurrency?.touched &&  myForm.accountCurrency?.errors?.required" class="help-block error">Required</span>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="mb-4"> </div>
        <div class="col-12 col-md-9 m-auto" *ngIf="showPaymentNote">
          <div class="important-notice" [innerHTML]="paymentNote"></div>
        </div>
      </form>
    </div> <!--"multisteps-form__content"-->
  </fieldset>
</div>
<modal #modal></modal>

