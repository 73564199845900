import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ICarrier } from '../../../interfaces/Carrier';
import { AppService } from '../../../services/common/app.service';
import { FormService } from '../../../services/common/form.service';


@Component({
  selector: 'app-carrier-selection',
  templateUrl: './carrier-selection.component.html',
  styleUrls: ['./carrier-selection.component.css']
})
export class CarrierSelectionComponent implements OnInit {

  carrierSelection = this.formService.carrierSelectionForm;
  carriers: ICarrier[] = [{ Prefix: 'AMX', Name: 'Aramex',Id:1 }, { Prefix: 'ABC', Name: 'ABC Transport', Id:2 }, { Prefix: 'BE', Name: 'Boarder Express',Id:3 }];
  constructor(private formService: FormService, private appService: AppService) {
    //this.carriers
   
  }

  ngOnInit(): void {
    this.appService.getClaimFormEnabledCarriers().subscribe(results => {
      this.carriers = results;

    });


  }

  navigateToClaimForm() {
    window.location.href = this.carrierSelection.get('selectedCarrier')?.value.toLowerCase();
  }

}
