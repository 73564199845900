import { Component, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { ModalOptions } from '../../../../models/ModalOptions';
import { FormService } from '../../../../services/common/form.service';
import { ModalComponent } from '../../../common/modal/modal.component';

@Component({
  selector: 'app-amx-purchasing-customer-details',
  templateUrl: './amx-purchasing-customer-details.component.html',
  styleUrls: ['./amx-purchasing-customer-details.component.css']
})
export class AmxPurchasingCustomerDetailsComponent implements OnInit {
  purchasingCustomerForm = this.formService.amxPurchasingCustomerForm;
  welcomeInformationForm = this.formService.amxWelcomeForm;
  modalbodyContent!: string;
  modalHeaderContent!: string;
  @ViewChild('modal') modal!: ModalComponent;
  constructor(private formService: FormService) { }
  get myForm() {
    return this.purchasingCustomerForm?.controls.purchasingCustomer;
  }
  get claimantRole() {
    return this.welcomeInformationForm?.controls.claimantRole;
  }

  resolveGoogleAddress(controls: any) {
    //this.formService.geocodeAddressAsync(controls).then(value => {
    //  if (value.error) {
    //    const textContent = `Geocode was not successful for the following reason: ${value.message}`;
    //    const title = "Attention";
    //    this.showInformation(title, textContent)
    //  }
    //});
  };
  ngOnInit(): void {
    this.formService.setPurchasingCustomerValidators();
  }

  showInformation(headerContent: string, bodyContent: string) {
    const modalOptions: ModalOptions = {
      animation: true,
      centered: true,
      size: 'md',
      showConfirmationButtons: false,
      isHtmlContent: true,
      showOkayButton: true
    }
    this.modalbodyContent = bodyContent;
    this.modalHeaderContent = headerContent;
    this.modal.modalOpen(this.modalHeaderContent, this.modalbodyContent, modalOptions).result.finally(() => {
    });
  }
}
