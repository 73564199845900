
<fieldset>
  <h3 class="multisteps-form__title"> Further Details </h3>
  <div class="multisteps-form__content">
    <form [formGroup]="furtherDetailsForm">

      <div class="form-row mt-4">
        <div class="row w-100">
          <div class="col-md-4 col-sm-12 text-right">
            <label class="control-label">
              Claim Type
              <span *ngIf="myForm.claimType?.errors?.required">*</span>
            </label>
          </div>
          <div class="col-md-4 col-sm-12">
            <select class="form-control" formControlName="claimType" id="dropClaimType" name="dropClaimType" (change)="onClaimTypeChanged()">
              <option selected disabled value="">Please Choose</option>
              <option *ngFor="let claimType of claimTypes " [ngValue]="claimType">{{claimType.name}}</option>
            </select>
          </div>
          <div class="col-md-4 col-sm-12">
            <span *ngIf="myForm.claimType?.touched && myForm.claimType?.errors?.required" class="help-block error">Claim Type is mandatory.</span>
          </div>
        </div>
      </div>


      <div class="form-row mt-4">
        <div class="row w-100">
          <div class="col-md-4 col-sm-12 text-right">
            <label class="control-label">
              Value of goods actually lost or damaged in transit
              <span *ngIf="myForm.claimValue.errors?.required">*</span>
            </label>
          </div>
          <div class="col-md-4 col-sm-12">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">$</span>
              </div>
              <input type="text" class="form-control" amount="claimValue" formControlName="claimValue" name="ClaimValue" />
            </div>
          </div>
          <div class="col-md-4 col-sm-12">
            <div [ngClass]="{'help-block error' : (myForm.claimValue.errors?.required && myForm.claimValue.touched)
                 || (myForm.claimValue.errors && (myForm.claimValue.value || myForm.claimValue.value==0))}">
              <span *ngIf="myForm.claimValue.touched && myForm.claimValue.errors?.required">Required<br /></span>
              <span *ngIf="myForm.claimValue.errors?.invalidDecimalNumber">Claim value should be numeric<br /></span>
              <span *ngIf="myForm.claimValue.errors?.canNotBeZero">Claim value can not be 0<br /></span>
            </div>
          </div>
        </div>
      </div>

      <div class="form-row mt-4" *ngIf="claimant?.isInternationalClaimant?.value =='true'">
        <div class="row w-100">
          <div class="col-md-4 col-sm-12 text-right">
            <label class="control-label">
              Currency
              <span *ngIf="myForm.claimCurrency?.errors?.required">*</span>
            </label>
          </div>
          <div class="col-md-4 col-sm-12">
            <select class="form-control" formControlName="claimCurrency" id="selectedClaimCurrency" name="selectedClaimCurrency">
              <option selected disabled value="">Please Choose</option>
              <option *ngFor="let currancy of currancies trackBy:identity" [ngValue]="currancy" [title]="currancy.name">
                {{currancy.code}}
              </option>
            </select>
          </div>
          <div class="col-md-4 col-sm-12">
            <span *ngIf="myForm.claimCurrency?.touched && myForm.claimCurrency?.errors?.required" class="help-block error">Required</span>
          </div>
        </div>
      </div>

      <div class="form-row mt-4">
        <div class="row w-100">
          <div class="col-md-4 col-sm-12 text-right">
            <label class="control-label">Were the goods damaged in transit wine?</label>
          </div>
          <div class="col-md-4 col-sm-12">
            <label class="input-radio">
              Yes
              <input id="yes" type="radio" value="1" name="isGoodsDamagedInTransitWine" formControlName="isGoodsDamagedInTransitWine">
              <span class="checkmark"></span>
            </label>
            <label class="input-radio">
              No
              <input id="no" type="radio" value="0" name="isGoodsDamagedInTransitWine" formControlName="isGoodsDamagedInTransitWine">
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
      </div>

      <div class="form-row mt-4" *ngIf="myForm.isGoodsDamagedInTransitWine?.value=='1'">
        <div class="row w-100">
          <div class="col-md-4 col-sm-12 text-right">
            <label class="control-label">Are you a winery?</label>
          </div>
          <div class="col-md-4 col-sm-12">
            <div *ngIf="claimantRole?.value?.value == 'Sender'" formGroupName="sender">
              <label class="input-radio">
                Yes
                <input id="yes" type="radio" value="1" name="isClaimantWinery" formControlName="isClaimantWinery">
                <span class="checkmark"></span>
              </label>
              <label class="input-radio">
                No
                <input id="no" type="radio" value="0" name="isClaimantWinery" formControlName="isClaimantWinery">
                <span class="checkmark"></span>
              </label>

            </div>
            <div *ngIf="claimantRole?.value?.value == 'Receiver'" formGroupName="receiver">
              <label class="input-radio">
                Yes
                <input id="yes" type="radio" value="1" name="isClaimantWinery" formControlName="isClaimantWinery">
                <span class="checkmark"></span>
              </label>
              <label class="input-radio">
                No
                <input id="no" type="radio" value="0" name="isClaimantWinery" formControlName="isClaimantWinery">
                <span class="checkmark"></span>
              </label>
            </div>
            <div *ngIf="claimantRole?.value?.value == 'Third Party'" formGroupName="purchasingCustomer">
              <label class="input-radio">
                Yes
                <input id="yes" type="radio" value="1" name="isClaimantWinery" formControlName="isClaimantWinery">
                <span class="checkmark"></span>
              </label>
              <label class="input-radio">
                No
                <input id="no" type="radio" value="0" name="isClaimantWinery" formControlName="isClaimantWinery">
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-3" *ngIf="myForm.isGoodsDamagedInTransitWine?.value=='1'">
        <div class="col-12 col-md-9 m-auto important-notice">
          <p class="mb-0"><b>IMPORTANT:</b></p>
          <ul>
            <li><div> You will only be compensated for the value of your goods at your <span *ngIf="claimantRole?.value?.valu == 'Sender' && myForm.isClaimantWinery?.value==1"> manufacturer's</span> cost price.</div></li>
            <li><div>If you want to claim for the full manufacturing value of label damaged bottles, these must be returned to the sending Regional Franchisee prior to a claim being finalised.</div></li>
          </ul>
        </div>
      </div>

      <div class="form-row mt-4">
        <div class="row w-100">
          <div class="col-md-4 col-sm-12 text-right">
            <label class="control-label">
              Description of Goods
              <span *ngIf="myForm.descriptionOfGoods?.errors?.required">*</span>
            </label>
          </div>
          <div class="col-md-4 col-sm-12">
            <input type="text" class="form-control" name="descriptionOfGoods" formControlName="descriptionOfGoods" />
          </div>
          <div class="col-md-4 col-sm-12">
            <span *ngIf="myForm.descriptionOfGoods?.touched && myForm.descriptionOfGoods?.errors?.required" class="help-block error">Required</span>
          </div>
        </div>
      </div>

      <div class="form-row mt-4">
        <div class="row w-100">
          <div class="col-md-4 col-sm-12 text-right">
            <label class="control-label">
              Nature of Loss or Damage
              <span *ngIf="myForm.descriptionOfLossOrDamage?.errors?.required">*</span>
            </label>
          </div>
          <div class="col-md-4 col-sm-12">
            <input type="text" class="form-control" name="descriptionOfLossOrDamage" formControlName="descriptionOfLossOrDamage" />
          </div>
          <div class="col-md-4 col-sm-12">
            <span *ngIf="myForm.descriptionOfLossOrDamage?.touched && myForm.descriptionOfLossOrDamage?.errors?.required" class="help-block error">Required</span>
          </div>
        </div>
      </div>

      <div class="form-row mt-4" *ngIf="myForm.claimType?.value?.id=='0'">
        <div class="row w-100">
          <div class="col-md-4 col-sm-12 text-right">
            <label class="control-label"> Are Goods Repairable?</label>
          </div>
          <div class="col-md-4 col-sm-12">

            <label class="input-radio">
              Yes
              <input id="yes" type="radio" value="true" name="isGoodsRepairable" formControlName="isGoodsRepairable">
              <span class="checkmark"></span>
            </label>
            <label class="input-radio">
              No
              <input id="no" type="radio" value="false" name="isGoodsRepairable" formControlName="isGoodsRepairable">
              <span class="checkmark"></span>
            </label>

          </div>
        </div>
      </div>
      <div class="form-row mt-4 mb-4" *ngIf="myForm.claimType?.value?.id=='0'">
        <div class="row w-100">
          <div class="col-md-4 col-sm-12 text-right">
            <label class="control-label">
              Current Location of Damaged Goods
              <span *ngIf="myForm.damagedGoodsLocation?.errors?.required">*</span>
            </label>
          </div>
          <div class="col-md-4 col-sm-12">
            <input type="text" class="form-control" name="damagedGoodsLocation" formControlName="damagedGoodsLocation" />
          </div>
          <div class="col-md-4 col-sm-12">
            <span *ngIf="myForm.damagedGoodsLocation?.touched && myForm.damagedGoodsLocation?.errors?.required" class="help-block error">Required</span>
          </div>
        </div>
      </div>

      <div class="form-row mt-4" *ngIf="myForm.claimType?.value?.id=='0'">
        <!--*ngIf="myForm.claimType?.value?.id=='0'"-->
        <div class="row w-100">
          <div class="col-md-4 col-sm-12 text-right">
            <label class="control-label"> Is Salvage Required?</label>
          </div>
          <div class="col-md-4 col-sm-12">

            <label class="input-radio">
              Yes
              <input id="yes" type="radio" value="true" name="isSalvageRequired" formControlName="isSalvageRequired">
              <span class="checkmark"></span>
            </label>
            <label class="input-radio">
              No
              <input id="no" type="radio" value="false" name="isSalvageRequired" formControlName="isSalvageRequired">
              <span class="checkmark"></span>
            </label>

          </div>
        </div>
      </div>
      <div class="form-row mt-4" *ngIf="myForm.isSalvageRequired?.value == 'true' && myForm.claimType?.value?.id=='0'">
        <div class="form-group row w-100">
          <div class="col-md-4 col-sm-12 text-right">
            <label class="control-label">Salvage Details</label>
          </div>
          <div class="col-md-4 col-sm-12">
            <textarea class="form-control" formControlName="salvageDetails" id="txtsalvageDetails" name="txtsalvageDetails" placeholder="Details of Salvage" rows="4" maxlength="1000"></textarea>
          </div>
          <div class="col-md-4 col-sm-12">
            <!--<span *ngIf="myForm.isSalvageRequired?.touched && myForm.isSalvageRequired?.errors?.required" class="help-block error">Required</span>-->
          </div>
        </div>
      </div>


      <div class="col-12 col-md-9 m-auto important-notice" *ngIf="myForm.claimType?.value?.id=='0'">
        <p class="mb-0"><b>IMPORTANT:</b> Please ensure you retain possession of any damaged goods throughout the claims process as you may be required to surrender back to the carrier for inspection or salvage.</p>
      </div>
    </form>
  </div>
</fieldset>
