import { Component, OnInit, ViewChild } from '@angular/core';
import { async } from '@angular/core/testing';
import { AbstractControl, ValidationErrors, Validators, ValidatorFn } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ModalOptions } from '../../../../models/ModalOptions';
import { AppService } from '../../../../services/common/app.service';
import { CustomValidationService } from '../../../../services/common/custom-validation.service';
import { FormService } from '../../../../services/common/form.service';
import { ModalComponent } from '../../../common/modal/modal.component';


@Component({
  selector: 'app-amx-payment',
  templateUrl: './amx-payment.component.html',
  styleUrls: ['./amx-payment.component.css']
})
export class AmxPaymentComponent implements OnInit {
  paymentForm = this.formService.amxPaymentForm;
  customerinformationForm = this.formService.amxCustomerinformationForm;

  @ViewChild('modal') modal!: ModalComponent;
  modalHeaderContent!: string;
  modalbodyContent!: string;
  showModalButtons: boolean = false;
  accountCurrencies: any;
  currancySub!: Subscription;
  identity(index: any, item: any) {
    return item.Id;
  }

  get claimant() {
    return this.customerinformationForm?.controls;
  }
  constructor(private formService: FormService, private appService: AppService, private customValidatorService: CustomValidationService) { }
  get myForm() {
    return this.paymentForm?.controls;
  }
  validationArray: any[] = [Validators.required, Validators.maxLength(6), Validators.minLength(6), this.customValidatorService.numericInputValidator()];
  ngOnInit(): void {

    this.formService.setPaymentValidators();
    this.formService.setBsbValidateAmx();

    if (!this.accountCurrencies) {
      this.currancySub = this.appService.getAllCurrencies().subscribe(result => {
        this.accountCurrencies = result;
        const selectedCurrancy = this.accountCurrencies.filter((cu: { id: any; }) => {
          return cu.id == this.myForm.accountCurrency?.value?.id;
        })[0];
        if (selectedCurrancy) {
          this.myForm.accountCurrency.setValue(selectedCurrancy, { onlySelf: true });
        } else {
          this.myForm.accountCurrency.setValue('', { onlySelf: true });
        }
      });
    } else {
      const selectedCurrancy = this.accountCurrencies.filter((cu: { id: any; }) => {
        return cu.id == this.myForm.accountCurrency?.value?.id;
      })[0];
      if (selectedCurrancy) {
        this.myForm.accountCurrency.setValue(selectedCurrancy, { onlySelf: true });
      } else {
        this.myForm.accountCurrency.setValue('', { onlySelf: true });
      }
    }
  }

  onBsbNumberChanged() {
    this.formService.setBsbValidateAmx();
  };

  showBsbInformation() {
    const modalOptions: ModalOptions = {
      animation: true,
      centered: true,
      size: 'md',
      showConfirmationButtons: false,
      isHtmlContent: true,
      showOkayButton: true
    }
    if (this.myForm?.bsbInformation.value != null) {
      this.modalbodyContent = "<strong>Bank Code</strong> : " + this.myForm?.bsbInformation.value?.bankCode +
        "<br/> <strong>BSB Name</strong> : " + this.myForm?.bsbInformation.value?.bsbName +
        "<br/> <strong>BSB Address</strong> : " + this.myForm?.bsbInformation.value?.bsbAddress +
        "<br/> <strong>City</strong> : " + this.myForm?.bsbInformation.value?.city +
        "<br/> <strong>State</strong> : " + this.myForm?.bsbInformation.value?.state +
        "<br/> <strong>Postal Code</strong> : " + this.myForm?.bsbInformation.value?.postalCode + "";
    }
    else {
      this.modalbodyContent = "No BSB information found";
    }
    this.modalHeaderContent = 'BSB Information';
    this.modal.modalOpen(this.modalHeaderContent, this.modalbodyContent, modalOptions);
  };
}
