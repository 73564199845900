import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Output, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MediaRecorder } from 'extendable-media-recorder';
import { interval, Subscription, timer } from 'rxjs';
import { map, share } from 'rxjs/operators';

@Component({
  selector: 'app-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.css']
})
export class CameraComponent {

  headerStyle: string = 'modal-title';
  headerSectionStyle: string = 'modal-header';
  footerStyle: string = 'modal-footer';

  WIDTH = 640;
  HEIGHT = 480;
  @ViewChild("canvas") canvas!: ElementRef;
  @ViewChild("video") video!: ElementRef;
  @ViewChild("videoPreview") videoPreview!: ElementRef;
  stream: any;
  recorder: any;
  //videoData: any[] = [];
  //isRecording: boolean = false;

  @Output() capturedImages: EventEmitter<any> = new EventEmitter();

  constructor(public activeModal: NgbActiveModal) { }


  subscription: Subscription | undefined;

  counter = 11;
  tick = 1000;

  captures: SourceFile[] = [];
  capturedFiles: File[] = [];
  error: any;
  isCaptured: boolean = false;
  isVideo: boolean = false;
  isIE: boolean = false;
  allowMultipleUploads: boolean = true;
  snapCount: number = 0;
  fileCount: number = this.snapCount;
  recordingTimeMS: number = 10000;
  //ttsrc: boolean = false;
  isStarted: boolean = false;

  //private subscription1: Subscription | undefined;

  //public dateNow = new Date();
  //public dDay = new Date('Jan 01 2021 00:00:00');
  //milliSecondsInASecond = 1000;
  //hoursInADay = 24;
  //minutesInAnHour = 60;
  //SecondsInAMinute = 60;

  //timeDifference: number | undefined;
  //secondsToDday: number | undefined;
  //minutesToDday: number | undefined;
  //hoursToDday: number | undefined;
  //daysToDday: number | undefined;

  ngAfterViewInit() {
    this.setupDevices();
  }
  ngOnInit() {
    //this.subscription1 = interval(1000)
    //  .subscribe(x => { this.getTimeDifference(); });
    // Using RxJS Timer

    //this.subscription = timer(0, this.tick)
    //  .subscribe(() => --this.counter);
  }
  //private getTimeDifference() {
  //  this.timeDifference = this.dDay.getTime() - new Date().getTime();
  //  this.allocateTimeUnits(this.timeDifference);
  //}

  //private allocateTimeUnits(timeDifference: any) {
  //  this.secondsToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
  //  this.minutesToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
  //  this.hoursToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute) % this.hoursInADay);
  //  this.daysToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute * this.hoursInADay));
  //}
  ngOnDestroy() {

    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    //if (this.subscription1) {
    //  this.subscription1.unsubscribe();
    //}
  }
  setupDevices() {
    this.isIE = /msie\s|trident\/|edge\//i.test(navigator.userAgent);
    if (!this.isIE) {
      if (!this.allowMultipleUploads) {
        this.snapCount = 0;
        this.fileCount = 0;
      }
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {

        navigator.mediaDevices.getUserMedia({
          audio: false,
          video: {
            width: { min: 320, ideal: 640, max: 1920 },
            height: { min: 180, ideal: 480, max: 1080 }
          }
        }).then((videoStream) => {
          if (videoStream) {
            this.stream = videoStream;
            this.video.nativeElement.srcObject = this.stream;
            this.video.nativeElement.play();
            this.error = null;

          } else {
            this.error = "We are unable to detect a video device connected. Please check and try again.";
          }
        }).catch((err) => {

          if (err.name == "NotAllowedError") {
            this.error = " User has denied permission to access the camera. Please allow access to use this feature.";
          }
          else if (err.name == "NotFoundError") {
            this.error = "No camera was found. Please check that the camera is working and retry.";
          }
          else if (err.name == "NotReadableError") {
            this.error = "An error occurred when trying to access the camera. The device is being used by another process.";
          }
          else if (err.code == "AbortError") {
            this.error = " An error occurred which prevented the camera from starting. Please check the device and retry.";
          }
          else if (err.code == "OverconstrainedError") {
            this.error = "An error occurred which prevented the camera from starting. Please check the device and retry.";
          }
          else {
            this.error = err;
          }
        });
      }
      else {
        this.error = "getUserMedia not supported on your browser";
      }
    }
    else {
      this.error = "Internet explorer does not support this feature";
    }
  }

  capture() {
    if (this.stream) {
      this.drawImageToCanvas(this.video.nativeElement);
      this.captures.push({ src: this.canvas.nativeElement.toDataURL("image/png"), isImage: true });
      this.fileCount = this.fileCount + 1;
      this.isCaptured = true;
      this.isVideo = false;
    }
  }

  captureVideo() {
    if (this.stream) {
      this.isStarted = true;
      this.fileCount = this.fileCount + 1;
      this.startRecording();
    }
  }

  startRecording() {
  
      //const options = { mimeType: "video/webm; codecs=vp9" };
    this.recorder = new MediaRecorder(this.stream, {
      mimeType: 'video/webm'
    });
      this.recorder.start();
      this.recorder.ondataavailable = (ev: any) => {
        //this.videoData.push(ev.data);
        //this.video.nativeElement.currentTime = 1;
        this.drawImageToCanvas(this.video.nativeElement);
        this.captures.push({ src: ev.data, isImage: false, imageUrl: this.canvas.nativeElement.toDataURL("image/png") });
        this.videoPreview.nativeElement.src = URL.createObjectURL(ev.data);
      }
      this.subscription = timer(0, this.tick)
        .subscribe(() => --this.counter);

      let stopped = new Promise((resolve, reject) => {
        this.recorder.onstop = resolve;
        this.recorder.onerror = (event: { error: any; }) => reject(event.error);
      });

      let recorded = this.wait().then(() => {
        if (this.recorder.state == "recording") {
          //this.isRecording = false;
          this.recorder.stop();
          this.isStarted = false;
        }
      });


      return Promise.all([
        stopped,
        recorded
      ]).then(async () => {
        this.isVideo = true;
        this.isCaptured = false;
        
        //this.video.nativeElement.currentTime = 1;
        //this.drawImageToCanvas(this.video.nativeElement);
        //this.captures.push({ src: this.videoData[0], isImage: false, imageUrl: this.canvas.nativeElement.toDataURL("image/png") });
        //this.videoPreview.nativeElement.src = URL.createObjectURL(this.videoData[0]);
        if (this.subscription) {
          this.subscription.unsubscribe();
        }
      });
    
    return null;
  }


  wait() {
    return new Promise(resolve => setTimeout(resolve, this.recordingTimeMS));
  }

  stop() {

    this.recorder.ondataavailable = (ev: any) => {
      //this.videoData.push(ev.data);
      //this.video.nativeElement.currentTime = 1;
      this.drawImageToCanvas(this.video.nativeElement);
      this.captures.push({ src: ev.data, isImage: false, imageUrl: this.canvas.nativeElement.toDataURL("image/png") });
      this.videoPreview.nativeElement.src = URL.createObjectURL(ev.data);
    }
    if (this.recorder.state == "recording") {
      this.recorder.stop();
      this.isStarted = false;
      this.isVideo = true;
      this.isCaptured = false;

      if (this.subscription) {
        this.subscription.unsubscribe();
      }

    }
  }
  removeCurrent() {
    this.isCaptured = false;
    this.isVideo = false;
    //this.setupDevices();
    this.counter = 11;
    this.tick = 1000;

  }

  setPhoto(sourceObject: any) {

    if (!sourceObject.isImage) {
      this.isVideo = true;
      this.isCaptured = false;
      let src = URL.createObjectURL(sourceObject.src);
      this.videoPreview.nativeElement.src = src;
      this.videoPreview.nativeElement.play();
    }
    else {
      this.isCaptured = true;
      this.isVideo = false;
      const image = new Image();
      image.src = sourceObject.src.toString();
      this.drawImageToCanvas(image);
    }

  }

  drawImageToCanvas(image: any) {
    this.canvas.nativeElement
      .getContext("2d")
      .drawImage(image, 0, 0, this.WIDTH, this.HEIGHT);
  }

  removePhoto(idx: number) {
    this.captures.splice(idx, 1);
    this.removeCurrent();
    if (this.fileCount > 0) {
      this.fileCount = this.fileCount - 1;
    }
  }

  upload() {
    for (let i = 0; i < this.captures.length; i++) {
      this.snapCount = this.snapCount + 1;
      if (!this.captures[i].isImage) {
        this.capturedFiles.push(new File([this.captures[i].src], 'video' + this.snapCount + '.webm', { type: 'video/webm', lastModified: Date.now() }));
      }
      else {
        this.capturedFiles.push(this.dataURLtoFile(this.captures[i].src, 'image' + this.snapCount + '.png'));
      }
    }
    this.activeModal.close({ files: this.capturedFiles, count: this.snapCount });
    if (this.stream) {
      this.stream.getTracks().forEach(function (track: { stop: () => void; }) {
        track.stop();
      });
    }
  }


  dataURLtoFile(dataurl: any, filename: any) {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  modalClose() {
    this.activeModal.dismiss('test');
    if (this.stream) {
      this.stream.getTracks().forEach(function (track: { stop: () => void; }) {
        track.stop();
      });
    }
  }
}

@Pipe({
  name: "formatTime",
  pure: true
})
export class FormatTimePipe implements PipeTransform {
  transform(value: number): string {
    const hours: number = Math.floor(value / 3600);
    const minutes: number = Math.floor((value % 3600) / 60);
    return (
      ("00" + hours).slice(-2) +
      ":" +
      ("00" + minutes).slice(-2) +
      ":" +
      ("00" + Math.floor(value - minutes * 60)).slice(-2)
    );
  }
}
export interface SourceFile {
  src: Blob | string;
  isImage: boolean;
  imageUrl?: string;
}
