import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Navigation, Router } from '@angular/router';

import { NavigationStatus } from '../../../enums/NavigationStatus';
import { ConfigurationModel } from '../../../models/ConfigurationModel';
import { Constant } from '../../../utilities/Constant';
import { NavigationModel } from '../../../models/NavigationModel';
import { CommonService } from '../../../services/common/common.service';
import { NavigationService } from '../../../services/common/navigation-service';
import { FormRegistry } from '../../../utilities/FormRegistry';
import { FormService } from '../../../services/common/form.service';
import { Subscription } from 'rxjs';



@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.css']
})
export class ProgressBarComponent implements OnInit, OnDestroy {
  cssClass: string = Constant.progressBarInActiveClass;
  carrierConfig!: ConfigurationModel;
  hideProgressBar: boolean = false;
  nextStepSub!: Subscription;
  isDraftClaimSub!: Subscription;
  steps!: NavigationModel[] | undefined;
  isIsolatedSub!: Subscription;

  constructor(private commonService: CommonService, private route: Router,
    private navigationService: NavigationService, private formService: FormService,
    private cd: ChangeDetectorRef  ) { }



  ngOnInit(): void {
    this.carrierConfig = this.commonService.getCarrierConfigObject();
    this.steps = this.carrierConfig.navigation?.filter(nav => !nav.isIsolated && nav.isVisible);
    this.navigationService.nextStep$.subscribe(step => {
      this.progressBarUpdate(step);

    });
  this.isDraftClaimSub =  this.commonService.isDraftClaim$.subscribe(value => {
      if (this.carrierConfig.prefix.toLocaleLowerCase() == Constant.carrierPrefix.amxCarrier.toLowerCase()) {
        this.hideProgressBar = value;
        this.cd.detectChanges();
      }
    });
    this.isIsolatedSub = this.commonService.isIsolatedComponet$.subscribe(value => {
      this.hideProgressBar = value;
      this.cd.detectChanges();
    });

    this.commonService.isVisibilityUpdated$.subscribe(value => {
      this.steps = this.carrierConfig.navigation?.filter(nav => !nav.isIsolated && nav.isVisible);
      this.cd.detectChanges();
    });

  }

  ngOnDestroy(): void {
    this.nextStepSub?.unsubscribe();
    this.isDraftClaimSub?.unsubscribe();
    this.isIsolatedSub?.unsubscribe();
  }

  public progressBarUpdate(step: NavigationModel) {
    if (!this.carrierConfig) {
      this.route.navigate([`${this.commonService.getUrl()}/${""}`]);
    }
    this.navigationService.setProgresBarStyleOrStatus(this.carrierConfig, step, false, true);
  }

  public navigate(step: NavigationModel) {
    /*update navigation status*/
    this.navigationService.setProgresBarStyleOrStatus(this.carrierConfig, step, true, false);
    step.status = NavigationStatus.Active;
    /*Update progress bar*/
    this.progressBarUpdate(step);
    this.navigationService.activeStep$.next(step);
    this.route.navigate([`${this.commonService.getUrl()}/${step.navigationPath}`]);
    this.commonService.updateConfigObject(this.carrierConfig);
    this.formService.setClaimFormDataToSessionStorage(this.carrierConfig.prefix);
  }
}
