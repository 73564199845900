import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, UntypedFormArray, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { switchMap } from 'rxjs/operators';
import { __values } from 'tslib';
import { RequiredStatus } from '../../enums/RequiredStatus';
import { CustomValidator } from '../../models/CustomValidator';
import { Constant } from '../../utilities/Constant';
import { Utility } from '../../utilities/Utility';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root'
})
export class CustomValidationService {

  constructor(private ngbCalendarService: NgbCalendar, private appService: AppService) { }

  datePatternValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean | string } | null => {
      if (!control.value) {
        return null;
      }

      const regex = new RegExp('^(0?[1-9]|[12][0-9]|3[01])[\/](0?[1-9]|1[012])[\/]\\d{4}$');
      let value = Utility.convertDateToString(control);
      const valid = regex.test(value);

      return valid ? null : { dateInvalid: true };
    }
  }

  dateValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean | string } | null => {
      if (!control.value) {
        return null;
      }
      let valid = true;
      let ngbDateValue = Utility.convertDateToNGBDate(control);
      if (ngbDateValue.after(this.ngbCalendarService.getToday())) {
        valid = false;
      }
      return valid ? null : { dateafterToday: true };
    }
  }


  numericInputValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean | string } | null => {

      if (!control.value) {
        return null;
      }
      const regex = new RegExp('[^0-9]');
      const valid = regex.test(`${control.value}`)
      return valid ? { invalidNumber: true } : null;
    }
  }

  customRequiredValidator(validator: ValidatorFn, validationObject: CustomValidator, requiredStatus: RequiredStatus, defaultValidation: ValidatorFn | null, claimForm: UntypedFormArray): ValidatorFn {
    return (formControl => {
      if (requiredStatus == RequiredStatus.Required && !validationObject.dependentFields) {
        return validator(formControl);
      } else if (requiredStatus == RequiredStatus.NotRequired && !validationObject.dependentFields) {
        return null;
      } else if (validationObject.dependentFields) {
        let result!: boolean;
        let dependentField = validationObject.dependentFields;
        let definedDepenedFields = Constant.depenentFieldNames;
        for (let j = 0; j < definedDepenedFields.length; j++) {
          for (let i = 0; i < claimForm.controls.length; i++) {
            let control = claimForm.controls[i].get(definedDepenedFields[j])
            if (control) {
              if ((dependentField[definedDepenedFields[j]] == control.value || (control.value?.id != undefined && dependentField[definedDepenedFields[j]] == control.value.id)) && requiredStatus == RequiredStatus.Required) {
                result = true;
              } else if ((dependentField[definedDepenedFields[j]] == control.value || (control.value?.id != undefined && dependentField[definedDepenedFields[j]] == control.value.id)) && requiredStatus == RequiredStatus.NotRequired) {
                result = false;
              }
            }
          }
        }
        if (result) {
          return validator(formControl);
        } else if (result == false) {
          return null;
        }
        return defaultValidation ? defaultValidation(formControl) : null;
      }
      return defaultValidation ? defaultValidation(formControl) : null;
    });
  }

  charactersOnlyValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean | string } | null => {

      if (!control.value) {
        return null;
      }
      const regex = new RegExp('[^a-zA-Z]');
      const valid = regex.test(`${control.value}`)
      return valid ? { invalidCharacter: true } : null;
    }
  }

  charactersAndNumbersOnlyValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean | string } | null => {

      if (!control.value) {
        return null;
      }
      const regex = new RegExp('[^A-Za-z0-9]');
      const valid = regex.test(`${control.value}`)
      return valid ? { invalidCharacter: true } : null;
    }
  }

  startWithCharactersOnly(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean | string } | null => {

      if (!control.value) {
        return null;
      }
      const regex = new RegExp('[,|^[-]]');
      const valid = regex.test(`${control.value}`)
      return valid ? { invalidCharacter: true } : null;
    }
  }

  numberIsGreaterThanZero(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean | string } | null => {

      if (!control.value) {
        return null;
      }
      const regex = new RegExp('[^0-9.]');
      const valid = regex.test(`${control.value}`)
      if (valid) {
        return { invalidNumber: true }
      } else {
        const value = control.value;
        if (value > 0) {
          return null;
        }
        return { valueCanNotBeZero: true };
      }
    }
  }

  checkboxValueTrue(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean | string } | null => {

      if (!control) {
        return null;
      }
      if (control.value) {
        return null;
      }
      return { checkboxNotChecked: true };
    }
  }

  numericInputwithdecimalNoZerosValidator() {
    return (control: AbstractControl): { [key: string]: boolean | string } | null => {
      if (control.value === null || control.value === undefined || control.value === '') {
        return null;
      }
      const regex = new RegExp('[^0-9.]');
      const valid = regex.test(`${control.value}`)
      if (valid) {
        return { invalidDecimalNumber: true };
      }
      if (control.value == 0) {
        return { canNotBeZero: true }
      }
      return null;
    }
  }

  numericInputwithdecimalValidator() {
    return (control: AbstractControl): { [key: string]: boolean | string } | null => {
      if (control.value === null || control.value === undefined || control.value === '') {
        return null;
      }
      const regex = new RegExp('[^0-9.]');
      const valid = regex.test(`${control.value}`)
      if (valid) {
        return { invalidDecimalNumber: true };
      }
      return null;
    }
  }


  customDependentFieldValidator(predicate: () => boolean, validators: ValidatorFn) {
    return (control: AbstractControl): { [key: string]: boolean | string } | null => {

      if (!control) {
        return null;
      }
      if (predicate()) {
        return validators(control);
      } else if (!predicate()) {
        return null;
      }
      return null;
    }
  }

  claimNumberPatternValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean | string } | null => {

      if (!control.value) {
        return null;
      }
      const regex = new RegExp('^[a-zA-Z]{2,3}\\d+$');
      const valid = regex.test(`${control.value}`);
      return !valid ? { invalidClaimNumber: true } : null;
    }
  }

  customEmailValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean | string } | null => {

      if (!control.value) {
        return null;
      }
      const pattern = "^(?!\\.)(\"\"([^\"\"\\r\\\\]|\\\\[\"\"\\r\\\\])*\"\"|([-a-z0-9!#$%&'*+/=?^_`{|}~]|[^\\.@,;:()[\\]]\\.)*)[^\\.@,;:()[\\]' ']@[a-z0-9][\\w\\.-]*[a-z0-9]\\.[a-z][a-z\\.]*[a-z]$";
      const regex = new RegExp(pattern);
      const valid = regex.test(control.value.toLowerCase());
      return !valid ? { invalidEmailFormat: true } : null;
    }
  }

  customGroupValidator() {
    return (group: AbstractControl): { [key: string]: boolean | string } | null => {
      const control = group.get('address');

      if (control?.value) {
        const longitudeControl = group.get('longitude');
        if (!longitudeControl?.value) {
          return { addressNotSelected: true };
        }
      }
      return null;
    }
  }


  customCancellationReasonValidator() {
    return (control: AbstractControl): { [key: string]: boolean | string } | null => {
      if (!control) {
        return null;
      }
      let form = control?.parent as UntypedFormGroup;
      if (form && form.controls) {
        if (form.controls.cancelReason.value?.Name == "Other" &&
          (!form.controls.description.value || !form.controls.description.value.trim())) {
          return { required: true }
        }
      }
      return null;
    }
  }



}
