<div class="form-row">
  <fieldset>
    <h3 class="multisteps-form__title"> Receiver Details </h3>
    <div class="multisteps-form__content">
      <form [formGroup]="receiverForm">
        <div formGroupName="receiver">
          <div class="form-row mt-4">
            <div class="row w-100">
              <div class="col-md-4 col-sm-12 text-right">
                <label class="control-label">Name <span *ngIf="myForm.get('name')?.errors?.required">*</span></label>
              </div>
              <div class="col-md-4 col-sm-12">
                <input type="text" class="form-control" name="name" formControlName="name">

              </div>
              <div class="col-md-4 col-sm-12">
                <span *ngIf="myForm.get('name')?.touched && myForm.get('name')?.errors?.required" class="help-block error">Required</span>
              </div>
            </div>
          </div>

          <div class="form-row mt-4">
            <div class="row w-100">
              <div class="col-md-4 col-sm-12 text-right">
                <label class="control-label">Contact Name <span *ngIf="myForm.get('contactName')?.errors?.required">*</span></label>
              </div>
              <div class="col-md-4 col-sm-12">
                <input type="text" class="form-control" name="contactName" formControlName="contactName">

              </div>
              <div class="col-md-4 col-sm-12">
                <span *ngIf="myForm.get('contactName')?.touched && myForm.get('contactName')?.errors?.required" class="help-block error">Required</span>
              </div>
            </div>
          </div>

          <div class="form-row mt-4">
            <div class="row w-100">
              <div class="col-md-4 col-sm-12 text-right">
                <label class="control-label">Street Address <span *ngIf="myForm.get('addressLine1')?.errors?.required">*</span></label>
              </div>
              <div class="col-md-4 col-sm-12">
                <input type="text" class="form-control" name="addressLine1" formControlName="addressLine1">
              </div>
              <div class="col-md-4 col-sm-12">
                <span *ngIf="myForm.get('addressLine1')?.touched && myForm.get('addressLine1')?.errors?.required" class="help-block error">Required</span>
              </div>
            </div>
          </div>

          <div class="form-row mt-4">
            <div class="row w-100">
              <div class="col-md-4 col-sm-12 text-right">
                <label class="control-label">Suburb <span *ngIf="myForm.get('city')?.errors?.required">*</span></label>
              </div>
              <div class="col-md-4 col-sm-12">
                <input type="text" class="form-control" name="city" formControlName="city">
              </div>
              <div class="col-md-4 col-sm-12">
                <span *ngIf="myForm.get('city')?.touched && myForm.get('city')?.errors?.required" class="help-block error">Required</span>
              </div>
            </div>
          </div>

          <div class="form-row mt-4">
            <div class="row w-100">
              <div class="col-md-4 col-sm-12 text-right">
                <label class="control-label">Postal Code <span *ngIf="myForm.get('postalCode')?.errors?.required">*</span></label>
              </div>
              <div class="col-md-4 col-sm-12">
                <input type="text" class="form-control" name="postalCode" formControlName="postalCode">
              </div>
              <div class="col-md-4 col-sm-12">
                <span *ngIf="myForm.get('postalCode')?.touched && myForm.get('postalCode')?.errors?.required" class="help-block error">Required</span>
              </div>
            </div>
          </div>

          <div class="form-row mt-4">
            <div class="row w-100">
              <div class="col-md-4 col-sm-12 text-right">
                <label class="control-label">Resolve Address</label>
              </div>
              <div class="col-md-4 col-sm-12">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <button class="form-control" (click)="resolveGoogleAddress(myForm)">
                      <span data-icon="e" class="icon"></span>
                    </button>
                  </div>
                  <input type="text" class="form-control" formControlName="address">
                </div>
              </div>
            </div>
          </div>

          <div class="form-row mt-4">
            <div class="row w-100">
              <div class="col-md-4 col-sm-12 text-right">
                <label class="control-label">Email <span *ngIf="myForm.get('email')?.errors?.required">*</span></label>
              </div>
              <div class="col-md-4 col-sm-12">
                <input type="email" class="form-control" name="email" formControlName="email">

              </div>
              <div class="col-md-4 col-sm-12">
                <div [ngClass]="{'help-block error' :  (myForm.get('email')?.errors?.required && myForm.get('email')?.touched)
                  || (myForm.get('email')?.errors && myForm.get('email')?.value)}">
                  <span *ngIf="myForm.get('email')?.touched && myForm.get('email')?.errors?.required">Required</span>
                  <span *ngIf="myForm.get('email')?.errors?.invalidEmailFormat">Email format Invalid. <br /> Ex:- "name@domain.com.au"</span>
                </div>
              </div>
              </div>
          </div>

          <div class="form-row mt-4">
            <div class="row w-100">
              <div class="col-md-4 col-sm-12 text-right">
                <label class="control-label mb-0">Phone Number <span *ngIf="myForm.get('officeNumber')?.errors?.required">*</span></label>
              </div>
              <div class="col-md-4 col-sm-12">
                <input type="text" class="form-control" name="officeNumber" formControlName="officeNumber" title="(xxxxxxxxxx)">
              </div>
              <div class="col-md-4 col-sm-12">
                <div [ngClass]="{'help-block error' : (myForm.get('officeNumber')?.errors?.required && myForm.get('officeNumber')?.touched)
                 || (myForm.get('officeNumber')?.errors && myForm.get('officeNumber')?.value)}">
                  <span *ngIf="myForm.get('officeNumber')?.touched && myForm.get('officeNumber')?.errors?.required">Required<br /></span>
                  <span *ngIf="myForm.get('officeNumber')?.errors?.minlength">Too Short<br /></span>
                  <span *ngIf="myForm.get('officeNumber')?.errors?.maxlength">Too Long<br /></span>
                  <span *ngIf="myForm.get('officeNumber')?.errors?.invalidNumber ">The phone number should be numeric<br /></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row mt-4">
          <div class="row w-100">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label">Receiver Comment</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <input type="text" class="form-control" name="comment" formControlName="receiverComment">
            </div>
          </div>
        </div>
      </form>
    </div>
  </fieldset>
</div>
<modal #modal></modal>
