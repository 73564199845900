<div class="form-row mt-4">
  <div class="col-12 col-md-7 m-auto">
    <div data-nosnippet>
      <p class="font-1 text-center">Lodge a FreightSafe Claim</p>
      <p class="font-3 text-justify thin"> Many of FreightSafe’s clients make their claim forms publicly available, which you can select from the list below by nominating the freight provider associated with your claim. </p>
      <p class="font-3 text-justify thin"> Claim forms for all other FreightSafe clients can only be accessed from the freight carrier directly. These carriers are not available in the list below and you will need to engage directly with them to initiate your claim.</p>
    </div>
    <form [formGroup]="carrierSelection">
      <div class="col-md-12 text-right">
        <div class="form-group">
          <!--row w-100-->
          <select class="form-control" formControlName="selectedCarrier">
            <option selected disabled value="">Please choose a freight provider</option>
            <option *ngFor="let carrier of carriers" [ngValue]="carrier.Prefix" [title]="carrier.Prefix">
              {{carrier.Name}}
            </option>
          </select>
        </div>
        <div>
          <button class="btn btn-primary ml-auto js-btn-next" type="button" title="Lodge Your Claim" (click)="navigateToClaimForm()">Lodge Your Claim</button>
        </div>
      </div>
     </form>
    
    <div style="margin-top:35px;">
     
        <p class="font-3 text-left font-red-header no-margin">Contact Us</p>
        <p class="font-3 text-left no-margin" >Australia:<i> (02) 9909 6111</i></p>
        <p class="font-3 text-left no-margin" >International:<i> +61 2 9909 6111</i> </p>
        <p class="font-3 text-left no-margin" >Email:<i> info@freightsafe.com.au</i> </p>
     
     
        </div>
    

  </div>
  </div>
