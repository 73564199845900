<div class="form-row">
  <fieldset>
    <h3 class="multisteps-form__title">Excess Labels</h3>

    <form [formGroup]="excessLabelForm">

      <div class="multisteps-form__content">
        <label class="chekbox mb-0">
          <input type="checkbox" formControlName="enableLabels" value="0" name="islabelsEnable">
          <span class="checkmark"></span>
          <label>If you have additional labels please select.</label>
        </label>
      </div>
      <ng-container *ngIf="myForm.enableLabels?.value==1">
        <div class="row">
          <div class="col-md-12 col-lg-6">
            <div class="multisteps-form__content scroll-x">

              <ag-grid-angular #excessLabelGrid
                               style="height: 250px;"
                               class="ag-theme-alpine"
                               [gridOptions]="gridOptions"
                               (gridReady)="onGridReady($event)">
              </ag-grid-angular>
              <label *ngIf="myForm.excessLabels?.value?.length > 0">If you require to update the cost of a label please select the record from the table.</label>
            </div>
            <div class="multisteps-form__content">
              <button class="btn btn-primary mr-3" [disabled]="gridItemSelected" (click)="addLabel()">Add Label</button>
              <button class="btn btn-primary" [disabled]="!gridItemSelected" (click)="deleteLabel()">Delete Label</button>
            </div>
          </div>
          <div class="col-md-12 col-lg-6 pl-4">
            <div class="multisteps-form__content p-0" *ngIf="((isAddLabel || gridItemSelected) && !multipleItemsSelected)">
              <div class="form-row mt-4">
                <div class="w-100">
                  <label class="font-3" *ngIf="isAddLabel">Please fill the label number and the cost of the label </label>
                  <label class="font-3" *ngIf="gridItemSelected">Please fill the cost of the label </label>
                  <div class="form-group row">
                    <div class="col-md-5 col-sm-12 text-right">
                      <label class="control-label" for="inputLabelNumber">Label Number</label>
                    </div>
                    <div class="col-md-7 col-sm-12">
                      <input type="text" class="form-control" formControlName="excesslabelNumber" id="inputlabelNumber" name="inputPreviousClaimNumber" placeholder="Ex:- AQ54321568">
                    </div>
                    <div class="col-sm-12 text-right">
                      <span *ngIf="myForm.excesslabelNumber?.touched && myForm.excesslabelNumber?.errors?.required" class="help-block error">Required</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-row mt-3">
                <div class="w-100">
                  <div class="form-group row">
                    <div class="col-md-5 col-sm-12 text-right">
                      <label class="control-label" for="inputPreviousClaimConsignmentNumber">Cost of Label</label>
                    </div>
                    <div class="col-md-7 col-sm-12">
                      <input type="text" class="form-control" formControlName="excesslabelCost" id="inputlabelCost" name="inputlabelCost" placeholder="Ex:- 5.04">
                    </div>
                    <div class="col-sm-12 text-right">
                      <span class="help-block error" *ngIf="myForm.excesslabelCost?.errors?.invalidDecimalNumber">Label cost should be numeric<br /></span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-row mt-3">
                <div class="w-100">
                  <div class="form-group row">
                    <div class="col-md-4 col-sm-12 text-right">
                    </div>
                    <div class="col-md-4 col-sm-12">
                      <input type="button"
                             value="Save"
                             class="btn btn-md btn-primary"
                             style="background-color:#AD002B"
                             [disabled]="excessLabelForm.invalid"
                             (click)="saveExcessLabel()" />
                    </div>
                    <div class="col-md-4 col-sm-12">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </form>
  </fieldset>
</div>
<modal #modal></modal>
