<fieldset>
  <h3 class="multisteps-form__title"> Parcel Details </h3>
  <div class="multisteps-form__content">
    <form [formGroup]="parcelDetailsForm">

      <div class="form-row mt-4" *ngIf="claimant?.isInternationalClaimant?.value == 'true'">
        <div class="row w-100">
          <div class="col-md-4 col-sm-12 text-right">
            <label class="control-label">Dispatch Location</label>
          </div>
          <div class="col-md-4 col-sm-12">
            <input type="text" class="form-control" formControlName="dispatchLocation" />
          </div>
        </div>

        <div class="row w-100 mt-4">
          <div class="col-md-4 col-sm-12 text-right">
            <label class="control-label">Original Tracking Reference</label>
          </div>
          <div class="col-md-4 col-sm-12">
            <input type="text" class="form-control" formControlName="originalTrackingReference" />
          </div>
        </div>
      </div>

      <div class="form-row mt-4">
        <div class="row w-100">
          <div class="col-md-4 col-sm-12 text-right">
            <label class="control-label">Aramex Label Number</label>
          </div>
          <div class="col-md-4 col-sm-12">
            <input type="text" class="form-control" formControlName="labelNumber" />
          </div>
        </div>
      </div>

      <div class="form-row mt-4">
        <div class="row w-100">
          <div class="col-md-4 col-sm-12 text-right">
            <label class="control-label">Alias Label</label>
          </div>
          <div class="col-md-4 col-sm-12">
            <input type="text" class="form-control" formControlName="aliasLabelNumber" />
          </div>
        </div>
      </div>

      <div class="form-row mt-4">
        <div class="row w-100">
          <div class="col-md-4 col-sm-12 text-right">
            <label class="control-label">Goods Packaged in</label>
          </div>
          <div class="col-md-4 col-sm-12">
            <div class="form-control" style="background-color:#e9ecef"> {{myForm.packageType.value?.name}}</div>            
          </div>
        </div>
      </div>

      <div class="form-row mt-4">
        <div class="row w-100">
          <div class="col-md-4 col-sm-12 text-right">
            <label class="control-label">Aramex Date of Dispatch</label>
          </div>
          <div class="col-md-4 col-sm-12">
            <input type="text" class="form-control" formControlName="dateTimeSent" />
          </div>
        </div>
      </div>

      <div class="form-row mt-4">
        <div class="row w-100">
          <div class="col-md-4 col-sm-12 text-right">
            <label class="control-label">Were there any additional labels dispatched together in this shipment?</label>
          </div>
          <div class="col-md-4 col-sm-12">

            <label class="input-radio">
              Yes
              <input id="yes" type="radio" value="true" name="isAdditionalLabelsDispatched" formControlName="isAdditionalLabelsDispatched">
              <span class="checkmark"></span>
            </label>
            <label class="input-radio">
              No
              <input id="no" type="radio" value="false" name="isAdditionalLabelsDispatched" formControlName="isAdditionalLabelsDispatched">
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
      </div>

      <div class="form-row mt-4">
        <div class="w-100">
          <div class="col-12 col-md-9 m-auto important-notice" *ngIf="myForm.isAdditionalLabelsDispatched?.value=='true'">
            <p class="mb-0"><b>IMPORTANT:</b></p>
            <ul>
              <li><div>Please note that this claim will be processed only for the goods included in the listed label.</div></li>
              <li><div>Any additional labels which have been lost / damaged must have a separate online enquiry lodged with Aramex, which will result in an additional claim(s) being submitted and processed. </div></li>
              <li><div> Please only state the value of the goods lost / damaged in this parcel and on this label number, and do not include the value of goods that may be lost / damaged in other parcels in the field below. </div></li>
            </ul>
          </div>
        </div>
      </div>
    </form>
  </div>
</fieldset>
