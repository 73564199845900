//import { error } from '@angular/compiler/src/util';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { RequiredValidator, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { ModalOptions } from '../../../../models/ModalOptions';
import { AppService } from '../../../../services/common/app.service';
import { CommonService } from '../../../../services/common/common.service';
import { FormService } from '../../../../services/common/form.service';
import { ErrorHelper } from '../../../../utilities/ErrorHelper';
import { ModalComponent } from '../../../common/modal/modal.component';

@Component({
  selector: 'app-approve-claim',
  templateUrl: './approve-claim.component.html',
  styleUrls: ['./approve-claim.component.css']
})
export class ApproveClaimComponent implements OnInit, OnDestroy {
  approveForm = this.formService.approve;
  consignmentNoteNo: string = "ADD9874";
  claimNumber: string = "ADD9874";
  processingOptions: { id: number, name: string }[] = [{ id: 1, name: 'Claim to be processed by FreightSafe' }, { id: 2, name: 'Claim not to be processed by FreightSafe' }];
  warrantyRates!: { id: number, name: string }[];  //= [{ id: 1, name: 'A:$2.5($1500 Limit)' }]
  carrierPrefix!: string;
  ipAddress!: string;

  // modal properties
  @ViewChild('modal', { read: ModalComponent }) private modal!: ModalComponent;
  modalHeaderContent!: string;
  modalbodyContent: string = "";
  isHtmlContent: boolean = false;

  warrantyPaidSub!: Subscription;

  get myForm() {
    return this.approveForm.controls;
  }

  constructor(private formService: FormService, private appService: AppService,
    private commonService: CommonService, private errorHelper: ErrorHelper) { }
 

  ngOnInit(): void {
    this.carrierPrefix = this.commonService.carrierPrefix;
    this.consignmentNoteNo = this.commonService.consignmentNoteNumber ? this.commonService.consignmentNoteNumber : '';
    this.claimNumber = this.commonService.claimNumber ? this.commonService.claimNumber : '';
    this.appService.getWarrantyRates(this.carrierPrefix, this.consignmentNoteNo, this.claimNumber).subscribe(result => {
      this.warrantyRates = result.warrantyRateDTOs;
      this.myForm.consignmentNoteNo.setValue(result.consignmentNoteNo);
      this.myForm.claimNumber.setValue(result.claimNumber);
    }, error => {
      let response = this.errorHelper.formatAndDisplayError(error,false);
      if (response) {
        let options = {
          size: 'md',
          centered: true,
          isHtmlContent: true
        };
        this.modal.modalOpen(response[0], response[1], options).result.then(result => {
          window.location.href = 'https://freightsafe.com/contact/';
        });
      }
    });

    this.appService.getIpAddress().subscribe(result => {
      this.ipAddress = result.ip;
    }, error => {
      this.ipAddress = '0.0.0.0';
    });

    /* Configure validation on warrantyRates When claim Is Warranty Paid is selected */
    this.warrantyPaidSub = this.myForm.isWarrantyPaid.valueChanges.subscribe(value => {
      if (value && value == 'true') {
        this.myForm.warrantyRate.setValidators(Validators.required);
      } else {
        this.myForm.warrantyRate.clearValidators();
      }
      this.approveForm.clearValidators();
    });
  }

  ngOnDestroy(): void {
    this.warrantyPaidSub?.unsubscribe();
  }

  public Submit() {
    this.commonService.isLoading$.next(true);
    let formData = {
      ConsignmentNoteNo: this.myForm.consignmentNoteNo.value,
      CarrierPrefix: this.carrierPrefix,
      IsApproved: this.myForm.processingOption.value.id == 1 ? true : false,
      IsWarrantyPaid: this.myForm.isWarrantyPaid.value,
      WarrantyRateId: this.myForm.warrantyRate.value.id,
      Comment: this.myForm.claimantComments.value,
      ClaimNumber: this.myForm.claimNumber.value,
      IPAddress: this.ipAddress
    }

    let serializedData = JSON.stringify({ ApprovalDTO: formData });
    if (environment.showConsoleMessages) {
      console.log(serializedData);
    }

    this.appService.submitApprovalforClaim(serializedData).subscribe(result => {
      this.commonService.isLoading$.next(false);
      // modal options
      let options: ModalOptions = {
        size: 'lg',
        centered: true,
        isHtmlContent: true
      }
      this.modalHeaderContent = 'Successful'
      this.modalbodyContent = 'Your selection has been submitted to FreightSafe'
      this.modal.modalOpen(this.modalHeaderContent, this.modalbodyContent, options).result.then(() => {
        window.location.href = 'https://freightsafe.com/contact/';
      });
    }, error => {
      this.commonService.isLoading$.next(false);
      var response = this.errorHelper.formatAndDisplayError(error);
      if (response) {
        var options = {
          size: 'md',
          centered: true,
          isHtmlContent: true
        };
        this.modal.modalOpen(response[0], response[1], options);
      }

    });
  }
}
