//import { MapsAPILoader } from '@agm/core';
import { HttpClient } from '@angular/common/http';
import { Directive, ElementRef, EventEmitter, NgZone, OnInit, Output } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';



@Directive({
  selector: '[appGooglePlace]'
})
export class GooglePlaceDirective implements OnInit {

  @Output() onPlaceChanged = new EventEmitter();
  placeDictionay: { [key: string]: string } = {};
  apiLoaded: Observable<boolean>
  constructor(private elementRef: ElementRef,
    private ngZone: NgZone, httpClient: HttpClient) {
    this.apiLoaded = httpClient
      .jsonp(`https://maps.googleapis.com/maps/api/js?key=${environment.googleApiKey}&libraries=places`, 'callback')
      .pipe(map(() => true),
        catchError(() => of(false)));

  }

  ngOnInit(): void {
    this.apiLoaded.subscribe(() => {
      let autoComplete = new google.maps.places.Autocomplete(this.elementRef.nativeElement);

      autoComplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          this.placeDictionay = {};
          let place: google.maps.places.PlaceResult = autoComplete.getPlace();

          if (place) {
            this.placeDictionay['latitude'] = `${place.geometry?.location?.lat()}`;
            this.placeDictionay['longitude'] = `${place.geometry?.location?.lat()}`;

            if (place.address_components) {

              for (let i = 0; i <= place.address_components.length; i++) {
                let addressType = place.address_components[i]?.types[0];
                switch (addressType) {
                  case 'administrative_area_level_1':
                    this.placeDictionay['state'] = place.address_components[i]['short_name'];
                    break;
                  case 'postal_code':
                    this.placeDictionay['postalCode'] = place.address_components[i]['short_name'];
                    break;
                  case 'street_number':
                    this.placeDictionay['streetNumber'] = place.address_components[i]['short_name'];
                    break;
                  case 'route':
                    this.placeDictionay['street'] = place.address_components[i]['short_name'];
                    break;
                  case 'locality':
                    this.placeDictionay['city'] = place.address_components[i]['long_name'];
                    break;
                  case 'country':
                    this.placeDictionay['country'] = place.address_components[i]['long_name'];
                    break;
                  default:
                    break;
                }
              }
              this.placeDictionay['streetAddress']
                = this.placeDictionay['streetNumber'] ?
                  `${this.placeDictionay['streetNumber']} ${this.placeDictionay['street']},` : this.placeDictionay['street']
              if (place.name) {
                let index = place.formatted_address?.indexOf(place.name);
                if (index != undefined && index > -1) {
                  this.placeDictionay['address'] = place.formatted_address ? place.formatted_address.toString() : '';
                } else {
                  this.placeDictionay['address'] = place.name + ', ' + place.formatted_address;
                }
              }
            }
          }
          //console.log(this.placeDictionay);
          this.onPlaceChanged.emit(this.placeDictionay);
        });
      });
    });
  }
}
