import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AppService } from '../../../../services/common/app.service';
import { FormService } from '../../../../services/common/form.service';
import { Constant } from '../../../../utilities/Constant';

@Component({
  selector: 'app-amx-further-details',
  templateUrl: './amx-further-details.component.html',
  styleUrls: ['./amx-further-details.component.css']
})
export class AmxFurtherDetailsComponent implements OnInit {
  furtherDetailsForm = this.formService.amxfurtherDetailsForm;
  welcomeInformationForm = this.formService.amxWelcomeForm;
  customerinformationForm = this.formService.amxCustomerinformationForm;
  supportingDocumentForm = this.formService.amxSupportingDocumentForm;
  currancies: any;
  currancySub!: Subscription
  claimTypes: { id: number, name: string }[] = Constant.claimType;
  get claimant() {
    return this.customerinformationForm?.controls;
  }
  get claimantRole() {
    return this.welcomeInformationForm?.controls.claimantRole;
  }
 
  constructor(private formService: FormService, private appService: AppService) { }
  get myForm() {
    return this.furtherDetailsForm?.controls;
  }
  identity(index: any, item: any) {
    return item.Id;
  }

  ngOnInit(): void {

    this.formService.setFurtherDetailsValidators();

    if (!this.currancies) {
      this.currancySub = this.appService.getAllCurrencies().subscribe(result => {
        this.currancies = result;
        const selectedCurrancy = this.currancies.filter((cu: { id: any; }) => {
          return cu.id == this.myForm.claimCurrency?.value?.id;
        })[0];
        if (selectedCurrancy) {
          this.myForm.claimCurrency.setValue(selectedCurrancy, { onlySelf: true });
        } else {
          this.myForm.claimCurrency.setValue('', { onlySelf: true });
        }
      });
    } else {
      const selectedCurrancy = this.currancies.filter((cu: { id: any; }) => {
        return cu.id == this.myForm.claimCurrency?.value?.id;
      })[0];
      if (selectedCurrancy) {
        this.myForm.claimCurrency.setValue(selectedCurrancy, { onlySelf: true });
      } else {
        this.myForm.claimCurrency.setValue('', { onlySelf: true });
      }

    }
  }

  onClaimTypeChanged() {
    this.supportingDocumentForm.get('attachments')?.setValue([], { onlyself: true });
    this.formService.setFurtherDetailsValidators();
    this.formService.resetRequiredSupportingDocumentForm(this.supportingDocumentForm);
    this.formService.setRequiredDocumentsValidators();
  }

}
