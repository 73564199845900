import { Component, OnDestroy, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { UntypedFormGroup, PatternValidator, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged, filter, skipWhile } from 'rxjs/operators';

import { ConfigurationModel } from '../../../models/ConfigurationModel';
import { Constant } from '../../../utilities/Constant';
import { CommonService } from '../../../services/common/common.service';
import { FormService } from '../../../services/common/form.service';
import { CustomValidationService } from '../../../services/common/custom-validation.service';
import { RequiredStatus } from '../../../enums/RequiredStatus';
import { AppService } from '../../../services/common/app.service';
import { NavigationService } from '../../../services/common/navigation-service';
import { environment } from '../../../../environments/environment.qa01';
import { ModalOptions } from '../../../models/ModalOptions';
import { ModalComponent } from '../modal/modal.component';
import { ErrorHelper } from '../../../utilities/ErrorHelper';



@Component({
  selector: 'app-consignment-information',
  templateUrl: './consignment-information.component.html',
  styleUrls: ['./consignment-information.component.css']

})
export class ConsignmentInformationComponent implements OnInit, OnDestroy {
  consignmetInformationForm: UntypedFormGroup = this.formService.consignmetInformationForm;
  welcomeForm: UntypedFormGroup = this.formService.welcomeForm;
  showInitialContactDetails: boolean = false;
  showDateOfReceipt: boolean = true;
  carrierName!: string;
  carrierConfigObject!: ConfigurationModel;
  claimTypes: { id: number, name: string }[] = Constant.claimType;
  dateFormat: string = Constant.dateFormat;
  Items: {}[] = [{ name: 'Yes', value: 1 }, { name: 'No', value: 0 }]
  maxDate: NgbDate = this.maptoNgbDate({ year: 2022, month: 5, day: 1 });;
  maxDateDispatchDate!: NgbDate;
  minDate: NgbDate = this.maptoNgbDate({ year: 1900, month: 1, day: 1 });
  draftset: boolean = false;
  conNoteNumberSub!: Subscription;

  minDateInitialContact!: NgbDate;
  consignmentNoteName!: string;
  dateOfReceiptName!: string;

  isInitialContactValidationSet!: boolean;
  isGoodsDispatchDateValidationSet!: boolean;
  isGoodsReceivedDateTimeSet!: boolean;
  @ViewChild('modal') modal!: ModalComponent;

  public get myForm() {
    return this.consignmetInformationForm?.controls;
  }

  /* Subscription Variables */
  private claimTypeSub!: Subscription;
  private dispatchDateSub!: Subscription;
  private receivedDateSub!: Subscription;

  constructor(private commonService: CommonService, private formService: FormService, private ngbCalendarService: NgbCalendar,
    private customerValidatorService: CustomValidationService, private cd: ChangeDetectorRef, private appService: AppService,
    private navigationService: NavigationService,
    private errorHelper: ErrorHelper) { }

  ngOnInit(): void {
   
    this.carrierConfigObject = this.commonService.getCarrierConfigObject();
    let defaultValues = this.formService.getCarrierSpecificDefaultValues(this.carrierConfigObject, 'claimType');
    this.claimTypes = defaultValues ? defaultValues as { id: number, name: string }[] : Constant.claimType;

    if (!this.myForm.consignmentNoteNo.value && this.commonService.consignmentNoteNumber) {
      this.myForm.consignmentNoteNo.setValue(this.commonService.consignmentNoteNumber);
      this.onBlur();
    }

    this.carrierName = this.commonService.carrierName;
    this.showInitialContactDetails = this.commonService.checkCustomFields('initialContactDetails');
    this.consignmentNoteName = this.getDisplayNameCustomField('consignmentNoteNo', 'Consignment Number');
    this.dateOfReceiptName = this.getDisplayNameCustomField('goodsReceivedDateTime', 'Date of Receipt')


    /* Start of Date Manupilation */
    this.myForm.goodsDispatchedDateTime.clearValidators();
    this.myForm.goodsDispatchedDateTime.updateValueAndValidity({ onlySelf: true });
    this.myForm.goodsReceivedDateTime.clearValidators();
    this.myForm.goodsReceivedDateTime.updateValueAndValidity({ onlySelf: true });
    this.myForm.initialContactDate.clearValidators();
    this.myForm.initialContactDate.updateValueAndValidity({ onlySelf: true });
    this.isInitialContactValidationSet = this.setDateValidation(this.carrierConfigObject, 'initialContactDate');
    this.isGoodsDispatchDateValidationSet = this.setDateValidation(this.carrierConfigObject, 'goodsDispatchedDateTime');
    this.isGoodsReceivedDateTimeSet = this.setDateValidation(this.carrierConfigObject, 'goodsReceivedDateTime');

    this.consignmetInformationForm.updateValueAndValidity();
    this.showDateOfReceipt = this.formService.setGoodsReceivedDate(this.myForm?.claimType.value, this.carrierConfigObject);
    this.consignmetInformationForm.updateValueAndValidity();
    this.cd.detectChanges();
    this.maxDate = this.ngbCalendarService.getToday();
    this.maxDateDispatchDate = this.initializeMaxDispatchDate()

    if (this.myForm.goodsDispatchedDateTime?.value) {
      let dateValue = this.maptoNgbDate(this.myForm.goodsDispatchedDateTime?.value);
      if (!dateValue.after(this.ngbCalendarService.getToday())) {
        this.minDate = dateValue;
      }
    }

    if (!this.showDateOfReceipt) {
      this.maxDateDispatchDate = this.ngbCalendarService.getToday();
    }
    /* ClaimType Subscription */
    this.claimTypeSub = this.myForm?.claimType.valueChanges.subscribe(value => {
      this.showDateOfReceipt = this.formService.setGoodsReceivedDate(value, this.carrierConfigObject);
      this.formService.updateDamageLocationBasedOnClaimType(this.carrierConfigObject);
      this.formService.updateValueandValidityOfFormControls(this.formService.supportingDocumentForm);

      if (!this.showDateOfReceipt) {
        this.maxDateDispatchDate = this.ngbCalendarService.getToday();
      } else {
        if (this.myForm.goodsDispatchedDateTime?.value) {
          let dateValue = this.maptoNgbDate(this.myForm.goodsDispatchedDateTime?.value);
          if (!dateValue.after(this.ngbCalendarService.getToday())) {
            this.minDate = dateValue;
          }
        }
      }

    });

    /* Dispatch date Subscription */
    if (this.isGoodsDispatchDateValidationSet) {
      this.dispatchDateSub = this.myForm.goodsDispatchedDateTime.valueChanges.pipe(distinctUntilChanged())
        .subscribe(value => {
          if (value == null || value == '') {
            this.initializeMaxDispatchDate();
            this.minDate = this.maptoNgbDate({ year: 1900, month: 1, day: 1 });
          } else {
            let dateValue = this.maptoNgbDate(value);
            if (!dateValue.after(this.ngbCalendarService.getToday())) {
              this.minDate = dateValue;
            }

          }
          this.myForm.goodsDispatchedDateTime.updateValueAndValidity();
          this.cd.detectChanges();
        });
    }
    /* Received Date Subscription */
    if (this.isGoodsReceivedDateTimeSet) {
      this.receivedDateSub = this.myForm.goodsReceivedDateTime.valueChanges.pipe(distinctUntilChanged())
        .subscribe(value => {
          if (value == null || value == '') {
            this.maxDate = this.ngbCalendarService.getToday();
            this.maxDateDispatchDate = this.initializeMaxDispatchDate();
          } else {
            this.maxDateDispatchDate = this.maptoNgbDate(value);
          }
          this.myForm.goodsReceivedDateTime.updateValueAndValidity();
          this.cd.detectChanges();
        });
    }
    this.conNoteNumberSub = this.myForm.consignmentNoteNo.valueChanges.pipe(distinctUntilChanged())
      .subscribe(value => {
        if (value == null || value.trim() == '') {
          this.commonService.isDraftClaim$.next(false);
          this.draftset = false;
        } else if (!this.draftset) {
          this.commonService.isDraftClaim$.next(true);
          this.draftset = true;
        }
      });


    /* Disable consignment note number if a draft is loaded. */
    if (this.welcomeForm.get('draftClaimId')?.value > 0) {
      this.myForm.consignmentNoteNo.disable({ onlySelf: true });
      this.cd.detectChanges();
    } else {
      this.myForm.consignmentNoteNo.enable({ onlySelf: true });
      this.cd.detectChanges();
    }

  }

  ngOnDestroy() {
    this.claimTypeSub?.unsubscribe();
    this.dispatchDateSub?.unsubscribe();
    this.receivedDateSub?.unsubscribe();
    this.conNoteNumberSub?.unsubscribe();
  }

  public getDisplayNameCustomField(fieldName: string, defaultValue: string): string {
    return this.commonService.getCustomFieldDisplayName(fieldName, defaultValue);
  }

  private maptoNgbDate(date: { year: number, month: number, day: number }) {
    return new NgbDate(date.year, date.month, date.day);
  }

  private initializeMaxDispatchDate(): NgbDate {
    return this.myForm.goodsReceivedDateTime?.value ?
      this.maptoNgbDate(this.myForm.goodsReceivedDateTime?.value) : this.ngbCalendarService.getToday();
  }
  //the following carrier list is suspended from creating a linked claim at claim form level 
  public isCarrierInLinkingSuspendedList(carrierName: string):boolean {
    const items = Constant.linkedClaimSuspendedCarriers.split(',').map(item => this.commonService.getCarrierNameByPrefix(item.trim()));
    return items.includes(carrierName);
  }

  //public onDispatchDateSelect(selectedDate: any) {
  //  console.log(selectedDate);
  //  this.minDate = selectedDate;
  //}

  //public onGoodsReceivedDateSelect(selectedDate: any) {
  //  console.log(selectedDate);
  //  this.maxDateDispatchDate = selectedDate;
  //}


  public setDateValidation(carrierConfigObject: ConfigurationModel, controlName: string) {
    // check the config validation value before setting.
    let result = this.formService.checkForCustomFiledValidators(carrierConfigObject, controlName);
    if (result == false) {
      return false;
    }
    this.consignmetInformationForm.get(controlName)?.setValidators(Validators.compose([Validators.required, this.customerValidatorService.datePatternValidator(),
    this.customerValidatorService.dateValidator()]));
    return true;
  }

   public onBlur() {
    if (this.myForm.consignmentNoteNo.value && this.myForm.consignmentNoteNo.value.trim()) {
      this.commonService.isLoading$.next(true);
      this.appService.getUbindDataFromConsignmentNoteNumber(this.myForm.consignmentNoteNo.value, this.commonService.carrierName)
        .pipe(distinctUntilChanged())
        .subscribe(response => {
          this.commonService.isLoading$.next(false);
          if (environment.showConsoleMessages) {
            console.log(response);
          }
          const bankComponent = this.carrierConfigObject.navigation?.filter(com => com.componentName === Constant.claimFormComponentNames.Bank)[0];
          if (response) {
            if (!bankComponent?.isCarrierSepcific) {
              this.navigationService.setComponentVisibility(Constant.claimFormComponentNames.Bank, this.carrierConfigObject, response.IsEligible);
              if (response.IsEligible && this.isCarrierInLinkingSuspendedList(response.CarrierName)) {
                response.IsEligible = false;
              }
              this.myForm.isFreightInsureClaimRequired.setValue(response.IsEligible.toString());
              this.myForm.policyCarrierPrefix.setValue(response.PolicyCarrierPrefix);
              if (response.IsEligible) {
                this.formService.addCarrierSpecificValidationByComponent(this.carrierConfigObject, Constant.claimFormComponentNames.Bank);

              } else {
                this.formService.removeValidationByComponentName(Constant.claimFormComponentNames.Bank);
              }
            } else {              
              if (response.IsEligible && this.isCarrierInLinkingSuspendedList(response.CarrierName)) {
                response.IsEligible = false;
              }
              this.myForm.isFreightInsureClaimRequired.setValue(response.IsEligible.toString());
              this.myForm.policyCarrierPrefix.setValue(response.PolicyCarrierPrefix);
            }
          }
        },
          (errorResponse) => {
            this.commonService.isLoading$.next(false);
            let error = this.errorHelper.formatAndDisplayError(errorResponse);
            if (error) {
              this.showInformation(error[0], error[1]);
              if (environment.showConsoleMessages) {
                console.log(error[0]);
                console.log(error[1]);
              }
            }
          }
        );
    }
  }

  showInformation(headerContent: string, bodyContent: string) {
    const modalOptions: ModalOptions = {
      animation: true,
      centered: true,
      size: 'md',
      showConfirmationButtons: false,
      isHtmlContent: true,
      showOkayButton: true
    }
    this.modal.modalOpen(headerContent, bodyContent, modalOptions);
  }




}

