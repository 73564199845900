import { Component, ElementRef, OnInit, Output, ViewChild, EventEmitter, Input } from '@angular/core';


@Component({
  selector: 'fileUploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.css']
})
export class FileUploaderComponent implements OnInit {
  @ViewChild('fileUpload') fileUploader!: ElementRef;
  @Output() selectedfiles: EventEmitter<any> = new EventEmitter();
  @Input() enableMultiple: boolean = false;
  @Input() showAddButton: boolean = false;
  showForDevice!: boolean;
  constructor() { }

  ngOnInit(): void {
    
    
    
  }

  public onClick() {
    if (this.fileUploader) {
      let element = this.fileUploader.nativeElement;
      element.click();
    }
  }

  onFileSelect(event: any) {
    let files:File[] = Array.from(event?.target?.files)
    if (files) {
      this.selectedfiles.emit(files);
      event.target.value = '';
    }
  }

 
}
