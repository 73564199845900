export const environment = {
  production: false,
  rootApiUrl: 'https://fsclaims-claimform-func-qa-ae01.azurewebsites.net/',
  claimPostUrl: 'https://fsclaims-webapi-apps-qa-ae01.azurewebsites.net',
  apiKey: '7C880BE394BA4B58BE3B7EA96AF928QA',
  code: 'UxdXo11E1xGFYiZEx2rQ7toKTKYOeEMR3016980nH0IXjB44r1HCNA==',
  amxApiKey: '93e8463f7e4643c989eab7b71fa5f1a3QA',
  googleApiKey: 'AIzaSyBx7nE9_BEruAbATcEiPkzlshvBp5l7TLU',
  showConsoleMessages: true,
  s3Bucket: 'freightsafedocuments.developer',
  appVersion: 1.01,
  ubindApiUrl: 'https://fsclaims-ubindapi-apps-qa-ae01.azurewebsites.net',
  ubindApiKey: '7C880BE394BA4B58BE3B7EA96AF928DEVUBIND'
};
