<fieldset>
  <h3 class="multisteps-form__title"> Required Supporting Information </h3>
  <div class="multisteps-form__content">
    <form [formGroup]="supportingDocumentForm" class="amx-supporting">

      <div div class="form-row mt-4">
        <div class="w-100">
          <div class="col-12 col-md-9 m-auto" *ngIf="supportingDocumentForm.invalid">
            <div class="important-notice" *ngIf="customer.customerType.value!=0">
              To verify the actual cost of the goods subject to this claim please supply the following documents.
              <ul>
                <ng-container *ngFor="let doc of documentTypes">
                  <li *ngIf="doc.requiredStatus==0 && doc.fileAdded ==false">{{doc?.name}}</li>
                </ng-container>
              </ul>
            </div>
          </div>
          <div class="col-12 col-md-9 m-auto mt-3" *ngIf="customer.customerType.value!=0">
            <div class="important-notice mt-3">
              <p class="mb-0"><b class="font-weight-bold">IMPORTANT:</b></p>
              <ul>
                <li> <div>If only a sales invoice is provided, your claim will be paid out at a maximum of 35% of the sales invoice value, exclusive of GST and subject to the Aramex Standard Warranty Conditions.</div></li>
                <li> <div>If a supplier’s invoice is provided, your claim will be paid out at a maximum of 100% of your cost, exclusive of GST and subject to the Aramex Standard Warranty Conditions.             </div></li>
              </ul>
            </div>
          </div>
          <div class="col-12 col-md-9 m-auto" *ngIf="customer.customerType.value==0 && supportingDocumentForm.invalid">
            <div class="important-notice mt-3">
              <p class="mb-0"><b class="font-weight-bold">IMPORTANT:</b></p>
              <ul>
                <li><div>At least one supporting document should be submitted (Purchase receipt / retail tax invoice / eBay sales invoice or similar is prefered)</div></li>
              </ul>
            </div>
          </div>

        </div>
      </div>

      <div div class="form-row mt-4" *ngIf="myForm.documentType.value?.information">
        <div class="w-100">
          <div class="col-12 col-md-9 m-auto">
            <div class="important-notice" [innerHtml]="myForm.documentType.value?.information"></div>
          </div>
        </div>
      </div>
      <div div class="form-row mt-4">
        <div class="w-100">
          <div class="col-12 col-md-9 m-auto">
            <div class="progress">
              <div [ngClass]="supportingDocumentProgressBar" role="progressbar" aria-valuemin="0" aria-valuemax="100" [ngStyle]="progressBarValueStyle">
                {{myForm.uploadedTotalFileSize.value?.toFixed(2) +"MB"}}
              </div>
            </div>
            <div> {{(27 - myForm.uploadedTotalFileSize.value).toFixed(2)}}MB free of 27MB </div>
          </div>
        </div>
      </div>
      <div div class="form-row mt-4">
        <div class="col-12 col-md-9 m-auto p-0">
          <div class="form-group row">
            <div class="d-flex w-100 pl-4 ml-1">
              <select class="form-control mr-3" formControlName="documentType" id="dropDocumentType" name="dropDocumentType">
                <option *ngFor="let docType of documentTypes" [ngValue]="docType">{{docType?.name}}</option>
              </select>

              <fileUploader (selectedfiles)="getSelectedFiles($event)"
                            [enableMultiple]="allowMultipleUploads"></fileUploader>
              <button class="btn btn-camera btn-primary ml-3" (click)="openCamera(allowMultipleUploads)"><i data-icon="h" class="camera-retro"></i></button>

            </div>
          </div>
          <div class="important-notice" *ngIf="!showSnapButton">
            Maximum file size is 27MB for this device.
          </div>
        </div>
      </div>

      <div *ngFor="let uploads of uploadedFiles; let indexOfDocType = index;" class="col-12 col-md-9 m-auto pl-2 pr-2">
        <div class="w-100">
          <div class="uploaded-files mt-3">
            <i class="icon-clip"></i> {{uploads.documentType.name}} -
            <div *ngFor="let file of uploads.files; let indexOfFile =index;" (click)="openFile(file)">
              <i class="icon-file-image-o"></i> {{file.name}}
              <i class="icon icon-cancel-circled" (click)="removeFile(indexOfFile,indexOfDocType);">
              </i>
            </div>
            <div class="upload-more">
              <fileUploader (selectedfiles)="getSelectedFiles($event,uploads.documentType)"
                            [enableMultiple]="allowMultipleUploads" [showAddButton]="true" title="Additional Documents"></fileUploader>
              <!--<i class="icon icon-cancel-circled" (click)="openCamera(uploads.documentType.multiple);"></i>-->
              <button *ngIf="showSnapButton" title="Additional Picture" class="btn btn-primary btn-attachment btn-attachment-camera" (click)="openCamera(uploads.documentType.multiple,uploads.documentType)"><i data-icon="h" class="camera-retro"></i></button>
            </div>
          </div>
        </div>
      </div>

    </form>
  </div>
</fieldset>
<modal #modal></modal>
