import { Component, OnInit, OnDestroy, TemplateRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppService } from '../../../services/common/app.service';
import { CommonService } from '../../../services/common/common.service';
import { FormService } from '../../../services/common/form.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { error } from 'protractor';
import { ModalComponent } from '../modal/modal.component';
import { ModalOptions } from '../../../models/ModalOptions';
import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { CustomValidationService } from '../../../services/common/custom-validation.service';

@Component({
  selector: 'app-bank-details',
  templateUrl: './bank-details.component.html',
  styleUrls: ['./bank-details.component.css']
})
export class BankDetailsComponent implements OnInit, OnDestroy {

  bankInformationForm = this.formService.bankInformationDetailForm;
  currancies: any;
  currancySub!: Subscription
  isInternationalClaimantSub!: Subscription
  showIsInternationalClaiamnt = false;
  showbsbNumber = true;
  showInternationalBankDetails = false
  modalHeaderContent!: string;
  modalbodyContent!: string;
  showModalButtons: boolean = false;
  showBSBButton: boolean = false;
  isHtmlContent: boolean = false;
  showPaymentNote: boolean = false;
  showbsbInforModalButton: boolean = false;
  paymentNote?: string;
  validBSBEntered: boolean = false;

  @ViewChild('modal') modal!: ModalComponent;
  bsbNumberInformation!: BsbInformation | null;

  constructor(private formService: FormService, private appService: AppService, private commonService: CommonService,
     private cd: ChangeDetectorRef) {
  }

  //identity(index: any, item: any) {
  //  return item.Id;
  //}

  get myForm() {
    return this.bankInformationForm.controls;
  }

  ngOnInit(): void {
    if (!this.currancies) {
      this.currancySub = this.appService.getAllCurrencies().subscribe(result => {
        this.currancies = result;
        let selectedCurrancy = this.currancies.filter((cu: { id: any; }) => {
          return cu.id == this.myForm.accountCurrency?.value?.id;
        })[0];
        if (selectedCurrancy) {
          this.myForm.accountCurrency.setValue(selectedCurrancy, { onlySelf: true });
        } else {
          this.myForm.accountCurrency.setValue('', { onlySelf: true });
        }
      });
    } else {
      let selectedCurrancy = this.currancies.filter((cu: { id: any; }) => {
        return cu.id == this.myForm.accountCurrency?.value?.id;
      })[0];
      if (selectedCurrancy) {
        this.myForm.accountCurrency.setValue(selectedCurrancy, { onlySelf: true });
      } else {
        this.myForm.accountCurrency.setValue('', { onlySelf: true });
      }
    }

    this.showIsInternationalClaiamnt = this.commonService.checkCustomFields('isInternationalClaimant');
    this.showInternationalBankDetails = this.myForm.isInternationalClaimant.value == 'true' && this.showIsInternationalClaiamnt ? true : false;
    this.showPaymentNote = this.commonService.checkCustomFields('paymentNote');
    this.paymentNote = this.commonService.getCustomFieldCarrierSpecificMessage('paymentNote');


    this.isInternationalClaimantSub! = this.myForm.isInternationalClaimant.valueChanges.subscribe(value => {
      if (value == 'true') {
        this.validBSBEntered = false;
        this.showbsbInforModalButton = false;
        this.openConfirmation();
      } else {
        this.showInternationalBankDetails = false;
        this.setBSBRelatedValidators();
        this.updateValidityOfInternationalBankDetails();
      }
      this.cd.detectChanges();
    });
    /* This conditon checks if the claimant is international and the carrier supports international claimants EX:- BE */
    if (this.myForm.isInternationalClaimant.value == 'true' && this.showIsInternationalClaiamnt) {
      this.removeBSBValidation();
    } else {
      this.setBSBRelatedValidators();
      this.updateValidityOfInternationalBankDetails();
    }
  }

  ngOnDestroy(): void {
    this.currancySub?.unsubscribe();
    this.isInternationalClaimantSub?.unsubscribe();
  }

  openConfirmation() {
    let modalOptions: ModalOptions = {
      animation: true,
      centered: true,
      size: 'xl',
      isHtmlContent: false,
      showConfirmationButtons: true,
      showOkayButton: false
    }

    this.modalHeaderContent = 'Important';
    this.modalbodyContent = 'This selection is only for customers who do not have a domestic bank account to facilitate claim payments. By selecting this option you are consenting to International transaction fees, which will be deducted from the final payment figure, should your claim be approved.'

    this.modal.modalOpen(this.modalHeaderContent, this.modalbodyContent, modalOptions).result.then((value) => {
      if (value == 'confirm') {
        this.myForm.bsbNumber.setValue('');
        this.removeBSBValidation();
        this.showInternationalBankDetails = true;
        this.cd.detectChanges();

      } else if (value == 'cancel') {
        this.myForm.isInternationalClaimant.setValue('false');
        this.showInternationalBankDetails = false;
        this.cd.detectChanges();

      }
    });
  }

  onBsbNumberChanged(bsbNumber: string, showError: boolean) {
    if (bsbNumber && bsbNumber.length == 6) {
      this.commonService.isLoading$.next(true);
      let bsbCheck = this.appService.getBsbInformation(bsbNumber).subscribe(result => {
        if (result) {
          this.bsbNumberInformation = result;
          this.myForm.bsbInformation.setValue(this.bsbNumberInformation);
          this.showbsbInforModalButton = true;
          this.validBSBEntered = true;
          this.setBSBProperties();
          this.myForm.bsbNumber.updateValueAndValidity();
          this.commonService.isLoading$.next(false);
        } else {
          this.bsbNumberInformation = null;
          this.myForm.bsbInformation.setValue(this.bsbNumberInformation);
          this.showbsbInforModalButton = false;
          this.validBSBEntered = false;
          this.setBSBProperties();
          this.commonService.isLoading$.next(false);
          if (showError) {
            let modalOptions: ModalOptions = {
              animation: true,
              centered: true,
              size: 'md',
              showConfirmationButtons: false,
              isHtmlContent: true,
              showOkayButton: true
            }
            this.modalHeaderContent = 'Attention';
            this.modalbodyContent = 'The BSB number is invalid, Please check the BSB number and resubmit the claim form.';
            this.modal.modalOpen(this.modalHeaderContent, this.modalbodyContent, modalOptions);
          }
        }

      }, error => {
        this.bsbNumberInformation = null;
        this.showbsbInforModalButton = false;
        this.commonService.isLoading$.next(false);
      }, () => {
        bsbCheck.unsubscribe();
      });
    }
    else {
      this.bsbNumberInformation = null;
      this.showbsbInforModalButton = false;
    }
  };

  openBSBInformation() {
    let modalOptions: ModalOptions = {
      animation: true,
      centered: true,
      size: 'md',
      showConfirmationButtons: false,
      isHtmlContent: true,
      showOkayButton: true
    }
    if (this.bsbNumberInformation) {
      this.modalbodyContent = "<strong>Bank Code</strong> : " + this.bsbNumberInformation?.bankCode +
        "<br/> <strong>BSB Name</strong> : " + this.bsbNumberInformation?.bsbName +
        "<br/> <strong>BSB Address</strong> : " + this.bsbNumberInformation?.bsbAddress +
        "<br/> <strong>City</strong> : " + this.bsbNumberInformation?.city +
        "<br/> <strong>State</strong> : " + this.bsbNumberInformation?.state +
        "<br/> <strong>Postal Code</strong> : " + this.bsbNumberInformation?.postalCode + "";

    } else {
      this.modalbodyContent = "No BSB information found";
    }
    this.modalHeaderContent = 'BSB Information';
    this.modal.modalOpen(this.modalHeaderContent, this.modalbodyContent, modalOptions);

  }

  validateBSB(): ValidatorFn | null {
    return (control: AbstractControl): { [key: string]: boolean | string } | null => {
      if (!control) {
        return null;
      }
      if (control.value?.length != 6) {
        return null;
      }
      if (!this.validBSBEntered) {
        return { invalidBSB: true };
      }
      return null;
    }
  }

  private setBSBRelatedValidators() {
    if (this.myForm.bsbNumber?.value) {
      this.myForm.bsbNumber.markAsDirty();
      if (this.myForm.bsbInformation.value) {
        this.bsbNumberInformation = this.myForm.bsbInformation.value;
        this.showbsbInforModalButton = true;
        this.validBSBEntered = true;
      } else {
        this.onBsbNumberChanged(this.myForm.bsbNumber?.value, false);
      }
    } else {
      this.resetBSBValidation();
    }
  }

  private setBSBProperties() {
    this.myForm.bsbNumber.markAllAsTouched();
    let arrayOfValidators = this.formService.getALLBSBValidatorsToArray();
    arrayOfValidators.push(this.validateBSB());
    this.myForm.bsbNumber.setValidators(arrayOfValidators);
    this.myForm.bsbNumber.updateValueAndValidity();
  }

  private removeBSBValidation() {
    this.myForm.bsbNumber.clearValidators();
    this.myForm.bsbNumber.updateValueAndValidity();
  }

  private resetBSBValidation() {
    let arrayOfValidators = this.formService.getALLBSBValidatorsToArray();
    arrayOfValidators.push(this.validateBSB());
    this.myForm.bsbNumber.setValidators(arrayOfValidators);
    if (!this.myForm.bsbNumber.pristine) {
      this.myForm.bsbNumber.markAsUntouched();
    }
    this.myForm.bsbNumber.markAsPristine();
  }

  private updateValidityOfInternationalBankDetails() {

    this.myForm.bankAddress.updateValueAndValidity()
    this.myForm.accountAddress.updateValueAndValidity()
    this.myForm.bankABA.updateValueAndValidity()
    this.myForm.bankSwift.updateValueAndValidity()
    this.myForm.accountCurrency.updateValueAndValidity()

  }
}



export interface BsbInformation {
  bankCode?: '',
  bsbName?: '',
  bsbAddress?: '',
  city?: '',
  state?: '',
  postalCode?: ''
}


