
<div [ngClass]="headerSectionStyle">
  <h4 [ngClass]="headerStyle" id="modal-basic-title">Capture/Record</h4>
  <button class="close" aria-label="Close" (click)="modalClose()">
    <span aria-hidden="true">×</span>
  </button>
</div>

<div class="video-container" *ngIf="!error">
  <!--<video class="video-stream" [class.show]="!isCaptured || !isVideo" [class.hide]="isCaptured || isVideo" #video id="video" [width]="WIDTH" [height]="HEIGHT"></video>
  <video [class.show]="isVideo" [class.hide]="!isVideo" #videoPreview id="videoPreview" [width]="WIDTH" [height]="HEIGHT"
         controls></video>-->
  <video class="video-stream" [hidden]="isCaptured || isVideo" #video id="video" [width]="WIDTH" [height]="HEIGHT"></video>
  <video [hidden]="!isVideo" #videoPreview id="videoPreview" [width]="WIDTH" [height]="HEIGHT"
         controls></video>
  <canvas [class.show]="isCaptured" [class.hide]="!isCaptured" #canvas id="canvas" [width]="WIDTH" [height]="HEIGHT"> </canvas>
</div>
<!--<div class="timer">
  <span id="hours"> {{hoursToDday}} </span>Hrs
  <span id="minutes"> {{minutesToDday}} </span>Min
  <span id="seconds"> {{secondsToDday}} </span>S
</div>-->
<div *ngIf="isStarted">
  {{counter | formatTime}}
  <label>Recording</label>
</div>
<div class="snap-list" style="max-height: 100%">
  <div class="pr-3" *ngFor="let srcObj of captures; let idx = index" (click)="setPhoto(srcObj)">
    <div *ngIf="srcObj.isImage">
      <img [src]="srcObj.src" height="50" />
      <i class="icon icon-cancel-circled" (click)="removePhoto(idx);">
      </i>
    </div>

    <div *ngIf="!srcObj.isImage">
      <img [src]="srcObj.imageUrl" height="50" />
      <i class="icon icon-cancel-circled" (click)="removePhoto(idx);">
      </i>
    </div>
  </div>
</div>

<div class="snap-container">
  <div *ngIf="!error">
    <button class="btn btn-primary mr-2" *ngIf="!isCaptured && !isStarted &&!isVideo && (allowMultipleUploads || fileCount==0)" (click)="capture()"><i data-icon="h" class="camera-retro"></i> Snap Photo</button>
    <button class="btn btn-primary mr-2" *ngIf="!isVideo && !isStarted &&!isCaptured && (allowMultipleUploads || fileCount==0)" (click)="captureVideo()"><i data-icon="h" class="camera-retro"></i> Start Video</button>
    <button class="btn btn-primary mr-2" *ngIf="isStarted" (click)="stop()"><i data-icon="h" class="camera-retro"></i> Stop Video</button>
    <button class="btn btn-primary mr-2" *ngIf="isCaptured || isVideo && (allowMultipleUploads || fileCount==0)" (click)="removeCurrent()">Take Another</button>
    <button class="btn btn-primary" *ngIf="captures.length > 0 && !isStarted" (click)="upload()"><i class="iocn icon-upload"></i>  Upload</button>
  </div>
  <div *ngIf="error" class="text-danger">
    {{ error }}
  </div>
</div>
<div [ngClass]="footerStyle">
  <button type="button" class="btn btn-primary mr-2" (click)="modalClose()">Cancel</button>
</div>





