<div class="form-row">
  <fieldset>
    <h3 class="multisteps-form__title">Claim Information</h3>
    <div class="multisteps-form__content">
      <form [formGroup]="claimInformationForm">
        <div class="form-row mt-4">
          <div class="form-group row">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label" for="txtGoodsDetails"> {{descriptionOfGoodsName}}<span *ngIf="myForm.descriptionOfGoods?.errors?.required">*</span></label>
            </div>
            <div class="col-md-4 col-sm-12">
              <textarea class="form-control" formControlName="descriptionOfGoods" id="txtGoodsDetails" name="descriptionOfGoods" [placeholder]="descriptionOfGoodsName" rows="4" maxlength="1000"></textarea>
            </div>
            <div class="col-md-4 col-sm-12">
              <span *ngIf="myForm.descriptionOfGoods?.touched && myForm.descriptionOfGoods?.errors?.required" class="help-block error">Required</span>
            </div>
          </div>
        </div>
        <div class="form-row mt-4">
          <div class="form-group row">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label" for="txtDetailsOfLossOrDamage" style="padding-left:0">{{descriptionOfLossOrDamageName}}<span *ngIf="myForm.descriptionOfLossOrDamage?.errors?.required">*</span></label>
            </div>
            <div class="col-md-4 col-sm-12">
              <textarea class="form-control" formControlName="descriptionOfLossOrDamage" id="txtDetailsOfLossOrDamage" name="txtDetailsOfLossOrDamage" [placeholder]="descriptionOfLossOrDamageName" rows="4" maxlength="1000"></textarea>

            </div>
            <div class="col-md-4 col-sm-12">
              <span *ngIf="myForm.descriptionOfLossOrDamage?.touched && myForm.descriptionOfLossOrDamage?.errors?.required" class="help-block error">Required</span>
            </div>
          </div>
        </div>
        <div class="form-row mt-4" *ngIf="claimType?.id != 1">
          <div class="form-group row">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label" for="radioIsGoodsRepairable">Are Goods Repairable?</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <div>
                <label class="input-radio">
                  Yes
                  <input type="radio" formControlName="isGoodsRepairable" checked="checked" value="1" name="isGoodsRepairable">
                  <span class="checkmark"></span>
                </label>
                <label class="input-radio">
                  No
                  <input type="radio" formControlName="isGoodsRepairable" value="0" name="isGoodsRepairable">
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
            <div class="col-md-4 col-sm-12">
              <span *ngIf="myForm.isGoodsRepairable?.touched && myForm.isGoodsRepairable?.errors?.required" class="help-block error">Required</span>
            </div>
          </div>
        </div>
        <div class="form-row mt-4" *ngIf="claimType?.id != 1">
          <div class="form-group row">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label" for="inputClaimValue">Current Location of Damaged Goods <span *ngIf="myForm.damagedGoodsLocation?.errors?.required">*</span></label>
            </div>
            <div class="col-md-4 col-sm-12">
              <input type="text" formControlName="damagedGoodsLocation" class="form-control" id="inputDamagedGoodsLocation" name="inputDamagedGoodsLocation" placeholder="Current Location of Damaged Goods">

            </div>
            <div class="col-md-4 col-sm-12">
              <span *ngIf="myForm.damagedGoodsLocation?.touched && myForm.damagedGoodsLocation?.errors?.required" class="help-block error">Required</span>
            </div>
          </div>
        </div>
        <div class="form-row mt-4">
          <div class="form-group row">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label d-inline" for="inputClaimValue" [innerHtml]="claimValueFieldDisplayName"> </label>
              <!--<label>&nbsp;<span *ngIf="myForm.claimValue.errors?.required">*</span></label>-->
            </div>
            <div class="col-md-4 col-sm-12">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                </div>
                <input type="text" class="form-control" formControlName="claimValue" id="inputClaimValue" name="inputClaimValue" placeholder="Amount" min="0.01" max="999999999999">
              </div>
            </div>
            <div class="col-md-4 col-sm-12">
              <div [ngClass]="{'help-block error' : (myForm.claimValue.errors?.required && myForm.claimValue.touched)
                   ||(myForm.claimValue.errors && myForm.claimValue.value)}">
                <span *ngIf="myForm.claimValue.touched && myForm.claimValue.errors?.required">Required<br /></span>
                <span *ngIf="myForm.claimValue.errors?.invalidDecimalNumber">Claim value should be numeric<br /></span>
                <span *ngIf="myForm.claimValue.errors?.canNotBeZero">Claim value can not be 0<br /></span>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </fieldset>
</div>
