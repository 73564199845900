<div>
  <fieldset>
    <h3 class="multisteps-form__title"> Sender Details </h3>
    <div class="multisteps-form__content">
      <form [formGroup] ="senderDetailsForm">
        <div formGroupName="sender">
          <div class="form-row mt-4">
            <div class="w-100">
                <div class="form-group row">
                  <div class="col-md-4 col-sm-12 text-right">
                    <label class="control-label" for="inputSender">Name <span *ngIf="sender.get('name')?.errors?.required">*</span></label>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <input type="text" class="form-control" formControlName="name" id="inputSender" name="name" placeholder="Name" maxlength="35">
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <span *ngIf="sender.get('name')?.touched && sender.get('name')?.errors?.required " class="help-block error">Required</span>
                  </div>
                </div>
            </div>
          </div>
          <div class="form-row mt-4">
            <div class="w-100">
                <div class="form-group row" >
                  <div class="col-md-4 col-sm-12 text-right">
                    <label class="control-label" for="inputSenderAddress">Pickup Address <span *ngIf="sender.get('address')?.errors?.required">*</span></label>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <input type="text" class="form-control" formControlName="address" autocomplete="off" id="inputSenderAddress" appGooglePlace (onPlaceChanged)="mapAddressValuesToControls($event)" name="inputSenderAddress" placeholder="Pickup Address" googleplace="sender"  maxlength="350">
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <span *ngIf="sender.get('address')?.touched && sender.get('address')?.errors?.required" class="help-block error">Required</span>
                    <span *ngIf="sender?.errors?.addressNotSelected" class="help-block error">Address should be selected from the list</span>
                    <span *ngIf="showInfoMessage" class="help-block text-info pl-4"><i class="icon-info-circled"></i>Please, search and select the address</span>

                  </div>
                </div>
            </div>
          </div>
          <div class="form-row mt-4">
            <div class="w-100">
                <div class="form-group row" >
                  <div class="col-md-4 col-sm-12 text-right">
                    <label class="control-label" for="inputSenderStreetAddress">Pickup Street <span *ngIf="sender.get('addressLine1')?.errors?.required">*</span></label>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <input type="text" class="form-control" formControlName="addressLine1" id="inputSenderStreetAddress" name="inputSenderStreetAddress" placeholder="Pickup Street Address" maxlength="50"> <!--[readonly]="sender.get('addressLine1')?.errors?.required"-->
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <span *ngIf="sender.get('addressLine1')?.touched && sender.get('addressLine1')?.errors?.required" class="help-block error">Required</span>
                  </div>
                </div>
            </div>
          </div>
          <div class="form-row mt-4">
            <div class="w-100">
                <div class="form-group row" >
                  <div class="col-md-4 col-sm-12 text-right">
                    <label class="control-label" for="inputSenderCity">Pickup City <span *ngIf="sender.get('city')?.errors?.required">*</span></label>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <input type="text" class="form-control" formControlName="city" id="inputSenderCity" name="inputSenderCity" placeholder="Pickup City" maxlength="35"> <!--[readonly]="sender.get('city')?.errors?.required"-->

                  </div>
                  <div class="col-md-4 col-sm-12">
                    <span *ngIf="sender.get('city')?.touched && sender.get('city')?.errors?.required" class="help-block error">Required</span>
                  </div>
                </div>
            </div>
          </div>
          <div class="form-row mt-4">
            <div class="w-100">
                <div class="form-group row" >
                  <div class="col-md-4 col-sm-12 text-right">
                    <label class="control-label" for="dropSenderState">Pickup State <span *ngIf="sender.get('state')?.errors?.required">*</span></label>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <select class="form-control" formControlName="state" id="dropSenderState" name="dropSenderState">
                      <option disabled selected value="">Please Choose</option>
                      <option *ngFor="let state of states" [ngValue] ="state.value" >{{state.name}}</option>
                    </select>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <span *ngIf="sender.get('state')?.touched && sender.get('state')?.errors?.required" class="help-block error">Required</span>
                    <span *ngIf="isInternationalState" class="help-block text-info pl-4"><i class="icon-info-circled"></i>State will be set automatically, for international addresses</span>
                  </div>
                </div>
            </div>
          </div>
          <div class="form-row mt-4">
            <div class="w-100">
                <div class="form-group row">
                  <div class="col-md-4 col-sm-12 text-right">
                    <label class="control-label" for="inputSenderPostCode">Pickup Postcode <span *ngIf="sender.get('postalCode')?.errors?.required">*</span></label>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <input type="text" class="form-control" formControlName="postalCode" id="inputSenderPostCode" name="inputSenderPostCode" placeholder="Pickup Postcode" max="9999" min="0"> <!--[readonly]="sender.get('postalCode')?.errors?.required"-->
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <span *ngIf="sender.get('postalCode')?.touched && sender.get('postalCode')?.errors?.required" class="help-block error">Required<br /></span>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </form>
    </div> <!--End multisteps-form__content-->
  </fieldset>
</div>
