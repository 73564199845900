import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { NavigationStatus } from '../../../enums/NavigationStatus';
import { ConfigurationModel } from '../../../models/ConfigurationModel';
import { NavigationModel } from '../../../models/NavigationModel';
import { CommonService } from '../../../services/common/common.service';
import { FormService } from '../../../services/common/form.service';
import { NavigationService } from '../../../services/common/navigation-service';
import { Constant } from '../../../utilities/Constant';

@Component({
  selector: 'app-action-buttons',
  templateUrl: './action-buttons.component.html',
  styleUrls: ['./action-buttons.component.css']
})
export class ActionButtonsComponent implements OnInit, OnDestroy {

  carrierConfig!: ConfigurationModel;
  isNextNavigationPathAvailable: boolean = true;
  isPreviewScreen: boolean = false;
  isShowSubmit: boolean = false;
  isSubmitDisabled: boolean = true;
  isShowPrevious: boolean = false;
  carrierIsAMX: boolean = false;
  hideActionButtons: boolean = false;
  isSaveAsDraftEnabled: boolean = false;
  claimFormStatusSub!: Subscription;
  amxclaimFormStatusSub!: Subscription;
  isDraftSub!: Subscription;
  isIsolatedSub!: Subscription;

  constructor(private commonService: CommonService, private route: Router,
    private formService: FormService, private navigationService: NavigationService) { }

  ngOnInit(): void {
    this.carrierConfig = this.commonService.getCarrierConfigObject();
    this.navigationService.activeStep$.subscribe(step => {
      this.changeButtonswithProgressBar(step);
    });
    if (this.carrierConfig && this.carrierConfig.navigation) {
      let activeStep = this.carrierConfig.navigation.filter(nav => nav.status == NavigationStatus.Active)[0];
      if (activeStep) {
        this.changeButtonswithProgressBar(activeStep);
      }
    }
    /* Checks the validity of the warranty claim form. */
    this.claimFormStatusSub = this.formService.claimForm.statusChanges.subscribe(sub => {
      this.isSubmitDisabled = sub == 'INVALID' ? true : false;
    });
    if (this.carrierConfig.prefix.toLowerCase() == Constant.carrierPrefix.amxCarrier.toLowerCase()) {
      this.carrierIsAMX = true;
      this.isSaveAsDraftEnabled = true;
      this.amxclaimFormStatusSub = this.formService.amxClaimForm.statusChanges.subscribe(sub => {
        this.isSubmitDisabled = sub == 'INVALID' ? true : false;
      });
    }

    this.isDraftSub = this.commonService.isDraftClaim$.subscribe(value => {
      if (this.carrierConfig.prefix.toLowerCase() == Constant.carrierPrefix.amxCarrier.toLowerCase()) {
        this.hideActionButtons = value;
      } else {
        this.isSaveAsDraftEnabled = value;
      }
    });


    this.isIsolatedSub = this.commonService.isIsolatedComponet$.subscribe(value => {
      this.hideActionButtons = value;
    });
  }

  ngOnDestroy() {
    this.claimFormStatusSub?.unsubscribe();
    this.amxclaimFormStatusSub?.unsubscribe();
    this.isDraftSub?.unsubscribe();
    this.isIsolatedSub?.unsubscribe();
  }

  public navigate(direction: string) {

    let nextNavigation: NavigationModel | undefined;
    let previousNavigation: NavigationModel | undefined;

    if (direction == Constant.navigationDirection.forward) {
      nextNavigation = this.navigationService.navigate(direction, this.carrierConfig);
      /*Setting up the preview button.*/
      this.isNextNavigationPathAvailable = nextNavigation?.showPreview ? false : true;
      this.isShowSubmit = nextNavigation?.showSubmit ? true : false;
      this.isShowPrevious = nextNavigation?.previous != undefined;
    } else {
      previousNavigation = this.navigationService.navigate(direction, this.carrierConfig);
      /*Setting up the preview button.*/
      this.isNextNavigationPathAvailable = previousNavigation?.showPreview ? false : true;
      this.isShowSubmit = previousNavigation?.showSubmit ? true : false;
      this.isShowPrevious = previousNavigation?.previous != undefined;
    }

    this.formService.setClaimFormDataToSessionStorage(this.carrierConfig.prefix);
  }

  public navigateToPreview() {
    let lastNavigation = this.navigationService.navigateToLastNavigationElement(this.carrierConfig);
    this.isShowSubmit = lastNavigation?.showSubmit ? true : false;
    this.formService.setClaimFormDataToSessionStorage(this.carrierConfig.prefix);
  }

  public changeButtonswithProgressBar(activestep: NavigationModel) {

    if (activestep) {
      this.isNextNavigationPathAvailable = !activestep.showPreview ? true : false;
      this.isPreviewScreen = activestep.showSubmit ? true : false;
      this.isShowSubmit = activestep.showSubmit ? true : false;
      this.isShowPrevious = activestep.previous != undefined;
    }
  }

  public submitClaimForm() {
    if (this.carrierConfig.prefix.toLowerCase() == Constant.carrierPrefix.amxCarrier.toLowerCase()) {
      this.formService.submitAmxClaim();
    }
    else {
      this.formService.submitClaim(this.carrierConfig);
    }
  }

  public reset() {
    this.formService.resetClaimForm(this.carrierConfig);
  }
  public saveAsDraft() {
    this.formService.saveAsDraft(this.carrierConfig);
  }
}

