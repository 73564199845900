import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { distinctUntilChanged } from 'rxjs/operators';

import { environment } from '../../../../../environments/environment';
import { ConfigurationModel } from '../../../../models/ConfigurationModel';
import { ModalOptions } from '../../../../models/ModalOptions';
import { AppService } from '../../../../services/common/app.service';
import { CommonService } from '../../../../services/common/common.service';
import { FormService } from '../../../../services/common/form.service';
import { Constant } from '../../../../utilities/Constant';
import { ErrorHelper } from '../../../../utilities/ErrorHelper';

@Component({
  selector: 'consignment-modal',
  templateUrl: './consignment-modal.component.html',
  styleUrls: ['./consignment-modal.component.css'],

})
export class ConsignmentModalComponent implements OnInit {
  consignmetInformationForm: UntypedFormGroup = this.formService.consignmetInformationForm;
  consignmentNoteName!: string;
  carrierConfigObject!: ConfigurationModel;
  freightCarriers!: { name: string, prefix: string }[];
  showListOfCarriers!: boolean;

  @ViewChild('modal') modalTemplateRef!: TemplateRef<ConsignmentModalComponent>;
  public get myForm() {
    return this.consignmetInformationForm?.controls;
  }

  constructor(private modalService: NgbModal, private formService: FormService,
    private commonService: CommonService, private appService: AppService,
    private errorHelper: ErrorHelper, public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
   
    this.carrierConfigObject = this.commonService.getCarrierConfigObject();
    this.consignmentNoteName = this.getDisplayNameCustomField('consignmentNoteNo', 'Consignment Number');
  }

  public onBlur() {
    if (this.myForm.consignmentNoteNo.value && this.myForm.consignmentNoteNo.value.trim()) {
      this.commonService.isLoading$.next(true);
      this.appService.getFreightCarrierPrefixFromConsignmentNoteNumber(this.myForm.consignmentNoteNo.value)
        .pipe(distinctUntilChanged())
        .subscribe(response => {
          this.commonService.isLoading$.next(false);
          if (environment.showConsoleMessages) {
            console.log(response);
          }
          this.showListOfCarriers = false;
          if (response) {
            if (response.length > 1) {
              /* Show the drop down list to selecte a carrier */
              this.freightCarriers = this.getCarriersFromPrefix(response);
              this.showListOfCarriers = true;
            } else if (response.length == 1) {
              if (response[0] !== Constant.carrierPrefix.finCarrier) {
                this.commonService.clearCacheObjects();
                window.location.href = `${window.location.origin}/${response[0].toLowerCase()}?consignment_note=${this.myForm.consignmentNoteNo.value}`;
              } else {
                /* Carrier is FIN */
                this.activeModal.close(true);
              }
            } else {
              /* Carriers are not known by FS */
              this.activeModal.close(true);
            }
          }

        },
          (errorResponse) => {
            this.commonService.isLoading$.next(false);
            let error = this.errorHelper.formatAndDisplayError(errorResponse);
            if (error) {
              if (environment.showConsoleMessages) {
                console.log(error[0]);
                console.log(error[1]);
              }
            }
          }
        );
    }
  }

  private getCarriersFromPrefix(response: string[]): { name: string; prefix: string; }[] {
    let resultArray: { name: string; prefix: string; }[] = [];
    response.forEach(item => {
      let carrierName = this.commonService.getCarrierNameByPrefix(item);
      if (carrierName) {
       let name = carrierName.replace('&amp;', '&');
        if (environment.showConsoleMessages) {
          console.log(name);
        }
        resultArray.push({ name: name, prefix: item });
      }
    });
    return resultArray;
  }
  public getDisplayNameCustomField(fieldName: string, defaultValue: string): string {
    return this.commonService.getCustomFieldDisplayName(fieldName, defaultValue);
  }

  public modalClose() {
    this.activeModal.close(false);
  }

  public navigateToClaimForm() {
    if (environment.showConsoleMessages) {
      console.log(this.myForm.selectedFreightCarrier.value);
    }
    if (this.myForm.selectedFreightCarrier) {
      if (this.myForm.selectedFreightCarrier.value?.prefix === Constant.carrierPrefix.finCarrier) {
        /* Carrier is FIN */
        this.activeModal.close(true);
      }
      this.commonService.clearCacheObjects();
      window.location.href = `${window.location.origin}/${this.myForm.selectedFreightCarrier.value?.prefix.toLowerCase()}?consignment_note=${this.myForm.consignmentNoteNo.value}`;

    }
  }

  public skipCarrierSelection() {
    this.activeModal.close(true);
  }
}
