import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonService } from '../../../services/common/common.service';

import { FormService } from '../../../services/common/form.service';
import { Constant } from '../../../utilities/Constant';

@Component({
  selector: 'app-receiver-details',
  templateUrl: './receiver-details.component.html',
  styleUrls: ['./receiver-details.component.css']
})
export class ReceiverDetailsComponent implements OnInit {
  receiverDetailsForm = this.formService.receiverDetailsForm;
  states = Constant.states;
  showReceiverPhoneNumber = false;
  showReceiverEmail = false;
  isInternationalState: boolean = false;
  showInfoMessage: boolean=true;

  addresssSub!: Subscription | undefined;

  get myForm() {
    return this.receiverDetailsForm.controls;
  }

  get receiver() {
    return this.myForm?.receiver;
  }

  constructor(private formService: FormService, private commonService: CommonService) { }

  ngOnInit(): void {
    this.showInfoMessage = this.receiver.get('address')?.value ? false : true;
    this.showReceiverEmail = this.commonService.checkCustomFields('receiver.email');
    this.showReceiverPhoneNumber = this.commonService.checkCustomFields('receiver.phone');
    this.setStateAndPostCodeStatus();

    this.addresssSub = this.receiver.get('address')?.valueChanges.subscribe(value => {
      if (value) {
        this.showInfoMessage = true;
      } else {
        this.receiver.get('longitude')?.setValue(0);
        this.receiver.get('latitude')?.setValue(0);
        this.showInfoMessage = false;
      }
    })
    if (!this.receiver.get('street')?.value) {
      this.receiver.get('street')?.setValue(this.receiver.get('addressLine1')?.value);
    }

    if (this.receiver.get('longitude')?.value && this.receiver.get('latitude')?.value) {
      this.enableReceiverAddressFields();
    }
  }

  ngOnDestroy(): void {
    this.addresssSub?.unsubscribe();

  }

  public mapAddressValuesToControls(event: any) {
    if (event && event['longitude'] != 'undefined' && event['latitude'] != 'undefined') {
      this.receiver.get('address')?.setValue(event['address'], { emitEvent: false });
      this.receiver.get('addressLine1')?.setValue(event['streetAddress']);
      this.receiver.get('city')?.setValue(event['city']);
      this.receiver.get('state')?.setValue(event['state']);
      this.receiver.get('postalCode')?.setValue(event['postalCode']);
      this.receiver.get('street')?.setValue(event['streetAddress']);
      this.receiver.get('longitude')?.setValue(event['longitude']);
      this.receiver.get('latitude')?.setValue(event['latitude']);
      this.receiver.get('country')?.setValue(event['country']);
      this.enableReceiverAddressFields();
      this.showInfoMessage = false;
      this.setStateAndPostCodeStatus();

    }
    else {
      this.receiver.get('street')?.setValue('NA');
      this.receiver.get('longitude')?.setValue(0);
      this.receiver.get('latitude')?.setValue(0);
      this.receiver.get('country')?.setValue('NA');
    }
  }

  private enableReceiverAddressFields() {
    this.receiver.get('addressLine1')?.enable({ onlySelf: true })
    this.receiver.get('city')?.enable({ onlySelf: true })
    this.receiver.get('state')?.enable({ onlySelf: true })
    this.receiver.get('postalCode')?.enable({ onlySelf: true })
    this.receiver.markAllAsTouched();
    this.receiver.updateValueAndValidity();

  }
  /**
   * Checks if state is not available and sets the state dropdown as disabled.
   * International address will not have a state in the dropdownlist, therefore, it will be disabled.
   * */
  private setStateAndPostCodeStatus() {
    if (this.receiver.get('address')?.value && this.receiver.get('state')?.value) {
      if (this.states.filter(state => state.name == this.receiver.get('state')?.value).length == 0) {
        this.receiver.get('state')?.disable({ onlySelf: true });
        this.isInternationalState = true;
      } else {
        this.isInternationalState = false;
      }
    }

  }

}
