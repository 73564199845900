import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//import { AgmCoreModule } from '@agm/core';
import { GooglePlaceDirective } from './directives/google-place.directive';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { FileFilterPipe } from './pipes/file-filter.pipe';
import { NgbDateCustomParserFormatter } from './utilities/NgbDateCustomParserFormatter';
import { GoogleMapsModule } from '@angular/google-maps';



import { AppComponent } from './app.component';
import { ContactInformationComponent } from './components/common/contact-information/contact-information.component';
import { ConsignmentInformationComponent } from './components/common/consignment-information/consignment-information.component';
import { ClaimantDetailsComponent } from './components/common/claimant-details/claimant-details.component';
import { SenderDetailsComponent } from './components/common/sender-details/sender-details.component';
import { ReceiverDetailsComponent } from './components/common/receiver-details/receiver-details.component';
import { ClaimInformationComponent } from './components/common/claim-information/claim-information.component';
import { SupportingDocumentsComponent } from './components/common/supporting-documents/supporting-documents.component';
import { ActionButtonsComponent } from './components/common/action-buttons/action-buttons.component';
import { WelcomeComponent } from './components/common/welcome/welcome.component';
import { BankDetailsComponent } from './components/common/bank-details/bank-details.component';
import { AmxwelcomeComponent } from './components/carriers/amx/amxwelcome/amxwelcome.component';
import { AmxPurchasingCustomerDetailsComponent } from './components/carriers/amx/amx-purchasing-customer-details/amx-purchasing-customer-details.component';
import { AmxCustomerInformationComponent } from './components/carriers/amx/amx-customer-information/amx-customer-information.component';
import { AmxParcelDetailsComponent } from './components/carriers/amx/amx-parcel-details/amx-parcel-details.component';
import { AmxSenderDetailsComponent } from './components/carriers/amx/amx-sender-details/amx-sender-details.component';
import { AmxReceiverDetailsComponent } from './components/carriers/amx/amx-receiver-details/amx-receiver-details.component';
import { AmxRequiredSupportingInformationComponent } from './components/carriers/amx/amx-required-supporting-information/amx-required-supporting-information.component';
import { AmxFurtherDetailsComponent } from './components/carriers/amx/amx-further-details/amx-further-details.component';
import { AmxPaymentComponent } from './components/carriers/amx/amx-payment/amx-payment.component';
import { AmxTermsAndConditionsComponent } from './components/carriers/amx/amx-terms-and-conditions/amx-terms-and-conditions.component';
import { ProgressBarComponent } from './components/common/progress-bar/progress-bar.component';
import { PreviewComponent } from './components/common/preview/preview.component';
import { Constant } from './utilities/Constant';
import { FranchiseeDetailsComponent } from './components/common/franchisee-details/franchisee-details.component';
import { ModalComponent } from './components/common/modal/modal.component';
import { AmxPreviewComponent } from './components/carriers/amx/amx-preview/amx-preview.component';
import { FileUploaderComponent } from './components/common/file-uploader/file-uploader.component';
import { CameraComponent, FormatTimePipe } from './components/common/modal/camera/camera.component';
import { AmxExcessLabelsComponent } from './components/carriers/amx/amx-excess-labels/amx-excess-labels.component';
import { AgGridModule } from 'ag-grid-angular';
import { ApproveClaimComponent } from './components/carriers/fmt/approve-claim/approve-claim.component';
import { CancellationComponent } from './components/cancellation/cancellation/cancellation.component';
import { CarrierSelectionComponent } from './components/common/carrier-selection/carrier-selection.component';
import { ConsignmentModalComponent } from './components/common/modal/consignment-modal/consignment-modal.component';



@NgModule({
  declarations: [
    AppComponent,
    ContactInformationComponent,
    ClaimantDetailsComponent,
    ConsignmentInformationComponent,
    SenderDetailsComponent,
    ReceiverDetailsComponent,
    ClaimInformationComponent,
    SupportingDocumentsComponent,
    ActionButtonsComponent,
    WelcomeComponent,
    BankDetailsComponent,
    AmxwelcomeComponent,
    AmxPurchasingCustomerDetailsComponent,
    AmxCustomerInformationComponent,
    AmxParcelDetailsComponent,
    AmxSenderDetailsComponent,
    AmxReceiverDetailsComponent,
    AmxRequiredSupportingInformationComponent,
    AmxFurtherDetailsComponent,
    AmxPaymentComponent,
    AmxTermsAndConditionsComponent,
    ProgressBarComponent,
    PreviewComponent,
    FranchiseeDetailsComponent,
    GooglePlaceDirective,
    AmxPreviewComponent,
    ModalComponent,
    FileFilterPipe,
    CameraComponent,
    FileUploaderComponent,
    AmxExcessLabelsComponent,
    ApproveClaimComponent,
    CancellationComponent,
    FormatTimePipe,
    CarrierSelectionComponent,
    ConsignmentModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    GoogleMapsModule,
    AgGridModule.withComponents([])
  ],
  providers: [
    {
      provide: NgbDateParserFormatter, useFactory: () => { return new NgbDateCustomParserFormatter(Constant.dateFormat) }
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
