<h3 class="multisteps-form__title">Claim Summary</h3>
<div class="accordion" id="accordionExample">
  <!--Purchasing customer Information-->
  <div class="card">
    <div id="headingOne" [ngClass]="this.purchasingCustomerForm.invalid?'card-header error':'card-header'">
      <h2 class="mb-0">
        <button type="button" class="btn btn-link" data-toggle="collapse" data-target="#collapseOne"> Purchasing Customer</button>
        <button type="button" class="btn btn-link float-right" (click)="editDetails('amxpurchasingcustomer')"> <span class="error-notice">Click to fix errors</span><i data-icon="k" class="icon-exclamation-triangle"></i><i data-icon="d" class="icon"></i> </button>
      </h2>
    </div>
    <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
      <div class="card-body">
        <div class="form-row mt-4">
          <div class="row w-100">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label">Name</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <label class="control-label" name="name">{{purchasingCustomer.get('name')?.value}}</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <span *ngIf="purchasingCustomer.get('name')?.errors?.required" class="help-block error">Required</span>
            </div>
          </div>
        </div>
        <ng-container *ngIf="claimantRole.value?.value == 'Third Party'">
          <div class="form-row mt-4">
            <div class="row w-100">
              <div class="col-md-4 col-sm-12 text-right">
                <label class="control-label">Contact Name</label>
              </div>
              <div class="col-md-4 col-sm-12">
                <label type="text" class="control-label" name="ContactName">{{purchasingCustomer.get('contactName')?.value}}</label>
              </div>
            </div>
          </div>
          <div class="form-row mt-4">
            <div class="row w-100">
              <div class="col-md-4 col-sm-12 text-right">
                <label class="control-label">Street Address</label>
              </div>
              <div class="col-md-4 col-sm-12">
                <label type="text" class="control-label" name="StreetAddress">{{purchasingCustomer.get('addressLine1')?.value}}</label>
              </div>
            </div>
          </div>

          <div class="form-row mt-4">
            <div class="row w-100">
              <div class="col-md-4 col-sm-12 text-right">
                <label class="control-label">Suburb</label>
              </div>
              <div class="col-md-4 col-sm-12">
                <label type="text" class="control-label" name="Suburb">{{purchasingCustomer.get('city')?.value}}</label>
              </div>
            </div>
          </div>
          <div class="form-row mt-4">
            <div class="row w-100">
              <div class="col-md-4 col-sm-12 text-right">
                <label class="control-label">Postal Code</label>
              </div>
              <div class="col-md-4 col-sm-12">
                <label type="text" class="control-label" name="PostalCode">{{purchasingCustomer.get('postalCode')?.value}}</label>
              </div>
            </div>
          </div>
          <div class="form-row mt-4">
            <div class="row w-100">
              <div class="col-md-4 col-sm-12 text-right">
                <label class="control-label">Resolve Address</label>
              </div>
              <div class="col-md-4 col-sm-12">
                <label type="text" class="control-label">{{purchasingCustomer.get('address')?.value}}</label>
              </div>
            </div>
          </div>
          <div class="form-row mt-4">
            <div class="row w-100">
              <div class="col-md-4 col-sm-12 text-right">
                <label class="control-label">Email</label>
              </div>
              <div class="col-md-4 col-sm-12">
                <label type="text" class="control-label" name="purchasingCustomerEmail">{{purchasingCustomer.get('email')?.value}}</label>
              </div>
              <div class="col-md-4 col-sm-12">
                <div [ngClass]="{'help-block error' : (purchasingCustomer.get('email')?.errors?.required && purchasingCustomer.get('email')?.touched)
                   ||(purchasingCustomer.get('email')?.errors && purchasingCustomer.get('email')?.value)}">
                  <span *ngIf="purchasingCustomer.get('email')?.errors?.required">Required</span>
                  <span *ngIf="purchasingCustomer.get('email')?.errors?.invalidEmailFormat">Email format Invalid <br /> Ex:- "name@domain.com.au"</span>
                </div>
                </div>
            </div>
          </div>
          <div class="form-row mt-4">
            <div class="row w-100">
              <div class="col-md-4 col-sm-12 text-right">
                <label class="control-label text-left-override">Phone Number</label>
              </div>
              <div class="col-md-4 col-sm-12">
                <label type="text" class="control-label" name="officeNumber" title="(xxxxxxxxxx)">{{purchasingCustomer.get('officeNumber')?.value}}</label>
              </div>
              <div class="col-md-4 col-sm-12">
                <div [ngClass]="{'help-block error' : (purchasingCustomer.get('officeNumber')?.errors?.required)
                 || (purchasingCustomer.get('officeNumber')?.errors && purchasingCustomer.get('officeNumber')?.value)}">
                  <span *ngIf="purchasingCustomer.get('officeNumber')?.errors?.required">Required<br /></span>
                  <span *ngIf="purchasingCustomer.get('officeNumber')?.errors?.minlength">Too Short<br /></span>
                  <span *ngIf="purchasingCustomer.get('officeNumber')?.errors?.maxlength">Too Long<br /></span>
                  <span *ngIf="purchasingCustomer.get('officeNumber')?.errors?.invalidNumber ">The phone number should be numeric<br /></span>
                </div>
              </div>
            </div>
          </div>

        </ng-container>
        <div class="form-row mt-4" *ngIf="claimantRole?.value?.value == 'Third Party'">
          <div class="row w-100">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label">Purchasing Customer Comment</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <label type="text" class="control-label" name="customerComment">{{purchasingCustomerForm.get('purchasingCustomerComment')?.value}}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Sender Information-->
  <div class="card">
    <div id="headingTwo" [ngClass]="this.senderForm.invalid?'card-header error':'card-header'">
      <h2 class="mb-0">
        <button type="button" class="btn btn-link" data-toggle="collapse" data-target="#collapseTwo"> Sender Information</button>
        <button type="button" class="btn btn-link float-right" (click)="editDetails('amxsenderdetails')"> <span class="error-notice">Click to fix errors</span><i data-icon="k" class="icon-exclamation-triangle"></i><i data-icon="d" class="icon"></i> </button>
      </h2>
    </div>
    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
      <div class="card-body">
        <div class="form-row mt-4">
          <div class="row w-100">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label">Name</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <label type="text" class="control-label" name="name">{{sender.get('name')?.value}}</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <span *ngIf="sender.get('name')?.errors?.required" class="help-block error">Required</span>
            </div>
          </div>
        </div>

        <div class="form-row mt-4">
          <div class="row w-100">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label">Contact Name</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <label type="text" class="control-label" name="name">{{sender.get('contactName')?.value}}</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <span *ngIf="sender.get('contactName')?.errors?.required" class="help-block error">Required</span>
            </div>
          </div>
        </div>

        <div class="form-row mt-4">
          <div class="row w-100">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label">Street Address</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <label type="text" class="control-label" name="name">{{sender.get('addressLine1')?.value}}</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <span *ngIf="sender.get('addressLine1')?.errors?.required" class="help-block error">Required</span>
            </div>
          </div>
        </div>

        <div class="form-row mt-4">
          <div class="row w-100">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label">Suburb</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <label type="text" class="control-label" name="name">{{sender.get('city')?.value}}</label>

            </div>
            <div class="col-md-4 col-sm-12">
              <span *ngIf="sender.get('city')?.errors?.required" class="help-block error">Required</span>
            </div>
          </div>
        </div>

        <div class="form-row mt-4">
          <div class="row w-100">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label">Postal Code</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <label type="text" class="control-label" name="name">{{sender.get('postalCode')?.value}}</label>

            </div>
            <div class="col-md-4 col-sm-12">
              <span *ngIf="sender.get('postalCode')?.errors?.required" class="help-block error">Required</span>
            </div>
          </div>
        </div>

        <div class="form-row mt-4">
          <div class="row w-100">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label">Resolve Address</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <label type="text" class="control-label">{{sender.get('address')?.value}}</label>
            </div>
          </div>
        </div>

        <div class="form-row mt-4">
          <div class="row w-100">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label">Email</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <label type="text" class="control-label" name="email">{{sender.get('email')?.value}}</label>

            </div>
            <div class="col-md-4 col-sm-12">
              <div [ngClass]="{'help-block error' :  (sender.get('email')?.errors?.required)
                  || (sender.get('email')?.errors && sender.get('email')?.value)}">
                <span *ngIf="sender.get('email')?.errors?.required" >Required</span>
                <span *ngIf="sender.get('email')?.errors?.invalidEmailFormat" >Email format Invalid <br /> Ex:- "name@domain.com.au"</span>
              </div>
            </div>
          </div>
        </div>

        <div class="form-row mt-4">
          <div class="row w-100">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label">Phone Number</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <label type="text" class="control-label" name="name" title="(xxxxxxxxxx)">{{sender.get('officeNumber')?.value}}</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <div [ngClass]="{'help-block error' : (sender.get('officeNumber')?.errors?.required)
                 || (sender.get('officeNumber')?.errors && sender.get('officeNumber')?.value)}">
                <span *ngIf="sender.get('officeNumber')?.errors?.required">Required<br /></span>
                <span *ngIf="sender.get('officeNumber')?.errors?.minlength">Too Short<br /></span>
                <span *ngIf="sender.get('officeNumber')?.errors?.maxlength">Too Long<br /></span>
                <span *ngIf="sender.get('officeNumber')?.errors?.invalidNumber ">The phone number should be numeric<br /></span>
              </div>
            </div>
          </div>
        </div>


        <div class="form-row mt-4">
          <div class="row w-100">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label">Sender Comment</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <label type="text" class="control-label" name="name">{{senderForm.get('senderComment')?.value}}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Receiver Information-->
  <div class="card">
    <div id="headingThree" [ngClass]="this.receiverForm.invalid?'card-header error':'card-header'">
      <h2 class="mb-0">
        <button type="button" class="btn btn-link" data-toggle="collapse" data-target="#collapseThree"> Receiver Information</button>
        <button type="button" class="btn btn-link float-right" (click)="editDetails('amxreceiverdetails')"><span class="error-notice">Click to fix errors</span><i data-icon="k" class="icon-exclamation-triangle"></i> <i data-icon="d" class="icon"></i> </button>
      </h2>
    </div>
    <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
      <div class="card-body">
        <div class="form-row mt-4">
          <div class="row w-100">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label">Name</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <label type="text" class="control-label" name="name">{{receiver.get('name')?.value}}</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <span *ngIf="receiver.get('name')?.errors?.required" class="help-block error">Required</span>
            </div>
          </div>
        </div>

        <div class="form-row mt-4">
          <div class="row w-100">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label">Contact Name</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <label type="text" class="control-label" name="name">{{receiver.get('contactName')?.value}}</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <span *ngIf="receiver.get('contactName')?.errors?.required" class="help-block error">Required</span>
            </div>
          </div>
        </div>

        <div class="form-row mt-4">
          <div class="row w-100">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label">Street Address</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <label type="text" class="control-label" name="name">{{receiver.get('addressLine1')?.value}}</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <span *ngIf="receiver.get('addressLine1')?.errors?.required" class="help-block error">Required</span>
            </div>
          </div>
        </div>

        <div class="form-row mt-4">
          <div class="row w-100">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label">Suburb</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <label type="text" class="control-label" name="name">{{receiver.get('city')?.value}}</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <span *ngIf="receiver.get('city')?.errors?.required" class="help-block error">Required</span>
            </div>
          </div>
        </div>

        <div class="form-row mt-4">
          <div class="row w-100">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label">Postal Code</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <label type="text" class="control-label" name="name">{{receiver.get('postalCode')?.value}}</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <span *ngIf="receiver.get('postalCode')?.errors?.required" class="help-block error">Required</span>
            </div>
          </div>
        </div>

        <div class="form-row mt-4">
          <div class="row w-100">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label">Resolve Address</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <label type="text" class="control-label" name="name">{{receiver.get('address')?.value}}</label>
            </div>
          </div>
        </div>

        <div class="form-row mt-4">
          <div class="row w-100">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label">Email</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <label type="text" class="control-label" name="name">{{receiver.get('email')?.value}}</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <div [ngClass]="{'help-block error' :  (receiver.get('email')?.errors?.required)
                  || (receiver.get('email')?.errors && receiver.get('email')?.value)}">
                <span *ngIf="receiver.get('email')?.errors?.required" >Required</span>
                <span *ngIf="receiver.get('email')?.errors?.invalidEmailFormat" >Email format Invalid <br /> Ex:- "name@domain.com.au"</span>
              </div>
            </div>
          </div>
        </div>

        <div class="form-row mt-4">
          <div class="row w-100">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label">Phone Number</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <label type="text" class="control-label" name="name" title="(xxxxxxxxxx)">{{receiver.get('officeNumber')?.value}}</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <div [ngClass]="{'help-block error' : (receiver.get('officeNumber')?.errors?.required)
                 || (receiver.get('officeNumber')?.errors && receiver.get('officeNumber')?.value)}">
                <span *ngIf="receiver.get('officeNumber')?.errors?.required">Required<br /></span>
                <span *ngIf="receiver.get('officeNumber')?.errors?.minlength">Too Short<br /></span>
                <span *ngIf="receiver.get('officeNumber')?.errors?.maxlength">Too Long<br /></span>
                <span *ngIf="receiver.get('officeNumber')?.errors?.invalidNumber ">The phone number should be numeric<br /></span>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row mt-4">
          <div class="row w-100">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label">Receiver Comment</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <label type="text" class="control-label" name="name">{{receiverForm.get('receiverComment')?.value}}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Customer Information-->
  <div class="card">
    <div id="headingFour" [ngClass]="this.customerForm.invalid?'card-header error':'card-header'">
      <h2 class="mb-0">
        <button type="button" class="btn btn-link" data-toggle="collapse" data-target="#collapseFour"> Customer Information</button>
        <button type="button" class="btn btn-link float-right" (click)="editDetails('amxcustomerInformations')"><span class="error-notice">Click to fix errors</span><i data-icon="k" class="icon-exclamation-triangle"></i> <i data-icon="d" class="icon"></i> </button>
      </h2>
    </div>
    <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
      <div class="card-body">

        <div class="form-row mt-4">
          <div class="col-md-4 col-sm-12 text-right">
            <label class="control-label font-weight-bold" *ngIf="customer.isInternationalClaimant.value =='false'">
              Aramex Domestic Customer
            </label>
            <label class="control-label font-weight-bold" *ngIf="customer.isInternationalClaimant.value =='true'">
              Aramex International Customer
            </label>
          </div>
          <div class="col-md-4 col-sm-12">
            <div>

            </div>
          </div>
        </div>

        <div class="row mt-4" [ngSwitch]="customer.customerType.value">
          <div class="col-md-4 col-sm-12 text-right">
            <label class="control-label">What best describes you:</label>
          </div>
          <div class="col-md-4 col-sm-12">
            <div>
              <div *ngSwitchCase="1">
                <label class="input-radio">
                  Manufacturer
                </label>
              </div>
              <div *ngSwitchCase="2">
                <label class="input-radio">
                  Wholesaler
                </label>
              </div>
              <div *ngSwitchCase="3">
                <label class="input-radio">
                  Retailer
                </label>
              </div>
              <div *ngSwitchCase="0">
                <label class="input-radio">
                  Individual
                </label>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="customer.customerType?.value !='0' && customer.isInternationalClaimant.value =='false'">
          <div *ngIf="claimantRole?.value?.value =='Sender'">
            <div>
              <div class="row mt-4">
                <div class="col-md-4 col-sm-12 text-right">
                  <label class="control-label">What is your ABN?</label>
                </div>
                <div class="col-md-4 col-sm-12">
                  <label type="text" class="control-label" name="name">{{customer.sender.get('abnNumber')?.value}}</label>
                </div>
                <div class="col-md-4 col-sm-12">
                  <div [ngClass]="{'help-block error' :(customer.sender.get('abnNumber')?.errors?.required)
                 || (customer.sender.get('abnNumber')?.errors && customer.sender.get('abnNumber')?.value)}">
                    <span *ngIf="customer.sender.get('abnNumber')?.errors?.required">Required<br /></span>
                    <span *ngIf="customer.sender.get('abnNumber')?.errors?.minlength">Too Short<br /></span>
                    <span *ngIf="customer.sender.get('abnNumber')?.errors?.maxlength">Too Long<br /></span>
                    <span *ngIf="customer.sender.get('abnNumber')?.errors?.invalidNumber ">The ABN number should be numeric<br /></span>
                  </div>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-4 col-sm-12 text-right">
                  <label class="control-label">Are you registered for GST</label>
                </div>
                <div class="col-md-4 col-sm-12">
                  <div>
                    <div *ngIf="customer.sender.get('isRegisteredForGst')?.value=='1'">
                      <label class="control-label" for="isRegisteredForGst">Yes</label>
                    </div>
                    <div *ngIf="customer.sender.get('isRegisteredForGst')?.value=='0'">
                      <label class="control-label">No</label>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div *ngIf="claimantRole?.value?.value =='Receiver'">
            <div>
              <div class="row mt-4">
                <div class="col-md-4 col-sm-12 text-right">
                  <label class="control-label">What is your ABN?</label>
                </div>
                <div class="col-md-4 col-sm-12">
                  <label type="text" class="control-label" name="name">{{customer.receiver.get('abnNumber')?.value}}</label>
                </div>
                <div class="col-md-4 col-sm-12">
                  <div [ngClass]="{'help-block error' : (customer.receiver.get('abnNumber')?.errors?.required)
                 || (customer.receiver.get('abnNumber')?.errors && customer.receiver.get('abnNumber')?.value)}">
                    <span *ngIf="customer.receiver.get('abnNumber')?.errors?.required">Required<br /></span>
                    <span *ngIf="customer.receiver.get('abnNumber')?.errors?.minlength">Too Short<br /></span>
                    <span *ngIf="customer.receiver.get('abnNumber')?.errors?.maxlength">Too Long<br /></span>
                    <span *ngIf="customer.receiver.get('abnNumber')?.errors?.invalidNumber ">The ABN number should be numeric<br /></span>
                  </div>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-4 col-sm-12 text-right">
                  <label class="control-label">Are you registered for GST</label>
                </div>
                <div class="col-md-4 col-sm-12">
                  <div>
                    <div *ngIf="customer.receiver.get('isRegisteredForGst')?.value=='1'">
                      <label class="control-label" for="isRegisteredForGst">Yes</label>
                    </div>
                    <div *ngIf="customer.receiver.get('isRegisteredForGst')?.value=='0'">
                      <label class="control-label">No</label>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div *ngIf="claimantRole?.value?.value =='Third Party'">
            <div>
              <div class="row mt-4">
                <div class="col-md-4 col-sm-12 text-right">
                  <label class="control-label">What is your ABN?</label>
                </div>
                <div class="col-md-4 col-sm-12">
                  <label type="text" class="control-label" name="name">{{customer.purchasingCustomer.get('abnNumber')?.value}}</label>
                </div>
                <div class="col-md-4 col-sm-12">
                  <div [ngClass]="{'help-block error' : (customer.purchasingCustomer.get('abnNumber')?.errors?.required)
                 || (customer.purchasingCustomer.get('abnNumber')?.errors && customer.purchasingCustomer.get('abnNumber')?.value)}">
                    <span *ngIf="customer.purchasingCustomer.get('abnNumber')?.errors?.required">Required <br /></span>
                    <span *ngIf="customer.purchasingCustomer.get('abnNumber')?.errors?.minlength">Too Short <br /></span>
                    <span *ngIf="customer.purchasingCustomer.get('abnNumber')?.errors?.maxlength">Too Long <br /></span>
                    <span *ngIf="customer.purchasingCustomer.get('abnNumber')?.errors?.invalidNumber ">The ABN number should be numeric <br /></span>
                  </div>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-4 col-sm-12 text-right">
                  <label class="control-label">Are you registered for GST</label>
                </div>
                <div class="col-md-4 col-sm-12">
                  <div>
                    <div *ngIf="customer.purchasingCustomer.get('isRegisteredForGst')?.value=='1'">
                      <label class="control-label" for="isRegisteredForGst">Yes</label>
                    </div>
                    <div *ngIf="customer.purchasingCustomer.get('isRegisteredForGst')?.value=='0'">
                      <label class="control-label">No</label>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="row mt-4" *ngIf="customer.customerType.value =='0'">
          <div class="col-md-4 col-sm-12 text-right">
            <label class="control-label">Claimant Comment</label>
          </div>
          <div class="col-md-4 col-sm-12">
            <label type="text" class="control-label" name="name">{{customer.claimantComment.value}}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Parcel Information-->
  <div class="card">

    <div id="headingSix" [ngClass]="this.parcelForm.invalid?'card-header error':'card-header'">
      <h2 class="mb-0">
        <button type="button" class="btn btn-link" data-toggle="collapse" data-target="#collapseSix"> Parcel Information</button>
        <button type="button" class="btn btn-link float-right" (click)="editDetails('amxparceldetails')"> <span class="error-notice">Click to fix errors</span><i data-icon="k" class="icon-exclamation-triangle"></i><i data-icon="d" class="icon"></i> </button>
      </h2>
    </div>
    <div id="collapseSix" class="collapse" aria-labelledby="headingSix" data-parent="#accordionExample">
      <div class="card-body">

        <div class="form-row" *ngIf="customer.isInternationalClaimant.value =='true'">
          <div class="row w-100 mt-4">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label">Dispatch Location</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <label type="text" class="control-label">{{parcel.dispatchLocation.value}}</label>
            </div>
          </div>

          <div class="row w-100 mt-4">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label">Original Tracking Reference</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <label type="text" class="control-label">{{parcel.originalTrackingReference.value}}</label>
            </div>
          </div>
        </div>

        <div class="form-row mt-4">
          <div class="row w-100">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label">Aramex Label Number</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <label type="text" class="control-label">{{parcel.labelNumber.value}}</label>
            </div>
          </div>
        </div>

        <div class="form-row mt-4">
          <div class="row w-100">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label">Alias Label</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <label type="text" class="control-label">{{parcel.aliasLabelNumber.value}}</label>
            </div>
          </div>
        </div>

        <div class="form-row mt-4">
          <div class="row w-100">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label">Goods Packaged in</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <label type="text" class="control-label">{{parcel.packageType.value?.name}}</label>
            </div>
          </div>
        </div>

        <div class="form-row mt-4">
          <div class="row w-100">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label">Aramex Date of Dispatch</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <div class="input-group">
                <label type="text" class="control-label">{{parcel.dateTimeSent.value}}</label>
              </div>

            </div>
          </div>
        </div>

        <div class="form-row mt-4">
          <div class="row w-100">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label">Were there any additional labels dispatched together in this shipment?</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <div *ngIf="parcel.isAdditionalLabelsDispatched?.value=='true'">
                <label type="text" class="control-label">Yes</label>
              </div>
              <div *ngIf="parcel.isAdditionalLabelsDispatched?.value=='false'">
                <label type="text" class="control-label">No</label>
              </div>

            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

  <!--Excess Label Information-->
  <div class="card" *ngIf="excessLabels.enableLabels.value==1">

    <div id="headingTen" [ngClass]="this.excessLabelForm.invalid?'card-header error':'card-header'">
      <h2 class="mb-0">
        <button type="button" class="btn btn-link" data-toggle="collapse" data-target="#collapseTen"> Excess Labels</button>
        <button type="button" class="btn btn-link float-right" (click)="editDetails('amxexcesslabel')"> <span class="error-notice">Click to fix errors</span><i data-icon="k" class="icon-exclamation-triangle"></i><i data-icon="d" class="icon"></i> </button>
      </h2>
    </div>
    <div id="collapseTen" class="collapse" aria-labelledby="headingTen" data-parent="#accordionExample">
      <div class="card-body">
        <div class="multisteps-form__content">

          <ag-grid-angular #excessLabelGrid
                           style="height: 250px;"
                           class="ag-theme-alpine"
                           [gridOptions]="gridOptions"
                           >
          </ag-grid-angular>
        </div>

      </div>
  </div>
   </div>
  <!--Further Details -->
  <div class="card">
    <div id="headingSeven" [ngClass]="this.furtherDetailsForm.invalid?'card-header error':'card-header'">
      <h2 class="mb-0">
        <button type="button" class="btn btn-link" data-toggle="collapse" data-target="#collapseSeven"> Further Details</button>
        <button type="button" class="btn btn-link float-right" (click)="editDetails('amxfurtherdetails')"><span class="error-notice">Click to fix errors</span> <i data-icon="k" class="icon-exclamation-triangle"></i> <i data-icon="d" class="icon"></i> </button>
      </h2>
    </div>
    <div id="collapseSeven" class="collapse" aria-labelledby="headingSeven" data-parent="#accordionExample">
      <div class="card-body">

        <div class="form-row mt-4">
          <div class="row w-100">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label">Claim Type</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <label type="text" class="control-label" name="claimType">{{claimType.value?.name}}</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <span *ngIf="furtherDetails.claimType.touched && furtherDetails.claimType.errors?.required" class="help-block error">Claim Type is mandatory.</span>
            </div>
          </div>
        </div>

        <div class="form-row mt-4">
          <div class="row w-100">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label">Value of goods actually lost or damaged in transit</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <div class="input-group">
                <label class="control-label mr-1">$</label>
                <label type="text" class="control-label" amount="claimValue" name="ClaimValue">{{furtherDetails.claimValue.value}}</label>
              </div>
            </div>
            <div class="col-md-4 col-sm-12">
              <div [ngClass]="{'help-block error' :(furtherDetails.claimValue.errors?.required)
                 || (furtherDetails.claimValue.errors && (furtherDetails.claimValue.value || furtherDetails.claimValue.value==0))}">
                <span *ngIf="furtherDetails.claimValue.errors?.required">Required <br /> </span>
                <span *ngIf="furtherDetails.claimValue.errors?.invalidDecimalNumber">Claim value should be numeric<br /></span>
                <span *ngIf="furtherDetails.claimValue.errors?.canNotBeZero">Claim value can not be 0<br /></span>
              </div>
            </div>
          </div>
        </div>

        <div class="form-row mt-4" *ngIf="customer.isInternationalClaimant.value =='true'">
          <div class="row w-100">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label">Currency</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <label type="text" class="control-label" amount="claimCurrency" name="claimCurrency">{{furtherDetails.claimCurrency.value?.code}}</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <span *ngIf="furtherDetails.claimCurrency.errors?.required" class="help-block error">Required</span>
            </div>
          </div>
        </div>

        <div class="form-row mt-4">
          <div class="row w-100">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label">Were the goods damaged in transit wine?</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <div *ngIf="furtherDetails.isGoodsDamagedInTransitWine.value =='1'">
                <label type="text" class="control-label" name="GoodsDamagedInTransitWine">Yes</label>
              </div>
              <div *ngIf="furtherDetails.isGoodsDamagedInTransitWine.value =='0'">
                <label type="text" class="control-label" name="GoodsDamagedInTransitWine">No</label>
              </div>
            </div>
          </div>
        </div>

        <div class="form-row mt-4" *ngIf="furtherDetails.isGoodsDamagedInTransitWine.value=='1'">
          <div class="row w-100">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label">Are you a winery?</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <div *ngIf="claimantRole?.value?.value == 'Sender'">
                <div *ngIf="furtherDetails.sender.get('isClaimantWinery')?.value=='1'">
                  <label type="text" class="control-label" name="GoodsDamagedInTransitWine">Yes</label>
                </div>
                <div *ngIf="furtherDetails.sender.get('isClaimantWinery')?.value=='0'">
                  <label type="text" class="control-label" name="GoodsDamagedInTransitWine">No</label>
                </div>
              </div>
              <div *ngIf="claimantRole?.value?.value == 'Receiver'">
                <div *ngIf="furtherDetails.receiver.get('isClaimantWinery')?.value=='1'">
                  <label type="text" class="control-label" name="GoodsDamagedInTransitWine">Yes</label>
                </div>
                <div *ngIf="furtherDetails.receiver.get('isClaimantWinery')?.value=='0'">
                  <label type="text" class="control-label" name="GoodsDamagedInTransitWine">No</label>
                </div>
              </div>
              <div *ngIf="claimantRole?.value?.value == 'Third Party'">
                <div *ngIf="furtherDetails.purchasingCustomer.get('isClaimantWinery')?.value=='1'">
                  <label type="text" class="control-label" name="GoodsDamagedInTransitWine">Yes</label>
                </div>
                <div *ngIf="furtherDetails.purchasingCustomer.get('isClaimantWinery')?.value=='0'">
                  <label type="text" class="control-label" name="GoodsDamagedInTransitWine">No</label>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="form-row mt-4">
          <div class="row w-100">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label">Description of Goods</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <label type="text" class="control-label" name="descriptionOfGoods">{{furtherDetails.descriptionOfGoods.value}}</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <span *ngIf="furtherDetails.descriptionOfGoods.errors?.required" class="help-block error">Required</span>
            </div>
          </div>
        </div>

        <div class="form-row mt-4">
          <div class="row w-100">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label">Nature of Loss or Damage</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <label type="text" class="control-label" name="descriptionOfNature">{{furtherDetails.descriptionOfLossOrDamage.value}}</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <span *ngIf="furtherDetails.descriptionOfLossOrDamage.errors?.required" class="help-block error">Required</span>
            </div>
          </div>
        </div>

        <div class="form-row mt-4" *ngIf="claimType.value?.id=='0'">
          <div class="row w-100">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label"> Are Goods Repairable?</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <div *ngIf="furtherDetails.isGoodsRepairable.value=='true'">
                <label type="text" class="control-label">Yes</label>
              </div>
              <div *ngIf="furtherDetails.isGoodsRepairable.value=='false'">
                <label type="text" class="control-label">No</label>
              </div>

            </div>
          </div>
        </div>
        <div class="form-row mt-4 mb-4" *ngIf="claimType.value?.id=='0'">
          <div class="row w-100">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label">Current Location of Damaged Goods</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <label type="text" class="control-label" name="descriptionOfGoods">{{furtherDetails.damagedGoodsLocation.value}}</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <span *ngIf="furtherDetails.damagedGoodsLocation.errors?.required" class="help-block error">Required</span>
            </div>
          </div>
        </div>
        <div class="form-row mt-4" *ngIf="claimType.value?.id=='0'">
          <div class="form-group row w-100">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label">Is Salvage Required?</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <div>
                <div *ngIf="furtherDetails.isSalvageRequired.value=='true'">
                  <label type="text" class="control-label">Yes</label>
                </div>
                <div *ngIf="furtherDetails.isSalvageRequired.value=='false'">
                  <label type="text" class="control-label">No</label>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-12">
              <span *ngIf="furtherDetails.isSalvageRequired.touched && furtherDetails.isSalvageRequired.errors?.required" class="help-block error">Salvage status must be selected</span>
            </div>
          </div>
        </div>
        <div class="form-row mt-4" id="initialContactDetails" *ngIf="furtherDetails.isSalvageRequired.value == 'true'">
          <div class="form-group row w-100">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label" for="txtSalvageDetails">Salvage Details</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <label class="control-label">{{furtherDetails.salvageDetails.value}}</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <!--<span *ngIf="consignmentForm.initialContactDetails?.touched && consignmentForm.initialContactDetails?.errors?.required" class="help-block error">Details of Initial Contact is required</span>-->
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
  <!--Supporting documents-->
  <div class="card">
    <div id="headingFive" [ngClass]="this.supportingDocumentsForm.invalid?'card-header error':'card-header'">
      <h2 class="mb-0">
        <button type="button" class="btn btn-link" data-toggle="collapse" data-target="#collapseFive"> Supporting Information</button>
        <button type="button" class="btn btn-link float-right" (click)="editDetails('amxsupportinginformation')"> <span class="error-notice">Click to fix errors</span><i data-icon="k" class="icon-exclamation-triangle"></i><i data-icon="d" class="icon"></i> </button>
      </h2>
    </div>
    <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
      <div class="card-body">

        <!--<div style="text-align:justify" *ngIf="customer.customerType.value=='1'">
          <div>To verify the actual cost of the goods subject to this claim please supply the following documents.</div>
          <div class="form-row mt-4">
            <div class="w-100">
              <div class="form-group row">
                <div class="col-md-4 col-sm-12 text-right">
                  <label class="control-label">Statement on company letterhead of cost to manufacture goods or screenshot of cost of goods from accounting system</label>
                </div>
                <div class="col-md-4 col-sm-12">
                  <ng-container *ngFor="let attachment of attachments let i = index">
                    <label class="control-label d-block" *ngIf="attachment.fileFrom==1">
                      {{attachment.file.name}}
                    </label>
                  </ng-container>
                </div>

              </div>
            </div>
          </div>

          <div class="form-row mt-4">
            <div class="w-100">
              <div class="form-group row">
                <div class="col-md-4 col-sm-12 text-right">
                  <label class="control-label">Sales invoice for the goods shipped</label>
                </div>
                <div class="col-md-4 col-sm-12">
                  <ng-container *ngFor="let attachment of attachments let i = index">
                    <label class="control-label d-block" *ngIf="attachment.fileFrom==2">
                      {{attachment.file.name}}
                    </label>
                  </ng-container>
                </div>
                <div class="col-md-4 col-sm-12">
                  <p *ngIf="supportingDocuments.fileIDRT2?.errors?.required" class="help-block error">Required</p>
                </div>
              </div>
            </div>
          </div>

          <div class="form-row mt-4" *ngIf="claimType.value?.id=='0'">
            <div class="w-100">
              <div class="form-group row">
                <div class="col-md-4 col-sm-12 text-right">
                  <label class="control-label">Repair quote to have goods repaired</label>
                </div>
                <div class="col-md-4 col-sm-12">
                  <ng-container *ngFor="let attachment of attachments let i = index">
                    <label class="control-label d-block" *ngIf="attachment.fileFrom==3">
                      {{attachment.file.name}}
                    </label><br />
                  </ng-container>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div style="text-align:justify" *ngIf="customer.customerType.value=='2' || customer.customerType.value=='3'">
          <div>To verify the actual cost of the goods subject to this claim please supply the following documents.</div>

          <div class="form-row mt-4">
            <div class="w-100">
              <div class="form-group row">
                <div class="col-md-4 col-sm-12 text-right">
                  <label class="control-label">The supplier's invoice showing the cost price of the goods</label>
                </div>
                <div class="col-md-4 col-sm-12">
                  <ng-container *ngFor="let attachment of attachments let i = index">
                    <label class="control-label d-block" *ngIf="attachment.fileFrom==1">
                      {{attachment.file.name}}
                    </label>
                  </ng-container>
                </div>

              </div>
            </div>
          </div>

          <div class="form-row mt-4">
            <div class="w-100">
              <div class="form-group row">
                <div class="col-md-4 col-sm-12 text-right">
                  <label class="control-label">Sales invoice for the goods shipped</label>
                </div>
                <div class="col-md-4 col-sm-12">
                  <ng-container *ngFor="let attachment of attachments let i = index">
                    <label class="control-label d-block" *ngIf="attachment.fileFrom==2">
                      {{attachment.file.name}}
                    </label>
                  </ng-container>
                </div>
                <div class="col-md-4 col-sm-12">
                  <p *ngIf="supportingDocuments.fileIDRT2?.errors?.required" class="help-block error">Required</p>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="claimType.value?.id=='0'">

            <div class="form-row mt-4">
              <div class="w-100">
                <div class="form-group row">
                  <div class="col-md-4 col-sm-12 text-right">
                    <label class="control-label">Repair quote to have goods repaired</label>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <ng-container *ngFor="let attachment of attachments let i = index">
                      <label class="control-label d-block" *ngIf="attachment.fileFrom==3">
                        {{attachment.file.name}}
                      </label>
                    </ng-container>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <p *ngIf="supportingDocuments.fileIDRT3?.errors?.required" class="help-block error">Required</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div style="text-align:justify" *ngIf="customer.customerType.value=='0'">
          <div>To verify the actual cost of the goods subject to this claim please supply the following documents.</div>

          <div class="form-row mt-4">
            <div class="w-100">
              <div class="form-group row">
                <div class="col-md-4 col-sm-12 text-right">
                  <label class="control-label">A purchase receipt / retail tax invoice / eBay sales invoice or similar</label>
                </div>
                <div class="col-md-4 col-sm-12">
                  <ng-container *ngFor="let attachment of attachments let i = index">
                    <label class="control-label d-block" *ngIf="attachment.fileFrom==1">
                      {{attachment.file.name}}
                    </label>
                  </ng-container>
                </div>
                <div class="col-md-4 col-sm-12">
                  <p *ngIf="supportingDocuments.fileIDRT1?.errors?.required" class="help-block error">Required</p>
                </div>
              </div>
            </div>
          </div>

          <div class="form-row mt-4">
            <div class="w-100">
              <div class="form-group row">
                <div class="col-md-4 col-sm-12 text-right">
                  <label class="control-label">If goods were not purchased, a detailed account of what the goods were and where they came from</label>
                </div>
                <div class="col-md-4 col-sm-12">
                  <ng-container *ngFor="let attachment of attachments let i = index">
                    <label class="control-label d-block" *ngIf="attachment.fileFrom==2">
                      {{attachment.file.name}}
                    </label>
                  </ng-container>
                </div>
                <div class="col-md-4 col-sm-12">
                  <p *ngIf="supportingDocuments.fileIDRT2?.errors?.required" class="help-block error">Required</p>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="claimType.value?.id=='0'">
            <div class="form-row mt-4">
              <div class="w-100">
                <div class="form-group row">
                  <div class="col-md-4 col-sm-12 text-right">
                    <label class="control-label">Repair quote to have goods repaired</label>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <ng-container *ngFor="let attachment of attachments let i = index">
                      <label class="control-label d-block" *ngIf="attachment.fileFrom==3">
                        {{attachment.file.name}}
                      </label>
                    </ng-container>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <p *ngIf="supportingDocuments.fileIDRT3?.errors?.required" class="help-block error">Required</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="form-row mt-4">
          <div class="w-100">
            <div class="form-group row">
              <div class="col-md-4 col-sm-12 text-right">
                <label class="control-label">If you'd like to provide any further supporting information that you believe would assist us in assessing your claim, please feel free to do so here</label>
              </div>
              <div class="col-md-4 col-sm-12">
                <ng-container *ngFor="let attachment of attachments let i = index">
                  <label class="control-label d-block" *ngIf="attachment.fileFrom==4">
                    {{attachment.file.name}}
                  </label>
                </ng-container>
              </div>
              <div class="col-md-4 col-sm-12">
                <p *ngIf="supportingDocuments.fileID4?.errors?.required" class="help-block error">Required</p>
              </div>
            </div>
          </div>
        </div>

        <div class="form-row mt-4">
          <div class="w-100">
            <div class="form-group row">
              <div class="col-md-4 col-sm-12 text-right">
                <label class="control-label">Photos of Packaging</label>
              </div>
              <div class="col-md-4 col-sm-12">
                <ng-container *ngFor="let attachment of attachments let i = index">
                  <label class="control-label d-block" *ngIf="attachment.fileFrom==5">
                    {{attachment.file.name}}
                  </label>
                </ng-container>
              </div>
              <div class="col-md-4 col-sm-12">
                <p *ngIf="supportingDocuments.fileID5?.errors?.required" class="help-block error">Required</p>
              </div>
            </div>
          </div>
        </div>-->


        <div div class="form-row mt-4">
          <div class="w-100">
            <div class="col-12 col-md-9 m-auto" *ngIf="supportingDocumentsForm.invalid">
              <div class="important-notice" *ngIf="customer.customerType.value!=0">
                To verify the actual cost of the goods subject to this claim please supply the following documents.
                <ul>
                  <ng-container *ngFor="let doc of documentTypes">
                    <li *ngIf="doc.requiredStatus==0 && doc.fileAdded == false">{{doc?.name}}</li>
                  </ng-container>

                </ul>
              </div>
            </div>
            <div class="col-12 col-md-9 m-auto mt-3" *ngIf="customer.customerType.value!=0">
              <div class="important-notice mt-3">
                <p class="mb-0"><b class="font-weight-bold">IMPORTANT:</b></p>
                <ul>
                  <li> <div>If only a sales invoice is provided, your claim will be paid out at a maximum of 35% of the sales invoice value, exclusive of GST and subject to the Aramex Standard Warranty Conditions.</div></li>
                  <li> <div>If a supplier’s invoice is provided, your claim will be paid out at a maximum of 100% of your cost, exclusive of GST and subject to the Aramex Standard Warranty Conditions.             </div></li>
                </ul>
              </div>
            </div>

            <div class="col-12 col-md-9 m-auto mt-3" *ngIf="customer.customerType.value==0 && supportingDocumentsForm.invalid">
              <div class="important-notice">
                <p class="mb-0"><b class="font-weight-bold">IMPORTANT:</b></p>
                <ul>
                  <li><div>At least one supporting document should be submitted (Purchase receipt / retail tax invoice / eBay sales invoice or similar is prefered)</div></li>
                </ul>
              </div>
            </div>

          </div>
        </div>

        <!--<div div class="form-row mt-4" *ngIf="myForm.documentType.value?.information">
          <div class="w-100">
            <div class="col-12 col-md-9 m-auto" *ngIf="supportingDocumentForm.invalid">
              <div class="important-notice" [innerHtml]="myForm.documentType.value?.information"></div>
            </div>
          </div>
        </div>-->

        <div *ngFor="let uploads of attachments; let indexOfDocType = index;" class="col-12 col-md-9 m-auto">
          <div class="w-100">
            <div class="uploaded-files mt-2">
              <i class="icon-clip"></i> {{uploads.documentType.name}} -
              <div *ngFor="let file of uploads.files; let indexOfFile =index; ">
                <i class="icon-file-image-o"></i> {{file.name}}
                <!--<i class="icon icon-cancel-circled" (click)="removeFile(indexOfFile,indexOfDocType);">
                </i>-->
              </div>
              <!--<fileUploader (selectedfiles)="getSelectedFiles($event,uploads)"
                [enableMultiple]="allowMultipleUploads" [showAddButton]="true"></fileUploader>-->
              <!--<i class="icon icon-cancel-circled" (click)="openCamera(uploads.documentType.multiple);">
              </i>-->
              <!--  <button class="btn btn-primary" (click)="openCamera(uploads.documentType.multiple)">Snap Photo</button>-->
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
  <!--Payment Information-->
  <div class="card">
    <div id="headingEight" [ngClass]="this.paymentDetailsForm.invalid?'card-header error':'card-header'">
      <h2 class="mb-0">
        <button type="button" class="btn btn-link" data-toggle="collapse" data-target="#collapseEight"> Payment Information</button>
        <button type="button" class="btn btn-link float-right" (click)="editDetails('amxpayment')"> <span class="error-notice">Click to fix errors</span><i data-icon="k" class="icon-exclamation-triangle"></i><i data-icon="d" class="icon"></i> </button>
      </h2>
    </div>
    <div id="collapseEight" class="collapse" aria-labelledby="headingEight" data-parent="#accordionExample">
      <div class="card-body">

        <div class="form-row mt-4">
          <div class="row w-100">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label">Account Name</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <label type="text" class="control-label" amount="claimValue" name="ClaimValue">{{paymentDetails.accountName.value}}</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <span *ngIf="paymentDetails.accountName.errors?.required" class="help-block error">Required</span>
            </div>
          </div>
        </div>

        <div class="form-row mt-4" *ngIf="customer.isInternationalClaimant.value == 'true'">
          <div class="row w-100">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label">Account Address</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <label type="text" class="control-label">{{paymentDetails.accountAddress.value}}</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <span *ngIf="paymentDetails.accountAddress.errors?.required" class="help-block error">Required</span>
            </div>
          </div>
        </div>

        <div class="form-row mt-4">
          <div class="row w-100">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label">Bank Name</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <label type="text" class="control-label">{{paymentDetails.nameOfBank.value}}</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <span *ngIf="paymentDetails.nameOfBank.errors?.required" class="help-block error">Required</span>
            </div>
          </div>
        </div>

        <div class="form-row mt-4" *ngIf="customer.isInternationalClaimant.value == 'true'">
          <div class="row w-100">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label">Bank Address</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <label type="text" class="control-label">{{paymentDetails.bankAddress.value}}</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <span *ngIf="paymentDetails.bankAddress.errors?.required" class="help-block error">Required</span>
            </div>
          </div>
        </div>

        <ng-container *ngIf="customer.isInternationalClaimant.value == 'false'">
          <!--class="form-row mt-4"-->
          <!---->
          <div class="form-row mt-4">
            <div class="row w-100">
              <div class="col-md-4 col-sm-12 text-right">
                <label class="control-label">BSB</label>
              </div>
              <div class="col-md-4 col-sm-12">
                <label type="text" class="control-label">{{paymentDetails.bsbNumber.value}}</label>
              </div>

              <div class="col-md-4 col-sm-12">
                <div [ngClass]="{'help-block error' : ((!this.paymentDetails.bsbNumber.valid) && (this.paymentDetails.bsbNumber.errors && this.paymentDetails.bsbNumber.value))
                 || ((this.paymentDetails.bsbNumber.touched)  && (this.paymentDetails.bsbNumber.errors && !this.paymentDetails.bsbNumber.value))}">
                  <span *ngIf="paymentDetails.bsbNumber.errors?.required">Required</span>
                  <span *ngIf="paymentDetails.bsbNumber.errors?.minlength">Too Short<br /></span>
                  <span *ngIf="paymentDetails.bsbNumber.errors?.maxlength">Too Long<br /></span>
                  <span *ngIf="paymentDetails.bsbNumber.errors?.invalidNumber">BSB number should be numeric<br /></span>
                  <span *ngIf="paymentDetails.bsbNumber.touched &&  paymentDetails.bsbNumber.errors?.invalidBSB">BSB number is invalid<br /></span>
                </div>
              </div>

            </div>
          </div>
        </ng-container>

        <div class="form-row mt-4">
          <div class="row w-100">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label">Bank Account Number</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <label type="text" class="control-label">{{paymentDetails.accountNumber.value}}</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <div [ngClass]="{'help-block error' : (paymentDetails.accountNumber.errors?.required)
                 || (paymentDetails.accountNumber.errors && paymentDetails.accountNumber.value)}">
                <span *ngIf="paymentDetails.accountNumber.errors?.required">Required<br /></span>
                <span *ngIf="paymentDetails.accountNumber.errors?.minlength">Too Short<br /></span>
                <span *ngIf="paymentDetails.accountNumber.errors?.maxlength">Too Long<br /></span>
                <span *ngIf="paymentDetails.accountNumber.errors?.invalidNumber ">The account number should be numeric<br /></span>
              </div>
            </div>
          </div>
        </div>

        <div class="form-row mt-4" *ngIf="customer.isInternationalClaimant.value == 'true'">
          <div class="row w-100">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label">Bank ABA/Routing</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <label type="text" class="control-label">{{paymentDetails.bankABA.value}}</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <div [ngClass]="{'help-block error' : (paymentDetails.bankABA.errors?.required)
                 || (paymentDetails.bankABA.errors && paymentDetails.bankABA.value)}">
                <span *ngIf="paymentDetails.bankABA.errors?.required">Required<br /></span>
                <span *ngIf="paymentDetails.bankABA.errors?.minlength">Too Short<br /></span>
                <span *ngIf="paymentDetails.bankABA.errors?.maxlength">Too Long<br /></span>
                <span *ngIf="paymentDetails.bankABA.errors?.invalidNumber ">The bank ABA number should be numeric<br /></span>
              </div>
            </div>
          </div>
        </div>

        <div class="form-row mt-4" *ngIf="customer.isInternationalClaimant.value =='true'">
          <div class="row w-100">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label">Bank SWIFT</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <label type="text" class="control-label">{{paymentDetails.bankSWIFT.value}}</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <div [ngClass]="{'help-block error' :(paymentDetails.bankSWIFT.errors?.required)
                 || (paymentDetails.bankSWIFT.errors && paymentDetails.bankSWIFT.value)}">
                <span *ngIf="paymentDetails.bankSWIFT.errors?.required">Required<br /></span>
                <span *ngIf="paymentDetails.bankSWIFT.errors?.minlength">Too Short<br /></span>
                <span *ngIf="paymentDetails.bankSWIFT.errors?.maxlength">Too Long<br /></span>
                <span *ngIf="paymentDetails.bankSWIFT.errors?.invalidCharacter ">The account number should be valid character<br /></span>
              </div>
            </div>
          </div>
        </div>

        <div class="form-row mt-4" *ngIf="customer.isInternationalClaimant.value =='true'">
          <div class="row w-100">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label">Currency</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <label type="text" class="control-label">{{paymentDetails.accountCurrency.value?.code}}</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <span *ngIf="paymentDetails.accountCurrency.errors?.required" class="help-block error">Required</span>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
  <!--T&C Information-->
  <div class="card">
    <div id="headingNine" [ngClass]="this.termsAndConditionsForm.invalid?'card-header error':'card-header'">
      <h2 class="mb-0">
        <button type="button" class="btn btn-link" data-toggle="collapse" data-target="#collapseNine"> Terms and Conditions </button>
        <button type="button" class="btn btn-link float-right" (click)="editDetails('amxtermsandconditions')"><span class="error-notice">Click to fix errors</span><i data-icon="k" class="icon-exclamation-triangle"></i> <i data-icon="d" class="icon"></i> </button>
      </h2>
    </div>
    <div id="collapseNine" class="collapse show" aria-labelledby="headingNine" data-parent="#accordionExample">
      <div class="card-body">

        <div class="form-row mt-4">
          <div class="col-sm-12">
            <label class="control-label font-weight-bold text-left" *ngIf="termsAndConditions?.isAgreed?.value ==false">
              You have not agreed to the terms and conditions of Aramex, In order to submit the claim you have to agree to the Terms and Conditions of Aramex. Please navigate to the previous page.
            </label>
            <label class="control-label font-weight-bold text-left" *ngIf="termsAndConditions?.isAgreed?.value ==true">
              I have read and agreed to the Terms and Conditions of Aramex.
            </label>
          </div>
          <div class="col-md-4 col-sm-12">
            <div>

            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
<modal #modal></modal>
