import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';

import { CommonService } from '../../../services/common/common.service';
import { FormService } from '../../../services/common/form.service';

@Component({
  selector: 'app-claim-information',
  templateUrl: './claim-information.component.html',
  styleUrls: ['./claim-information.component.css']
})
export class ClaimInformationComponent implements OnInit {
  claimInformationForm: UntypedFormGroup = this.formService.claimInformationForm;
  claimType = this.formService.consignmetInformationForm?.controls?.claimType?.value;
  claimValueFieldDisplayName!: string;
  descriptionOfGoodsName!: string;
  descriptionOfLossOrDamageName!: string;
  get myForm() {
    return this.claimInformationForm.controls;
  }

  constructor(private formService: FormService, private commonService: CommonService) { }


  ngOnInit(): void {
    let labelName = 'Value of Claim at Cost <em>(excluding GST)</em> ';
    this.claimValueFieldDisplayName = this.getDisplayNameCustomField('claimValue', labelName);
    this.claimValueFieldDisplayName = this.claimValueFieldDisplayName.concat(this.myForm.claimValue?.hasError('required') ? '<span *ngIf="myForm.claimValue?.errors?.required">*</span>' : '');
    this.descriptionOfGoodsName = this.getDisplayNameCustomField('descriptionOfGoods', 'Description of Goods');
    this.descriptionOfLossOrDamageName=this.getDisplayNameCustomField('descriptionOfLossOrDamage', 'Nature of Loss or Damage')

    this.myForm.claimValue.valueChanges.subscribe(value => {
      if (value) {
        let labelName = 'Value of Claim at Cost <em>(excluding GST)</em> ';
        this.claimValueFieldDisplayName = this.getDisplayNameCustomField('claimValue', labelName);
        this.claimValueFieldDisplayName = this.claimValueFieldDisplayName.concat(this.myForm.claimValue?.hasError('required') ? ' <span *ngIf="myForm.claimValue?.errors?.required">*</span>' : '');

      } else {
        let labelName = 'Value of Claim at Cost <em>(excluding GST)</em> ';
        this.claimValueFieldDisplayName = this.getDisplayNameCustomField('claimValue', labelName);
        this.claimValueFieldDisplayName = this.claimValueFieldDisplayName.concat(this.myForm.claimValue?.hasError('required') ? ' <span *ngIf="myForm.claimValue?.errors?.required">*</span>' : '');

      }

    });
  }
  getDisplayNameCustomField(fieldName: string, defaultValue: string): string {
    return this.commonService.getCustomFieldDisplayName(fieldName, defaultValue);
  }
}
