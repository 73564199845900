<!--<template #modal >-->
  <div class="modal-header"> {{consignmentNoteName}}
  <button class="close" aria-label="Close" (click)="modalClose()">
  <span aria-hidden="true">×</span>
</button>
  </div>
  <div class="multisteps-form__content">
    <form [formGroup]="consignmetInformationForm">
      <div class="form-row mt-4">
        <div class="w-100">
          <div class="form-group row">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label" for="inputConNoteNumber">
                {{consignmentNoteName}}  <span *ngIf="myForm.consignmentNoteNo.errors?.required">*</span>
              </label>
            </div>
            <div class="col-md-4 col-sm-12">
              <input type="text" class="form-control" formControlName="consignmentNoteNo"
                     id="inputConNoteNumber" name="inputConNoteNumber"
                     [placeholder]="consignmentNoteName" maxlength="50"
                     (blur)="onBlur()">
            </div>
            <div class="col-md-4 col-sm-12">
              <span *ngIf="myForm.consignmentNoteNo?.touched && myForm.consignmentNoteNo?.errors?.required" class="help-block error">Required</span>
            </div>
          </div>
          <div class="form-group row" *ngIf="showListOfCarriers == true">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label" for="inputConNoteNumber">
                Please Select a Freight Carrier  <span *ngIf="myForm.consignmentNoteNo.errors?.required">*</span>
              </label>
            </div>
            <div class="col-md-4 col-sm-12">
              <select class="form-control" formControlName="selectedFreightCarrier">
                <option *ngFor="let freightCarrier of freightCarriers" [ngValue]="freightCarrier">{{freightCarrier.name}}</option>
              </select>
            </div>
            <div class="col-md-4 col-sm-12">
              <!-- <span *ngIf="myForm.consignmentNoteNo?.touched && myForm.consignmentNoteNo?.errors?.required" class="help-block error">Required</span>-->
            </div>
          </div>

          <div class="form-group row" *ngIf="showListOfCarriers == true">
            <div class="col-md-12 col-sm-12 text-center">
              <label class="control-label" for="inputConNoteNumber">
                If the Freight Carrier is not listed above please click <button class="btn-primary" (click)="skipCarrierSelection()">skip</button>
              </label>
            </div>
          </div>
        </div>
      </div>
      </form>
    </div>
  <div class="modal-footer">
   <button type="button" [disabled] ="(!myForm.selectedFreightCarrier.value && showListOfCarriers) || (!showListOfCarriers && !myForm.consignmentNoteNo.value)" class="btn btn-success" (click)="navigateToClaimForm()">Okay</button>
  </div>

<!--</template>-->
