<fieldset>
  <h3 class="multisteps-form__title">Franchisee Details </h3>
  <div class="multisteps-form__content">
    <form [formGroup]="franchiseeDetailsForm">
      <div formGroupName="franchisee">
        <div class="form-row mt-4">
          <div class="w-100">
            <div class="form-group row ">
              <div class="col-md-4 col-sm-12 text-right">
                <label for="inputFranchiseeCode" class="control-label">Franchisee Code <span *ngIf="franchisee.get('code')?.errors?.required">*</span> </label>
              </div>
              <div class="col-md-4 col-sm-12">
                <input class="form-control" formControlName="code" id="inputFranchiseeCode" name="inputFranchiseeCode" placeholder="Franchisee Code">
              </div>
              <div class="col-md-4 col-sm-12">
                <div [ngClass]="{'help-block error' : (franchisee.get('code')?.errors?.required && franchisee.get('code')?.touched)
                   ||(franchisee.get('code')?.errors && franchisee.get('code')?.value)}">
                  <span *ngIf="franchisee.get('code')?.touched && franchisee.get('code')?.errors?.required">Required<br /></span>
                  <span *ngIf="franchisee.get('code')?.errors?.maxlength || franchisee.get('code')?.errors?.minlength">Franchisee code should be 3 digits<br /></span>
                  <span *ngIf="franchisee.get('code')?.errors?.invalidNumber">Franchisee code should be numeric<br /></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row mt-4">
          <div class="w-100">
            <div class="form-group row ">
              <div class="col-md-4 col-sm-12 text-right">
                <label for="name" class="control-label">Franchisee Name <span *ngIf="franchisee.get('name')?.errors?.required">*</span> </label>
              </div>
              <div class="col-md-4 col-sm-12">
                <input type="text" class="form-control" formControlName="name" id="inputFranchiseeName" name="name" placeholder="Franchisee Name">
              </div>
              <div class="col-md-4 col-sm-12">
                <span *ngIf="franchisee.get('name')?.touched && franchisee.get('name')?.errors?.required" class="help-block error">Required</span>

              </div>
            </div>
          </div>
        </div>
        <div class="form-row mt-4">
          <div class="w-100">
            <div class="form-group row ">
              <div class="col-md-4 col-sm-12 text-right">
                <label for="inputFranchiseeEmail" class="control-label">Email Address <span *ngIf="franchisee.get('email')?.errors?.required">*</span></label>
              </div>
              <div class="col-md-4 col-sm-12">
                <input type="email" class="form-control" formControlName="email" id="inputFranchiseeEmail" name="email" placeholder="Email Address">
              </div>
              <div class="col-md-4 col-sm-12">
                <div [ngClass]="{'help-block error' : (franchisee.get('email')?.errors?.required && franchisee.get('email')?.touched)
                   ||(franchisee.get('email')?.errors && franchisee.get('email')?.value)}">
                  <span *ngIf="franchisee.get('email')?.touched && franchisee.get('email')?.errors?.required">Required<br /></span>
                  <span *ngIf="franchisee.get('email')?.errors?.invalidEmailFormat">Email format Invalid <br /> Ex:- "name@domain.com.au"</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row mt-4">
          <div class="w-100">
            <div class="form-group row ">
              <div class="col-md-4 col-sm-12 text-right">
                <label for="inputFranchiseePhone" class="control-label">Phone Number</label>
              </div>
              <div class="col-md-4 col-sm-12">
                <input type="text" class="form-control" formControlName="businessphone" id="inputFranchiseePhone" name="businessphone" placeholder="Phone Number" numbers-Only ng-maxlength="10" ng-minlength="10">
              </div>
              <div class="col-md-4 col-sm-12">
                <div [ngClass]="{'help-block error' : (franchisee.get('businessphone')?.errors?.required && franchisee.get('businessphone')?.touched)
                   ||(franchisee.get('businessphone')?.errors && franchisee.get('businessphone')?.value)}">
                  <span *ngIf="franchisee.get('businessphone')?.errors?.invalidNumber">The phone number should be numeric<br /></span>
                  <span *ngIf="franchisee.get('businessphone')?.errors?.maxlength">Phone number must be 10 digits<br /></span>
                  <span *ngIf="franchisee.get('businessphone')?.errors?.minlength">Phone number must be 10 digits<br /></span>
                </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </form>
  </div>  <!--multisteps-form__content-->

</fieldset>

