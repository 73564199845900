<button class="btn btn-primary d-flex" (click)="onClick()" *ngIf="!showAddButton"><i class="iocn icon-upload pr-1"></i> Upload</button>
<button class="btn btn-primary btn-attachment" (click)="onClick()" *ngIf="showAddButton"><i class="iocn icon-plus"></i></button>
<input type="file" id="upload" style="display: none;" #fileUpload name="fileupload" (change)="onFileSelect($event)"
       [multiple]="enableMultiple"
       accept="application/pdf, text/plain,
        image/bmp, image/png, image/jpeg, image/jpg, image/tiff,
        application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
        application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document,video/mp4,video/x-m4v,video/*">
<!--<label type="hidden" class="control-label" id="podFile" name="podFile">{{myForm.podFile?.value}}</label>-->
<!--<label type="hidden" class="control-label" id="podFile" name="podFile">{{deviceType}}</label>-->
