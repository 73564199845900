

export class Constant {

  static readonly progressBarActiveClass = 'multisteps-form__progress-btn js-active icon-';

  static readonly progressBarInActiveClass = 'multisteps-form__progress-btn icon-';

  static readonly progressBarErrorClass = 'multisteps-form__progress-btn pending icon-';

  static readonly progressBarCompletedClass = 'multisteps-form__progress-btn completed icon-';

  static readonly supportingDocumentProgressBar = 'progress-bar progress-bar-striped progress-bar-animated bg-success';
  static readonly supportingDocumentProgressBarWarning = 'progress-bar progress-bar-striped progress-bar-animated bg-warning';
  static readonly supportingDocumentProgressBarDanger = 'progress-bar progress-bar-striped progress-bar-animated bg-danger';

  static readonly s3url = 'https://s3.ap-southeast-1.amazonaws.com/';

  static readonly dateFormat = 'DD/MM/YYYY'

  /** Max File Size is 25 MB'S*/
  static readonly maxFileSizeAllowed = 27 
  static readonly totalFileSizeAllowed = 27 

  static readonly claimantRole = [
    { id: 0, value: 'Sender' },
    { id: 1, value: 'Receiver' },
    { id: 2, value: 'Third Party' }
  ];

  static readonly claimType = [
    { id: 0, name: 'Damaged' },
    { id: 1, name: 'Lost in Transit' }
  ];

  static readonly states = [
    { name: 'NSW', value: 'NSW' },
    { name: 'NT', value: 'NT' },
    { name: 'QLD', value: 'QLD' },
    { name: 'SA', value: 'SA' },
    { name: 'TAS', value: 'TAS' },
    { name: 'VIC', value: 'VIC' },
    { name: 'WA', value: 'WA' }
  ];

  static readonly claimantTypes =
    [
      { id: 0, value: 'Individual' },
      { id: 1, value: 'Business' }
    ];

  static readonly amxCustomerType =
    [
      { id: 0, value: 'Individual' },
      { id: 1, value: 'Manufacturer' },
      { id: 2, value: 'Wholesaler' },
      { id: 3, value: 'Retailer' }
    ];

  static readonly depenentFieldNames =
    ['claimType', 'claimantRole', 'isInternationalClaimant']

  static readonly navigationDirection =
    {
      forward: 'Nxt',
      backward: 'Prev'
    }

  static readonly customComponentNames =
    {
      bank: 'Bank',
      franchisee: 'Franchisee',
      claimantdetail:'ClaimantDetails'
    }

  static readonly validators = {

    maxLengthValidator: 'maxLength',
    minLengthValidator: 'minLength',
    numericValidator: 'numeric',
    characterValidator: 'character',
    characterNumberValidator: 'charNum',
    startsWithChar: 'startsWithChar',
    emailValidator: 'email',
    customEmailValidator:'customEmail',
    requiredValidator: 'required'
  }

  static readonly carrierPrefix =
    {
      amxCarrier: 'AMX',
      finCarrier: 'FIN'
    }
  //linked claim creation will be suspended for following list at claim form level
  static linkedClaimSuspendedCarriers = "OWC";

  static readonly claimFormComponentNames =
    {
      Welcome: 'Welcome',
      Contact: 'Contact',
      ClaimantDetails:'ClaimantDetails',
      Consignment: 'Consignment',
      Sender: 'Sender',
      Receiver: 'Receiver',
      Claim: 'Claim',
      Document: 'Document',
      Franchisee: 'Franchisee',
      Bank: 'Bank',
      Preview: 'Preview',
      AMXWelcome: 'AMXWelcome',
      AmxExcessLabel: 'AmxExcessLabel',
      AmxPurchasingCustomer: 'AmxPurchasingCustomer',
      AmxSenderDetails: 'AmxSenderDetails',
      AmxReceiverDetails: 'AmxReceiverDetails',
      AmxCustomerInformation: 'AmxCustomerInformation',
      AmxRequiredSupportingInformation: 'AmxRequiredSupportingInformation',
      AmxParcelDetails: 'AmxParcelDetails',
      AmxFurtherDetails: 'AmxFurtherDetails',
      AmxPayment: 'AmxPayment',
      AmxTermsAndConditions: 'AmxTermsAndConditions',
      AmxPreview: 'AmxPreview',
      Approve:'Approve',
      Cancel: 'Cancel',
      CarrierSelection:'CarrierSelection'
    }

  static readonly documentTypeDescription = [
    {
      controlName: 'cpiFile',
      description: 'Statement on company letterhead of cost to manufacture goods or screenshot of cost of goods from accounting system',
      customerType: Constant.amxCustomerType[1].id
    },
    {
      controlName: 'cpiFile',
      description: 'A purchase receipt / retail tax invoice / eBay sales invoice or similar. <br/> If goods were not purchased, a detailed account of what the goods were and where they came from.',
      customerType: Constant.amxCustomerType[0].id
    }
]

  static readonly suportingDocumentTypesKey = 'SuportingDocumentTypesKey';
}
