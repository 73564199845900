import { Component, OnInit } from '@angular/core';
import { FormService } from '../../../../services/common/form.service';

@Component({
  selector: 'app-amx-terms-and-conditions',
  templateUrl: './amx-terms-and-conditions.component.html',
  styleUrls: ['./amx-terms-and-conditions.component.css']
})
export class AmxTermsAndConditionsComponent implements OnInit {

  termsAndConditionsForm = this.formService.amxTermsAndConditionsForm;
  constructor(private formService: FormService) { }
  date = new Date();
  get myForm() {
    return this.termsAndConditionsForm?.controls;
  }
 
  ngOnInit(): void {
  }

}
