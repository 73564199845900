<!--<img src="../assets/images/abc/logo.png" />-->
<!--<body>-->
<!-- partial:index.partial.html -->
<!--PEN HEADER-->
<header class="header"> </header>
<!--PEN CONTENT     -->
<div class="content">
  <meta *ngIf="carrierConfig" name="description" [content]="metaContent">
  <!--content inner-->
  <div class="content__inner">
    <div class="container"> </div>
    <div class="container">
      <!--multisteps-form-->
      <div class="multisteps-form background-1">
        <!--progress bar-->
        <div class="row">
          <div class="col-md-7 col-sm-12">
            <app-progress-bar *ngIf="carrierConfig"> </app-progress-bar>
          </div>
          <div class="col-md-5 col-sm-hidden">
            <div class="text-right">
              <img class="img-fluid logo" src="../assets/images/logo-freight-safe.png" />
            </div>
          </div>
          <hr class="top-line" />
        </div>
        <div class="row">
          <!--form panels-->
          <div class="col-12 m-auto">
            <div class="multisteps-form__form">
              <div class="p-4 js-active" data-animation="scaleIn">
                <div class="multisteps-form__content">
                  <router-outlet></router-outlet>
                  <div class="row button-row mt-4">
                    <div class="col-md-6 text-left col-sm-hidden">
                      <img class="img-fluid img-client" *ngIf="show" [src]="carrierLogo" />
                    </div>
                    <app-action-buttons *ngIf="carrierConfig" class="col-md-6 col-sm-12 text-right"></app-action-buttons>
                  </div>
                  <div class="row">
                    <div class="col-md-12 text-left text-center small bottom">
                      &copy; FreightSafe {{year}} <br />
                      <a href="https://freightsafe.com/privacy-policy/" target="_blank">Privacy Policy</a>
                    </div>
                  </div>
                </div> <!--End of multisteps-form__content-->
              </div>
            </div>
          </div>
        </div>
        <div id="loader" *ngIf="isLoading && !isIE"><div class="lds-roller"><div></div><div></div><div></div><div></div> <div></div><div></div><div></div><div></div></div></div>
        <div id="loader" *ngIf="isLoading && isIE"> <div class="lds-roller IE"><p>Please Wait ...</p></div></div>
      </div>
    </div>
  </div>
</div>
<modal #mainmodal></modal>
<!-- partial -->
<!--</body>-->
