import { Component, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { AgGridAngular } from 'ag-grid-angular';
import { GridOptions } from 'ag-grid-community';
import { ModalOptions } from '../../../../models/ModalOptions';
import { CustomValidationService } from '../../../../services/common/custom-validation.service';
import { FormService } from '../../../../services/common/form.service';
import { ModalComponent } from '../../../common/modal/modal.component';

@Component({
  selector: 'app-amx-excess-labels',
  templateUrl: './amx-excess-labels.component.html',
  styleUrls: ['./amx-excess-labels.component.css']
})
export class AmxExcessLabelsComponent implements OnInit {
  columnsDef!: any;

  @ViewChild('modal') modal!: ModalComponent;
  modalbodyContent!: string;
  modalHeaderContent!: string;


  constructor(private formService: FormService, private customServiceValidator: CustomValidationService) { }
  excessLabelForm = this.formService.amxExcessLabelForm;
  gridItemSelected: boolean = false;
  multipleItemsSelected: boolean = false;
  isAddLabel: boolean = false;
  isEditLabel: boolean = false;
  selectedRows: any = null;

  /* Grid options*/
  gridOptions!: GridOptions;
  gridApi!: any;
  gridColumnApi!: any;


  get myForm() {
    return this.excessLabelForm.controls;
  }
  ngOnInit(): void {

    this.myForm?.enableLabels?.setValue(this.myForm?.excessLabels?.value && this.myForm?.excessLabels?.value.length > 0 ? 1 : 0);

    this.myForm?.enableLabels.valueChanges.subscribe(value => {
      if (!value) {
        this.resetFields();
      }
    });

    this.columnsDef = [
      {
        headerName: 'Label Number',
        field: 'excessLabel',
        checkboxSelection: true,
        colId: 'excessLabel',
        suppressMovable: true,
        resizable: true,
        flex: 1
      },
      {
        headerName: 'Label Cost',
        field: 'cost',
        suppressMovable: true,
        resizable: true,
        flex: 1,
        valueFormatter: (params: any) => {
          if (params.value == null || params.value == "") {
            return 0;
          }
          return params.value;
        }
      }
    ];

    this.gridOptions = {
      context: this,
      columnDefs: this.columnsDef,
      rowSelection: 'multiple',
      rowMultiSelectWithClick: true,
      onSelectionChanged: (event) => { this.getSelectedData(event) },
      getRowNodeId: (data) => data.excessLabel

    }
    this.setRemoveValidation(false);
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.setRowData(this.myForm?.excessLabels?.value)
  }

  getSelectedData(event: any) {
    const nodes: any[] = event.api.getSelectedNodes();
    this.selectedRows = nodes.map(n => { return n.data });

    if (this.selectedRows.length > 0) {
      if (this.selectedRows.length > 1) {
        this.multipleItemsSelected = true;
      } else {
        this.multipleItemsSelected = false;
      }
      this.gridItemSelected = true;
      this.setRemoveValidation(true);
      this.isAddLabel = false;
      this.myForm.excesslabelNumber.setValue(this.selectedRows[0].excessLabel);
      this.myForm.excesslabelCost.setValue(this.selectedRows[0].cost);
      this.myForm.excesslabelNumber.disable({ onlySelf: true });
    } else {
      this.gridItemSelected = false;
      this.multipleItemsSelected = false;
      this.isAddLabel = false;
      this.setRemoveValidation(false);
    }

  }

  saveExcessLabel() {
    let excessLabels: { excessLabel: string, cost: number }[];
    excessLabels = (this.myForm?.excessLabels?.value && this.myForm?.excessLabels?.value != "") ? this.myForm?.excessLabels?.value : [];
    /* Check if label already exists */

    var label = excessLabels?.filter(item => item.excessLabel == this.myForm.excesslabelNumber.value)[0];
    let cost = 0;
    if (this.myForm.excesslabelCost.value) {
      cost = this.myForm.excesslabelCost.value;
    }
    if (this.gridItemSelected) {
      // update the grid.
      if (label != null) {
        if (this.myForm?.excesslabelNumber?.value?.toLowerCase() == this.formService.amxParcelDetailsForm.get('labelNumber')?.value?.toLowerCase()) {
          const bodyContent = `The label ${this.myForm?.excesslabelNumber?.value} is the main label number for the claim it can not be edited.`;
          this.showInformation('Error', bodyContent, false);
          return;
        }
        label.cost = parseFloat(cost.toString());
        let transaction = {
          update: [{ excessLabel: this.myForm.excesslabelNumber.value, cost: cost }]
        }
        this.gridApi.applyTransaction(transaction);
      }
    } else {
      // add to grid
      if (label) {
        /// TODO show error message
        const bodyContent = `The label ${label.excessLabel} already exists in the list.<br/> If you want to edit the cost please select the label from the table above. `;
        this.showInformation('Error', bodyContent, false);
        return;
      } else {
        if (this.myForm?.excesslabelNumber?.value?.toLowerCase() == this.formService.amxParcelDetailsForm.get('labelNumber')?.value?.toLowerCase()) {

          const bodyContent = `The label ${this.myForm?.excesslabelNumber?.value} is the main label number for the claim it can not be added again.`;
          this.showInformation('Error', bodyContent, false);
          return;
        }

      }

      excessLabels?.push({ excessLabel: this.myForm.excesslabelNumber.value, cost: cost });
      this.myForm.excessLabels.setValue(excessLabels);
      let transaction = {
        add: [{ excessLabel: this.myForm.excesslabelNumber.value, cost: cost }]
      }
      this.gridApi.applyTransaction(transaction);
      this.myForm.excesslabelNumber.setValue(null);
      this.myForm.excesslabelCost.setValue(null);
      this.setRemoveValidation(false);
    }
    this.resetFields();
  }

  addLabel() {
    this.isAddLabel = true;
    this.setRemoveValidation(true, true);
    this.isEditLabel = false;
    this.myForm.excesslabelNumber.setValue(null);
    this.myForm.excesslabelCost.setValue(null);
    this.myForm.excesslabelNumber.enable({ onlySelf: true });
    this.myForm.excesslabelNumber.markAsUntouched();
  }

  deleteLabel() {

    const modalOptions: ModalOptions = {
      animation: true,
      centered: true,
      size: 'md',
      showConfirmationButtons: true,
      isHtmlContent: true,
      showCloseButton: true,
      showOkayButton: false
    }
    this.modalbodyContent = "Are you sure you want to delete this label?";
    this.modalHeaderContent = "Attention";

    this.modal.modalOpen(this.modalHeaderContent, this.modalbodyContent, modalOptions).result.then((value) => {
      if (value == 'confirm') {
        let excessLabels: { excessLabel: string, cost: number }[];
        excessLabels = this.myForm?.excessLabels?.value;

        for (let i = 0; i <= this.selectedRows.length; i++) {
          let index = excessLabels.findIndex(item => item.excessLabel == this.selectedRows[i].excessLabel);
          excessLabels.splice(index, 1);

          let transaction = {
            remove: [{ excessLabel: this.selectedRows[i].excessLabel, cost: this.selectedRows[i].cost }]
          }
          this.gridApi.applyTransaction(transaction);
        }

      }
    });

    //let label = excessLabels.find(item => item.excessLabel == this.myForm.excesslabelNumber.value);
    //if (excessLabels.length <= 0) {
    //  this.myForm?.enableLabels?.setValue(0);
    //}
  }

  showInformation(headerContent: string, bodyContent: string, rederect: boolean) {
    const modalOptions: ModalOptions = {
      animation: true,
      centered: true,
      size: 'md',
      showConfirmationButtons: false,
      isHtmlContent: true,
      showOkayButton: true
    }

    this.modalbodyContent = bodyContent;
    this.modalHeaderContent = headerContent;
    this.modal.modalOpen(this.modalHeaderContent, this.modalbodyContent, modalOptions).result.finally(() => {
      if (rederect) {
        window.location.href = 'https://www.aramex.com.au';
      }
    });

  }

  setRemoveValidation(setValidation: boolean, setLabelValidation: boolean = false) {
    if (setValidation) {
      this.myForm.excesslabelCost.setValidators(this.customServiceValidator.numericInputwithdecimalValidator());
      if (setLabelValidation) {
        this.myForm.excesslabelNumber.setValidators(Validators.required);
      }
    } else {
      this.myForm.excesslabelCost.clearValidators();
      this.myForm.excesslabelNumber.clearValidators();
    }
    this.excessLabelForm.updateValueAndValidity({ onlySelf: true });

  }

  resetFields() {
    this.myForm.excesslabelNumber.setValue(null);
    this.myForm.excesslabelNumber.markAsUntouched();
    this.myForm.excesslabelCost.setValue(null);
    this.isAddLabel = false;
    this.gridItemSelected = false;
    this.gridApi.deselectAll();
  }
}



