<div>
  <fieldset>
    <h3 class="multisteps-form__title">Consignment Information </h3>
    <div class="multisteps-form__content">
      <form [formGroup]="consignmetInformationForm">
        <div class="form-row mt-4">
          <div class="w-100">
            <div class="form-group row">
              <div class="col-md-4 col-sm-12 text-right">
                <label class="control-label" for="inputConNoteNumber">
                  {{consignmentNoteName}}  <span *ngIf="myForm.consignmentNoteNo.errors?.required">*</span>
                </label>
              </div>
              <div class="col-md-4 col-sm-12">
                <input type="text" class="form-control" formControlName="consignmentNoteNo"
                       id="inputConNoteNumber" name="inputConNoteNumber"
                       [placeholder]="consignmentNoteName" maxlength="50"
                       (blur) ="onBlur()">
              </div>
              <div class="col-md-4 col-sm-12">
                <span *ngIf="myForm.consignmentNoteNo?.touched && myForm.consignmentNoteNo?.errors?.required" class="help-block error">Required</span>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row mt-4">
          <div class="form-group row w-100">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label" for="dropClaimType">
                Claim Type <span *ngIf="myForm.claimType.errors?.required">*</span>
              </label>
            </div>
            <div class="col-md-4 col-sm-12">
              <select class="form-control" formControlName="claimType" id="dropClaimType" name="dropClaimType">
                <option selected disabled value="">Please Choose</option>
                <option *ngFor="let claimType of claimTypes " accesskey="claimType?.id" [ngValue]="claimType">{{claimType.name}}</option>
              </select>
            </div>
            <div class="col-md-4 col-sm-12">
              <span *ngIf="myForm.claimType?.touched && myForm.claimType?.errors?.required" class="help-block error">Required</span>
            </div>
          </div>
        </div>
        <div class="form-row mt-4">
          <div class="form-group row w-100">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label" for="inputDateDispatched">Date of Dispatch <span *ngIf="myForm.goodsDispatchedDateTime.errors?.required">*</span></label>
            </div>
            <div class="col-md-4 col-sm-12">
              <!--<md-datepicker id="inputDateDispatched" name="inputDateDispatched" md-placeholder="Please Select" required ng-model="claimForm.goodsDispatchedDateTime" md-max-date="claimForm.goodsReceivedDateTime ? claimForm.goodsReceivedDateTime : maxDate"></md-datepicker>
        <ngb-datepicker #dp [(ngModel)]="model" (navigate)="date = $event.next"></ngb-datepicker>-->
              <div class="input-group">
                <input class="form-control" formControlName="goodsDispatchedDateTime" [placeholder]="dateFormat"
                       name="ddp" ngbDatepicker #c="ngbDatepicker"
                       [maxDate]="maxDateDispatchDate">
                <!--(dateSelect)="onDispatchDateSelect($event)"-->

                <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" (click)="c.toggle()" type="button"><i data-icon="a" class="icon"></i></button>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-12">

              <div [ngClass]="(this.myForm.goodsDispatchedDateTime && this.myForm.goodsDispatchedDateTime.errors) && ((this.myForm.goodsDispatchedDateTime.errors.required && this.myForm.goodsDispatchedDateTime.touched)
          || (this.myForm.goodsDispatchedDateTime.value))? 'help-block error' :'' ">
                <span *ngIf="myForm.goodsDispatchedDateTime?.touched && myForm.goodsDispatchedDateTime?.errors?.required">Required<br /></span>
                <span *ngIf="myForm.goodsDispatchedDateTime?.errors?.dateInvalid">Invalid date format<br /></span>
                <span *ngIf="myForm.goodsDispatchedDateTime?.errors?.dateafterToday">Invalid date<br /></span>
                <span *ngIf="myForm.goodsDispatchedDateTime?.errors?.ngbDate?.maxDate && !myForm.goodsDispatchedDateTime?.errors?.dateafterToday">Dispatch date is later than the received date<br /></span>
              </div>
            </div>
          </div>
        </div>

        <div class="form-row mt-4" *ngIf="showDateOfReceipt">
          <div class="w-100">
            <div class="form-group row">
              <div class="col-md-4 col-sm-12 text-right">
                <label class="control-label" for="inputDateReceived">{{dateOfReceiptName}} <span *ngIf="myForm.goodsReceivedDateTime.errors?.required">*</span></label>
                <!--&& claimForm.claimType != 1-->
              </div>
              <div class="col-md-4 col-sm-12">
                <!--<md-datepicker id="inputDateReceived" name="inputDateReceived" md-placeholder="Please Select" md-min-date="claimForm.goodsDispatchedDateTime" md-max-date="maxDate" ng-model="claimForm.goodsReceivedDateTime" ng-required="claimForm.claimType != 1"></md-datepicker>-->
                <div class="input-group">
                  <input class="form-control" formControlName="goodsReceivedDateTime" [placeholder]="dateFormat"
                         [maxDate]="maxDate"
                         [minDate]="minDate"
                         name="rdp" ngbDatepicker #d="ngbDatepicker">
                  <!--(dateSelect)="onGoodsReceivedDateSelect($event)"-->

                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"><i data-icon="a" class="icon"></i></button>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-12">
                <div [ngClass]="(this.myForm.goodsReceivedDateTime && this.myForm.goodsReceivedDateTime.errors)
                     && ((this.myForm.goodsReceivedDateTime.errors.required && this.myForm.goodsReceivedDateTime.touched)  || (this.myForm.goodsReceivedDateTime.value))? 'help-block error' :'' ">
                  <span *ngIf="myForm.goodsReceivedDateTime?.touched && myForm.goodsReceivedDateTime?.errors?.required">Required<br /></span>
                  <span *ngIf="myForm.goodsReceivedDateTime?.errors?.dateInvalid">Invalid date format<br /></span>
                  <span *ngIf="myForm.goodsReceivedDateTime?.errors?.dateafterToday">Invalid date<br /></span>
                  <span *ngIf="myForm.goodsReceivedDateTime?.errors?.ngbDate?.minDate">Received date is earlier than the dispatch date<br /></span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-row mt-4">
          <div class="form-group row w-100">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label">{{ getDisplayNameCustomField('isSalvageRequired','Is Salvage Required?')}}</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <div>
                <label class="input-radio">
                  Yes
                  <input type="radio" formControlName="isSalvageRequired" value="1" name="isSalvageRequired">
                  <span class="checkmark"></span>
                </label>
                <label class="input-radio">
                  No
                  <input type="radio" formControlName="isSalvageRequired" value="0" name="isSalvageRequired">
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
            <div class="col-md-4 col-sm-12">
              <span *ngIf="myForm.isSalvageRequired?.touched && myForm.isSalvageRequired?.errors?.required" class="help-block error">Required</span>
            </div>
          </div>
        </div>
        <div class="form-row mt-4" *ngIf="myForm.isSalvageRequired.value == 1">
          <div class="form-group row w-100">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label">Salvage Details</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <textarea class="form-control" formControlName="salvageDetails" id="txtsalvageDetails" name="txtsalvageDetails" placeholder="Details of Salvage" rows="4" maxlength="1000"></textarea>
            </div>
            <div class="col-md-4 col-sm-12">
              <!--<span *ngIf="myForm.isSalvageRequired?.touched && myForm.isSalvageRequired?.errors?.required" class="help-block error">Required</span>-->
            </div>
          </div>
        </div>
        <div class="form-row mt-4">
          <div class="form-group row w-100">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label" for="inputDateInitialContact">
                Date of Initial Contact with {{carrierName}} <!--Needs to be carrier Name not prefix-->
                regarding lost/damaged goods <span *ngIf="myForm.initialContactDate?.errors?.required">*</span>
              </label>
            </div>
            <div class="col-md-4 col-sm-12">
              <!--<md-datepicker id="inputDateInitialContact" name="inputDateInitialContact" md-placeholder="Please Select" md-min-date="claimForm.goodsDispatchedDateTime" md-max-date="maxDate" ng-model="claimForm.initialContactDate" required></md-datepicker>-->
              <div class="input-group">
                <input class="form-control" formControlName="initialContactDate" [placeholder]="dateFormat"
                       [maxDate]="maxDate"
                       [minDate]="minDate"
                       name="idp" ngbDatepicker #e="ngbDatepicker">

                <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" (click)="e.toggle()" type="button"><i data-icon="a" class="icon"></i></button>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-12">
              <div [ngClass]="(this.myForm.initialContactDate && this.myForm.initialContactDate.errors) && ((this.myForm.initialContactDate.errors.required && this.myForm.initialContactDate.touched)
          || (this.myForm.initialContactDate.value))? 'help-block error' :'' ">
                <span *ngIf="myForm.initialContactDate?.touched && myForm.initialContactDate?.errors?.required">Required<br /></span>
                <span *ngIf="myForm.initialContactDate?.errors?.dateInvalid">Invalid date format<br /></span>
                <span *ngIf="myForm.initialContactDate?.errors?.dateafterToday">Invalid date<br /></span>
                <!--<span *ngIf="myForm.initialContactDate?.errors?.ngbDate?.maxDate"><br /></span>-->
                <span *ngIf="myForm.initialContactDate?.errors?.ngbDate?.maxDate && !myForm.initialContactDate?.errors?.dateafterToday">Initial contact date is in the future<br /></span>
                <span *ngIf="myForm.initialContactDate?.errors?.ngbDate?.minDate && !myForm.initialContactDate?.errors?.dateafterToday">Initial contact date is earlier than the dispatch date<br /></span>
              </div>
            </div>
          </div>
        </div>
        <!--This is not rendered to the DOM unless the carrier config has the field-->
        <div class="form-row mt-4" id="initialContactDetails" *ngIf="showInitialContactDetails">
          <div class="form-group row w-100">

            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label" for="txtInitialContactDetails">Details of Initial Contact with {{carrierName}} Regarding the Lost/Damaged Goods <span *ngIf="myForm.initialContactDetails?.errors?.required">*</span></label>
            </div>
            <div class="col-md-4 col-sm-12">
              <textarea class="form-control" formControlName="initialContactDetails" id="txtInitialContactDetails" name="txtInitialContactDetails" placeholder="Details of Initial Contact with {{carrierName}} Regarding the Lost/Damaged Goods" rows="4" maxlength="1000"></textarea>
            </div>
            <div class="col-md-4 col-sm-12">
              <span *ngIf="myForm.initialContactDetails?.touched && myForm.initialContactDetails?.errors?.required" class="help-block error">Required</span>
            </div>
          </div>
        </div>
      </form>
    </div>
    <!--multisteps-form__content-->
  </fieldset>

</div>
<modal #modal></modal>
