import { Component, OnInit, ViewChild } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import { ConfigurationModel } from '../../../../models/ConfigurationModel';
import { ModalOptions } from '../../../../models/ModalOptions';
import { SupportingDocumentType } from '../../../../models/SupportingDocumentType';
import { CommonService } from '../../../../services/common/common.service';
import { FormService } from '../../../../services/common/form.service';
import { NavigationService } from '../../../../services/common/navigation-service';
import { ModalComponent } from '../../../common/modal/modal.component';

@Component({
  selector: 'app-amx-preview',
  templateUrl: './amx-preview.component.html',
  styleUrls: ['./amx-preview.component.css']
})
export class AmxPreviewComponent implements OnInit {
  purchasingCustomerForm = this.formService.amxPurchasingCustomerForm;
  welcomeForm = this.formService.amxWelcomeForm;
  senderForm = this.formService.amxSenderForm;
  receiverForm = this.formService.amxReceiverForm;
  customerForm = this.formService.amxCustomerinformationForm;
  parcelForm = this.formService.amxParcelDetailsForm;
  furtherDetailsForm = this.formService.amxfurtherDetailsForm;
  paymentDetailsForm = this.formService.amxPaymentForm;
  termsAndConditionsForm = this.formService.amxTermsAndConditionsForm;
  supportingDocumentsForm = this.formService.amxSupportingDocumentForm;
  excessLabelForm = this.formService.amxExcessLabelForm;
  attachments = this.supportingDocuments.attachments.value;
  documentTypes: SupportingDocumentType[] = [];

  attachmentSub!: Subscription;

  carrierConfig!: ConfigurationModel;
  // modal properties
  @ViewChild('modal', { read: ModalComponent }) private modal!: ModalComponent;

  //excess label grid
  gridOptions!: GridOptions;
  columnsDef!: any;


  constructor(private formService: FormService,
    private navigationService: NavigationService,
    private commonService: CommonService) {

  }

  get purchasingCustomer() {
    return this.purchasingCustomerForm.controls.purchasingCustomer;
  }
  get claimantRole() {
    return this.welcomeForm.controls.claimantRole;
  }
  get sender() {
    return this.senderForm.controls.sender;
  }
  get receiver() {
    return this.receiverForm.controls.receiver;
  }
  get customer() {
    return this.customerForm.controls;
  }
  get parcel() {
    return this.parcelForm.controls;
  }
  get furtherDetails() {
    return this.furtherDetailsForm.controls;
  }
  get paymentDetails() {
    return this.paymentDetailsForm.controls;
  }
  get termsAndConditions() {
    return this.termsAndConditionsForm.controls;
  }
  get supportingDocuments() {
    return this.supportingDocumentsForm.controls;
  }
  get claimType() {
    return this.furtherDetailsForm?.controls?.claimType;
  }

  get excessLabels() {
    return this.excessLabelForm.controls;
  }

  ngOnInit(): void {

    this.purchasingCustomerForm.markAllAsTouched();
    this.welcomeForm.markAllAsTouched();
    this.senderForm.markAllAsTouched();
    this.receiverForm.markAllAsTouched();
    this.customerForm.markAllAsTouched();
    this.parcelForm.markAllAsTouched();
    this.furtherDetailsForm.markAllAsTouched();
    this.paymentDetailsForm.markAllAsTouched();
    this.termsAndConditionsForm.markAllAsTouched();
    this.supportingDocumentsForm.markAllAsTouched();
    this.carrierConfig = this.commonService.getCarrierConfigObject();

    this.documentTypes = this.formService.customizeDocumentTypes(this.carrierConfig, this.supportingDocumentsForm);

    // modal options
    const options: ModalOptions = {
      size: 'xl',
      centered: true,
      isHtmlContent: true
    }
    this.formService.modalCallBackMethod = (header: string, body: string) => { return this.modal.modalOpen(header, body, options); };

    this.attachmentSub = this.supportingDocuments.attachments.valueChanges.pipe().subscribe(value => {
      this.attachments = value;
    });

    this.columnsDef = [
      {
        headerName: 'Label Number',
        field: 'excessLabel',
        colId: 'excessLabel',
        suppressMovable: true,
        resizable: true,
        flex: 1
      },
      {
        headerName: 'Label Cost',
        field: 'cost',
        suppressMovable: true,
        resizable: true,
        flex: 1,
        valueFormatter: (params: any) => {
          if (params.value == null || params.value == "") {
            return 0;
          }
          return params.value;
        }
      }
    ];

    // excess label grid
    this.gridOptions = {
      context: this,
      columnDefs: this.columnsDef,
      rowData: this.excessLabels.excessLabels.value,
      suppressRowClickSelection: true
    }

  }
  editDetails(navigationPath: string) {
    this.navigationService.navigateToComponent(this.carrierConfig, undefined, navigationPath);
  }


}
