<h3 class="multisteps-form__title">Claim Summary & Confirmation</h3>
<div class="accordion" id="accordionExample">
  <!--Contact Information-->
  <div class="card">
    <div id="headingOne" [ngClass]="contactInformation.invalid?'card-header error':'card-header'">
      <!--<div class="card-header error" id="headingOne">-->
      <h2 class="mb-0">
        <button type="button" class="btn btn-link" data-toggle="collapse" data-target="#collapseOne"> Contact Information</button>
        <button type="button" class="btn btn-link float-right" (click)="editDetails('contact')"><span class="error-notice">Click to fix errors</span> <i data-icon="k" class="icon-exclamation-triangle"></i><i data-icon="d" class="icon"></i> </button>
      </h2>
    </div>
    <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
      <div class="card-body">
        <div div class="form-row mt-4">
          <fieldset>
            <div>
              <div class="form-row mt-4">
                <div class="form-group row w-100">
                  <div class="col-md-4 col-sm-12 text-right">
                    <label class="control-label" for="inputClaimant">Company Name</label>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <label type="text" class="control-label" id="inputClaimant" name="name" placeholder="Company Name">{{claimant.get('name')?.value}}</label>
                  </div>
                  <div class="col-md-4 col-sm-12"></div>
                </div>
              </div>
              <div class="form-row mt-4">

                <div class="form-group row">
                  <div class="col-md-4 col-sm-12 text-right">
                    <label class="control-label" for="inputClaimantContact">Contact Name <span *ngIf="claimant.get('claimantContact.name')?.errors?.required">*</span> </label>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <label type="text" class="control-label" id="inputClaimantContact" name="name" placeholder="Contact Name" maxlength="35">{{claimant.get('claimantContact.name')?.value}}</label>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <span *ngIf="claimant.get('claimantContact.name')?.touched && claimant.get('claimantContact.name')?.errors?.required " class="help-block error">Contact name is required</span>
                  </div>
                </div>
              </div>
              <div class="form-row mt-4" *ngIf="showAccountNumber">
                <div class="form-group row">
                  <div class="col-md-4 col-sm-12 text-right">
                    <label class="control-label" for="inputCustomerNumber">{{displayNameAccNumber}} <span *ngIf="claimant.get('accountNumber')?.errors?.required">*</span></label>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <label type="text" class="control-label" id="inputCustomerNumber" name="inputCustomerNumber" placeholder="Account Number">{{claimant.get('accountNumber')?.value}}</label>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <div [ngClass]="{'help-block error' : ((claimant.get('accountNumber')?.dirty || claimant.get('accountNumber')?.value || claimant.get('accountNumber')?.touched) && claimant.get('accountNumber')?.errors)}">
                      <span *ngIf="claimant.get('accountNumber')?.touched && claimant.get('accountNumber')?.errors?.required ">{{displayNameAccNumber}} is required</span>
                      <span *ngIf="(claimant.get('accountNumber')?.errors?.maxlength || claimant.get('accountNumber')?.errors?.minlength)">The {{displayNameAccNumber}} is invalid<br /></span>
                      <span *ngIf="claimant.get('accountNumber')?.errors?.invalidNumber ">The {{displayNameAccNumber}} should be numeric<br /></span>
                      <span *ngIf="claimant.get('accountNumber')?.errors?.invalidCharacter ">The {{displayNameAccNumber}} should be character<br /></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row mt-4">
                <div class="form-group row">
                  <div class="col-md-4 col-sm-12 text-right">
                    <label class="control-label" for="inputClaimantEmail">Email Address <span *ngIf="claimant.get('email')?.errors?.required">*</span></label>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <label type="email" class="control-label" id="inputClaimantEmail">{{claimant.get('email')?.value}}</label>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <span *ngIf="claimant.get('email')?.touched && claimant.get('email')?.errors?.required" class="help-block error">A valid customer email is required</span>
                    <span *ngIf="claimant.get('email')?.touched && claimant.get('email')?.errors?.invalidEmailFormat" class="help-block error">Email format Invalid <br /> Ex:- "name@domain.com.au"</span>
                  </div>
                </div>
              </div>
              <div class="form-row mt-4">
                <div class="form-group row">
                  <div class="col-md-4 col-sm-12 text-right">
                    <label class="control-label" for="inputClaimantPhone">Phone Number <span *ngIf="claimant.get('phone')?.errors?.required">*</span></label>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <label type="text" class="control-label" id="inputClaimantPhone">{{claimant.get('phone')?.value}}</label>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <div [ngClass]="{'help-block error' : ((claimant.get('phone')?.dirty || claimant.get('phone')?.value || claimant.get('phone')?.touched) && claimant.get('phone')?.errors)}">
                      <span *ngIf="claimant.get('phone')?.touched && claimant.get('phone')?.errors?.required">A valid phone number is required</span>
                      <span *ngIf="claimant.get('phone')?.errors?.maxlength">Phone number must be 10 digits<br /></span>
                      <span *ngIf="claimant.get('phone')?.errors?.minlength">Phone number must be 10 digits<br /></span>
                      <span *ngIf="claimant.get('phone')?.errors?.invalidNumber">The phone number should be numeric<br /></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row mt-4" *ngIf="showClaimantRole">
              <div class="form-group row">
                <div class="col-md-4 col-sm-12 text-right">
                  <label class="control-label">Claimant Role <span *ngIf="contactForm.claimantRole.errors?.required">*</span></label>
                </div>
                <div class="col-md-4 col-sm-12">
                  <label type="text" class="control-label" id="inputClaimantPhone">{{contactForm.claimantRole.value?.value}}</label>
                </div>
                <div class="col-md-4 col-sm-12">
                  <span *ngIf="contactForm.claimantRole.touched && contactForm.claimantRole.errors?.required" class="help-block error">Claimant role is required</span>
                </div>
              </div>
            </div>
            <div class="form-row mt-4" *ngIf="showclaimantPartyType">
              <!--*ngIf="showclaimantPartyType"-->
              <div class="form-group row">
                <div class="col-md-4 col-sm-12 text-right">
                  <label class="control-label" for="inputClaimantRole">Customer Type <span *ngIf="claimant.get('claimantPartyType')?.errors?.required">*</span></label>
                </div>
                <div class="col-md-4 col-sm-12">
                  <label class="control-label">{{claimant.get('claimantPartyType')?.value?.value}}</label>
                </div>
                <div class="col-md-4 col-sm-12">
                  <span *ngIf="claimant.get('claimantPartyType')?.touched && claimant.get('claimantPartyType')?.errors?.required" class="help-block error">Customer type is required</span>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    </div>
  </div>
  <!--Claimant Details-->
  <div class="card" *ngIf="carrierHasClaimantDetails">
    <div id="headingFour" [ngClass]="this.claimantDetails.invalid?'card-header error':'card-header'">
      <h2 class="mb-0">
        <button type="button" class="btn btn-link" data-toggle="collapse" data-target="#collapseFour"> Claimant Details</button>
        <button type="button" class="btn btn-link float-right" (click)="editDetails('claimantdetail')"><span class="error-notice">Click to fix errors</span><i data-icon="k" class="icon-exclamation-triangle"></i> <i data-icon="d" class="icon"></i> </button>
      </h2>
    </div>
    <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
      <div class="card-body">
        <div class="row mt-4" *ngIf="claimantDetailForm.claimantRole?.value?.value !=''">
          <div class="col-md-4 col-sm-12 text-right">
            <label class="control-label">claimantRole</label>
          </div>
          <div class="col-md-4 col-sm-12">
            <label type="text" class="control-label" name="name">{{claimantDetailForm.claimantRole?.value?.value}}</label>
          </div>
        </div>
        <div class="row mt-4" [ngSwitch]="claimantDetailForm.customerType.value">
          <div class="col-md-4 col-sm-12 text-right">
            <label class="control-label">What best describes you:</label>
          </div>
          <div class="col-md-4 col-sm-12">
            <div>
              <div *ngSwitchCase="1">
                <label class="input-radio">
                  Manufacturer
                </label>
              </div>
              <div *ngSwitchCase="2">
                <label class="input-radio">
                  Wholesaler
                </label>
              </div>
              <div *ngSwitchCase="3">
                <label class="input-radio">
                  Retailer
                </label>
              </div>
              <div *ngSwitchCase="0">
                <label class="input-radio">
                  Individual
                </label>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="claimantDetailForm.customerType?.value !='0'">
          <div *ngIf="claimantDetailForm.claimantRole?.value?.value =='Sender'">
            <div>
              <div class="row mt-4">
                <div class="col-md-4 col-sm-12 text-right">
                  <label class="control-label">What is your ABN?</label>
                </div>
                <div class="col-md-4 col-sm-12">
                  <label type="text" class="control-label" name="name">{{claimantDetailForm.sender.get('abnNumber')?.value}}</label>
                </div>
                <div class="col-md-4 col-sm-12">
                  <div [ngClass]="{'help-block error' :(claimantDetailForm.sender.get('abnNumber')?.errors?.required)
               || (claimantDetailForm.sender.get('abnNumber')?.errors && claimantDetailForm.sender.get('abnNumber')?.value)}">
                    <span *ngIf="claimantDetailForm.sender.get('abnNumber')?.errors?.required">Required<br /></span>
                    <span *ngIf="claimantDetailForm.sender.get('abnNumber')?.errors?.minlength">Too Short<br /></span>
                    <span *ngIf="claimantDetailForm.sender.get('abnNumber')?.errors?.maxlength">Too Long<br /></span>
                    <span *ngIf="claimantDetailForm.sender.get('abnNumber')?.errors?.invalidNumber ">The ABN number should be numeric<br /></span>
                  </div>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-4 col-sm-12 text-right">
                  <label class="control-label">Are you registered for GST</label>
                </div>
                <div class="col-md-4 col-sm-12">
                  <div>
                    <div *ngIf="claimantDetailForm.sender.get('isRegisteredForGst')?.value=='1'">
                      <label class="control-label" for="isRegisteredForGst">Yes</label>
                    </div>
                    <div *ngIf="claimantDetailForm.sender.get('isRegisteredForGst')?.value=='0'">
                      <label class="control-label">No</label>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div *ngIf="claimantDetailForm.claimantRole?.value?.value =='Receiver'">
            <div>
              <div class="row mt-4">
                <div class="col-md-4 col-sm-12 text-right">
                  <label class="control-label">What is your ABN?</label>
                </div>
                <div class="col-md-4 col-sm-12">
                  <label type="text" class="control-label" name="name">{{claimantDetailForm.receiver.get('abnNumber')?.value}}</label>
                </div>
                <div class="col-md-4 col-sm-12">
                  <div [ngClass]="{'help-block error' : (claimantDetailForm.receiver.get('abnNumber')?.errors?.required)
               || (claimantDetailForm.receiver.get('abnNumber')?.errors && claimantDetailForm.receiver.get('abnNumber')?.value)}">
                    <span *ngIf="claimantDetailForm.receiver.get('abnNumber')?.errors?.required">Required<br /></span>
                    <span *ngIf="claimantDetailForm.receiver.get('abnNumber')?.errors?.minlength">Too Short<br /></span>
                    <span *ngIf="claimantDetailForm.receiver.get('abnNumber')?.errors?.maxlength">Too Long<br /></span>
                    <span *ngIf="claimantDetailForm.receiver.get('abnNumber')?.errors?.invalidNumber ">The ABN number should be numeric<br /></span>
                  </div>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-4 col-sm-12 text-right">
                  <label class="control-label">Are you registered for GST</label>
                </div>
                <div class="col-md-4 col-sm-12">
                  <div>
                    <div *ngIf="claimantDetailForm.receiver.get('isRegisteredForGst')?.value=='1'">
                      <label class="control-label" for="isRegisteredForGst">Yes</label>
                    </div>
                    <div *ngIf="claimantDetailForm.receiver.get('isRegisteredForGst')?.value=='0'">
                      <label class="control-label">No</label>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div *ngIf="claimantDetailForm.claimantRole?.value?.value =='Third Party'">
            <div>
              <div class="row mt-4">
                <div class="col-md-4 col-sm-12 text-right">
                  <label class="control-label">What is your ABN?</label>
                </div>
                <div class="col-md-4 col-sm-12">
                  <label type="text" class="control-label" name="name">{{claimantDetailForm.purchasingCustomer.get('abnNumber')?.value}}</label>
                </div>
                <div class="col-md-4 col-sm-12">
                  <div [ngClass]="{'help-block error' : (claimantDetailForm.purchasingCustomer.get('abnNumber')?.errors?.required)
               || (claimantDetailForm.purchasingCustomer.get('abnNumber')?.errors && claimantDetailForm.purchasingCustomer.get('abnNumber')?.value)}">
                    <span *ngIf="claimantDetailForm.purchasingCustomer.get('abnNumber')?.errors?.required">Required <br /></span>
                    <span *ngIf="claimantDetailForm.purchasingCustomer.get('abnNumber')?.errors?.minlength">Too Short <br /></span>
                    <span *ngIf="claimantDetailForm.purchasingCustomer.get('abnNumber')?.errors?.maxlength">Too Long <br /></span>
                    <span *ngIf="claimantDetailForm.purchasingCustomer.get('abnNumber')?.errors?.invalidNumber ">The ABN number should be numeric <br /></span>
                  </div>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-4 col-sm-12 text-right">
                  <label class="control-label">Are you registered for GST</label>
                </div>
                <div class="col-md-4 col-sm-12">
                  <div>
                    <div *ngIf="claimantDetailForm.purchasingCustomer.get('isRegisteredForGst')?.value=='1'">
                      <label class="control-label" for="isRegisteredForGst">Yes</label>
                    </div>
                    <div *ngIf="claimantDetailForm.purchasingCustomer.get('isRegisteredForGst')?.value=='0'">
                      <label class="control-label">No</label>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="row mt-4" *ngIf="claimantDetailForm.customerType.value =='0'">
          <div class="col-md-4 col-sm-12 text-right">
            <label class="control-label">Claimant Comment</label>
          </div>
          <div class="col-md-4 col-sm-12">
            <label type="text" class="control-label" name="name">{{claimantDetailForm.claimantComment.value}}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Consignment Information-->
  <div class="card">
    <div id="headingTwo" [ngClass]="consignmetInformation.invalid?'card-header error':'card-header'">
      <h2 class="mb-0">
        <button type="button" class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo">Consignment Information</button>
        <button type="button" class="btn btn-link float-right" data-toggle="collapse" data-target="#collapseTwo" (click)="editDetails('consignment')"><span class="error-notice">Click to fix errors</span><i data-icon="k" class="icon-exclamation-triangle"></i><i data-icon="d" class="icon"></i> </button>
      </h2>
    </div>
    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
      <div class="card-body">
        <div class="form-row mt-4">
          <fieldset>
            <div>
              <div class="form-row mt-4">
                <div class="w-100">
                  <div class="form-group row">
                    <div class="col-md-4 col-sm-12 text-right">
                      <label class="control-label" for="inputConNoteNumber">
                        {{consignmentNoteName}} <span *ngIf="consignmentForm.consignmentNoteNo.errors?.required">*</span>
                      </label>
                    </div>
                    <div class="col-md-4 col-sm-12">
                      <label class="control-label">{{consignmentForm.consignmentNoteNo.value}}</label>
                    </div>
                    <div class="col-md-4 col-sm-12">
                      <span *ngIf="consignmentForm.consignmentNoteNo.touched && consignmentForm.consignmentNoteNo.errors?.required" class="help-block error">{{consignmentNoteName}} is required</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row mt-4">
                <div class="form-group row w-100">
                  <div class="col-md-4 col-sm-12 text-right">
                    <label class="control-label" for="dropClaimType">
                      Claim Type <span *ngIf="consignmentForm.claimType.errors?.required">*</span>
                    </label>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <label class="control-label">{{consignmentForm.claimType.value?.name}}</label>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <span *ngIf="consignmentForm.claimType.touched && consignmentForm.claimType.errors?.required" class="help-block error">Claim type is required</span>
                  </div>
                </div>
              </div>
              <div class="form-row mt-4">
                <div class="form-group row w-100">
                  <div class="col-md-4 col-sm-12 text-right">
                    <label class="control-label" for="inputDateDispatched">Date of Dispatch <span *ngIf="consignmentForm.goodsDispatchedDateTime.errors?.required">*</span></label>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <div class="input-group">
                      <label class="control-label">{{getStringDateFromControl(consignmentForm.goodsDispatchedDateTime)}}</label>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <span *ngIf="consignmentForm.goodsDispatchedDateTime?.touched && consignmentForm.goodsDispatchedDateTime?.errors?.required" class="help-block error">Date of dispatch is required</span>
                    <span *ngIf="consignmentForm.goodsDispatchedDateTime?.touched && consignmentForm.goodsDispatchedDateTime?.errors?.dateInvalid" class="help-block error">Invalid date format</span>
                    <span *ngIf="consignmentForm.goodsDispatchedDateTime?.touched && consignmentForm.goodsDispatchedDateTime?.errors?.dateafterToday" class="help-block error">Invalid date</span>
                    <span *ngIf="consignmentForm.goodsDispatchedDateTime?.touched && consignmentForm.goodsDispatchedDateTime?.errors?.ngbDate?.maxDate && !consignmentForm.goodsDispatchedDateTime?.errors?.dateafterToday" class="help-block error">Dispatch date is later than the received date</span>
                  </div>
                </div>
              </div>
              <div class="form-row mt-4" *ngIf="claimType.id != 1 || !claimType ">
                <div class="w-100">
                  <div class="form-group row">
                    <div class="col-md-4 col-sm-12 text-right">
                      <label class="control-label" for="inputDateReceived">{{dateOfReceiptName}} <span *ngIf="consignmentForm.goodsReceivedDateTime.errors?.required">*</span></label>
                      <!--&& claimForm.claimType != 1-->
                    </div>
                    <div class="col-md-4 col-sm-12">
                      <div class="input-group">
                        <label class="control-label">{{getStringDateFromControl(consignmentForm.goodsReceivedDateTime)}}</label>
                      </div>
                    </div>
                    <div class="col-md-4 col-sm-12">
                      <span *ngIf="consignmentForm.goodsReceivedDateTime?.touched && consignmentForm.goodsReceivedDateTime?.errors?.required" class="help-block error">{{dateOfReceiptName}} is required</span>
                      <span *ngIf="consignmentForm.goodsReceivedDateTime?.touched && consignmentForm.goodsReceivedDateTime?.errors?.dateInvalid" class="help-block error">Invalid date format</span>
                      <span *ngIf="consignmentForm.goodsReceivedDateTime?.touched && consignmentForm.goodsReceivedDateTime?.errors?.dateafterToday" class="help-block error">Invalid date</span>
                      <span *ngIf="consignmentForm.goodsReceivedDateTime?.touched && consignmentForm.goodsReceivedDateTime?.errors?.ngbDate?.minDate" class="help-block error">Received date is earlier than the dispatch date</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row mt-4">
                <div class="form-group row w-100">
                  <div class="col-md-4 col-sm-12 text-right">
                    <label class="control-label">{{ getDisplayNameCustomField('isSalvageRequired','Is Salvage Required?')}}</label>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <div>
                      <label class="control-label">{{isSalvageRequired}}</label>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <span *ngIf="consignmentForm.isSalvageRequired.touched && consignmentForm.isSalvageRequired.errors?.required" class="help-block error">Salvage status must be selected</span>
                  </div>
                </div>
              </div>
              <div class="form-row mt-4" id="initialContactDetails" *ngIf="consignmentForm.isSalvageRequired.value == 1">
                <div class="form-group row w-100">
                  <div class="col-md-4 col-sm-12 text-right">
                    <label class="control-label" for="txtSalvageDetails">Salvage Details</label>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <label class="control-label">{{consignmentForm.salvageDetails.value}}</label>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <!--<span *ngIf="consignmentForm.initialContactDetails.touched && consignmentForm.initialContactDetails.errors?.required" class="help-block error">Details of Initial Contact is required</span>-->
                  </div>
                </div>
              </div>

              <div class="form-row mt-4">
                <div class="form-group row w-100">
                  <div class="col-md-4 col-sm-12 text-right">
                    <label class="control-label" for="inputDateInitialContact">
                      Date of Initial Contact with {{carrierName}} <!--Needs to be carrier Name not prefix-->
                      regarding lost/damaged goods <span *ngIf="consignmentForm.initialContactDate.errors?.required">*</span>
                    </label>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <label type="radio" value="0" name="isSalvageRequired">{{getStringDateFromControl(consignmentForm.initialContactDate)}}</label>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <span *ngIf="consignmentForm.initialContactDate.touched && consignmentForm.initialContactDate.errors?.required" class="help-block error">Date of initial contact is required</span>
                    <span *ngIf="consignmentForm.initialContactDate.touched && consignmentForm.initialContactDate.errors?.dateInvalid" class="help-block error">Invalid date forma</span>
                    <span *ngIf="consignmentForm.initialContactDate.touched && consignmentForm.initialContactDate.errors?.dateafterToday" class="help-block error">Invalid date</span>
                    <span *ngIf="consignmentForm.initialContactDate.touched && consignmentForm.initialContactDate.errors?.ngbDate?.maxDate && !consignmentForm.initialContactDate.errors?.dateafterToday" class="help-block error">Initial contact date is in the future</span>
                    <span *ngIf="consignmentForm.initialContactDate.touched && consignmentForm.initialContactDate.errors?.ngbDate?.minDate && !consignmentForm.initialContactDate.errors?.dateafterToday" class="help-block error">Initial contact date is earlier than the dispatch date</span>
                  </div>
                </div>
              </div>
              <!--This is not rendered to the DOM unless the carrier config has the field-->
              <div class="form-row mt-4" id="initialContactDetails" *ngIf="showInitialContactDetails">
                <div class="form-group row w-100">
                  <div class="col-md-4 col-sm-12 text-right">
                    <label class="control-label" for="txtInitialContactDetails">Details of Initial Contact with {{carrierName}} Regarding the Lost/Damaged Goods <span *ngIf="consignmentForm.initialContactDetails.errors?.required">*</span></label>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <label class="control-label">{{consignmentForm.initialContactDetails.value}}</label>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <span *ngIf="consignmentForm.initialContactDetails.touched && consignmentForm.initialContactDetails.errors?.required" class="help-block error">Details of Initial Contact is required</span>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    </div>
  </div>
  <!--Sender Details-->
  <div class="card">
    <div id="headingSender" [ngClass]="senderDetails.invalid?'card-header error':'card-header'">
      <h2 class="mb-0">
        <button type="button" class="btn btn-link collapsed" data-toggle="collapse" data-target="#heading4">Sender Details</button>
        <button type="button" class="btn btn-link float-right" data-toggle="collapse" data-target="#heading4" (click)="editDetails('sender')"> <span class="error-notice">Click to fix errors</span><i data-icon="k" class="icon-exclamation-triangle"></i><i data-icon="d" class="icon"></i> </button>
      </h2>
    </div>
    <div id="heading4" class="collapse" aria-labelledby="headingSender" data-parent="#accordionExample">
      <div class="card-body">
        <div class="form-row mt-4">
          <fieldset>
            <div class="multisteps-form__content">
              <div class="form-row mt-4">
                <div class="row w-100">
                  <div class="col-md-12">
                    <div class="form-group row">
                      <div class="col-md-4 col-sm-12 text-right">
                        <label class="control-label" for="inputSender">Name <span *ngIf="sender.get('name')?.errors?.required">*</span></label>
                      </div>
                      <div class="col-md-4 col-sm-12">
                        <label class="control-label">{{sender.get('name')?.value}}</label>
                      </div>
                      <div class="col-md-4 col-sm-12">
                        <span *ngIf="sender.get('name')?.touched && sender.get('name')?.errors?.required " class="help-block error">Sender name is required</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row mt-4">
                <div class="row w-100">
                  <div class="col-md-12">
                    <div class="form-group row">
                      <div class="col-md-4 col-sm-12 text-right">
                        <label class="control-label" for="inputSenderAddress">Pickup Address <span *ngIf="sender.get('address')?.errors?.required">*</span></label>
                      </div>
                      <div class="col-md-4 col-sm-12">
                        <label class="control-label">{{sender.get('address')?.value}}</label>
                      </div>
                      <div class="col-md-4 col-sm-12">
                        <span *ngIf="sender.errors?.addressNotSelected" class="help-block error">Address should be selected from the list</span>
                        <span *ngIf="sender.get('address')?.touched && sender.get('address')?.errors?.required" class="help-block error">Sender address is required</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row mt-4">
                <div class="row w-100">
                  <div class="col-md-12">
                    <div class="form-group row">
                      <div class="col-md-4 col-sm-12 text-right">
                        <label class="control-label" for="inputSenderStreetAddress">Pickup Street <span *ngIf="sender.get('addressLine1')?.errors?.required">*</span></label>
                      </div>
                      <div class="col-md-4 col-sm-12">
                        <label class="control-label">{{sender.get('addressLine1')?.value}}</label>
                      </div>
                      <div class="col-md-4 col-sm-12">
                        <span *ngIf="sender.get('addressLine1')?.touched && sender.get('addressLine1')?.errors?.required" class="help-block error">Pickup Street address is required</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row mt-4">
                <div class="row w-100">
                  <div class="col-md-12">
                    <div class="form-group row">
                      <div class="col-md-4 col-sm-12 text-right">
                        <label class="control-label" for="inputSenderCity">Pickup City <span *ngIf="sender.get('city')?.errors?.required">*</span></label>
                      </div>
                      <div class="col-md-4 col-sm-12">
                        <label class="control-label">{{sender.get('city')?.value}}</label>
                      </div>
                      <div class="col-md-4 col-sm-12">
                        <span *ngIf="sender.get('city')?.touched && sender.get('city')?.errors?.required" class="help-block error">Pickup city is required</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row mt-4">
                <div class="row w-100">
                  <div class="col-md-12">
                    <div class="form-group row">
                      <div class="col-md-4 col-sm-12 text-right">
                        <label class="control-label" for="dropSenderState">Pickup State <span *ngIf="sender.get('state')?.errors?.required">*</span></label>
                      </div>
                      <div class="col-md-4 col-sm-12">
                        <label class="control-label">{{sender.get('state')?.value}}</label>
                      </div>
                      <div class="col-md-4 col-sm-12">
                        <span *ngIf="sender.get('state')?.touched && sender.get('state')?.errors?.required" class="help-block error">Pickup state is required</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row mt-4">
                <div class="row w-100">
                  <div class="col-md-12">
                    <div class="form-group row">
                      <div class="col-md-4 col-sm-12 text-right">
                        <label class="control-label" for="inputSenderPostCode">Pickup Postcode <span *ngIf="sender.get('postalCode')?.errors?.required">*</span></label>
                      </div>
                      <div class="col-md-4 col-sm-12">
                        <label class="control-label">{{sender.get('postalCode')?.value}}</label>
                      </div>
                      <div class="col-md-4 col-sm-12">
                        <span *ngIf="sender.get('postalCode')?.touched && sender.get('postalCode')?.errors?.required" class="help-block error">Pickup postal code is required</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div> <!--End multisteps-form__content-->
          </fieldset>
        </div>
      </div>
    </div>
  </div>
  <!--Receiver Details-->
  <div class="card">
    <div id="headingReciver" [ngClass]="receiverDetails.invalid?'card-header error':'card-header'">
      <h2 class="mb-0">
        <button type="button" class="btn btn-link collapsed" data-toggle="collapse" data-target="#heading5">Receiver Details</button>
        <button type="button" class="btn btn-link float-right" data-toggle="collapse" data-target="#heading5" (click)="editDetails('receiver')"> <span class="error-notice">Click to fix errors</span><i data-icon="k" class="icon-exclamation-triangle"></i><i data-icon="d" class="icon"></i> </button>
      </h2>
    </div>
    <div id="heading5" class="collapse" aria-labelledby="headingReciver" data-parent="#accordionExample">
      <div class="card-body">
        <div>
          <fieldset>
            <div class="multisteps-form__content">
              <div class="form-row mt-4">
                <div class="w-100">
                  <div class="form-group row">
                    <div class="col-md-4 col-sm-12 text-right">
                      <label class="control-label" for="inputReceiver">Name <span *ngIf="receiver.get('name')?.errors?.required">*</span></label>
                    </div>
                    <div class="col-md-4 col-sm-12">
                      <label class="control-label">{{receiver.get('name')?.value}}</label>
                    </div>
                    <div class="col-md-4 col-sm-12">
                      <span *ngIf="receiver.get('name')?.touched && receiver.get('name')?.errors?.required " class="help-block error">Receiver name is required</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-row mt-4" *ngIf="showReceiverEmail">
                <div class="form-group row">
                  <div class="col-md-4 col-sm-12 text-right">
                    <label class="control-label" for="inputClaimantEmail">Email Address <span *ngIf="receiver.get('email')?.invalid">*</span></label>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <!--<input type="email" class="form-control" formControlName="email" id="inputClaimantEmail" name="inputClaimantEmail" placeholder="Email Address" maxlength="254">-->
                    <label class="control-label">{{receiver.get('email')?.value}}</label>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <div [ngClass]="{'help-block error' : ((receiver.get('email')?.dirty || receiver.get('email')?.value || receiver.get('email')?.touched) && receiver.get('email')?.errors)}">
                      <span *ngIf="receiver.get('email')?.touched && receiver.get('email')?.errors?.required">A valid customer email is required<br /></span>
                      <span *ngIf="receiver.get('email')?.errors?.invalidEmailFormat">Email format Invalid <br /> Ex:- "name@domain.com.au"</span>
                      <span *ngIf="receiver.get('email')?.errors?.maxlength">Invalid email address max length is 254 characters</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-row mt-4" *ngIf="showReceiverPhoneNumber">
                <div class="form-group row">
                  <div class="col-md-4 col-sm-12 text-right">
                    <label class="control-label" for="inputClaimantPhone">Phone Number <span *ngIf="receiver.get('phone')?.invalid">*</span></label>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <!--<input type="text" class="form-control" formControlName="phone" id="inputClaimantPhone" name="inputClaimantPhone" placeholder="Phone Number" numbers-only ng-maxlength="10" ng-minlength="10">-->
                    <label class="control-label">{{receiver.get('phone')?.value}}</label>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <div [ngClass]="{'help-block error' : ((receiver.get('phone')?.dirty || receiver.get('phone')?.value || receiver.get('phone')?.touched) && receiver.get('phone')?.errors)}">
                      <span *ngIf="receiver.get('phone')?.touched && receiver.get('phone')?.errors?.required">A valid phone number is required</span>
                      <span *ngIf="receiver.get('phone')?.errors?.invalidNumber">Phone number must be numeric <br /></span>
                      <span *ngIf="receiver.get('phone')?.errors?.maxlength">Phone number must be 10 digits <br /></span>
                      <span *ngIf="receiver.get('phone')?.errors?.minlength">Phone number must be 10 digits</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-row mt-4">
                <div class="w-100">
                  <div class="form-group row">
                    <div class="col-md-4 col-sm-12 text-right">
                      <label class="control-label" for="inputReceiverAddress">Delivery Address <span *ngIf="receiver.get('address')?.errors?.required">*</span></label>
                    </div>
                    <div class="col-md-4 col-sm-12">
                      <label class="control-label">{{receiver.get('address')?.value}}</label>
                    </div>
                    <div class="col-md-4 col-sm-12">
                      <span *ngIf="receiver.errors?.addressNotSelected" class="help-block error">Address should be selected from the list</span>
                      <span *ngIf="receiver.get('address')?.touched && receiver.get('address')?.errors?.required " class="help-block error">Receiver address is required</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-row mt-4">
                <div class="w-100">
                  <div class="form-group row">
                    <div class="col-md-4 col-sm-12 text-right">
                      <label class="control-label" for="inputReceiverStreetAddress">Delivery Street <span *ngIf="receiver.get('addressLine1')?.errors?.required">*</span></label>
                    </div>
                    <div class="col-md-4 col-sm-12">
                      <label class="control-label">{{receiver.get('addressLine1')?.value}}</label>
                    </div>
                    <div class="col-md-4 col-sm-12">
                      <span *ngIf="receiver.get('addressLine1')?.touched && receiver.get('addressLine1')?.errors?.required " class="help-block error">Delivery street address is required</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-row mt-4">
                <div class="w-100">
                  <div class="form-group row">
                    <div class="col-md-4 col-sm-12 text-right">
                      <label class="control-label" for="inputReceiverCity">Delivery City <span *ngIf="receiver.get('city')?.errors?.required">*</span></label>
                    </div>
                    <div class="col-md-4 col-sm-12">
                      <label class="control-label">{{receiver.get('city')?.value}}</label>
                    </div>
                    <div class="col-md-4 col-sm-12">
                      <span *ngIf="receiver.get('city')?.touched && receiver.get('city')?.errors?.required " class="help-block error">Delivery city address is required</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-row mt-4">
                <div class="w-100">
                  <div class="form-group row">
                    <div class="col-md-4 col-sm-12 text-right">
                      <label class="control-label" for="dropReceiverState">Delivery State <span *ngIf="receiver.get('state')?.errors?.required">*</span></label>
                    </div>
                    <div class="col-md-4 col-sm-12">
                      <label class="control-label">{{receiver.get('state')?.value}}</label>
                    </div>
                    <div class="col-md-4 col-sm-12">
                      <span *ngIf="receiver.get('state')?.touched && receiver.get('state')?.errors?.required" class="help-block error">Delivery state is required</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-row mt-4">
                <div class="w-100">
                  <div class="form-group row">
                    <div class="col-md-4 col-sm-12 text-right">
                      <label class="control-label" for="inputReceiverPostCode">Delivery Postcode <span *ngIf="receiver.get('postalCode')?.errors?.required">*</span></label>
                    </div>
                    <div class="col-md-4 col-sm-12">
                      <label class="control-label">{{receiver.get('postalCode')?.value}}</label>
                    </div>
                    <div class="col-md-4 col-sm-12">
                      <span *ngIf="receiver.get('postalCode')?.touched && receiver.get('postalCode')?.errors?.required " class="help-block error">Delivery postal code is required</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>

        </div>
      </div>
    </div>
  </div>
  <!--Fanchisee Details-->
  <div class="card" *ngIf="carrierHasFranchiceeDetails">
    <div id="headingFranchisee" [ngClass]="franchiseeDetails.invalid?'card-header error':'card-header'">
      <h2 class="mb-0">
        <button type="button" class="btn btn-link collapsed" data-toggle="collapse" data-target="#heading6">Franchisee Details </button>
        <button type="button" class="btn btn-link float-right" data-toggle="collapse" data-target="#heading6" (click)="editDetails('franchisee')"><span class="error-notice">Click to fix errors</span> <i data-icon="k" class="icon-exclamation-triangle"></i><i data-icon="d" class="icon"></i> </button>
      </h2>
    </div>
    <div id="heading6" class="collapse" aria-labelledby="headingFranchisee" data-parent="#accordionExample">
      <div class="card-body">
        <div>
          <fieldset>
            <div class="multisteps-form__content">
              <div class="form-row mt-4">
                <div class="w-100">
                  <div class="form-group row ">
                    <div class="col-md-4 col-sm-12 text-right">
                      <label for="inputFranchiseeCode" class="control-label">Franchisee Code <span *ngIf="franchisee.get('code')?.errors?.required">*</span> </label>
                    </div>
                    <div class="col-md-4 col-sm-12">
                      <label class="control-label">{{franchisee.get('code')?.value}}</label>
                    </div>
                    <div class="col-md-4 col-sm-12">
                      <div [ngClass]="{'help-block error' :((franchisee.get('code')?.dirty || franchisee.get('code')?.value || franchisee.get('code')?.touched) && franchisee.get('code')?.errors) }">
                        <span *ngIf="franchisee.get('code')?.touched && franchisee.get('code')?.errors?.required">3 digit franchisee code is required</span>
                        <span *ngIf="franchisee.get('code')?.errors?.maxlength || franchisee.get('code')?.errors?.minlength">Franchisee code should be 3 digits<br /></span>
                        <span *ngIf="franchisee.get('code')?.errors?.invalidNumber">Franchisee code should be numeric</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row mt-4">
                <div class="w-100">
                  <div class="form-group row ">
                    <div class="col-md-4 col-sm-12 text-right">
                      <label for="name" class="control-label">Franchisee Name <span *ngIf="franchisee.get('name')?.errors?.required">*</span> </label>
                    </div>
                    <div class="col-md-4 col-sm-12">
                      <label class="control-label">{{franchisee.get('name')?.value}}</label>
                    </div>
                    <div class="col-md-4 col-sm-12">
                      <span *ngIf="franchisee.get('name')?.touched && franchisee.get('name')?.errors?.required" class="help-block error">Franchisee name is required</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row mt-4">
                <div class="w-100">
                  <div class="form-group row ">
                    <div class="col-md-4 col-sm-12 text-right">
                      <label for="inputFranchiseeEmail" class="control-label">Email Address <span *ngIf="franchisee.get('email')?.errors?.required">*</span></label>
                    </div>
                    <div class="col-md-4 col-sm-12">
                      <label class="control-label">{{franchisee.get('email')?.value}}</label>
                    </div>
                    <div class="col-md-4 col-sm-12">
                      <span *ngIf="franchisee.get('email')?.touched && franchisee.get('email')?.errors?.required" class="help-block error">A valid franchisee email is required</span>
                      <span *ngIf="franchisee.get('email')?.touched && franchisee.get('email')?.errors?.invalidEmailFormat" class="help-block error">Email format Invalid <br /> Ex:- "name@domain.com.au"</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row mt-4">
                <div class="w-100">
                  <div class="form-group row">
                    <div class="col-md-4 col-sm-12 text-right">
                      <label for="inputFranchiseePhone" class="control-label">Phone Number</label>
                    </div>
                    <div class="col-md-4 col-sm-12">
                      <label class="control-label">{{franchisee.get('businessphone')?.value}}</label>
                    </div>
                    <div class="col-md-4 col-sm-12">
                      <div [ngClass]="{'help-block error' : ((franchisee.get('businessphone')?.dirty || franchisee.get('businessphone')?.value || franchisee.get('businessphone')?.touched) && franchisee.get('businessphone')?.errors)}">
                        <span *ngIf="franchisee.get('businessphone')?.touched && franchisee.get('businessphone')?.errors?.invalidNumber">The phone number should be numeric<br /></span>
                        <span *ngIf=" franchisee.get('businessphone')?.errors?.maxlength">Phone number must be 10 digits<br /></span>
                        <span *ngIf=" franchisee.get('businessphone')?.errors?.minlength">Phone number must be 10 digits<br /></span>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>

        </div>
      </div>
    </div>
  </div>
  <!--Claim Information-->
  <div class="card">
    <div id="headingClaim" [ngClass]="claimInformation.invalid?'card-header error':'card-header'">
      <h2 class="mb-0">
        <button type="button" class="btn btn-link collapsed" data-toggle="collapse" data-target="#heading7">Claim Information</button>
        <button type="button" class="btn btn-link float-right" data-toggle="collapse" data-target="#heading7" (click)="editDetails('claim')"><span class="error-notice">Click to fix errors</span> <i data-icon="k" class="icon-exclamation-triangle"></i><i data-icon="d" class="icon"></i> </button>
      </h2>
    </div>
    <div id="heading7" class="collapse" aria-labelledby="headingClaim" data-parent="#accordionExample">
      <div class="card-body">
        <div class="form-row mt-4">
          <fieldset>
            <div class="multisteps-form__content">
              <div class="form-row mt-4">
                <div class="form-group row">
                  <div class="col-md-4 col-sm-12 text-right">
                    <label class="control-label" for="txtGoodsDetails">Goods Description <span *ngIf="claimInforForm.descriptionOfGoods.errors?.required">*</span></label>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <label class="control-label">{{claimInforForm.descriptionOfGoods.value}}</label>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <span *ngIf="claimInforForm.descriptionOfGoods.touched && claimInforForm.descriptionOfGoods.errors?.required" class="help-block error">Description of goods is required</span>
                  </div>
                </div>
              </div>
              <div class="form-row mt-4">
                <div class="form-group row">
                  <div class="col-md-4 col-sm-12 text-right">
                    <label class="control-label" for="txtDetailsOfLossOrDamage" style="padding-left:0">Details of Loss/Damage <span *ngIf="claimInforForm.descriptionOfLossOrDamage.errors?.required">*</span></label>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <label class="control-label">{{claimInforForm.descriptionOfLossOrDamage.value}}</label>

                  </div>
                  <div class="col-md-4 col-sm-12">
                    <span *ngIf="claimInforForm.descriptionOfLossOrDamage.touched && claimInforForm.descriptionOfLossOrDamage.errors?.required" class="help-block error">Details of loss or damage is required</span>
                  </div>
                </div>
              </div>
              <div class="form-row mt-4" *ngIf="claimType.id != 1 || !claimType">
                <div class="form-group row">
                  <div class="col-md-4 col-sm-12 text-right">
                    <label class="control-label" for="radioIsGoodsRepairable">Are Goods Repairable?</label>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <label class="control-label">{{areGoodsRepairable}}</label>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <span *ngIf="claimInforForm.isGoodsRepairable.touched && claimInforForm.isGoodsRepairable.errors?.required" class="help-block error">Goods Repairable status must be selected</span>
                  </div>
                </div>
              </div>
              <div class="form-row mt-4" *ngIf="claimType.id != 1 || !claimType">
                <div class="form-group row">
                  <div class="col-md-4 col-sm-12 text-right">
                    <label class="control-label">Current Location of Damaged Goods <span *ngIf="claimInforForm.damagedGoodsLocation.errors?.required">*</span></label>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <label class="control-label">{{claimInforForm.damagedGoodsLocation.value}}</label>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <span *ngIf="claimInforForm.damagedGoodsLocation.touched && claimInforForm.damagedGoodsLocation.errors?.required" class="help-block error">Current Location of Damaged Goods is required</span>
                  </div>
                </div>
              </div>
              <div class="form-row mt-4">
                <div class="form-group row">
                  <div class="col-md-4 col-sm-12 text-right">
                    <label class="control-label d-inline" for="inputClaimValue" [innerHtml]="claimValueFieldDisplayName"></label>

                  </div>
                  <div class="col-md-4 col-sm-12">
                    <label class="control-label mr-1">$</label>
                    <label class="control-label">{{claimInforForm.claimValue.value}}</label>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <div [ngClass]="{'help-block error' : ((claimInforForm.claimValue.dirty || claimInforForm.claimValue.value || claimInforForm.claimValue.touched) && claimInforForm.claimValue.errors)}">
                      <span *ngIf="claimInforForm.claimValue.touched && claimInforForm.claimValue.errors?.required">A valid claim value is required<br /></span>
                      <span *ngIf="claimInforForm.claimValue.errors?.invalidDecimalNumber">Claim value should be numeric<br /></span>
                      <span *ngIf="claimInforForm.claimValue.errors?.canNotBeZero">Claim value can not be 0</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    </div>
  </div>
  <!--Bank Details-->
  <div class="card" *ngIf="carrierHasBankDetails">
    <div id="headingBank" [ngClass]="bankInformationDetail.invalid?'card-header error':'card-header'">
      <h2 class="mb-0">
        <button type="button" class="btn btn-link collapsed" data-toggle="collapse" data-target="#heading8">Bank Details</button>
        <button type="button" class="btn btn-link float-right" data-toggle="collapse" data-target="#heading8" (click)="editDetails('bank')"><span class="error-notice">Click to fix errors</span> <i data-icon="k" class="icon-exclamation-triangle"></i><i data-icon="d" class="icon"></i> </button>
      </h2>
    </div>
    <div id="heading8" class="collapse" aria-labelledby="headingBank" data-parent="#accordionExample">
      <div class="card-body">
        <div class="form-row mt-4">
          <fieldset>
            <div class="multisteps-form__content">
              <div class="form-row mt-4" *ngIf="showIsInternationalClaiamnt">
                <div class="form-group row">
                  <div class="col-md-4 col-sm-12 text-right">
                    <label for="isInternationalClaim" class="control-label">Are you an International claimant </label>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <label class="control-label">{{isClaimantInternational}}</label>
                  </div>
                </div>
              </div>
              <div class="form-row mt-4">
                <div class="form-group row">
                  <div class="col-md-4 col-sm-12 text-right">
                    <label for="txtBankName" class="control-label">Bank Name <span *ngIf="bankInforForm.nameOfBank.errors?.required">*</span></label>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <label class="control-label">{{bankInforForm.nameOfBank.value}}</label>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <span *ngIf="bankInforForm.nameOfBank.touched &&  bankInforForm.nameOfBank.errors?.required" class="help-block error">Bank Name is required</span>
                  </div>
                </div>
              </div>
              <div class="form-row mt-4">
                <div class="form-group row">
                  <div class="col-md-4 col-sm-12 text-right">
                    <label for="inputAccountName" class="control-label">Account Name <span *ngIf="bankInforForm.accountName.errors?.required">*</span></label>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <label class="control-label">{{bankInforForm.accountName.value}}</label>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <span *ngIf="bankInforForm.accountName.touched &&  bankInforForm.accountName.errors?.required" class="help-block error">Account Name is required</span>
                  </div>
                </div>
              </div>
              <div class="form-row mt-4" *ngIf="!showInternationalBankDetails">
                <div class="form-group row">
                  <div class="col-md-4 col-sm-12 text-right">
                    <label for="txtBSBNumber" class="control-label">BSB Number <span *ngIf="bankInforForm.bsbNumber.errors?.required">*</span></label>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <label class="control-label">{{bankInforForm.bsbNumber.value}}</label>
                    <div ng-if="bsbInformation!=null">
                      <i class="fa fa-check" style="color:green;position:absolute;margin-left:2px;margin-top:10px" title="click to see more information" ng-click="showBsbInformation($event)"></i>
                    </div>
                    <div ng-if="bsbInformation==null">
                      <i class="fa fa-exclamation-triangle" style="color:green;position:absolute;margin-left:2px;margin-top:10px" title="click to see more information" ng-click="showBsbInformation($event)"></i>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <div [ngClass]="{'help-block error' : ((bankInforForm.bsbNumber.dirty || bankInforForm.bsbNumber.value || bankInforForm.bsbNumber.touched) && bankInforForm.bsbNumber.errors)}">
                      <span *ngIf="(!bankInforForm.bsbNumber.pristine || bankInforForm.bsbNumber.touched ) && bankInforForm.bsbNumber.errors?.required">BSB Number is required<br /></span>
                      <span *ngIf="bankInforForm.bsbNumber.errors?.invalidNumber">BSB should be numeric<br /></span>
                      <span *ngIf="bankInforForm.bsbNumber.value &&  bankInforForm.bsbNumber.errors?.invalidBSB">BSB number is invalid<br /></span>
                      <span *ngIf="bankInforForm.bsbNumber.errors?.minlength || bankInforForm.bsbNumber.errors?.maxlength">BSB number is 6 digits<br /></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row mt-4">
                <div class="form-group row">
                  <div class="col-md-4 col-sm-12 text-right">
                    <label class="control-label" for="inputAccountNumber">Bank Account Number <span *ngIf="bankInforForm.accountNumber.errors?.required">*</span></label>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <label class="control-label">{{bankInforForm.accountNumber.value}}</label>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <div [ngClass]="{'help-block error' : ((bankInforForm.accountNumber.dirty || bankInforForm.accountNumber.value || bankInforForm.accountNumber.touched) && bankInforForm.accountNumber.errors)}">
                      <span *ngIf="bankInforForm.accountNumber.touched && bankInforForm.accountNumber.errors?.required">Account number is required</span>
                      <span *ngIf="bankInforForm.accountNumber.errors?.maxlength">Account number should have only 10 digits</span>
                      <span *ngIf="bankInforForm.accountNumber.errors?.minlength">Account number should have 6 digits minimum</span>
                      <span *ngIf="bankInforForm.accountNumber.errors?.invalidNumber">Account number should be numeric</span>
                    </div>
                  </div>
                </div>
              </div>
              <ng-container *ngIf="showInternationalBankDetails">
                <div class="form-row mt-4">
                  <div class="form-group row">
                    <div class="col-md-4 col-sm-12 text-right">
                      <label for="inputBankAddress" class="control-label">Bank Address <span *ngIf="bankInforForm.bankAddress.errors?.required">*</span></label>
                    </div>
                    <div class="col-md-4 col-sm-12">
                      <label class="control-label">{{bankInforForm.bankAddress.value}}</label>
                    </div>
                    <div class="col-md-4 col-sm-12">
                      <span *ngIf="bankInforForm.bankAddress.touched &&  bankInforForm.bankAddress.errors?.required" class="help-block error">Bank address is required</span>
                    </div>
                  </div>
                </div>
                <div class="form-row mt-4">
                  <div class="form-group row">
                    <div class="col-md-4 col-sm-12 text-right">
                      <label for="inputAccountAddress" class="control-label">Account Address <span *ngIf="bankInforForm.accountAddress.errors?.required">*</span></label>
                    </div>
                    <div class="col-md-4 col-sm-12">
                      <label class="control-label">{{bankInforForm.accountAddress.value}}</label>

                    </div>
                    <div class="col-md-4 col-sm-12">
                      <span *ngIf="bankInforForm.accountAddress.touched &&  bankInforForm.accountAddress.errors?.required" class="help-block error">Account address is required</span>
                    </div>
                  </div>
                </div>
                <div class="form-row mt-4">
                  <div class="form-group row">
                    <div class="col-md-4 col-sm-12 text-right">
                      <label for="inputBankABA" class="control-label">Bank ABA/Routing <span *ngIf="bankInforForm.bankABA.errors?.required">*</span></label>
                    </div>
                    <div class="col-md-4 col-sm-12">
                      <label class="control-label">{{bankInforForm.bankABA.value}}</label>

                    </div>
                    <div class="col-md-4 col-sm-12">
                      <div [ngClass]="{'help-block error' : ((bankInforForm.bankABA.dirty || bankInforForm.bankABA.value || bankInforForm.bankABA.touched) && bankInforForm.bankABA.errors)}">
                        <span *ngIf="bankInforForm.bankABA.touched &&  bankInforForm.bankABA.errors?.required">Bank ABA/Routing is required <br /></span>
                        <span *ngIf="bankInforForm.bankABA.errors?.invalidNumber">Bank ABA/Routing should be numeric<br /></span>
                        <span *ngIf="bankInforForm.bankABA.errors?.minlength">Too short<br /></span>
                        <span *ngIf="bankInforForm.bankABA.errors?.maxlength">Too long<br /></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-row mt-4">
                  <div class="form-group row">
                    <div class="col-md-4 col-sm-12 text-right">
                      <label for="inputBankSwift" class="control-label">Bank SWIFT <span *ngIf="bankInforForm.bankSwift.errors?.required">*</span></label>
                    </div>
                    <div class="col-md-4 col-sm-12">
                      <label class="control-label">{{bankInforForm.bankSwift.value}}</label>
                    </div>
                    <div class="col-md-4 col-sm-12">
                      <div [ngClass]="{'help-block error' : ((bankInforForm.bankSwift.dirty || bankInforForm.bankSwift.value || bankInforForm.bankSwift.touched) && bankInforForm.bankSwift.errors)}">
                        <span *ngIf="bankInforForm.bankSwift.touched &&  bankInforForm.bankSwift.errors?.required">Bank SWIFT is required<br /></span>
                        <span *ngIf="bankInforForm.bankSwift.errors?.invalidCharacter">Special characters are not allowed as Bank SWIFT<br /></span>
                        <span *ngIf="bankInforForm.bankSwift.errors?.minlength">Too short<br /></span>
                        <span *ngIf="bankInforForm.bankSwift.errors?.maxlength">Too long<br /></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-row mt-4">
                  <div class="form-group row">
                    <div class="col-md-4 col-sm-12 text-right">
                      <label for="selectAccountCurrancy" class="control-label">Currency <span *ngIf="bankInforForm.accountCurrency.errors?.required">*</span></label>
                    </div>
                    <div class="col-md-4 col-sm-12">
                      <label class="control-label">{{bankInforForm.accountCurrency.value?.code}}</label>
                    </div>
                    <div class="col-md-4 col-sm-12">
                      <span *ngIf="bankInforForm.accountCurrency.touched &&  bankInforForm.accountCurrency.errors?.required" class="help-block error">Currency is required</span>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div> <!--"multisteps-form__content"-->
          </fieldset>
        </div>
      </div>
    </div>
  </div>
  <!--Upload Supporting Documentation-->
  <div class="card">
    <div id="headingUpload" [ngClass]="supportingDocumentForm.invalid?'card-header error':'card-header'">
      <h2 class="mb-0">
        <button type="button" class="btn btn-link collapsed" data-toggle="collapse" data-target="#heading9">Supporting Documentation</button>
        <button type="button" class="btn btn-link float-right" data-toggle="collapse" data-target="#heading9" (click)="editDetails('document')"> <span class="error-notice">Click to fix errors</span><i data-icon="k" class="icon-exclamation-triangle"></i><i data-icon="d" class="icon"></i> </button>
      </h2>
    </div>
    <div id="heading9" class="collapse" aria-labelledby="headingUpload" data-parent="#accordionExample">
      <div class="card-body">
        <div>
          <fieldset>

            <div div class="form-row mt-4">
              <div class="w-100">
                <div class="col-12 col-md-9 m-auto" *ngIf="supportingDocumentForm.invalid">
                  <div class="important-notice">
                    The following document(s) are required to submit this claim.
                    <ul>
                      <ng-container *ngFor="let doc of documentTypes">
                        <li *ngIf="doc.requiredStatus==0 && doc.fileAdded ==false">{{doc?.name}}</li>
                      </ng-container>

                    </ul>
                    Please click on the edit button to navigate to the page and add the required documents.
                  </div>
                </div>
              </div>
            </div>



            <div *ngFor="let uploads of uploadedFiles; let indexOfDocType = index;" class="col-12 col-md-9 m-auto">
              <div class="w-100">
                <div class="uploaded-files mt-2">
                  <b><i class="icon-clip"></i>  {{uploads.documentType.name}}</b> -
                  <p class="mb-0" *ngFor="let file of uploads.files; let indexOfFile =index;">
                    <i class="icon-file-image-o"></i>  {{file.name}}
                  </p>
                </div>
              </div>
            </div>

            <!--<div class="form-row mt-4" *ngIf="showPodFile">
            <div class="w-100">
              <div class="form-group row ">
                <div class="col-md-4 col-sm-12 text-right">
                  <label for="uploadPoD" class="control-label">Upload the Proof of Delivery  <span *ngIf="supportingDocumentForm.podFile?.errors?.required || supportingDocumentForm.podFile?.errors?.required">*</span></label>-->
            <!--&& claimForm.claimType != 1-->
            <!--</div>
            <div class="col-md-4 col-sm-12">
              <ng-container *ngFor="let file of supportingDocumentForm.podFile?.value ">
                <label class="control-label">{{file.name}}</label>-->
            <!--ng-file-select="onFileSelect($files, 'podFile')"-->  <!--ng-required="claimForm.claimType != 1"-->
            <!--</ng-container>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <p *ngIf="supportingDocumentForm.podFile?.touched && supportingDocumentForm.podFile?.errors?.required " class="help-block error">Proof of Delivery is required for claim submission</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-row mt-4">
              <div class="w-100">
                <div class="form-group row">
                  <div class="col-md-4 col-sm-12 text-right">
                    <label for="uploadTER" [innerHtml]="terFileName" class="control-label"> </label><label>&nbsp;<span *ngIf="supportingDocumentForm.terFile?.errors?.required"> *</span></label>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <label class="control-label">{{supportingDocumentForm.terFile?.value}}</label>
                    <div class="col-md-4 col-sm-12">
                      <ng-container *ngFor="let file of supportingDocumentForm.terFile?.value ">
                        <label class="control-label">{{file.name}}</label>-->
            <!--ng-file-select="onFileSelect($files, 'podFile')"-->  <!--ng-required="claimForm.claimType != 1"-->
            <!--</ng-container>
                    </div>
                    <div class="col-md-4 col-sm-12">
                      <p *ngIf="supportingDocumentForm.terFile?.touched && supportingDocumentForm.terFile?.errors?.required" class="help-block error" [innerHtml]="terFileNameError"> </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-row mt-4" *ngIf="showinvoiceFile">
              <div class="w-100">
                <div class="form-group row">
                  <div class="col-md-4 col-sm-12 text-right">
                    <label for="uploadInvoice" class="control-label">{{getDisplayNameCustomField('invoiceFile','Upload your Consignment Note')}}</label>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <label class="control-label">{{supportingDocumentForm.invoiceFile?.value}}</label>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-row mt-4">
              <div class="w-100">
                <div class="form-group row">
                  <div class="col-md-4 col-sm-12 text-right">
                    <label for="uploadImage" class="control-label">{{imageFile1Name}} <span *ngIf="supportingDocumentForm.imageFile1?.pristine && supportingDocumentForm.imageFile1?.errors?.required">*</span></label>
                  </div>
                  <div class="col-md-4 col-sm-12">

                    <label class="control-label">{{supportingDocumentForm.imageFile1?.value}}</label>
                    <ng-container *ngFor="let file of supportingDocumentForm.terFile?.value ">
                      <label class="control-label">{{file.name}}</label>-->
            <!--ng-file-select="onFileSelect($files, 'podFile')"-->  <!--ng-required="claimForm.claimType != 1"-->
            <!--</ng-container>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <p *ngIf="supportingDocumentForm.imageFile1?.touched && supportingDocumentForm.imageFile1?.errors?.required" class="help-block error">{{imageFile1Name}} is required for claim submission</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-row mt-4">
              <div class="w-100">
                <div class="form-group row">
                  <div class="col-md-4 col-sm-12 text-right">
                    <label for="uploadAdditionalImage2" class="control-label">Upload the Sales Invoice</label>
                  </div>
                  <div class="col-md-4 col-sm-12">

                    <label class="control-label">{{supportingDocumentForm.imageFile2?.value}}</label>
                    <ng-container *ngFor="let file of supportingDocumentForm.terFile?.value ">
                      <label class="control-label">{{file.name}}</label>-->
            <!--ng-file-select="onFileSelect($files, 'podFile')"-->  <!--ng-required="claimForm.claimType != 1"-->
            <!--</ng-container>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-row mt-4">
              <div class="w-100">
                <div class="form-group row">
                  <div class="col-md-4 col-sm-12 text-right">
                    <label for="uploadAdditionalImage1" class="control-label">{{imageFile3Name}}<span *ngIf="supportingDocumentForm.imageFile3?.pristine && supportingDocumentForm.imageFile3?.errors?.required">&nbsp;*</span> </label>
                  </div>
                  <div class="col-md-4 col-sm-12">

                    <label class="control-label">{{supportingDocumentForm.imageFile3?.value}}</label>
                    <ng-container *ngFor="let file of supportingDocumentForm.terFile?.value ">
                      <label class="control-label">{{file.name}}</label>-->
            <!--ng-file-select="onFileSelect($files, 'podFile')"-->  <!--ng-required="claimForm.claimType != 1"-->
            <!--</ng-container>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <p *ngIf="supportingDocumentForm.imageFile3?.touched && supportingDocumentForm.imageFile3?.errors?.required" class="help-block error">{{imageFile3Name}} is required for claim submission</p>
                  </div>
                </div>
              </div>
            </div>



            <div class="form-row mt-4" *ngIf="showImage4">
              <div class="w-100">
                <div class="form-group row">
                  <div class="col-md-4 col-sm-12 text-right">
                    <label for="uploadAdditionalImage3" class="control-label">Upload an additional photo</label>
                  </div>
                  <div class="col-md-4 col-sm-12">

                    <label class="control-label">{{supportingDocumentForm.imageFile4?.value}}</label>
                    <ng-container *ngFor="let file of supportingDocumentForm.terFile?.value ">
                      <label class="control-label">{{file.name}}</label>-->
            <!--ng-file-select="onFileSelect($files, 'podFile')"-->  <!--ng-required="claimForm.claimType != 1"-->
            <!--</ng-container>
                  </div>
                </div>
              </div>
            </div>-->
            <!--<fieldset>
            <div class="form-row mt-4">
              <div class="w-100">
                <div class="form-group row">
                  <div class="col-md-4 col-sm-12 text-right">
                   <label class="control-label">Add and Upload Photos of Packaging</label>
                  </div>
                  <div class="col-md-4  col-sm-12">
                    <div>
                      <ng-container *ngFor="let doc of supportingDocumentForm.popFiles?.value">
                        <label class="control-label">{{doc.fileName}} </label><br />-->
            <!--class="control-label"-->
            <!--</ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>-->

          </fieldset>
        </div>
      </div>
    </div>
  </div>
  <!--Declaration-->
  <div class="card">
    <div [ngClass]="previewForm.invalid?'card-header error':'card-header'" id="headingDeclaration">
      <h2 class="mb-0">
        <button type="button" class="btn btn-link collapsed" data-toggle="collapse" data-target="#heading10">Declaration</button>
        <button type="button" class="btn btn-link float-right" data-toggle="collapse" data-target="#heading10"><span class="error-notice">Click to fix errors</span> <i data-icon="k" class="icon-exclamation-triangle"></i><i data-icon="d" class="icon"></i> </button>
      </h2>
    </div>
    <div id="heading10" class="collapse show" aria-labelledby="headingDeclaration" data-parent="#accordionExample">
      <div class="card-body">
        <div>
          <form [formGroup]="previewForm">
            <fieldset>
              <div class="form-row mt-4">
                <div class="form-group" ng-class="{'has-error' : formClaimForm.chkDeclaration.$dirty && formClaimForm.chkDeclaration.$invalid}">
                  <div class="col-sm-12">
                    <div class="form-group row">
                      <div class="col-md-12 col-sm-12">
                        <label class="chekbox mb-0">
                          <input type="checkbox" formControlName="isDeclarationChecked" value="0" name="isDeclarationChecked">
                          <span class="checkmark"></span>
                          <label [innerHtml]="getDisplayNameCustomField('declaration','I declare to the best of my knowledge that the information in this form is true and correct and I have not withheld any relevant information.')"></label>
                        </label>
                      </div>
                      <div class="col-md-12 col-sm-12">
                        <span *ngIf="(previewFormControl.isDeclarationChecked.errors?.checkboxNotChecked || previewFormControl.isDeclarationChecked.errors?.required) && previewFormControl.isDeclarationChecked.touched" class="help-block error">Declaration is required</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<modal #modal></modal>
