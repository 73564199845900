import { Injectable } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

import { ConfigurationModel } from '../../models/ConfigurationModel';
import data from '../../../assets/config.json';
import { NavigationStatus } from '../../enums/NavigationStatus';
import { BehaviorSubject, Subject } from 'rxjs';
import { debug } from 'console';



@Injectable({
  providedIn: 'root'
})
export class CommonService {
  jsonConfigs: ConfigurationModel[] = data.metadata;
  private carrierConfig!: ConfigurationModel;
  url!: string;
  private _carrierPrefix!: string;
  private _carrierName!: string;
  private _warrantyName?: string;
  private _termsAndConditionLink!: string;
  private _requiredDocuments?: string;
  private _claimNumber!: string;
  private _welcomeParaOne?: string;
  private _welcomeParaTwo?: string;
  private _isolatedComponent?: string;
  private _consignment_note?: string;
  private _component?: string;
  private _userGuideLink!: string;
  private _carrierConfirmMessageEnabled!: boolean;
  private _carrierAlternateMessage?: boolean;
  private _carrierConfirmMessageText?: string;

  /*Key used to store config object in localStorage*/
  private carrierConfigKey: string = 'carrierConfig';
  private claimNumberKey: string = 'claimNumber';


  public isLoading$ = new Subject<boolean>();
  public isDraftClaim$ = new BehaviorSubject<boolean>(false);
  public isUploaded$ = new Subject<boolean>();
  public isIsolatedComponet$ = new BehaviorSubject<boolean>(false);
  public isVisibilityUpdated$ = new BehaviorSubject<boolean>(false);
  

  /** returns the encoded claim number
   */
  public get claimNumber() {
    return this._claimNumber;
  }

  public get TermsAndConditionLink(): string {
    this._termsAndConditionLink = this._termsAndConditionLink ? this._termsAndConditionLink : this.carrierConfig?.termsAndConditionLink;
    return this._termsAndConditionLink;
  }
  public get carrierPrefix() {
    this._carrierPrefix = this._carrierPrefix ? this._carrierPrefix : this.carrierConfig?.prefix;
    return this._carrierPrefix;
  }

  public get carrierName() {
    this._carrierName = this._carrierName ? this._carrierName : this.carrierConfig?.carrierName;
    return this._carrierName;
  }

  public get warrantyName() {
    this._warrantyName = this._warrantyName ? this._warrantyName : this.carrierConfig?.warrantyName;
    return this._warrantyName;
  }

  public get requiredDocuments(): string | undefined {
    this._requiredDocuments = this._requiredDocuments ? this._requiredDocuments : this.carrierConfig?.requiredDocuments;
    return this._requiredDocuments;
  }

  public get welcomeParaOne() {
    this._welcomeParaOne = this._welcomeParaOne ? this._welcomeParaOne : this.carrierConfig?.welcomeParaOne;
    return this._welcomeParaOne;
  }

  public get welcomeParaTwo() {
    this._welcomeParaTwo = this._welcomeParaTwo ? this._welcomeParaTwo : this.carrierConfig?.welcomeParaTwo;
    return this._welcomeParaTwo;
  }

  public get isolatedComponent() {
    return this._isolatedComponent;
  }
  public get userGuideLink() {
    this._userGuideLink = this._userGuideLink ? this._userGuideLink : this.carrierConfig?.userGuideLink;
    return this._userGuideLink;
  }

  public get consignmentNoteNumber() {
    return this._consignment_note;
  }

  public get component() {
    return this._component;
  }

  public get carrierConfirmMessageEnabled() {

    this._carrierConfirmMessageEnabled = this._carrierConfirmMessageEnabled ? this._carrierConfirmMessageEnabled : this.carrierConfig?.carrierConfirmMessageEnabled;
    return this._carrierConfirmMessageEnabled;

  }

  public get carrierAlternateMessage() {

    this._carrierAlternateMessage = this._carrierAlternateMessage ? this._carrierAlternateMessage : this.carrierConfig?.carrierAlternateMessage;
    return this._carrierAlternateMessage;

  } 
  
  public get carrierConfirmMessageText() {
    this._carrierConfirmMessageText = this._carrierConfirmMessageText ? this._carrierConfirmMessageText : this.carrierConfig?.carrierConfirmMessageText;
    return this._carrierConfirmMessageText;
  }

  constructor(private router: Router, private route: ActivatedRoute) { }
  /**
   * Returns the specific configuration of the carrier.
   * This should only be used at initial app load.
   * To get the carrierConfig object use getCarrierConfigObject() method.*/
  public async getCarrierConfig(): Promise<ConfigurationModel> {
    await this.setUrlAsync();
    this.getParameters();

    // let test = this.getCacheObject(this.claimNumberKey);
    let tempObject = this.getCacheObject(this.carrierConfigKey);
    if (tempObject && tempObject != "undefined") {
      let configObject = JSON.parse(tempObject);
      if (configObject.prefix == this.url) {
        this.carrierConfig = configObject;
      }
    }
    /* -- Inital load when the carrierConfig is yet to be added to the localStorage. -- */
    if (!this.carrierConfig) {
      let styleName: string = '';
      /* -- Get the carrier config based on the url and carrier prefix -- */
      this.carrierConfig = this.jsonConfigs.filter(config => config.prefix == this.url)[0];
      if (this.carrierConfig) {
        /* -- Check for carrier specific navigation -- */
        if (!this.carrierConfig.navigation) {
          /* -- If no carrier specific navigation is available add the common navigation -- */
          let commonNaviagation = this.jsonConfigs.filter(nav => nav.prefix == '**')[0];
          this.carrierConfig.navigation = commonNaviagation.navigation;
        } else if (this.carrierConfig.replaceAll) {
          /* replace all option is set no common Navigation will be added */
        } else {
          /* -- Carrier specific Navigation is available and will be added with the common navigation array. --  */
          let commonNaviagation = this.jsonConfigs.filter(nav => nav.prefix == '**')[0].navigation;
          /* -- Iterate through the Carrier specific Navigation and either replace or merge
           * naviation to common navigation array. --  */
          this.carrierConfig.navigation.forEach(n => {
            /* -- Logic to merge the Carrier specific Navigation to the common array.  --  */
            if (n.mergeIndex != null && n.mergeIndex != undefined) {
              if (commonNaviagation) {
                /* Updating the Previous and Next naviagtion paths in the common array. */
                let previousIndex = n.mergeIndex - 1;
                let nextIndex = n.mergeIndex
                if (previousIndex > 0) {
                  let previousElement = commonNaviagation[previousIndex];
                  previousElement.next = n.navigationPath;
                  commonNaviagation[previousIndex] = previousElement;
                }
                if (nextIndex < commonNaviagation.length) {
                  let nextElement = commonNaviagation[nextIndex];
                  nextElement.previous = n.navigationPath;
                  commonNaviagation[nextIndex] = nextElement;
                }
                commonNaviagation?.splice(n.mergeIndex, 0, n);
              }
            }
            /* -- Logic to replace the Carrier specific Navigation with the common array.  --  */
            if (n.replaceIndex != null && n.replaceIndex != undefined) {
              if (commonNaviagation) {
                /* Updating the Previous and Next naviagtion paths in the common array. */
                let previousIndex = n.replaceIndex - 1;
                let nextIndex = n.replaceIndex + 1;
                if (previousIndex > 0) {
                  let previousElement = commonNaviagation[previousIndex];
                  previousElement.next = n.navigationPath;
                  commonNaviagation[previousIndex] = previousElement;
                }
                if (nextIndex < commonNaviagation.length) {
                  let nextElement = commonNaviagation[nextIndex];
                  nextElement.previous = n.navigationPath;
                  commonNaviagation[nextIndex] = nextElement;
                }
                commonNaviagation?.splice(n.replaceIndex, 1, n);
              }
            }
            this.carrierConfig.navigation = commonNaviagation;
          });
        }
       let activeNavigation = this.carrierConfig.navigation?.filter(nav => nav.status == NavigationStatus.Active)[0];
       //Navigate to the current Active component										  
        if (activeNavigation) {
          this.router.navigate([`${this.carrierConfig.prefix}/${activeNavigation.navigationPath}`]);
        }

      } else {

        //Navigate to the current Active component										  
          this.router.navigate([``]);
      }
      this.setCacheObject(this.carrierConfigKey, this.carrierConfig);
    }
    return this.carrierConfig;
  }
  /**
   * returns the url current url.*/
  public getUrl(): string {
    return this.url;
  }
  /**
   * loads the carrier specific style*/
  public loadStyle() {
    const head = document.getElementsByTagName('head')[0];
    let themeLink = document.getElementById(
      'client-theme'
    ) as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = this.carrierConfig.css;
    } else {
      if (this.carrierConfig) {
        const style = document.createElement('link') as HTMLLinkElement;
        style.id = 'client-theme';
        style.rel = 'stylesheet';
        style.type = 'text/css'
        style.href = `${this.carrierConfig.css}`;
        head.appendChild(style);
      }
    }
  }
  /**
   * returns the carrierConfigObject*/
  public getCarrierConfigObject(): ConfigurationModel {
    if (!this.carrierConfig) {
      this.carrierConfig = this.getLocalStorageObject('carrierConfig');
    }
    return this.carrierConfig;

  }
  /**
   * update the localStorage CarrierConfigObject
   * @param carrierConfigObject
   */
  public updateConfigObject(carrierConfigObject: ConfigurationModel) {
    this.carrierConfig = carrierConfigObject;
    this.setCacheObject(this.carrierConfigKey, carrierConfigObject);
  }
  /**
   * Checks for carrier specific fields and returns true if found.
   * @param field
   */
  public checkCustomFields(field: string, defaultValue: boolean = false): boolean {
    let fieldValue = this.carrierConfig.customFields?.filter(f => f.fieldName == field)[0];
    if (!fieldValue) {
      return defaultValue;
    } else if (fieldValue.showField) {
      return true;
    } else if (fieldValue.showField == false) {
      return false;
    } else if (defaultValue) {
      return defaultValue;
    }
    return false;
  }

  public getCustomFieldDisplayName(field: string, defaultValue: string): string {
    if (this.checkCustomFields(field)) {
      let fieldValue = this.carrierConfig.customFields?.filter(f => f.fieldName == field);
      if (fieldValue && fieldValue.length > 0) {
        return fieldValue[0].displayName ? fieldValue[0].displayName : defaultValue;
      }
    }
    return defaultValue;
  }

  public isCarrierSupportedComponent(componentName: string): boolean {
    let index = this.carrierConfig.navigation?.findIndex(nav => nav.componentName == componentName);
    if (index) {
      return index > -1 ? true : false;
    } else {
      return false;
    }
  }

  /**
   * Checks if a perticular component is available for the carrier or not.
   * @param sectionName Component Name to be checked.
   */
  public checkForCarrierSepecificComponent(sectionName: string): boolean {
    let filteredSection = this.carrierConfig.navigation?.filter(nav => nav.componentName == sectionName && nav.isVisible);
    if (filteredSection && filteredSection?.length > 0) {
      return true;
    }
    return false;
  }

  // ------ Private Methods --------
  /**
   * Gets the config object from the local storage.
   * @param key localStorage object key
   */
  public getLocalStorageObject(key: string): any {
    let tempObject = this.getCacheObject(key);
    if (tempObject && tempObject != "undefined") {
      let configObject = JSON.parse(tempObject);
      return configObject;
    }
  }
  /** Gets the url of the current route
   * */
  private async setUrlAsync() {
    if (!this.url) {
      let subSetURL = await this.router.events.subscribe((e) => {
        if (e instanceof NavigationEnd) {
          let url = e.url;
          url = url.split("/")[1];
          const amxUrl = url.indexOf('?');
          /** Checks if the # exists in the url if so removes and redirects the application */
          const oldUrlParts = url.indexOf('#');
          if (amxUrl > -1) {
            url = url.split('?')[0];
          }
          if (oldUrlParts > -1) {
            url = e.url;
            url = url.split('/')[2];
            this.router.navigate(['/' + url]).then(() => {
              window.location.reload();
            });
          }
          let prefixIndex = e.url.indexOf('prefix=');
          if (prefixIndex > -1) {
            url = e.url.split('=')[1];
          }
          let isolatedUrl = e.url.split('/');
          if (isolatedUrl.length >= 2) {
            let index = isolatedUrl[2]?.indexOf('?');
            if (index != null && index > -1) {
              this._isolatedComponent = isolatedUrl[2].split('?')[0];
              this._component = this._isolatedComponent;
            }
          }
          /** if component is entered in the url */
          let urlcomponents = e.url.split('/');
          if (urlcomponents.length >= 2 && !this._isolatedComponent) {
            this._component = urlcomponents[2];
          }

          this.url = url;
          if (!this.url) {
            this._component = "";
          }
        }
      }, () => {
        subSetURL?.unsubscribe();
      });
    }



  }

  private getParameters() {
    let claimNum = this.route.queryParams
      .subscribe(params => {

        if (params.claim_number) {
          let index = params.claim_number.indexOf('/');
          if (index > -1) {
            this._claimNumber = params.claim_number.split('/')[0];
          } else {
            this._claimNumber = params.claim_number;
          }
          this.addKeyToSessionStorage(this.claimNumberKey, this._claimNumber);
        } else if (params.consignment_note) {
          let index = params.consignment_note.indexOf('/');
          if (index > -1) {
            this._consignment_note = params.consignment_note.split('/')[0];
          } else {
            this._consignment_note = params.consignment_note;
          }
        } else {
          let number = this.getCacheObject(this.claimNumberKey);
          if (number) {
            this._claimNumber = number;

          }
        }
      }, () => {
        claimNum?.unsubscribe();

        return;
      });
  }

  public getCustomFieldCarrierSpecificMessage(field: string) {
    let fieldValue = this.carrierConfig.customFields?.filter(f => f.fieldName == field);
    if (fieldValue && fieldValue.length > 0) {
      return fieldValue[0].fieldmessage;
    }
    return undefined;
  }

  private setCacheObject(key: string, object: ConfigurationModel) {
    sessionStorage.setItem(key, JSON.stringify(object));
  }

  public getCacheObject(key: string) {
    return sessionStorage.getItem(key);
  }

  public addKeyToSessionStorage(key: string, value: string) {
    sessionStorage.setItem(key, value);
  }

  public clearCacheObjects() {
    sessionStorage.clear();
  }

  public getCarrierNameByPrefix(prefix: string): string | null {
    let carrierName: string | null = null;
    const configObject = this.jsonConfigs.filter(config => config.prefix == prefix.toLowerCase())[0];
    if (configObject) {
      carrierName = configObject.carrierName;
    }
    return carrierName;
  }
}
