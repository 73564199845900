<div class="form-row mt-4">
  <div class="col-12 col-md-7 m-auto">
    <div data-nosnippet [innerHtml]="welcomeParaOne"> </div>
    <!--<p class="font-1 text-center">Warranty Claim Form</p>
  <p class="font-2 text-center">Let's get your claim moving</p>
  <p class="font-3 text-justify">FreightSafe is here to assist you in processing your claim with {{carrierName}}. Prior to submitting your claim form, please ensure that you have read the <a [href]="link" [attr.target]="target">Terms and Conditions</a> of the {{warrantyName}}.</p>-->
    <!--<p class="text-left font-3 thin">
      In order to lodge your claim, you will need the following documents:-->
      <ul class="font-3">
        <li data-nosnippet *ngFor="let docs of requireddocs">{{docs}}</li>
      </ul>
      <div></div>
    <div [innerHtml]="welcomeParaTwo"> </div>
    <!--<p class="text-justify font-3 thin">Please ensure you have notified {{carrierName}} of your lost or damaged consignment as soon as you become aware of any issue, and prior to lodging your claim. All damaged goods should be held throughout the claims process and made available to {{carrierName}} if collection is required.</p>-->
    <!--<div [innerHtml]="welcomeText"></div>-->
    <div class="col-md-12">
      <div class="form-group">
        <!--This error is fixed when the welcomeParaTwo is loaded.-->
        <form [formGroup]="welcomeForm">

          <div class="form-row mt-4 font-weight-bold">
            <div class="form-group row w-100 mb-0">
              <div class="col-md-8 col-sm-12 text-right">
                <label class="control-label">Have you lodged before?</label>
              </div>
              <div class="col-md-4 col-sm-12">
                <label class="input-radio font-3">
                  Yes
                  <input type="radio" formControlName="isPreviousClaimant" value="1" (change)="onPreviousClaimantChanged()">
                  <span class="checkmark"></span>
                </label>
                <label class="input-radio font-3">
                  No
                  <input type="radio" formControlName="isPreviousClaimant" value="0" (change)="onPreviousClaimantChanged()">
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
          </div>

          <div class="form-row font-weight-bold">
            <div class="form-group row w-100">
              <div class="col-md-8 col-sm-12 text-right">
                <label class="control-label">Do you wish to continue with a saved claim?</label>
              </div>
              <div class="col-md-4 col-sm-12">
                <label class="input-radio font-3">
                  Yes
                  <input type="radio" formControlName="isDraftClaim" value="1" (change)="onDraftClaimChanged()">
                  <span class="checkmark"></span>
                </label>
                <label class="input-radio font-3">
                  No
                  <input type="radio" formControlName="isDraftClaim" value="0" (change)="onDraftClaimChanged()">
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
          </div>
        </form>
        <form [formGroup]="previousClaimFormDetails">
          <fieldset>
            <div class="col-12 mt-2 additional-panel" *ngIf="myForm.isPreviousClaimant.value =='1'&& myForm.isDraftClaim?.value !='1'">
              <label class="text-left font-3"> If you enter the claim and consignment number associated with a previous claim, we can assist you by pre-populating some of the fields.</label><br /><br />
              <div class="form-row w-100">
                <div class="row w-100 mb-3">
                  <div class="col-md-6 col-sm-12 text-right">
                    <label class="control-label" for="inputPreviousClaimNumber">Previous Claim Number <span *ngIf="myForm.isPreviousClaimant.value =='1' && previousClaimForm.previousClaimNumber.errors?.required">*</span> </label>
                  </div>
                  <div class="col-md-6 col-sm-12 pr-0">
                    <input type="text" class="form-control" formControlName="previousClaimNumber" id="inputPreviousClaimNumber" name="inputPreviousClaimNumber" [placeholder]="claimNumberFormat">
                  </div>
                  <div class="text-right col-sm-12 pr-0">
                    <span *ngIf="previousClaimForm.previousClaimNumber.errors?.invalidClaimNumber" class="help-block error">Invalid claim number format<br /></span>
                  </div>
                </div>
              </div>
              <div class="form-row w-100">
                <div class="row w-100">
                  <div class="col-md-6 col-sm-12 text-right">
                    <label class="control-label" for="inputPreviousClaimConsignmentNumber">Consignment Number <span *ngIf="myForm.isPreviousClaimant?.value =='1' && previousClaimForm.previousClaimLabelNumber.errors?.required">*</span> </label>
                  </div>
                  <div class="col-md-6 col-sm-12 pr-0">
                    <input type="text" class="form-control" formControlName="previousClaimLabelNumber" id="inputPreviousClaimConsignmentNumber" name="inputPreviousClaimConsignmentNumber" placeholder="Consignment number" maxlength="50">
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-right mt-3">
                <input type="button"
                       value="Submit"
                       class="btn btn-md btn-primary"
                       style="background-color:#AD002B"
                       (click)="getPreviousClaimDetailsAndNavigate(previousClaimForm.previousClaimNumber.value,previousClaimForm.previousClaimLabelNumber.value)"
                       [disabled]="(!previousClaimForm.previousClaimNumber.value || !previousClaimForm.previousClaimLabelNumber.value || !previousClaimFormDetails.valid)" /><br /><br />
              </div>
            </div>
          </fieldset>
        </form>
        <form [formGroup]="welcomeForm">
          <div class="col-12 mt-2 additional-panel" *ngIf="myForm.isPreviousClaimant.value !='1' && myForm.isDraftClaim?.value =='1'">
            <label class="text-left font-3"> If you enter the consignment number, we can assist you by populating the fields.</label><br /><br />
            <div class="form-row w-100">
              <div class="row w-100">
                <div class="col-md-6 col-sm-12 text-right">
                  <label class="control-label" for="inputPreviousClaimConsignmentNumber">Consignment Number <span *ngIf="myForm.isDraftClaim?.value =='1' && myForm.previousClaimLabelNumber.errors?.required">*</span> </label>
                </div>
                <div class="col-md-6 col-sm-12 pr-0">
                  <input type="text" class="form-control" formControlName="previousClaimLabelNumber" id="inputPreviousClaimConsignmentNumber" name="inputPreviousClaimConsignmentNumber" placeholder="Consignment number" maxlength="50">
                </div>
              </div>
            </div>
            <div class="col-md-12 text-right mt-3">
              <input type="button"
                     value="Submit"
                     class="btn btn-md btn-primary"
                     style="background-color:#AD002B"
                     (click)="getDraftClaim(myForm.previousClaimLabelNumber.value)"
                     [disabled]="(!myForm.previousClaimLabelNumber.value)" /><br /><br />
            </div>
          </div>
        </form>
      </div>
      <span class="font-3 text-justify thin">If you require any assistance completing the claim form, please refer to our <a [href]="userGuide" [target]="target">user guide</a></span>
    </div>
  </div>
</div>
<modal #modal></modal>
