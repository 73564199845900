import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment.qa02';

import { ICancellationReason } from '../../../interfaces/CancellationReason';
import { ModalOptions } from '../../../models/ModalOptions';
import { AppService } from '../../../services/common/app.service';
import { CommonService } from '../../../services/common/common.service';
import { FormService } from '../../../services/common/form.service';
import { ErrorHelper } from '../../../utilities/ErrorHelper';
import { ModalComponent } from '../../common/modal/modal.component';

@Component({
  selector: 'app-cancellation',
  templateUrl: './cancellation.component.html',
  styleUrls: ['./cancellation.component.css']
})
export class CancellationComponent implements OnInit {

  @ViewChild('modal') modal!: ModalComponent
  cancelForm: UntypedFormGroup = this.formService.CancelForm;
  claimNumberFormat: string = 'Ex :-';
  cancellationReasons!: ICancellationReason[];
  claimNumber: string = '';
  modalbodyContent!: string;
  modalHeaderContent!: string;
  formInValid: boolean = true;
  ipAddress!: string;


  constructor(private formService: FormService, private commonService: CommonService,
    private appService: AppService, private errorHelper: ErrorHelper) { }

  get myForm() {
    return this.cancelForm.controls;
  }
  ngOnInit(): void {
    this.claimNumberFormat = this.claimNumberFormat + `${this.commonService.carrierPrefix}001`;
    this.claimNumber = this.commonService.claimNumber;
    this.appService.getCancellationReasons(this.claimNumber).subscribe(result => {
      this.cancellationReasons = result;
    }, error => {
      let response = this.errorHelper.formatAndDisplayError(error, false);
      if (response) {
        let options = {
          size: 'md',
          centered: true,
          isHtmlContent: true
        };
        this.modal.modalOpen(response[0], response[1], options).result.then(result => {
          window.location.href = 'https://freightsafe.com/contact/';
        });
      }
    });

    this.appService.getIpAddress().subscribe(result => {
      this.ipAddress = result.ip;
    }, error => {
      this.ipAddress = '0.0.0.0';
    });
    
    /*this.cancellationReasons$ =*/
    // Uncomment if needed.
    //if (!this.claimNumber) {
    //let options: ModalOptions = {
    //  size: 'md',
    //  centered: true,
    //  isHtmlContent: true
    //}
    //this.modalHeaderContent = 'Error'
    //this.modalbodyContent = 'No claim  number provided in the url';
    //this.model.modalOpen(this.modalHeaderContent, this.modalbodyContent, options);
    //}
  }


  public submitCancelation() {
    this.commonService.isLoading$.next(true);

    let claimCancellationObj = {
      ClaimCancellationObject: {
        ClaimNumber: this.claimNumber,
        Reason_Id: this.myForm.cancelReason?.value.Id,
        Description: this.myForm.description.value,
        IPAddress :this.ipAddress
      }
    };

    this.appService.submitClaimCancellation(claimCancellationObj).subscribe(value => {
      this.commonService.isLoading$.next(false);
      if (environment.showConsoleMessages) {
        console.log(value);
      }
      let options: ModalOptions = {
        size: 'md',
        centered: true,
        isHtmlContent: true
      }
      this.modalHeaderContent = 'Successful!'
      this.modalbodyContent = 'You have successfully submitted the claim to be cancelled';
      this.modal.modalOpen(this.modalHeaderContent, this.modalbodyContent, options).result.then(() => {
        window.location.href = 'https://freightsafe.com/contact/';
      });

    }, errorResponse => {
      this.commonService.isLoading$.next(false);
      let response = this.errorHelper.formatAndDisplayError(errorResponse);
      if (response) {
        let options: ModalOptions = {
          size: 'md',
          centered: true,
          isHtmlContent: true
        }
        this.modalHeaderContent = 'Error'
        this.modalbodyContent = '';
        this.modal.modalOpen(response[0], response[1], options);
      }

    });


  }




}


