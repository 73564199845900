<ng-template #modal let-modal>
  <div [ngClass]="headerSectionStyle">
    <h4 [ngClass]="headerStyle" id="modal-basic-title">{{headerContent}}</h4>
    <button *ngIf ="showCloseButton" type="button" class="close" aria-label="Close" (click)="modal.close('cancel')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div *ngIf="!isHtmlContent" class="modal-body">
    <p>{{bodyContent}}</p>
  </div>
  <div *ngIf="isHtmlContent" [innerHTML]="bodyContent" class="modal-body">

  </div>
  <div *ngIf="showConfirmationButtons" [ngClass]="footerStyle">
    <!--"modal-footer"-->
    <button type="button" [ngClass]="confirmationButtonStyle" (click)="modal.close('confirm')">Confirm</button> <!--btn btn-success-->
    <button type="button" [ngClass]="cancelButtonStyle" (click)="modal.close('cancel')">Cancel</button> <!--btn btn-danger-->
  </div>
  <div *ngIf="showOkayButton" [ngClass]="footerStyle">
    <!--"modal-footer"-->
    <button type="button" [ngClass]="okayButtonStyle" (click)="modal.close('Okay')">Okay</button> <!--btn btn-success-->
  </div>
</ng-template>
