import { Component, Injectable, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalOptions } from '../../../models/ModalOptions';

@Component({
  selector: 'modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
@Injectable({
  providedIn: 'root'
})
export class ModalComponent implements OnInit {
  @ViewChild('modal') modalTeplateRef!: TemplateRef<ModalComponent>;
  headerContent!: string;
  bodyContent!: string;
  showOkayButton: boolean = true;
  showConfirmationButtons: boolean = false;
  isHtmlContent: boolean = false;
  footerStyle: string = 'modal-footer';
  confirmationButtonStyle: string = 'btn btn-success';
  cancelButtonStyle: string = 'btn btn-success';
  okayButtonStyle: string = 'btn btn-success';
  headerSectionStyle: string = 'modal-header';
  headerStyle: string = 'modal-title';
  showCloseButton: boolean = true;

  modalRef!: NgbModalRef

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {


  }

  modalOpen(header: string, body: string, options?: ModalOptions): NgbModalRef {
    let modalOptios = options ? this.mapModalOptions(options) : {};
    this.headerContent = header;
    this.bodyContent = body;
    return this.modalService.open(this.modalTeplateRef, modalOptios);
  }
  mapModalOptions(options: ModalOptions) {
    let modalOption = {};
    if (options) {
      this.showOkayButton = options.showOkayButton != undefined ? options.showOkayButton : this.showOkayButton;
      this.showConfirmationButtons = options.showConfirmationButtons != undefined ? options.showConfirmationButtons : this.showConfirmationButtons;
      this.isHtmlContent = options.isHtmlContent != undefined ? options.isHtmlContent : this.isHtmlContent;
      this.footerStyle = options.footerStyle ? options.footerStyle : this.footerStyle;
      this.confirmationButtonStyle = options.confirmationButtonStyle ? options.confirmationButtonStyle : this.confirmationButtonStyle;
      this.cancelButtonStyle = options.cancelButtonStyle ? options.cancelButtonStyle : this.cancelButtonStyle;
      this.okayButtonStyle = options.okayButtonStyle ? options.okayButtonStyle : this.okayButtonStyle;
      this.headerSectionStyle = options.headerSectionStyle ? options.headerSectionStyle : this.headerSectionStyle;
      this.headerStyle = options.headerStyle ? options.headerStyle : this.headerStyle;
      this.showCloseButton = options.showCloseButton != undefined ? options.showCloseButton : this.showCloseButton;

      modalOption = {
        animation: options.animation,
        size: options.size,
        centered: options.centered,
        backdrop: 'static',
        keyboard: false
      }
    }

    return modalOption;
  }

}




