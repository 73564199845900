import { Injectable } from '@angular/core';
import { Route, Router } from '@angular/router';

import { ComponentRegistry } from '../../utilities/ComponentRegistry';
import data from '../../../assets/config.json';
import { ConfigurationModel } from '../../models/ConfigurationModel';
import { NavigationModel } from '../../models/NavigationModel';


@Injectable({
  providedIn: 'root'
})
export class RouteService {
  /**
   * Mapping the config JSON to the ConfigurationModel
   */
  jsonConfigs: ConfigurationModel[] = data.metadata;
  constructor(private router: Router, private componentRegistry: ComponentRegistry) { }
  /**
   * Configures the route array for all components and claim forms.
   * */
  loadRoutes() {
    /**
     * Filters the wild card Navigation from the configuration.*/
    const commonRoute = this.jsonConfigs.filter(config => config.prefix == '**')[0];
    for (let i = 0; i < this.jsonConfigs.length; i++) {
      let url = this.jsonConfigs[i].prefix;
      let routes = this.router.config;
      let routeObject: Route =
      {
        path: url
      }
      if (!routeObject.children) {
        routeObject.children = [];
      }
      // add the common routes as children.
      routeObject.children = this.constructNavigationArray(commonRoute.navigation);

      if (this.jsonConfigs[i].navigation) {
        if (this.jsonConfigs[i].replaceAll) {
          routeObject.children = this.constructNavigationArray(this.jsonConfigs[i].navigation);
        } else {
          let arrayNav = this.jsonConfigs[i].navigation;
          arrayNav?.forEach(n => {
            let item: Route = {
              path: n.navigationPath,
              component: this.componentRegistry.registry[n.componentName]
            }
            if (n.mergeIndex) {
              if (routeObject.children) {
                routeObject.children.splice(n.mergeIndex, 0, item);
              }
            }
            if (n.replaceIndex) {
              routeObject.children?.splice(n.replaceIndex, 1, item);
            }
          });
        }
        //routeObject.children = this.constructNavigationArray(this.jsonConfigs[i].navigation);
      } 
      routes.push(routeObject);
      this.router.resetConfig(routes);
    }
  }
  /**
   * Returns an array of Route objects
   * @param navigation - used to match the correct component to be added to the Route object
   */
  constructNavigationArray(navigation?: NavigationModel[]): Route[] {
    let routArray: Route[] = [];
    navigation?.forEach(nav => {
      routArray.push(
        {
          path: nav.navigationPath,
          component: this.componentRegistry.registry[nav.componentName]
        }
      );
    });
    return routArray;
  }


}
