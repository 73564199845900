<div>
  <fieldset>
    <h3 class="multisteps-form__title">Cancel Claim</h3>
    <div class="multisteps-form__content">
      <form [formGroup]="cancelForm">
        <div class="form-row mt-4">
          <div class="w-100">
            <div class="form-group row">
              <div class="col-md-4 col-sm-12 text-right">
                <label class="control-label">
                  Reason for Cancelling Claim
                </label>
              </div>
              <div class="col-md-4 col-sm-12">

                <select class="form-control" formControlName="cancelReason">
                  <option selected disabled value="">Please select</option>
                  <option *ngFor="let cancelReason of cancellationReasons" [ngValue]="cancelReason">{{cancelReason.Name}}</option>
                </select>
              </div>
              <div class="col-md-4 col-sm-12">
                <input type="button"
                       value="Submit"
                       class="btn btn-md btn-primary"
                       (click)="submitCancelation()"
                       style="background-color:#AD002B"
                       [disabled]="(!myForm.cancelReason.value && myForm.cancelReason.value?.Name !== 'Other' )||
                       (myForm.cancelReason.value?.Name == 'Other' && (!myForm.description.value || myForm.description.errors))"/>
              </div>
            </div>
           
          </div>
        </div>
        <div class="form-row mt-4" *ngIf="myForm.cancelReason.value?.Name == 'Other'">
          <div class="w-100">
            <div class="form-group row">
              <div class="col-md-4 col-sm-12 text-right">
                <label class="control-label">
                  Description
                  <span *ngIf="(!myForm.description.value || myForm.description.errors?.required)">*</span>
                </label>
              </div>
              <div class="col-md-4 col-sm-12">
                <textarea class="form-control" formControlName="description" id="txtDescription" name="txtDescription" placeholder="Description" rows="4" maxlength="1000"></textarea>

              </div>
              <div class="col-md-4 col-sm-12">
                <span *ngIf="myForm.description.errors?.required" class="help-block error">Required</span>
              </div>
            </div>
          </div>
         
        </div>
        

      </form>
    </div>
  </fieldset>

</div>
<modal #modal></modal>


