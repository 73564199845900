import { Component, OnInit } from '@angular/core';
import { AppService } from '../../../../services/common/app.service';
import { FormService } from '../../../../services/common/form.service';

@Component({
  selector: 'app-amx-parcel-details',
  templateUrl: './amx-parcel-details.component.html',
  styleUrls: ['./amx-parcel-details.component.css']
})
export class AmxParcelDetailsComponent implements OnInit {
  dateTimeSent: any;
  parcelDetailsForm = this.formService.amxParcelDetailsForm;
  customerinformationForm = this.formService.amxCustomerinformationForm;
  packageTypes: any;
  constructor(private formService: FormService, private appService: AppService) { }
  identity(index: any, item: any) {
    return item.Id;
  }
  get claimant() {
    return this.customerinformationForm?.controls;
  }

  get myForm() {
    return this.parcelDetailsForm?.controls;
  }
  
  ngOnInit(): void {
    this.myForm.packageType.disable({ onlySelf: true });
  }



       

}
