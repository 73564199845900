<fieldset>
  <h3 class="multisteps-form__title"> Payment </h3>
  <div class="multisteps-form__content">

    <form [formGroup]="paymentForm">
      <p class="font-3">In the event your claim is approved</p>
      <div class="form-row mt-4">
        <div class="row w-100">
          <div class="col-md-4 col-sm-12 text-right">
            <label class="control-label">Account Name <span *ngIf="myForm.accountName?.errors?.required">*</span></label>
          </div>
          <div class="col-md-4 col-sm-12">
            <input type="text" class="form-control" name="accountName" formControlName="accountName" />
          </div>
          <div class="col-md-4 col-sm-12">
            <span *ngIf="myForm.accountName?.touched && myForm.accountName?.errors?.required" class="help-block error">Required <br /></span>
          </div>
        </div>
      </div>

      <div class="form-row mt-4" *ngIf="claimant?.isInternationalClaimant?.value =='true'">
        <div class="row w-100">
          <div class="col-md-4 col-sm-12 text-right">
            <label class="control-label">Account Address <span *ngIf="myForm.accountAddress?.errors?.required">*</span></label>
          </div>
          <div class="col-md-4 col-sm-12">
            <input type="text" class="form-control" name="accountAddress" formControlName="accountAddress" />
          </div>
          <div class="col-md-4 col-sm-12">
            <span *ngIf="myForm.accountAddress?.touched && myForm.accountAddress?.errors?.required" class="help-block error">Required</span>
          </div>
        </div>
      </div>

      <div class="form-row mt-4">
        <div class="row w-100">
          <div class="col-md-4 col-sm-12 text-right">
            <label class="control-label">Bank Name <span *ngIf="myForm.nameOfBank?.errors?.required">*</span></label>
          </div>
          <div class="col-md-4 col-sm-12">
            <input type="text" class="form-control" name="nameOfBank" formControlName="nameOfBank" />
          </div>
          <div class="col-md-4 col-sm-12">
            <span *ngIf="myForm.nameOfBank?.touched && myForm.nameOfBank?.errors?.required" class="help-block error">Required</span>
          </div>
        </div>
      </div>

      <div class="form-row mt-4" *ngIf="claimant?.isInternationalClaimant?.value =='true'">
        <div class="row w-100">
          <div class="col-md-4 col-sm-12 text-right">
            <label class="control-label">Bank Address <span *ngIf="myForm.bankAddress?.errors?.required">*</span></label>
          </div>
          <div class="col-md-4 col-sm-12">
            <input type="text" class="form-control" name="bankAddress" formControlName="bankAddress" />
          </div>
          <div class="col-md-4 col-sm-12">
            <span *ngIf="myForm.bankAddress?.touched && myForm.bankAddress?.errors?.required" class="help-block error">Required</span>
          </div>
        </div>
      </div>

      <div class="form-row mt-4" *ngIf="claimant?.isInternationalClaimant?.value =='false'">
        <div class="row w-100">
          <div class="col-md-4 col-sm-12 text-right">
            <label class="control-label">BSB <span *ngIf="myForm.bsbNumber.errors?.required">*</span></label>
          </div>
          <div class="col-md-4 col-sm-12">
            <input type="text" class="form-control" name="bsbNumber" formControlName="bsbNumber" (change)="onBsbNumberChanged()" />
            <div *ngIf="myForm.bsbInformation?.value != null">
              <i (click)="showBsbInformation()" title="click to see more information" class="bsb-status icon- completed" aria-hidden="true"></i>
            </div>
            <div *ngIf="myForm.bsbInformation?.value == null">
              <i (click)="showBsbInformation()" title="click to see more information" class="bsb-status icon- issue" aria-hidden="true"></i>
            </div>
          </div>
          <div class="col-md-4 col-sm-12">
            <div [ngClass]="{'help-block error' : ((!this.myForm.bsbNumber.valid) && (this.myForm.bsbNumber.errors && this.myForm.bsbNumber.value))
                 || ((this.myForm.bsbNumber.touched)  && (this.myForm.bsbNumber.errors && !this.myForm.bsbNumber.value))}">
              <span *ngIf="myForm.bsbNumber.touched && myForm.bsbNumber.errors?.required">Required</span>
              <span *ngIf="myForm.bsbNumber.errors?.minlength">Too Short<br /></span>
              <span *ngIf="myForm.bsbNumber.errors?.maxlength">Too Long<br /></span>
              <span *ngIf="myForm.bsbNumber.errors?.invalidNumber">BSB number should be numeric<br /></span>
              <span *ngIf="myForm.bsbNumber.errors?.invalidBSB">BSB number is invalid<br /></span>
            </div>
          </div>
        </div>
      </div>

      <div class="form-row mt-4">
        <div class="row w-100">
          <div class="col-md-4 col-sm-12 text-right">
            <label class="control-label">Bank Account Number <span *ngIf="myForm.accountNumber.errors?.required">*</span></label>
          </div>
          <div class="col-md-4 col-sm-12">
            <input type="text" class="form-control" name="accountNumber" formControlName="accountNumber" />
          </div>
          <div class="col-md-4 col-sm-12">
            <div [ngClass]="{'help-block error' : (myForm.accountNumber.errors?.required && myForm.accountNumber.touched)
                 || (myForm.accountNumber.errors && myForm.accountNumber.value)}">
              <span *ngIf="myForm.accountNumber.touched && myForm.accountNumber.errors?.required">Required<br /></span>
              <span *ngIf="myForm.accountNumber.errors?.minlength">Too Short<br /></span>
              <span *ngIf="myForm.accountNumber.errors?.maxlength">Too Long<br /></span>
              <span *ngIf="myForm.accountNumber.errors?.invalidNumber ">The account number should be numeric<br /></span>
            </div>
          </div>
        </div>
      </div>

      <div class="form-row mt-4" *ngIf="claimant?.isInternationalClaimant?.value =='true'">
        <div class="row w-100">
          <div class="col-md-4 col-sm-12 text-right">
            <label class="control-label">Bank ABA/Routing <span *ngIf="myForm.bankABA.errors?.required">*</span></label>
          </div>
          <div class="col-md-4 col-sm-12">
            <input type="text" class="form-control" name="bankABA" formControlName="bankABA" />
          </div>
          <div class="col-md-4 col-sm-12">
            <div [ngClass]="{'help-block error' :  (myForm.bankABA.errors?.required && myForm.bankABA.touched)
                 || (myForm.bankABA.errors && myForm.bankABA.value)}">
              <span *ngIf="myForm.bankABA.touched && myForm.bankABA.errors?.required">Required<br /></span>
              <span *ngIf="myForm.bankABA.errors?.minlength">Too Short<br /></span>
              <span *ngIf="myForm.bankABA.errors?.maxlength">Too Long<br /></span>
              <span *ngIf="myForm.bankABA.errors?.invalidNumber ">The bank ABA number should be numeric.<br /></span>
            </div>
          </div>
        </div>
      </div>

      <div class="form-row mt-4" *ngIf="claimant?.isInternationalClaimant?.value =='true'">
        <div class="row w-100">
          <div class="col-md-4 col-sm-12 text-right">
            <label class="control-label">Bank SWIFT <span *ngIf="myForm.bankSWIFT.errors?.required">*</span></label>
          </div>
          <div class="col-md-4 col-sm-12">
            <input type="text" class="form-control" name="bankSwift" formControlName="bankSWIFT" />
          </div>
          <div class="col-md-4 col-sm-12">
            <div [ngClass]="{'help-block error' : (myForm.bankSWIFT.errors?.required && myForm.bankSWIFT.touched)
                 || (myForm.bankSWIFT.errors && myForm.bankSWIFT.value)}">
              <span *ngIf="myForm.bankSWIFT.touched && myForm.bankSWIFT.errors?.required">Required<br /></span>
              <span *ngIf="myForm.bankSWIFT.errors?.minlength">Too Short<br /></span>
              <span *ngIf="myForm.bankSWIFT.errors?.maxlength">Too Long<br /></span>
              <span *ngIf="myForm.bankSWIFT.errors?.invalidCharacter ">The account number should be valid character.<br /></span>
            </div>
          </div>
        </div>
      </div>

      <div class="form-row mt-4" *ngIf="claimant?.isInternationalClaimant?.value =='true'">
        <div class="row w-100">
          <div class="col-md-4 col-sm-12 text-right">
            <label class="control-label">Currency <span *ngIf="myForm.accountCurrency?.errors?.required">*</span></label>
          </div>
          <div class="col-md-4 col-sm-12">
            <select class="form-control" formControlName="accountCurrency" id="accountCurrency" name="accountCurrency">
              <option selected disabled value="">Please Choose</option>
              <option *ngFor="let currancy of accountCurrencies trackBy:identity" [ngValue]="currancy" [title]="currancy.name">
                {{currancy.code}}
              </option>
            </select>
          </div>

          <div class="col-md-4 col-sm-12">
            <span *ngIf="myForm.accountCurrency?.touched && myForm.accountCurrency?.errors?.required" class="help-block error">Required</span>
          </div>
        </div>
      </div>

      <div class="form-row mt-4">
        <div class="w-100">
          <div class="col-12 col-md-9 m-auto important-notice">
            <p class="mb-0"><b>IMPORTANT:</b> Please verify that the bank details entered are correct, to ensure that you receive your funds in the event of a successful claim. FreightSafe takes no responsibility for funds paid into incorrectly nominated accounts.</p>
          </div>
        </div>
      </div>

    </form>
  </div>
</fieldset>
<modal #modal></modal>
