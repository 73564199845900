import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ErrorHelper {
  /**
   * takes in the error response as a parameter and formts the error
   * @param response
   * returns a tuple, with first value of title and second value of the error message.
   */
  public formatAndDisplayError(response: any,showErrorDetails=true): [string, string] | null {

    if (response) {
      if (environment.showConsoleMessages) {
        console.log(response.error);
        console.log(response.message);
      }
      const title = 'Error';

      let textContent = "";
      if (response) {
        if (response.error) {
          if (response.error.Message) {
            textContent = response.error.Message + "<br>";
          }
          else {
            textContent = response.error + "<br>";
          }
        }
        if (response.message && showErrorDetails) {
          textContent = textContent + response.message;
        }
      }
      else {
        textContent = "No error response"
      }

      if (!textContent) {
        textContent = response;
      }

      //this.showInformation(title, textContent, true);
      return [title, textContent];
    }
    return null;
  }

}
