import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { Device } from '../../../../enums/Device';
import { RequiredStatus } from '../../../../enums/RequiredStatus';
import { ConfigurationModel } from '../../../../models/ConfigurationModel';
import { ModalOptions } from '../../../../models/ModalOptions';
import { DocumentTypeUploads, DraftFile, SupportingDocumentType } from '../../../../models/SupportingDocumentType';
import { CommonService } from '../../../../services/common/common.service';
import { FormService } from '../../../../services/common/form.service';
import { Constant } from '../../../../utilities/Constant';
import { CameraComponent } from '../../../common/modal/camera/camera.component';
import { ModalComponent } from '../../../common/modal/modal.component';

@Component({
  selector: 'app-amx-required-supporting-information',
  templateUrl: './amx-required-supporting-information.component.html',
  styleUrls: ['./amx-required-supporting-information.component.css']
})
export class AmxRequiredSupportingInformationComponent implements OnInit, OnDestroy {

  supportingDocumentForm = this.formService.amxSupportingDocumentForm;
  //new version
  carrierConfig: ConfigurationModel = this.commonService.getCarrierConfigObject();
  documentTypes: SupportingDocumentType[] = [];
  uploadedFiles: DocumentTypeUploads[] = [];
  allowMultipleUploads!: boolean;
  totalFileSizeAllowed = Constant.totalFileSizeAllowed
  customerinformationForm = this.formService.amxCustomerinformationForm;
  supportingDocumentProgressBar: String = Constant.supportingDocumentProgressBar;
  progressBarValueStyle = { "width": "0%" };
  deviceType!: any;
  showSnapButton!: boolean;
  parcelDetailsForm = this.formService.amxParcelDetailsForm;

  documentTypeSub!: Subscription;
  attachmentSub!: Subscription;
  isLoadedSub!: Subscription;
  progressBarSub!: Subscription;



  //furtherDetailsForm = this.formService.amxfurtherDetailsForm;
  //attachments: { file: File, typeName: string, fileFrom: number }[] = [];
  modalbodyContent!: string;
  modalHeaderContent!: string;
  @ViewChild('modal') modal!: ModalComponent;

  constructor(private formService: FormService, private commonService: CommonService, private modalService: NgbModal) { }
  get myForm() {
    return this.supportingDocumentForm?.controls;
  }
  get customer() {
    return this.customerinformationForm?.controls;
  }

  get parcelDetails() {
    return this.formService.amxParcelDetailsForm?.controls;
  }

  ngOnInit(): void {

    //new version
    /*initial load*/
    this.deviceType = this.formService.getDeviceType();
    this.showSnapButton = this.deviceType == Device.Other;
    this.formService.setRequiredDocumentsValidators();
    this.documentTypes = this.formService.customizeDocumentTypes(this.carrierConfig, this.supportingDocumentForm);
    this.updateDocumentInformationBasedOnCustomerType(this.documentTypes);
    this.autoSelectRequiredDocuments();
    this.allowMultipleUploads = this.myForm.documentType?.value?.multiple;

    if (this.myForm.attachments.value) {
      this.uploadedFiles = this.myForm.attachments.value;
    }

    this.documentTypeSub = this.myForm.documentType.valueChanges.subscribe(value => {
      this.allowMultipleUploads = value.multiple;
    })
    /*If cutomer Type is individual*/
    if (this.customer.customerType.value == 0) {
      this.attachmentSub! = this.myForm.attachments?.valueChanges.pipe(distinctUntilChanged()).subscribe(value => {
        this.formService.setRequiredDocumentsValidators();
        this.formService.customizeDocumentTypes(this.carrierConfig, this.supportingDocumentForm);
        this.updateDocumentInformationBasedOnCustomerType(this.documentTypes);
      });
    } else {
      this.attachmentSub?.unsubscribe();
    }

    this.setFileProgressBar(this.myForm.uploadedTotalFileSize.value);

    this.progressBarSub = this.myForm.uploadedTotalFileSize.valueChanges.subscribe(value => {
      this.setFileProgressBar(value);

    });

  }

  ngOnDestroy(): void {
    this.documentTypeSub?.unsubscribe();
    this.attachmentSub?.unsubscribe();
    this.isLoadedSub?.unsubscribe();
  }


  //new version

  /**
 * Adding selected Files.
 * @param files
 */
  public getSelectedFiles(files: File[], documentType: SupportingDocumentType | null = null) {

    /*check if uploaded files have extensions if not remove them from the upload list */
    const result = this.formService.checkFileExtensions(files);
    if (result.filesWithoutExtensions) {
      // modal options
      let options: ModalOptions = {
        size: 'lg',
        centered: true,
        isHtmlContent: true
      }
      this.modalHeaderContent = 'Attention'
      this.modalbodyContent = 'The following file(s) will not be uploaded, as they do not contain extension(s)<br/><br/>'
      this.modalbodyContent = this.modalbodyContent + result.filesWithoutExtensions;
      this.showInformation(this.modalHeaderContent, this.modalbodyContent, options).result.then(() => {
        this.uploadFiles(result.files, documentType);
      });
    } else {
      this.uploadFiles(files, documentType);
    }

  }

  private uploadFiles(files: File[], documentType: SupportingDocumentType | null = null) {
    let isUploadableSize: boolean = false;

    const selectedDocumentType = documentType ? documentType : this.myForm.documentType.value;


    if (this.uploadedFiles && files.length > 0) {

      if (this.deviceType != Device.Other && files.length == 1) {
        if (files[0].type.indexOf('image') > -1 || files[0].type.indexOf('video') > -1) { 
          selectedDocumentType.deviceSnapCount = selectedDocumentType.deviceSnapCount + 1;
          files = this.formService.renameFileAccordingToDevice(files, this.deviceType, selectedDocumentType.deviceSnapCount);
        }
      }

      /* gets the selected document Type. */
      let resultFiles: { allFiles: (File | DraftFile)[], uniqueFiles: File[] } | null;

      resultFiles = this.formService.updateUploadedFiles(this.uploadedFiles, files, selectedDocumentType);

      if (resultFiles != null && resultFiles != undefined) {
        if (resultFiles?.uniqueFiles?.length > 0) {
          isUploadableSize = this.uploadedFileSizeProcessing(resultFiles.uniqueFiles);
        }
        if (isUploadableSize) {
          this.formService.uploadFiles(this.uploadedFiles, resultFiles.uniqueFiles, selectedDocumentType);

          if (documentType) {
            this.supportingDocumentForm.get(documentType.controlName)?.setValue(resultFiles.allFiles);
          } else {
            /*Sets the validity and the fileadded property at the first time*/
            this.supportingDocumentForm.get(this.myForm.documentType.value.controlName)?.setValue(resultFiles.allFiles);
            this.myForm.documentType.value.fileAdded = true;
            this.myForm.documentType.value.requiredStatus = RequiredStatus.NotRequired;
          }
          this.myForm.attachments.setValue(this.uploadedFiles);
          /* File uploaded true, which will trigger a notification if page is refreshed */
          this.commonService.isUploaded$.next(true);
          /* move to the next document type on the list once files are added */
          this.autoSelectRequiredDocuments();


          if (selectedDocumentType.name === 'Sales Invoice') {
            if (this.customer.customerType.value != '0') {
              const title = 'Does your sales invoice comply?';
              const textContent = '<strong>Is your sales invoice dated on or prior to the pick up date?</strong> <br/> <strong>Can we see the receiver details?</strong>';
              this.showInformation(title, textContent);
            }
          }

        }
      }
    }
  }

  /**
   * removes files from documentType and removes the document type from upload array if no files exist.
   * @param indexOfFile
   * @param indexOfDocType
   */
  public removeFile(indexOfFile: number, indexOfDocType: number) {

    let controlName = this.uploadedFiles[indexOfDocType].documentType.controlName;

    //reduce total file size before removing the files.
    let fileSize = (this.uploadedFiles[indexOfDocType].files[indexOfFile].size / (1024 * 1024));
    let value = this.myForm.uploadedTotalFileSize.value - fileSize;
    this.myForm.uploadedTotalFileSize.setValue(value);

    this.uploadedFiles[indexOfDocType].files.splice(indexOfFile, 1);
    /* removes the documentType from the array if there are no files. */
    if (this.uploadedFiles[indexOfDocType].files.length == 0) {
      this.uploadedFiles.splice(indexOfDocType, 1);
      //TODO
      //reset the control so that validation will trigger.
      this.formService.setRequiredDocumentsValidators();
      this.supportingDocumentForm.get(controlName)?.setValue(null);
      this.supportingDocumentForm.updateValueAndValidity();
      let index = this.documentTypes.findIndex(d => d.controlName == controlName);

      this.documentTypes[index].fileAdded = false;
      this.documentTypes[index].snapCount = 0;
      if (this.supportingDocumentForm.get(controlName)?.hasError('required')) {
        this.documentTypes[index].requiredStatus = RequiredStatus.Required;
      }
      if (this.uploadedFiles && this.uploadedFiles.length <= 0) {
        this.myForm.attachments.setValue(null);
      } else {
        this.myForm.attachments.setValue(this.uploadedFiles);
      }
      
      this.autoSelectRequiredDocuments()
      if (this.uploadedFiles.length == 0) {
        /* File uploaded false, which will stop triggering a notification if page is refreshed */
        this.commonService.isUploaded$.next(false);
      }
    } else {
      this.documentTypes[indexOfDocType].snapCount = this.documentTypes[indexOfDocType].snapCount - 1;
      this.supportingDocumentForm.get(controlName)?.setValue(this.uploadedFiles[indexOfDocType].files);
    }
  }

  private autoSelectRequiredDocuments() {
    let doctypes: SupportingDocumentType[] = Object.assign([],
      this.myForm.documentTypes.value);

    let filteredDocs = doctypes.filter(doc => doc.fileAdded == false &&
      doc.requiredStatus == RequiredStatus.Required &&
      doc.visible == true)
    let nextDocumentType = filteredDocs.sort((a, b) => a.id - b.id)[0];
    if (nextDocumentType) {
      this.myForm.documentType.setValue(nextDocumentType);
    } else {
      const doc = doctypes.filter(dt => dt.id == this.myForm.documentType?.value.id)[0];
      this.myForm.documentType.setValue(doc);
    }
  }


  private updateDocumentInformationBasedOnCustomerType(doctypes: SupportingDocumentType[]) {

    let filterbyCustomerType = Constant.documentTypeDescription.filter(dd => dd.customerType == this.customer?.customerType?.value);
    if (filterbyCustomerType.length <= 0) {
      return;
    }
    for (let i = 0; i < doctypes.length; i++) {
      let doc = filterbyCustomerType.filter(dd => dd.controlName == doctypes[i].controlName)[0];
      if (!doc || !doc.description) {
        continue;
      }
      doctypes[i].information = doc.description;
    }
  }

  openCamera(multiple: boolean, documentType: SupportingDocumentType | null = null) {
    let modalOptions: NgbModalOptions = {
      animation: true,
      centered: true,
      size: 'md',
      backdrop: 'static',
      keyboard: false
    }

    let selectedDocType: SupportingDocumentType;
    if (documentType == null) {
      selectedDocType = this.myForm.documentType.value;
    }
    else {
      selectedDocType = documentType;
    }
    const modalRef = this.modalService.open(CameraComponent, modalOptions);

    modalRef.componentInstance.allowMultipleUploads = selectedDocType?.multiple;
    modalRef.componentInstance.snapCount = selectedDocType?.snapCount;
    modalRef.result.then(imageFiles => {
      selectedDocType.snapCount = imageFiles.count;
      this.getSelectedFiles(imageFiles.files, selectedDocType);
    }).catch(val => {
      console.log(val);
    });
  }

  showInformation(headerContent: string, bodyContent: string,options:ModalOptions|null=null) {
    let modalOptions: ModalOptions = {
      animation: true,
      centered: true,
      size: 'md',
      showConfirmationButtons: false,
      isHtmlContent: true,
      showOkayButton: true
    }
    if (options) {
      modalOptions = options;
    }
    this.modalbodyContent = bodyContent;
    this.modalHeaderContent = headerContent;
    return this.modal.modalOpen(this.modalHeaderContent, this.modalbodyContent, modalOptions);
  }

  public openFile(file: File | DraftFile) {
    if (file instanceof File) {
      this.formService.downloadFile(file);
    } else {
      //TODO:
      this.formService.openDraftFile(file, this.parcelDetails.labelNumber.value, this.carrierConfig.prefix);
    }
  }

  /**
 * Checks file size and that are uploaded and 
 * @param files
 */
  private uploadedFileSizeProcessing(files: File[]) {

    const overSizeFiles = this.formService.checkFileSize(files);

    if (overSizeFiles != null && overSizeFiles.filenames != "") {
      // modal options
      let options: ModalOptions = {
        size: 'md',
        centered: true,
        isHtmlContent: true
      }
      this.modalHeaderContent = 'Error'
      this.modalbodyContent = 'Unable to upload the following file(s), the file(s) size exceeds the limit<br/><br/>'
      this.modalbodyContent = this.modalbodyContent + overSizeFiles.filenames;
      this.modalbodyContent = this.modalbodyContent + `<br/><br/> Maximum file size per file allowed is ${Constant.maxFileSizeAllowed} MBs`;
      this.modal.modalOpen(this.modalHeaderContent, this.modalbodyContent, options)
      return false;
    }
    let value = this.myForm.uploadedTotalFileSize.value + overSizeFiles?.totalFileSize


    if (value > Constant.totalFileSizeAllowed) {
      // modal options
      let options: ModalOptions = {
        size: 'md',
        centered: true,
        isHtmlContent: true
      }
      this.modalHeaderContent = 'Error'
      this.modalbodyContent = 'The maximum cumulative file size has been reached. <br/> Please reduce the total file size and re-upload.'
      this.modal.modalOpen(this.modalHeaderContent, this.modalbodyContent, options)
      return false;
    }
    this.myForm.uploadedTotalFileSize.setValue(value);
    return true;
  }


  public setFileProgressBar(progressValue: number) {
    let totalPercentage = (progressValue / Constant.totalFileSizeAllowed) * 100
    this.progressBarValueStyle.width = totalPercentage.toFixed(2) + "%";

    if (totalPercentage > 95) {
      this.supportingDocumentProgressBar = Constant.supportingDocumentProgressBarDanger;

    } else if (totalPercentage > 75) {
      this.supportingDocumentProgressBar = Constant.supportingDocumentProgressBarWarning;
    } else {
      this.supportingDocumentProgressBar = Constant.supportingDocumentProgressBar;
    }
  }
}

