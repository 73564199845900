<div class="form-row">
  <fieldset>
    <h3 class="multisteps-form__title">Purchasing Customer Details</h3>
    <div class="multisteps-form__content">
      <form [formGroup]="purchasingCustomerForm">
        <div formGroupName="purchasingCustomer">
          <div class="col-12 col-md-9 m-auto" *ngIf="claimantRole.value.value != 'Third Party'">
            <div class="important-notice"> <b>IMPORTANT:</b> <br /> The Purchasing Customer Name field is <b>NOT</b> required to be filled, if the field is blank please proceed to the next section by clicking the next button. </div>
          </div>
          <div class="form-row mt-4">
            <div class="row w-100">
              <div class="col-md-4 col-sm-12 text-right">
                <label class="control-label">Name <span *ngIf="myForm.get('name')?.errors?.required">*</span></label>
              </div>
              <div class="col-md-4 col-sm-12">
                <input type="text" formControlName="name" class="form-control" name="name">
              </div>
              <div class="col-md-4 col-sm-12">
                <span *ngIf="myForm.get('name')?.touched && myForm.get('name')?.errors?.required" class="help-block error">Required</span>
              </div>
            </div>
          </div>
          <div *ngIf="claimantRole.value.value == 'Third Party'">

            <div class="form-row mt-4">
              <div class="row w-100">
                <div class="col-md-4 col-sm-12 text-right">
                  <label class="control-label">Contact Name <span *ngIf="myForm.get('contactName')?.errors?.required">*</span></label>
                </div>
                <div class="col-md-4 col-sm-12">
                  <input type="text" class="form-control" name="ContactName" formControlName="contactName">
                </div>
              </div>
            </div>
            <div class="form-row mt-4">
              <div class="row w-100">
                <div class="col-md-4 col-sm-12 text-right">
                  <label class="control-label">Street Address</label>
                </div>
                <div class="col-md-4 col-sm-12">
                  <input type="text" class="form-control" name="StreetAddress" formControlName="addressLine1">
                </div>
              </div>
            </div>
            <div class="form-row mt-4">
              <div class="row w-100">
                <div class="col-md-4 col-sm-12 text-right">
                  <label class="control-label">Suburb</label>
                </div>
                <div class="col-md-4 col-sm-12">
                  <input type="text" class="form-control" name="Suburb" formControlName="city">
                </div>
              </div>
            </div>
            <div class="form-row mt-4">
              <div class="row w-100">
                <div class="col-md-4 col-sm-12 text-right">
                  <label class="control-label">Postal Code</label>
                </div>
                <div class="col-md-4 col-sm-12">
                  <input type="text" class="form-control" name="PostalCode" formControlName="postalCode">
                </div>
              </div>
            </div>
            <div class="form-row mt-4">
              <div class="row w-100">
                <div class="col-md-4 col-sm-12 text-right">
                  <label class="control-label">Resolve Address</label>
                </div>
                <div class="col-md-4 col-sm-12">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <button class="form-control"
                              (click)="resolveGoogleAddress(myForm)">
                        <span data-icon="e" class="icon"></span>
                      </button>
                    </div>
                    <input type="text" class="form-control" formControlName="address">
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row mt-4">
              <div class="row w-100">
                <div class="col-md-4 col-sm-12 text-right">
                  <label class="control-label">Email<span *ngIf="myForm.get('email')?.errors?.required">*</span></label>
                </div>
                <div class="col-md-4 col-sm-12">
                  <input type="email" class="form-control" name="purchasingCustomerEmail" formControlName="email">
                </div>
                <div class="col-md-4 col-sm-12">
                  <div [ngClass]="{'help-block error' : (myForm.get('email')?.errors?.required && myForm.get('email')?.touched)
                   ||(myForm.get('email')?.errors && myForm.get('email')?.value)}">
                    <span *ngIf="myForm.get('email')?.touched && myForm.get('email')?.errors?.required">Required</span>
                    <span *ngIf="myForm.get('email')?.errors?.invalidEmailFormat">Email format Invalid <br /> Ex:- "name@domain.com.au"</span>
                  </div>
                  </div>
              </div>
            </div>
            <div class="form-row mt-4">
              <div class="row w-100">
                <div class="col-md-4 col-sm-12 text-right">
                  <label class="control-label text-left-override">Phone Number <span *ngIf="myForm.get('officeNumber')?.errors?.required">*</span></label>
                </div>
                <div class="col-md-4 col-sm-12">
                  <input type="text" class="form-control" name="officeNumber" formControlName="officeNumber">
                </div>
                <div class="col-md-4 col-sm-12">
                  <div [ngClass]="{'help-block error' : (myForm.get('officeNumber')?.errors?.required && myForm.get('officeNumber')?.touched)
                 || (myForm.get('officeNumber')?.errors && myForm.get('officeNumber')?.value)}">
                    <span *ngIf="myForm.get('officeNumber')?.touched && myForm.get('officeNumber')?.errors?.required">Required<br /></span>
                    <span *ngIf="myForm.get('officeNumber')?.errors?.minlength">Too Short<br /></span>
                    <span *ngIf="myForm.get('officeNumber')?.errors?.maxlength">Too Long<br /></span>
                    <span *ngIf="myForm.get('officeNumber')?.errors?.invalidNumber ">The phone number should be numeric<br /></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="claimantRole.value.value == 'Third Party'">
          <div class="form-row mt-4">
            <div class="row w-100">
              <div class="col-md-4 col-sm-12 text-right">
                <label class="control-label">Purchasing Customer Comment</label>
              </div>
              <div class="col-md-4 col-sm-12">
                <input type="text" class="form-control" name="customerComment" formControlName="purchasingCustomerComment">
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </fieldset>
</div>
<modal #modal></modal>
