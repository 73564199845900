import { Component, OnInit } from '@angular/core';
import { FormService } from '../../../services/common/form.service';

@Component({
  selector: 'app-franchisee-details',
  templateUrl: './franchisee-details.component.html',
  styleUrls: ['./franchisee-details.component.css']
})
export class FranchiseeDetailsComponent implements OnInit {
  franchiseeDetailsForm = this.formService.franchiseeDetailsForm;

  get franchisee() {
    return this.franchiseeDetailsForm?.controls?.franchisee
  }

  constructor(private formService: FormService) { }

  ngOnInit(): void {
  }

}
