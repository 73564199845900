import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';

import { Constant } from '../../../utilities/Constant';
import { CommonService } from '../../../services/common/common.service';
import { AppService } from '../../../services/common/app.service';
import { FormService } from '../../../services/common/form.service';
import { NavigationService } from '../../../services/common/navigation-service';
import { ModalComponent } from '../modal/modal.component';
import { ModalOptions } from '../../../models/ModalOptions';
import { environment } from '../../../../environments/environment';
import { ConsignmentModalComponent } from '../modal/consignment-modal/consignment-modal.component';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';





@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit, AfterViewInit {
  welcomeForm = this.formService.welcomeForm;
  preview = this.formService.preview;
  previousClaimFormDetails = this.formService.previousClaimForm;
  carrierName!: string;
  warrantyName: string = 'FreightSafe Warranty'
  link!: string;
  target = '_blank';
  requireddocs: string[] = ['Cost Price Invoice / Proof of Manufactured Cost',
    'Proof of Delivery (POD) - (Required for Damage Claims)',
    'Photos of Damage - (Required for Damage Claims)'];

  // modal properties
  @ViewChild('modal', { read: ModalComponent }) private modal!: ModalComponent;
  @ViewChild('consignmentmodal', { read: ConsignmentModalComponent }) private consignmentmodal!: ConsignmentModalComponent;
  modalHeaderContent!: string;
  modalbodyContent!: string;
  showOkayButton!: boolean;
  showModalConfirmationButtons!: boolean;
  isHtmlContent: boolean = false;
  userGuide!: string;

  welcomeText!: string;
  isSubmitDisabled: boolean = true;
  welcomeParaOne: string = '';
  welcomeParaTwo: string = '';
  claimNumberFormat: string = 'Ex :-';

  get previewForm() {
    return this.preview.controls;
  }
  get myForm() {
    return this.welcomeForm.controls;
  }
  get previousClaimForm() {
    return this.previousClaimFormDetails.controls;
  }

  constructor(private commonService: CommonService,
    private appService: AppService,
    private formService: FormService,
    private navigationService: NavigationService,
    private modalService: NgbModal) { }
    
  ngOnInit(): void {
    this.myForm.browser.setValue(this.formService.getBrowserType());
    this.appService.getIpAddress().subscribe(value => {
      this.myForm.ipAddress.setValue(value?.ip);
    }, error => {
      this.myForm.ipAddress.setValue(`${error} 0.0.0.0`);
    });
    this.myForm.browserVersion.setValue(this.formService.getBrowserVersion());

    this.carrierName = this.commonService.carrierName;
    this.claimNumberFormat = this.claimNumberFormat + `${this.commonService.carrierPrefix}001`
    if (this.commonService.warrantyName) {
      this.warrantyName = this.commonService.warrantyName;
    } else {
      this.warrantyName = `${this.carrierName} ${this.warrantyName}`;
    }
    this.userGuide = `${Constant.s3url}${environment.s3Bucket}${this.commonService.userGuideLink}`;
    this.link = this.commonService.TermsAndConditionLink;
    if (this.commonService.welcomeParaOne) {
      this.welcomeParaOne = this.commonService.welcomeParaOne;
    } else {
      this.welcomeParaOne = `<p class="font-1 text-center">Warranty Claim Form</p>
                           <p class="font-2 text-center"> Let\'s get your claim moving</p>
                           <p class="font-3 text-justify thin"> FreightSafe is here to assist you in processing your claim with  ${this.carrierName}.
                            Prior to submitting your claim form, please ensure that you have read the
                            <a href = ${this.link} target = ${this.target}> Terms and Conditions </a> of the ${this.warrantyName}. </p>
                            <p class="text-left font-3 thin">
                            In order to lodge your claim, you will need the following documents:`

    }

    if (this.commonService.welcomeParaTwo) {
      this.welcomeParaTwo = this.commonService.welcomeParaTwo;
    } else {
      this.welcomeParaTwo = `<p class="text-justify font-3 thin">Please ensure you have notified ${this.carrierName} of your lost or damaged consignment as soon as you become aware of any issue, and prior to lodging your claim. All damaged goods should be held throughout the claims process and made available to ${this.carrierName} if collection is required.</p>`;
    }
    /* set required documents to be displayed in the welcome screen */
    this.setRequiredDocuments(this.commonService.requiredDocuments)
    this.myForm.previousClaimLabelNumber.setValue("");

   

  }

  ngAfterViewInit(): void {
    let options: NgbModalOptions = {
        animation: true,
        centered: true,
        size: 'xl',
        backdrop: 'static',
        keyboard: false
      }
    if (this.commonService.carrierAlternateMessage && this.myForm.alreadyChecked.value === false) {
      this.modalService.open(ConsignmentModalComponent, options).result.then(value => {
        if (value === true) {
          this.myForm.alreadyChecked.setValue(value);
        }
        
      }, (error) => {
        if (environment.showConsoleMessages) {
          console.log(error);
        }
        
      });
     
    }
  }

  public getPreviousClaimDetailsAndNavigate(claimNumber: string, consignmentNoteNumber: string) {
    this.commonService.isLoading$.next(true);
    claimNumber = claimNumber.trim();
    consignmentNoteNumber = consignmentNoteNumber.trim();
    this.appService.getPreviousClaimDetails(claimNumber, consignmentNoteNumber).subscribe(result => {
      let configObject = this.commonService.getCarrierConfigObject();
      this.formService.resetClaimFormFieldsAndStoreSession(configObject);
      this.formService.setPreviouseClaimData(result);

      /* Update sender receiver details */
      this.formService.updateSenderReceiver();
      /* Navigate to next component. */
      let currentNavigation = this.navigationService.navigate(Constant.navigationDirection.forward, configObject);
      this.commonService.isLoading$.next(false);
      /*Sets the action buttons accordingly*/
      this.navigationService.activeStep$.next(currentNavigation);
    }, error => {
      // modal options
      let options: ModalOptions = {
        size: 'md',
        centered: true,
        isHtmlContent: true
      }
      this.modalHeaderContent = 'Attention'
      this.modalbodyContent = 'Claim not found';
      console.error(error?.message);
      this.commonService.isLoading$.next(false);
      this.modal.modalOpen(this.modalHeaderContent, this.modalbodyContent, options)
    });
  };

  private setRequiredDocuments(documentsString: string | undefined) {
    if (documentsString) {
      let documents = documentsString.split(',');
      for (let j = 0; j < documents.length; j++) {
        let newDoc = this.requireddocs.filter(doc => doc.trim().toLowerCase() === documents[j].trim().toLowerCase())[0];
        if (!newDoc) {
          this.requireddocs.unshift(documents[j].trim());
        } else {
          let index = this.requireddocs.indexOf(newDoc);
          this.requireddocs.splice(index, 1);
        }
      }
      this.requireddocs.sort();
    }
  }

  public getDraftClaim(consignmentNoteNumber: string) {
    this.appService.getDraftByConsignmentNumber(consignmentNoteNumber, this.commonService.carrierPrefix).subscribe(draftResult => {
      if (draftResult) {
        let configObject = this.commonService.getCarrierConfigObject();
        this.formService.resetClaimFormFieldsAndStoreSession(configObject);
        this.myForm.draftClaimId?.setValue(draftResult.id);
        this.formService.mapClaimFormDataToClaimForm(this.commonService.carrierPrefix, draftResult.serializedString, configObject);
        this.previewForm.isDeclarationChecked.setValue(false, { onlyself: true });
        this.previewForm.isDeclarationChecked.updateValueAndValidity();
        this.formService.updateSenderReceiver();
        /* sets visibility of BankDetails component*/
        this.formService.setBankDetailVisibilityAndValidation(configObject, this.formService.isFreightInsureClaimRequired);


        this.commonService.isDraftClaim$.next(true);
        /* Navigate to next component. */
        let currentNavigation = this.navigationService.navigate(Constant.navigationDirection.forward, configObject);
        this.commonService.isLoading$.next(false);
        /*Sets the action buttons accordingly*/
        this.navigationService.activeStep$.next(currentNavigation);
      } else {
        let options: ModalOptions = {
          size: 'md',
          centered: true,
          isHtmlContent: true
        }
        this.modalHeaderContent = 'Attention'
        this.modalbodyContent = 'Draft Claim not found';
        //console.error(error?.message);
        this.commonService.isLoading$.next(false);
        this.modal.modalOpen(this.modalHeaderContent, this.modalbodyContent, options)
      }
    }, error => {

      let options: ModalOptions = {
        size: 'md',
        centered: true,
        isHtmlContent: true
      }
      this.modalHeaderContent = 'Attention'
      this.modalbodyContent = 'Error Retrieving Draft Claim';
      if (environment.showConsoleMessages) {
        console.error(error?.message);
      }
      this.commonService.isLoading$.next(false);
      this.modal.modalOpen(this.modalHeaderContent, this.modalbodyContent, options)

    });
  }

  public onPreviousClaimantChanged() {
    if (this.myForm.isPreviousClaimant.value == '1') {

      this.myForm.previousClaimNumber.setValue("");
      this.myForm.previousClaimLabelNumber.setValue("");
      this.myForm.isDraftClaim?.setValue('0');
      this.myForm.isDraftClaim?.updateValueAndValidity();
    }

  }

  public onDraftClaimChanged() {
    if (this.myForm.isDraftClaim.value == '1') {
      this.myForm.previousClaimNumber.setValue("");
      this.myForm.previousClaimLabelNumber.setValue("");
      this.myForm.isPreviousClaimant?.setValue('0');
      this.myForm.isPreviousClaimant?.updateValueAndValidity();
    }
  }
}
