import { AbstractControl } from '@angular/forms';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { Constant } from './Constant';

export class Utility {
  /**
  * Convert the date time control value to moment date format
  * Example :- {year,month,day} to 19/05/2021
  * @param date
  */
  public static convertDateToString(control: AbstractControl): string {
    if (!control.value) {
      return "";
    }
    let d = moment({
      year: control.value?.year,
      month: control.value?.month - 1,
      date: control.value?.day
    });
    return d.isValid() ? d.format(Constant.dateFormat) : '';
  }

  /**
 * Converts the date object into a date string to be posted to the server.
 * Example :- 19/05/2021 to Wed May 19 2021
 * @param date
 */
  public static convertDateToStringDate(date: { year: number, month: number, day: number }): string {
    return new Date(date.year, date.month - 1, date.day).toDateString();
  }
  /**
   * Converts String date to NgbDate date object.
   * Example :- Wed May 19 2021 to 19/05/2021
   * @param dateString
   */
  public static convertDateStringToNgbDate(dateString: string) {
    let date = new Date(dateString)
    let d = new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
    return d;
  }

  /**
   * Converts String date to date object.
   * Example :- (Wed May 19 2021 | 2021-05-19T00:00:00 ) to 19/05/2021
   * @param dateString
   */
  public static convertDateStringToDate(dateString: string) {
    //let date = new Date(dateString);

    //let d = moment({
    //  year: date.getFullYear(),
    //  month: date.getMonth(),
    //  date: date.getDate()
    //});
    //return d.isValid() ? d.format(Constant.dateFormat) : '';
    let d = moment(dateString).format(Constant.dateFormat);
    return d;
  }

  /**
  * Converts String date to formated string date.
  * Example :- Wed May 19 2021 to 19/05/2021
  * @param dateString
  */
  public static convertStringDateToFormattedDateString(dateString: string) {
    let formattedDate = moment(dateString, Constant.dateFormat);
    let date = formattedDate.toDate();
    return date.toDateString();
  }


  /**
* Convert the date time control value to ngb date format
* Example :- {year,month,day} to 19/05/2021
* @param date
*/
  public static convertDateToNGBDate(control: AbstractControl): NgbDate {
  
    let d = new NgbDate(control.value?.year, control.value?.month, control.value?.day);
    return d;
  
  }
}
