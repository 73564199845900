<fieldset>
  <h3 class="multisteps-form__title"> Terms and conditions </h3>
  <div class="multisteps-form__content">
    <form [formGroup]="termsAndConditionsForm">

      <div class="font-3 text-justify">
        I declare that to the best of my knowledge, I believe the information in this form is true and correct and I have not withheld any relevant information. I consent to Aramex using my personal information provided on this form for the purpose of processing my claim. I understand that if I choose not to provide the required detail, this is my choice, however, Aramex may not be able to process my claim.
        <br />
        <br />
        I consent to Aramex also disclosing my personal information to, and / or collecting additional information about me, from investigators or legal advisors.
        <br />
        <br />
      </div>
      <div class="form-row">
        <div class="row w-100">
          <div class="col-md-4 col-sm-12 text-right col-sm-3">
            <label class="control-label mt-0">I Agree</label>
          </div>
          <div class="col-md-8 col-sm-9">
            <label class="chekbox d-inline">

              <!-- I declare to the best of my knowledge that the information in this form is true and correct and I have not withheld any relevant information.-->
              <input type="checkbox" aria-label="Agree" name="isAgreed" formControlName="isAgreed" value="0">
              <span class="checkmark"></span>
            </label>
            <span *ngIf="myForm.isAgreed?.errors?.checkboxNotChecked || myForm.isAgreed?.errors?.required" class="help-block error mt-0">You need to agree to terms and conditions.</span>
          </div>
        </div>
      </div>

      <div class="form-row mt-4">
        <div class="row w-100">
          <div class="col-md-4 col-sm-12 text-right">
            <label class="control-label">Date</label>
          </div>
          <div class="col-md-4 col-sm-12">
            <input class="form-control" type="text" formControlName="date" />
          </div>
        </div>
      </div>

      <div class="form-row mt-4">
        <div class="row w-100">
          <div class="col-md-4 col-sm-12 text-right">
            <label class="control-label">IP addresses are logged. Your IP address is</label>
          </div>
          <div class="col-md-4 col-sm-12">
            <input type="text" class="form-control" formControlName="ipAddress" />
          </div>
        </div>
      </div>

      <h4 class="mt-4">Privacy policy</h4>
      <div class="mb-3 font-3 text-justify">

        In completing this form you may be providing us with personal information. If so, we are required to provide you with a privacy collection statement. Our privacy policy and our privacy collection statement are available on the Aramex website at <a href="https://www.aramex.com.au/terms-and-conditions/standard-warranty" target="_blank">www.aramex.com.au</a> 
      </div>
    </form>
  </div>
</fieldset>
