import { Component, OnInit, ViewChild, ChangeDetectorRef} from '@angular/core';
import { FormService } from '../../../services/common/form.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Console } from 'console';
import { ModalOptions } from '../../../models/ModalOptions';
import { ModalComponent } from '../../common/modal/modal.component';
import { Subscription } from 'rxjs';
import { Validators } from '@angular/forms';
import { CustomValidationService } from '../../../services/common/custom-validation.service';
import { Constant } from '../../../utilities/Constant';

@Component({
  selector: 'app-claimant-details',
  templateUrl: './claimant-details.component.html',
  styleUrls: ['./claimant-details.component.css']
})
export class ClaimantDetailsComponent implements OnInit {

  claimantDetailsForm = this.formService.claimantDetailsForm;
  claimantRoles = Constant.claimantRole;

  //customerinformationForm = this.formService.amxCustomerinformationForm;
  //welcomeInformationForm = this.formService.amxWelcomeForm;
  //paymentForm = this.formService.amxPaymentForm;
  //senderForm = this.formService.amxSenderForm;
  //receiverForm = this.formService.amxReceiverForm;
  //purchasingCustomerForm = this.formService.amxPurchasingCustomerForm;
  //supportingDocumentForm = this.formService.amxSupportingDocumentForm;

  //@ViewChild('modal') modal!: ModalComponent;
  //modalHeaderContent!: string;
  //modalbodyContent!: string;
  //isInternationalClaimantSub!: Subscription
  private claimantRoleSub!: Subscription;
  //private claimantDetailsFormSub!: Subscription;
  constructor(private formService: FormService,
    private modalService: NgbModal,
    private cd: ChangeDetectorRef,
    private customValidatorService: CustomValidationService) { }

  get myForm() {
    return this.claimantDetailsForm?.controls;
  }
 
  get claimantRole() {
    return this.claimantDetailsForm?.controls.claimantRole;
  }

  //get payment() {
  //  return this.paymentForm?.controls;
  //}
  //get sender() {
  //  return this.senderForm?.controls;
  //}
  //get receiver() {
  //  return this.receiverForm?.controls;
  //}
  //get purchasingCustomer() {
  //  return this.purchasingCustomerForm?.controls;
  //}
  ngOnInit(): void {
    this.claimantRoleSub = this.myForm.claimantRole.valueChanges.subscribe(() => {
      this.formService.updateSenderReceiver();
    });
    //this.claimantDetailsFormSub = this.formService.claimantDetailsForm.statusChanges.subscribe(sub => {
    //  console.log('Contact.claimFormStatusSub : ' + sub);
    //});
      //this.formService.setCustomerinforValidators();
    //this.isInternationalClaimantSub = this.myForm.isInternationalClaimant.valueChanges.subscribe(value => {
      //this.formService.setCustomerinforValidators();
      //if (value == 'true') {
      //  this.openConfirmation();
      //  this.cd.detectChanges();
      //} else {
      //  this.payment.accountCurrency.setValue("");
      //  this.payment.bankSWIFT.setValue("");
      //  this.payment.bankABA.setValue("");
      //  this.payment.bankAddress.setValue("");
      //  this.payment.accountAddress.setValue("");
      //}
      //this.formService.setFurtherDetailsValidators();
      //this.formService.setPaymentValidators();
    //});
    //this.myForm.sender.updateValueAndValidity();
  }

  //openConfirmation() {
  //  const modalOptions: ModalOptions = {
  //    animation: true,
  //    centered: true,
  //    size: 'xl',
  //    isHtmlContent: false,
  //    showConfirmationButtons: true,
  //    showOkayButton: false
  //  }

  //  this.modalHeaderContent = 'Important';

  //  this.modalbodyContent = 'This selection is only for customers who do not have a domestic bank account to facilitate claim payments. By selecting this option you are consenting to International transaction fees, which will be deducted from the final payment figure, should your claim be approved.';

  //  this.modal.modalOpen(this.modalHeaderContent, this.modalbodyContent, modalOptions).result.then((value) => {
  //    if (value == 'confirm') {
  //      this.myForm?.sender?.get('abnNumber')?.setValue("");
  //      this.myForm?.receiver?.get('abnNumber')?.setValue("");
  //      this.myForm?.purchasingCustomer?.get('abnNumber')?.setValue("");
  //      this.payment.bsbNumber.setValue("");
  //      this.cd.detectChanges();

  //    } else if (value == 'cancel') {
  //      this.myForm?.isInternationalClaimant?.setValue("false");
  //      this.cd.detectChanges();
  //    }
  //  });
  //}

  ngOnDestroy(): void {
    this.claimantRoleSub?.unsubscribe();
  }

  onCustomerTypeChanged() {   
    //this.supportingDocumentForm.get('attachments')?.setValue([], { onlyself: true });
    //this.myForm?.sender?.get('isRegisteredForGst')?.setValue('0', { onlyself: true });
    //this.formService.resetRequiredSupportingDocumentForm(this.supportingDocumentForm);
    //this.formService.setRequiredDocumentsValidators();
    //this.formService.setCustomerinforValidators();
   // this.formService.mapCustomerTypeToClaimForm();
  }
  getCustomerTypeName = function (index: any) {
    switch (index) {
      case '0': return 'individual';
        break;
      case '1': return 'manufacturer';
        break;
      case '2': return 'wholesaler';
        break;
      case '3': return 'retailer';
        break;
      default: return '';
        break;
    }
  }
}
