<fieldset>
  <h3 class="multisteps-form__title">Upload Supporting Documentation </h3>
  <div class="multisteps-form__content supporting">
    <form [formGroup]="supportingDocumentForm">

      <div div class="form-row mt-4">
        <div class="w-100">
          <div class="col-12 col-md-9 m-auto" *ngIf="supportingDocumentForm.invalid">
            <div class="important-notice">
              The following document(s) are required to process your claim.
              <ul>
                <ng-container *ngFor="let doc of documentTypes">
                  <li *ngIf="doc.requiredStatus==0 && doc.fileAdded ==false">{{doc?.name}}</li>
                </ng-container>

              </ul>
            </div>
          </div>
        </div>
      </div>
      <div div class="form-row mt-4">
        <div class="w-100">
          <div class="col-12 col-md-9 m-auto" *ngIf="!this.supportingDocumentForm.invalid">
            <div class="important-notice">
              <b> Notice - </b>Thank you for uploading the documents required to submit a claim
              <ul>
                <li> If you have any additional documents which would assist us in processing the claim faster, please upload them by selecting the documet type </li>
                <li> If you are not able to find the document type please upload using the 'Other' document type category</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div div class="form-row mt-4" *ngIf="myForm.documentType.value?.information">
        <div class="w-100">
          <div class="col-12 col-md-9 m-auto" *ngIf="supportingDocumentForm.invalid">
            <div class="important-notice" [innerHtml]="myForm.documentType.value?.information"></div>
          </div>
        </div>
      </div>

      <div div class="form-row mt-4">
        <div class="w-100">
          <div class="col-12 col-md-9 m-auto">

            <div class="progress">
              <div [ngClass]="supportingDocumentProgressBar" role="progressbar" aria-valuemin="0" aria-valuemax="100" [ngStyle]="progressBarValueStyle">
                {{myForm.uploadedTotalFileSize.value?.toFixed(2) +"MB"}}
              </div>
            </div>
            <div> {{(27 - myForm.uploadedTotalFileSize.value).toFixed(2)}}MB free of 27MB </div>
          </div>
        </div>
      </div>

      <div div class="form-row mt-4">
        <div class="col-12 col-md-9 m-auto p-0">
          <div class="form-group row">
            <div class="d-flex w-100 pl-4 ml-1">
              <select class="form-control mb-3 mr-3" formControlName="documentType" id="dropDocumentType" name="dropDocumentType">
                <option *ngFor="let docType of documentTypes" [ngValue]="docType">{{docType?.name}}</option>
              </select>
              <fileUploader (selectedfiles)="getSelectedFiles($event)"
                            [enableMultiple]="allowMultipleUploads"></fileUploader>
              <button *ngIf="showSnapButton" class="btn btn-camera btn-primary ml-3" (click)="openCamera(allowMultipleUploads)"><i data-icon="h" class="camera-retro"></i></button>
            </div>
          </div>
          <div class="important-notice" *ngIf="!showSnapButton">
            Maximum file size is 27MB for this device.
          </div>
        </div>
      </div>

      <div *ngFor="let uploads of uploadedFiles; let indexOfDocType = index;" class="col-12 col-md-9 m-auto pl-2 pr-2">
        <div class="w-100">
          <div class="uploaded-files mt-3">
            <b><i class="icon-clip"></i>  {{uploads.documentType.name}}</b>  -
            <p *ngFor="let file of uploads.files; let indexOfFile =index;" class="mb-0" (click)="openFile(file)">
              <i class="icon-file-image-o"></i>  {{file.name}}
              <i class="icon icon-cancel-circled" (click)="removeFile(indexOfFile,indexOfDocType);">
              </i>

            </p>
            <div class="upload-more">
              <fileUploader (selectedfiles)="getSelectedFiles($event,uploads.documentType)"
                            [enableMultiple]="allowMultipleUploads" [showAddButton]="true" title="Additional Documents"></fileUploader>

              <button *ngIf="showSnapButton" class="btn btn-primary btn-attachment btn-attachment-camera" title="Additional Picture" (click)="openCamera(uploads.documentType.multiple,uploads.documentType)"><i data-icon="h" class="camera-retro"></i></button>

            </div>
          </div>
        </div>
      </div>

      <!--<div class="form-row mt-4" *ngIf="showPodFile">
      <div class="w-100">
        <div class="form-group row ">
          <div class="col-md-4 col-sm-12 text-right">
            <label for="uploadPoD">Upload the Proof Of Delivery (POD) <span *ngIf="myForm.podFile?.pristine && myForm.podFile?.errors?.required">*</span></label>-->
      <!--&& claimForm.claimType != 1-->
      <!--</div>
      <div class="col-md-4 col-sm-12">
        <input type="file" id="uploadPoD" style="color: transparent;" name="podFile" (click)="onClick($event,'podFile')" (change)="onFileSelect($event,'podFile')"
               accept="application/pdf, text/plain,
        image/bmp, image/png, image/jpeg, image/jpg, image/tiff,
        application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
        application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document">-->
      <!--ng-file-select="onFileSelect($files, 'podFile')"-->  <!--ng-required="claimForm.claimType != 1"-->
      <!--<label type="hidden" class="control-label" id="podFile" name="podFile">{{myForm.podFile?.value}}</label>
              <input type="hidden" class="control-label" formControlName="podFile" id="podFile" name="podFile">

            </div>
            <div class="col-md-4 col-sm-12">
              <p *ngIf="myForm.podFile?.touched && myForm.podFile?.errors?.required " class="help-block error">Required</p>
            </div>
          </div>
        </div>
      </div>
      <div class="form-row mt-4">
        <div class="w-100">
          <div class="form-group row">
            <div class="col-md-4 col-sm-12 text-right">
                    <label for="uploadTER" [innerHtml]="terFileName"> </label><label>&nbsp;<span *ngIf="myForm.terFile?.errors?.required">*</span></label>
            </div>
            <div class="col-md-4 col-sm-12">
              <input type="file" id="uploadTER" style="color: transparent;" name="uploadTER" (click)="onClick($event,'terFile')" (change)="onFileSelect($event,'terFile')"
                     accept="application/pdf, text/plain,
                               image/bmp, image/png, image/jpeg, image/jpg, image/tiff,
                               application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                               application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document">
              <input type="hidden" class="control-label" formControlName="terFile" id="terFile" name="terFile">
              <label type="hidden" class="control-label" id="terFile" name="terFile">{{myForm.terFile?.value}}</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <p *ngIf="myForm.terFile?.touched && myForm.terFile?.errors?.required" class="help-block error">Required</p>
            </div>
          </div>
        </div>
      </div>

      <div class="form-row mt-4" *ngIf="showinvoiceFile">
        <div class="w-100">
          <div class="form-group row">
            <div class="col-md-4 col-sm-12 text-right">
              <label for="uploadInvoice">{{getDisplayNameCustomField('invoiceFile','Upload your Consignment Note')}}</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <input type="file" id="uploadInvoice"  style="color: transparent;" name="uploadInvoice" (click)="onClick($event,'invoiceFile')" (change)="onFileSelect($event, 'invoiceFile')"
                     accept="application/pdf, text/plain,
                               image/bmp, image/png, image/jpeg, image/jpg, image/tiff,
                               application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                               application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document">
              <input type="hidden" class="control-label" formControlName="invoiceFile" id="invoiceFile" name="invoiceFile">
              <label type="hidden" class="control-label" id="invoiceFile" name="invoiceFile">{{myForm.invoiceFile?.value}}</label>
            </div>
          </div>
        </div>
      </div>

      <div class="form-row mt-4">
        <div class="w-100">
          <div class="form-group row">
            <div class="col-md-4 col-sm-12 text-right">
              <label for="uploadImage">{{imageFile1Name}} <span *ngIf="myForm.imageFile1?.pristine && myForm.imageFile1?.errors?.required">*</span></label>
            </div>
            <div class="col-md-4 col-sm-12">
              <input type="file" id="uploadImage" style="color: transparent;" name="uploadImage" (click)="onClick($event,'imageFile1')" (change)="onFileSelect($event,'imageFile1')"
                     accept="application/pdf, text/plain,
                               image/bmp, image/png, image/jpeg, image/jpg, image/tiff,
                               application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                               application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document">
              <input type="hidden" class="control-label" formControlName="imageFile1" id="imageFile1" name="imageFile1">
              <label type="hidden" class="control-label" id="imageFile1" name="imageFile1">{{myForm.imageFile1?.value}}</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <p *ngIf="myForm.imageFile1?.touched && myForm.imageFile1?.errors?.required" class="help-block error">Required</p>
            </div>
          </div>
        </div>
      </div>

      <div class="form-row mt-4">
        <div class="w-100">
          <div class="form-group row">
            <div class="col-md-4 col-sm-12 text-right">
              <label for="uploadAdditionalImage2">Upload the Sales Invoice</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <input type="file" id="uploadAdditionalImage2" style="color: transparent;" name="uploadAdditionalImage2" (click)="onClick($event,'imageFile2')" (change)="onFileSelect($event, 'imageFile2')"
                     accept="application/pdf, text/plain,
                               image/bmp, image/png, image/jpeg, image/jpg, image/tiff,
                               application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                               application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document">
              <input type="hidden" class="control-label" formControlName="imageFile2" id="imageFile2" name="imageFile2">
              <label type="hidden" class="control-label" id="imageFile3" name="imageFile3">{{myForm.imageFile2?.value}}</label>
            </div>
          </div>
        </div>
      </div>

      <div class="form-row mt-4">
        <div class="w-100">
          <div class="form-group row">
            <div class="col-md-4 col-sm-12 text-right">
              <label for="uploadAdditionalImage1">{{imageFile3Name}} <span *ngIf="myForm.imageFile3?.pristine && myForm.imageFile3?.errors?.required">*</span></label>
            </div>
            <div class="col-md-4 col-sm-12">
              <input type="file" id="uploadAdditionalImage1" style="color: transparent;" name="uploadAdditionalImage1" (click)="onClick($event,'imageFile3')" (change)="onFileSelect($event, 'imageFile3')"
                     accept="application/pdf, text/plain,
                               image/bmp, image/png, image/jpeg, image/jpg, image/tiff,
                               application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                               application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document">
              <input type="hidden" class="control-label" formControlName="imageFile3" id="imageFile3" name="imageFile3">
              <label type="hidden" class="control-label" id="imageFile3" name="imageFile2">{{myForm.imageFile3?.value}}</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <p *ngIf="myForm.imageFile3?.touched && myForm.imageFile3?.errors?.required" class="help-block error">Required</p>
            </div>
          </div>
        </div>
      </div>



      <div class="form-row mt-4" *ngIf="showImage4">
        <div class="w-100">
          <div class="form-group row">
            <div class="col-md-4 col-sm-12 text-right">
              <label for="uploadAdditionalImage3">Upload an Additional Photo</label>
            </div>
            <div class="col-md-4 col-sm-12">
              <input type="file" id="uploadAdditionalImage3" style="color: transparent;" name="uploadAdditionalImage3" (click)="onClick($event,'imageFile4')" (change)="onFileSelect($event, 'imageFile4')"
                     accept="application/pdf, text/plain,
                               image/bmp, image/png, image/jpeg, image/jpg, image/tiff,
                               application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                               application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document">
              <input type="hidden" class="control-label" formControlName="imageFile4" id="imageFile4" name="imageFile4">
              <label type="hidden" class="control-label" id="imageFile4" name="imageFile4">{{myForm.imageFile4?.value}}</label>
            </div>
          </div>
        </div>
      </div>

      <fieldset>
        <div class="form-row mt-4">
          <div class="w-100">
            <div class="form-group row">
              <div class="col-md-4 col-sm-12 text-right">
                Add and Upload Photos of Packaging
              </div>
              <div class="col-md-4  col-sm-12">-->
      <!--ng-repeat='item in items track by $index'-->
      <!--<div>
                  <ng-container *ngFor="let item of items let i = index">
                    <input type="file" id='item' class='input mb-2' style="color: transparent;" (click)="onClick($event, 'item-'+ i)" (change)="onFileSelect($event, 'item-'+ i ,item)"
                           accept="application/pdf, text/plain,
                                   image/bmp, image/png, image/jpeg, image/jpg, image/tiff,
                                   application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                                   application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document" />

                    <label type="hidden" class="control-label d-block" id="popFiles" name="popFiles">{{item.fileName}}</label>
                  </ng-container>
                  <button type="button" (click)='add()' class="btn btn-sm btn-primary">+ Add</button>
                  <input type="hidden" class="control-label" formControlName="popFiles" id="popFiles" name="popFiles">
                </div>
              </div>
            </div>
          </div>
        </div>
      </fieldset>-->
    </form>
  </div>
</fieldset>
<modal #modal></modal>
