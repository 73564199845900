import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ICancellationReason } from '../../interfaces/CancellationReason';
import { ICarrier } from '../../interfaces/Carrier';

const APP_CONSTANTS = {
  rootApiUrl: environment.rootApiUrl,
  claimPostUrl: environment.claimPostUrl,
  apiKey: environment.apiKey,
  code: environment.code,
  amxApiKey: environment.amxApiKey,
  ubindApiUrl: environment.ubindApiUrl,
  ubindApiKey: environment.ubindApiKey
};


@Injectable({
  providedIn: 'root'
})
export class AppService {
  constructor(
    private http: HttpClient,
  ) { }

  getPreviousClaimDetails(claimNumber: string, consignmentNoteNo: string): Observable<any> {
    const url = APP_CONSTANTS.rootApiUrl + 'api/getpreviousclaimdetails';
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const params = new HttpParams().set("claimNumber", claimNumber).set("consignmentNoteNo", consignmentNoteNo).set("apiKey", APP_CONSTANTS.apiKey).set("code", APP_CONSTANTS.code);
    return this.http.get<any>(url, { headers: headers, params: params }).pipe()
  }

  getBsbInformation(bsbNumber: string): Observable<any> {
    const url = APP_CONSTANTS.rootApiUrl + 'api/getbsbinformation';
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const params = new HttpParams().set("bsbNumber", bsbNumber).set("apiKey", APP_CONSTANTS.apiKey).set("code", APP_CONSTANTS.code);
    return this.http.get<any>(url, { headers: headers, params: params }).pipe();
  };

  getAllCurrencies(): Observable<any> {
    const url = APP_CONSTANTS.rootApiUrl + 'api/getallcurrency';
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const params = new HttpParams().set("apiKey", APP_CONSTANTS.apiKey).set("code", APP_CONSTANTS.code);
    return this.http.get<any>(url, { headers: headers, params: params }).pipe();
  };

  submitClaim(claim: any, attachments: any): Observable<any> {

    const url = APP_CONSTANTS.claimPostUrl + '/claims/v1';
    const headers = new HttpHeaders({
      'enctype': 'multipart/form-data'

    });
    const params = new HttpParams().set("apikey", APP_CONSTANTS.apiKey);
    const formData = new FormData();

    if (attachments) {
      for (let i = 0; i < attachments.length; i++) {
        for (let j = 0; j < attachments[i].files.length; j++)
          formData.append(attachments[i].documentType?.name + j, attachments[i].files[j]);
      }
    }
    formData.append("data", claim);
    //console.log(formData.getAll('file1'));

    return this.http.post(url, formData, { headers, params });
  }


  getPackageTypes(): Observable<any> {
    const url = APP_CONSTANTS.rootApiUrl + 'api/packagetypes/';
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const params = new HttpParams().set("apiKey", APP_CONSTANTS.apiKey).set("code", APP_CONSTANTS.code);
    return this.http.get<any>(url, { headers: headers, params: params }).pipe();
  };

  getClaimByClaimNumber(claimNumber: string, isClaimNumberEncoded: string, carrierPrefix: string, carrierName: string): Observable<any> {
    const url = APP_CONSTANTS.rootApiUrl + 'api/getclaimdatabyclaimnumber';
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const params = new HttpParams().set("isClaimNumberEncoded", isClaimNumberEncoded)
      .set("claimNumber", claimNumber)
      .set("apiKey", APP_CONSTANTS.apiKey).set("code", APP_CONSTANTS.code)
      .set("carrierPrefix", carrierPrefix)
      .set("carrierName", carrierName);
    return this.http.get<any>(url, { headers: headers, params: params }).pipe();
  };

  getIpAddress(): Observable<any> {
    const url = "https://myexternalip.com/json";
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    //const params = new HttpParams().set("apiKey", APP_CONSTANTS.apiKey);
    return this.http.get<any>(url, { headers: headers }).pipe();
  };

  submitAmxClaim(claim: any, attachments: any): Observable<any> {

    const url = APP_CONSTANTS.claimPostUrl + '/claims/aramex/updateclaim';
    const headers = new HttpHeaders({
      'enctype': 'multipart/form-data'

    });
    const params = new HttpParams().set("apiKey", APP_CONSTANTS.amxApiKey);

    const formData = new FormData();

    if (attachments) {
      for (let i = 0; i < attachments.length; i++) {
        for (let j = 0; j < attachments[i].files.length; j++) {
          formData.append("file" + j, attachments[i].files[j]);
        }
      }
    }
    formData.append("data", claim);

    return this.http.post(url, formData, { headers, params });
  }


  getAllSupportingDocumentTypes(): Observable<any> {
    const url = APP_CONSTANTS.rootApiUrl + 'api/getsupportingdocumenttypes';
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const params = new HttpParams().set("apiKey", APP_CONSTANTS.apiKey).set("code", APP_CONSTANTS.code);
    return this.http.get<any>(url, { headers: headers, params: params }).pipe();
  };

  submitDraftClaim(draftClaim: any, attachments: any): Observable<any> {

    const url = APP_CONSTANTS.claimPostUrl + '/claims/draftclaimform';
    const headers = new HttpHeaders({
      'enctype': 'multipart/form-data'

    });
    const params = new HttpParams().set("apikey", APP_CONSTANTS.apiKey);

    const formData = new FormData();
    if (attachments) {
      for (let i = 0; i < attachments.length; i++) {
        for (let j = 0; j < attachments[i].files.length; j++)
          formData.append(attachments[i].documentType?.name + j, attachments[i].files[j]);
      }
    }
    formData.append("data", draftClaim);

    return this.http.post(url, formData, { headers, params });
  }

  getDraftByConsignmentNumber(consignmentNumber: string, carrierPrefix: string): Observable<any> {
    const url = APP_CONSTANTS.rootApiUrl + 'api/getdraftbyconsignmentNumber';
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const params = new HttpParams().set("carrierPrefix", carrierPrefix).set("consignmentNumber", consignmentNumber).set("apiKey", APP_CONSTANTS.apiKey).set("code", APP_CONSTANTS.code);
    return this.http.get<any>(url, { headers: headers, params: params }).pipe();
  };

  getDraftAttachment(consignmentNumber: string, fileName: string, carrierPrefix: string): Observable<any> {
    const url = APP_CONSTANTS.rootApiUrl + 'api/getdraftattachaments';
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const params = new HttpParams().set("consignmentNumber", consignmentNumber).set("apiKey", APP_CONSTANTS.apiKey).set("code", APP_CONSTANTS.code).set("fileName", fileName).set("carrierPrefix", carrierPrefix);
    return this.http.get<any>(url, { headers: headers, params: params }).pipe();
  };

  getWarrantyRates(carrierPrefix: string, consignmentNote: string, claimNumber: string): Observable<any> {
    const url = APP_CONSTANTS.rootApiUrl + 'api/getwarrantyrates';
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const params = new HttpParams().set("carrierPrefix", carrierPrefix)
      .set("consignmentNote", consignmentNote)
      .set("claimNumber", claimNumber)
      .set("apiKey", APP_CONSTANTS.apiKey).set("code", APP_CONSTANTS.code);
    return this.http.get<{ id: number, name: string }[]>(url, { headers: headers, params: params }).pipe();
  };

  submitApprovalforClaim(data: any): Observable<any> {

    const url = APP_CONSTANTS.claimPostUrl + '/claims/externalclaimapproval';
    const headers = new HttpHeaders({
      'enctype': 'multipart/form-data'
    });
    const params = new HttpParams().set("apikey", APP_CONSTANTS.apiKey);

    const formData = new FormData();
    formData.append("data", data);

    return this.http.post(url, formData, { headers, params });
  }


  getCancellationReasons(claimNumber: string): Observable<ICancellationReason[]> {
    const url = APP_CONSTANTS.rootApiUrl + 'api/getcancellationreasons/';
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const params = new HttpParams().set("apiKey", APP_CONSTANTS.apiKey).set("code", APP_CONSTANTS.code).set("claimNumber", claimNumber);
    return this.http.get<ICancellationReason[]>(url, { headers: headers, params: params });
  };


  submitClaimCancellation(claimCancellation: any) {

    const url = APP_CONSTANTS.claimPostUrl + '/claims/cancelclaim';
    const headers = new HttpHeaders({
      'enctype': 'multipart/form-data'

    });
    const params = new HttpParams().set("apikey", APP_CONSTANTS.apiKey);
    const formData = new FormData();
    formData.append("data", JSON.stringify(claimCancellation));
    return this.http.post(url, formData, { headers, params });

  }

  getUbindDataFromConsignmentNoteNumber(consignmentNoteNumber: string, carrierName: string) {
    const url = APP_CONSTANTS.ubindApiUrl + '/consignmentrate';
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const params = new HttpParams().set("apiKey", APP_CONSTANTS.ubindApiKey).set("carrierName", carrierName).set("consignmentNoteNumber", consignmentNoteNumber);
    return this.http.get<any>(url, { headers: headers, params: params });

  }

  getFreightCarrierPrefixFromConsignmentNoteNumber(consignmentNoteNumber: string) {
    const url = APP_CONSTANTS.rootApiUrl + 'api/getcarrierbyconsignmentnotenumber';
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const params = new HttpParams().set("apiKey", APP_CONSTANTS.apiKey).set("code", APP_CONSTANTS.code).set("consignmentNoteNumber", consignmentNoteNumber);
    return this.http.get<any>(url, { headers: headers, params: params });

  }

  getClaimFormEnabledCarriers(): Observable<ICarrier[]> {
    const url = APP_CONSTANTS.rootApiUrl + 'api/getclaimformenabledcarriers';
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const params = new HttpParams().set("apiKey", APP_CONSTANTS.apiKey).set("code", APP_CONSTANTS.code);
    return this.http.get<ICarrier[]>(url, { headers: headers, params: params });
  }

}

