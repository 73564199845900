//import { MapsAPILoader } from '@agm/core';
import { Component, ElementRef, OnInit, ViewChild, NgZone, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormService } from '../../../services/common/form.service';
import { Constant } from '../../../utilities/Constant';

@Component({
  selector: 'app-sender-details',
  templateUrl: './sender-details.component.html',
  styleUrls: ['./sender-details.component.css']
})
export class SenderDetailsComponent implements OnInit, OnDestroy {
  senderDetailsForm = this.formService.senderDetailsForm;
  states: { name: string, value: string }[] = Constant.states
  isDisabled: boolean = false;
  isInternationalState: boolean = false;
  showInfoMessage: boolean = true;

  addresssSub!: Subscription | undefined;

  constructor(private formService: FormService) {
  }

  get myForm() {
    return this.senderDetailsForm.controls;
  }

  get sender() {
    return this.myForm?.sender;
  }

  ngOnInit(): void {
    this.showInfoMessage = this.sender.get('address')?.value ? false : true;
    this.addresssSub = this.sender.get('address')?.valueChanges?.subscribe(value => {
      if (value) {
        this.showInfoMessage = true;
      } else {
        this.sender.get('longitude')?.setValue(0);
        this.sender.get('latitude')?.setValue(0);
        this.showInfoMessage = false;
      }
    })
    this.setStateStatus();
    if (!this.sender.get('street')?.value) {
      this.sender.get('street')?.setValue(this.sender.get('addressLine1')?.value);
    }

    if (this.sender.get('longitude')?.value && this.sender.get('latitude')?.value) {
      this.enableSenderAddressFields();
    }   
  }

  ngOnDestroy(): void {
    this.addresssSub?.unsubscribe();

  }

  public mapAddressValuesToControls(event: any) {
    if (event && event['longitude'] != 'undefined' && event['latitude'] != 'undefined') {
      this.sender.get('address')?.setValue(event['address'], { emitEvent: false });
      this.sender.get('addressLine1')?.setValue(event['streetAddress']);
      this.sender.get('city')?.setValue(event['city']);
      this.sender.get('state')?.setValue(event['state']);
      this.sender.get('postalCode')?.setValue(event['postalCode']);
      this.sender.get('street')?.setValue(event['streetAddress']);
      this.sender.get('longitude')?.setValue(event['longitude']);
      this.sender.get('latitude')?.setValue(event['latitude']);
      this.sender.get('country')?.setValue(event['country']);
      this.showInfoMessage = false;
      this.enableSenderAddressFields();
      this.setStateStatus();

    } else {
      this.sender.get('street')?.setValue('NA');
      this.sender.get('longitude')?.setValue(0);
      this.sender.get('latitude')?.setValue(0);
      this.sender.get('country')?.setValue('NA');
    }
  }

  private enableSenderAddressFields() {
    this.sender.get('addressLine1')?.enable({ onlySelf: true })
    this.sender.get('city')?.enable({ onlySelf: true })
    this.sender.get('state')?.enable({ onlySelf: true })
    this.sender.get('postalCode')?.enable({ onlySelf: true })
    this.sender.markAllAsTouched();
    this.sender.updateValueAndValidity();

  }
  /**
  * Checks if state is not available and sets the state dropdown as disabled.
  * International address will not have a state in the dropdownlist, therefore, it will be disabled.
  * */
  private setStateStatus() {
    if (this.sender.get('address')?.value && this.sender.get('state')?.value) {
      if (this.states.filter(state => state.name == this.sender.get('state')?.value).length == 0) {
        this.sender.get('state')?.disable({ onlySelf: true });
        this.isInternationalState = true;

      } else {
        this.isInternationalState = false;
      }

    }
  }



}
