<div>
  <fieldset>
    <h3 class="multisteps-form__title">Approve Claim</h3>
    <div class="multisteps-form__content">
      <form [formGroup]="approveForm">
        <div class="form-row mt-4">
          <div class="w-100">
            <div class="form-group row">
              <div class="col-md-4 col-sm-12 text-right">
                <label class="control-label" for="inputConNoteNumber">
                  Consignment Note Number  <span *ngIf="myForm.consignmentNoteNo.errors?.required">*</span>
                </label>
              </div>
              <div class="col-md-4 col-sm-12">
                <label type="text" class="control-label" id="inputConNoteNumber" name="inputConNoteNumber" placeholder="Consignment Note Number" maxlength="15">
                  {{myForm.consignmentNoteNo.value}}
                </label>
              </div>
              <div class="col-md-4 col-sm-12">
                <span *ngIf="myForm.consignmentNoteNo?.touched && myForm.consignmentNoteNo?.errors?.required" class="help-block error">Required</span>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row mt-4">
          <div class="w-100">
            <div class="form-group row">
              <div class="col-md-4 col-sm-12 text-right">
                <label class="control-label" for="inputClaimNumber">
                  Claim Number  <span *ngIf="myForm.claimNumber.errors?.required">*</span>
                </label>
              </div>
              <div class="col-md-4 col-sm-12">
                <label type="text" class="control-label" id="inputClaimNumber" name="inputClaimNumber" placeholder="Claim Number" maxlength="15">
                  {{myForm.claimNumber.value}}
                </label>
              </div>
              <div class="col-md-4 col-sm-12">
                <span *ngIf="myForm.claimNumber?.touched && myForm.claimNumber?.errors?.required" class="help-block error">Required</span>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row mt-4">
          <div class="form-group row w-100">
            <div class="col-md-4 col-sm-12 text-right">
              <label class="control-label" for="dropClaimType">
                Claim Processing options  <span *ngIf="myForm.processingOption.errors?.required">*</span>
              </label>
            </div>
            <div class="col-md-4 col-sm-12">
              <select class="form-control" formControlName="processingOption" id="dropClaimType" name="dropClaimType">
                <option selected disabled value="">Please Choose</option>
                <option *ngFor="let processingOption of processingOptions " accesskey="processingOption?.id" [ngValue]="processingOption">{{processingOption.name}}</option>
              </select>
            </div>
            <div class="col-md-4 col-sm-12">
              <span *ngIf="myForm.processingOption?.touched && myForm.processingOption?.errors?.required" class="help-block error">Required</span>
            </div>
          </div>
        </div>
        <ng-container *ngIf="myForm.processingOption.value?.id==1">
          <div class="form-row mt-4">
            <div class="form-group row w-100">
              <div class="col-md-4 col-sm-12 text-right">
                <label class="control-label">Is Warranty Paid</label>
              </div>
              <div class="col-md-4 col-sm-12">
                <div>
                  <label class="input-radio">
                    Yes
                    <input type="radio" formControlName="isWarrantyPaid" value="true" name="isWarrantyPaid">
                    <span class="checkmark"></span>
                  </label>
                  <label class="input-radio">
                    No
                    <input type="radio" formControlName="isWarrantyPaid" value="false" name="isWarrantyPaid">
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
              <div class="col-md-4 col-sm-12">
                <span *ngIf="myForm.isWarrantyPaid?.touched && myForm.isWarrantyPaid?.errors?.required" class="help-block error">Required</span>
              </div>
            </div>
          </div>
          <ng-container *ngIf="myForm.isWarrantyPaid.value=='true'">
            <div class="form-row mt-4">
              <div class="form-group row w-100">
                <div class="col-md-4 col-sm-12 text-right">
                  <label class="control-label" for="dropClaimType">
                    Warranty rate applicable  <span *ngIf="myForm.warrantyRate.errors?.required">*</span>
                  </label>
                </div>
                <div class="col-md-4 col-sm-12">
                  <select class="form-control" formControlName="warrantyRate" id="dropClaimType" name="dropClaimType">
                    <option selected disabled value="">Please Choose</option>
                    <option *ngFor="let warrantyRate of warrantyRates" accesskey="warrantyRate?.id" [ngValue]="warrantyRate">{{warrantyRate.name}}</option>
                  </select>
                </div>
                <div class="col-md-4 col-sm-12">
                  <span *ngIf="myForm.warrantyRate?.touched && myForm.warrantyRate?.errors?.required" class="help-block error">Required</span>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <div class="form-group row w-100">

          <div class="col-md-4 col-sm-12 text-right">
            <label class="control-label" for="txtInitialContactDetails">Comments </label>
          </div>
          <div class="col-md-4 col-sm-12">
            <textarea class="form-control" formControlName="claimantComments" id="txtclaimantComments" name="txtclaimantComments" placeholder="Claimant Comments" rows="4" maxlength="1000"></textarea>
          </div>
          <div class="col-md-4 col-sm-12">
          </div>
        </div>
        <div class="form-group row w-100">

          <div class="col-md-4 col-sm-12 text-right">

          </div>
          <div class="col-md-4 col-sm-12">
            <button class="btn btn-primary d-block" (click)="Submit()"
                    [disabled]="!approveForm.valid">
              Submit
            </button>
          </div>
          <div class="col-md-4 col-sm-12">
          </div>
        </div>
      </form>
    </div>
    <!--multisteps-form__content-->
  </fieldset>

</div>
<modal #modal></modal>
