import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { ConfigurationModel } from '../../../../models/ConfigurationModel';
import { ModalOptions } from '../../../../models/ModalOptions';

import { AppService } from '../../../../services/common/app.service';
import { CommonService } from '../../../../services/common/common.service';
import { FormService } from '../../../../services/common/form.service';
import { Constant } from '../../../../utilities/Constant';
import { ErrorHelper } from '../../../../utilities/ErrorHelper';
import { ModalComponent } from '../../../common/modal/modal.component';

@Component({
  selector: 'app-amxwelcome',
  templateUrl: './amxwelcome.component.html',
  styleUrls: ['./amxwelcome.component.css']
})
export class AmxwelcomeComponent implements OnInit {
  modalbodyContent!: string;
  modalHeaderContent!: string;
  @ViewChild('modal') modal!: ModalComponent;
  claimantRoles = Constant.claimantRole;
  welcomeInformationForm = this.formService.amxWelcomeForm;
  paymentDetailsForm = this.formService.amxPaymentForm;
  parcelDetailsForm = this.formService.amxParcelDetailsForm;
  termsAndConditionsForm = this.formService.amxTermsAndConditionsForm;
  claimNumberFormat = 'Ex :- AMX001';
  previousClaimDataLoaded: boolean = false;
  displayOnlyDraftClaims: boolean = false;
  userGuide!: string;
  target = '_blank';
  carrierConfig!: ConfigurationModel;

  previousClaimForm = this.formService.amxPreviousForm;
  constructor(private formService: FormService,
    private commonService: CommonService,
    private appService: AppService,
    private errorHelper: ErrorHelper) { }

  get myForm() {
    return this.welcomeInformationForm?.controls;
  }
  get paymentDetails() {
    return this.paymentDetailsForm.controls;
  }
  get parcelDetails() {
    return this.parcelDetailsForm.controls;
  }
  get previousClaimFormDetails() {
    return this.previousClaimForm.controls;
  }
  get termsAndConditions() {
    return this.termsAndConditionsForm?.controls;
  }

  ngOnInit(): void {
    this.carrierConfig = this.commonService.getCarrierConfigObject();

    this.userGuide = `${Constant.s3url}${environment.s3Bucket}${this.commonService.userGuideLink}`;

    /* Check if the form has a claimNumber assoicated, from cache. */
    if (!this.myForm.claimNumber?.value) {
      if (this.commonService.claimNumber) {
        this.getClaimByClaimNumber(this.commonService.claimNumber, this.commonService.carrierName);
        this.myForm.encodedClaimNumber?.setValue(this.commonService.claimNumber);
        this.displayOnlyDraftClaims = false;
        this.commonService.isDraftClaim$.next(false);
      } else {
        /* Display the claim form with the draft claim option. */
        this.displayOnlyDraftClaims = true;
        this.commonService.isDraftClaim$.next(true);
      }

    } else {
      if (this.commonService.claimNumber) {
        /* Checks if the cached claim number and the url claim number are not the same
         * if not the same retrieve the data for the new claim. */
        if (this.myForm.encodedClaimNumber?.value != this.commonService.claimNumber) {
          this.getClaimByClaimNumber(this.commonService.claimNumber, this.commonService.carrierName);
          this.myForm.encodedClaimNumber?.setValue(this.commonService.claimNumber);
        }
        this.displayOnlyDraftClaims = false;
        this.commonService.isDraftClaim$.next(false);
      }
    }
  }

  public getClaimByClaimNumber(claimNumber: string, carrierName: string) {
    this.commonService.isLoading$.next(true);
    const claimData = this.appService.getClaimByClaimNumber(claimNumber, 'true', Constant.carrierPrefix.amxCarrier, carrierName).subscribe(result => {

      if (result.isDraft && result.claimPhaseName == "Inactive") {
        this.commonService.isLoading$.next(false);
        this.openConfirmation(result);
      }
      else {
        this.commonService.isLoading$.next(false);
        this.setClaimData(result);
      }

    }, (response) => {
      this.commonService.isLoading$.next(false);
      let errorResponse = this.errorHelper.formatAndDisplayError(response);
      if (errorResponse) {
        this.showInformation(errorResponse[0], errorResponse[1], true);
      }
      claimData?.unsubscribe();

      //window.location.href = 'https://www.aramex.com.au';
      return
    });


  }

  public getPreviousClaimDetails(claimNumber: string, consignmentNoteNumber: string) {

    claimNumber = claimNumber.trim();
    consignmentNoteNumber = consignmentNoteNumber.trim();
    if (claimNumber && consignmentNoteNumber) {
      this.commonService.isLoading$.next(true);
      const previousClaim = this.appService.getPreviousClaimDetails(claimNumber, consignmentNoteNumber).subscribe(result => {
        this.formService.setAmxPreviouseClaimData(result, false);
        this.commonService.isLoading$.next(false);
        this.previousClaimDataLoaded = true;
      }, error => {

        this.paymentDetails?.bsbInformation.setValue(null, { onlySelf: true });
        const title = 'Attention';
        const textContent = 'Claim not found';
        this.commonService.isLoading$.next(false);
        this.showInformation(title, textContent, false)
        return;
      }, () => {
        previousClaim.unsubscribe();
      });
    } else {
      const title = 'Attention';
      const textContent = 'Claim Number and Label Number can not be null';
      this.showInformation(title, textContent, false)
    }
  };

  public getDraftClaimDetails(labelNumber: string) {
    this.appService.getDraftByConsignmentNumber(labelNumber, Constant.carrierPrefix.amxCarrier).subscribe(result => {
      if (result) {
        this.formService.mapClaimFormDataToClaimForm(Constant.carrierPrefix.amxCarrier, result.serializedString, this.carrierConfig);
        this.myForm.draftClaimId?.setValue(result.id);

        this.displayOnlyDraftClaims = false;
        this.commonService.isDraftClaim$.next(false);
      }
      else {
        let options: ModalOptions = {
          size: 'md',
          centered: true,
          isHtmlContent: true
        }
        this.modalHeaderContent = 'Attention'
        this.modalbodyContent = 'Draft Claim not found';
        //console.error(error?.message);
        this.commonService.isLoading$.next(false);
        this.modal.modalOpen(this.modalHeaderContent, this.modalbodyContent, options);
      }
    }, (error) => {
      let errorResponse = this.errorHelper.formatAndDisplayError(error);
      if (errorResponse) {
        this.showInformation(errorResponse[0], errorResponse[1], true);
      }
    });
  }

  resetPreviousClaim() {
    if (this.myForm?.claimNumber?.value) {
      this.commonService.isLoading$.next(true);
      this.appService.getPreviousClaimDetails(this.myForm?.claimNumber?.value, this.parcelDetails.labelNumber?.value).subscribe(result => {
        this.formService.setAmxPreviouseClaimData(result, true);
        this.commonService.isLoading$.next(false);
      }, error => {
        const title = 'Attention';
        const textContent = 'Claim not found associated to Claim Number or Label Number';
        this.commonService.isLoading$.next(false);
        this.showInformation(title, textContent, false)
        return;
      });
    }
    else {
      const title = 'Attention';
      const textContent = 'Claim Number and Label Number can not be null';
      this.showInformation(title, textContent, false)
    }
  };

  showInformation(headerContent: string, bodyContent: string, rederect: boolean) {
    const modalOptions: ModalOptions = {
      animation: true,
      centered: true,
      size: 'md',
      showConfirmationButtons: false,
      isHtmlContent: true,
      showOkayButton: true
    }

    this.modalbodyContent = bodyContent;
    this.modalHeaderContent = headerContent;
    this.modal.modalOpen(this.modalHeaderContent, this.modalbodyContent, modalOptions).result.finally(() => {
      if (rederect) {
        window.location.href = 'https://www.aramex.com.au';
      }
    });

  }

  openConfirmation(result: any) {
    let modalOptions: ModalOptions = {
      animation: true,
      centered: true,
      size: 'xl',
      isHtmlContent: false,
      showConfirmationButtons: true,
      showOkayButton: false
    }

    this.modalHeaderContent = 'Attention';
    this.modalbodyContent = 'There is already a claim saved, please confirm to load data from the saved claim';

    this.modal.modalOpen(this.modalHeaderContent, this.modalbodyContent, modalOptions).result.then((value) => {
      if (value == 'confirm') {
        this.appService.getDraftByConsignmentNumber(result.labelNumber, Constant.carrierPrefix.amxCarrier).subscribe(draftResult => {
          if (draftResult) {
            this.formService.mapClaimFormDataToClaimForm(Constant.carrierPrefix.amxCarrier, draftResult.serializedString, this.carrierConfig);
            this.myForm.draftClaimId?.setValue(draftResult.id);
            this.termsAndConditions.isAgreed.setValue(false, { onlyself: true });
          }
          else {
            let options: ModalOptions = {
              size: 'md',
              centered: true,
              isHtmlContent: true
            }
            this.modalHeaderContent = 'Attention'
            this.modalbodyContent = 'Draft Claim not found';
            //console.error(error?.message);
            this.commonService.isLoading$.next(false);
            this.modal.modalOpen(this.modalHeaderContent, this.modalbodyContent, options);
          }
        });

      } else if (value == 'cancel') {

        this.setClaimData(result);
      }
    });
  }

  onClaimantRoleChanged() {
    this.formService.setPurchasingCustomerValidators();
  }


  setClaimData(result: any) {
    if (result.claimPhaseName == "Void") {
      this.commonService.isLoading$.next(false);

      // this.isLoading = false;
      const title = 'Attention';
      const textContent = 'This claim has expired, as your claim form was not completed within the required 14 days.';
      this.showInformation(title, textContent, true)
      return;
    }
    else if (result.claimPhaseName == "Cancelled") {
      this.commonService.isLoading$.next(false);
      const title = 'Attention';
      const textContent = 'The claim is already marked as cancelled.';
      this.showInformation(title, textContent, true)
      return;
    }
    else if (result.isClaimFormSubmitted) {
      this.commonService.isLoading$.next(false);
      //this.isLoading = false;
      const title = 'Attention';
      const textContent = 'The claim form has already been posted';
      this.showInformation(title, textContent, true)
      return;
    }
    else if (result.claimPhaseName != "Inactive") {
      this.commonService.isLoading$.next(false);
      //this.isLoading = false;
      const title = 'Attention';
      const textContent = 'The claim is already marked as active';
      this.showInformation(title, textContent, true)
      return;
    }
    this.formService.setAmxClaimData(result);
    this.formService.setClaimFormDataToSessionStorage(Constant.carrierPrefix.amxCarrier);
    this.commonService.isLoading$.next(false);
    //this.isLoading = false;
  }
}
