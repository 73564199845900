<div class="form-row">
  <fieldset>
    <h3 class="multisteps-form__title">Customer Information</h3>
    <div class="multisteps-form__content">

      <form [formGroup]="customerinformationForm">

        <div class="form-row mt-4" *ngIf="myForm.isInternationalClaimantInitially?.value =='false'">
          <div class="col-md-4 col-sm-12 text-right">

          </div>
          <div class="col-md-4 col-sm-12">
            <div>
              <label class="input-radio">
                Aramex Domestic Customer
                <input id="yes" type="radio" formControlName="isInternationalClaimant" value="false" name="isInternationalClaimant">
                <span class="checkmark"></span>
              </label>
              <label class="input-radio">
                Aramex International Customer
                <input id="no" type="radio" formControlName="isInternationalClaimant" value="true" name="isInternationalClaimant">
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </div>

        <div class="form-row mt-4">
          <div class="col-md-4 col-sm-12 text-right">
            <label class="control-label">What best describes you:</label>
          </div>
          <div class="col-md-4 col-sm-12">

            <div>
              <div>
                <label class="input-radio">
                  Manufacturer
                  <input id="customerType" type="radio" formControlName="customerType" value="1" name="customerType" (change)="onCustomerTypeChanged()">
                  <span class="checkmark"></span>
                </label>
              </div>
              <div>
                <label class="input-radio">
                  Wholesaler
                  <input id="customerType" type="radio" formControlName="customerType" value="2" name="customerType" (change)="onCustomerTypeChanged()">
                  <span class="checkmark"></span>
                </label>
              </div>
              <div>
                <label class="input-radio">
                  Retailer
                  <input id="customerType" type="radio" formControlName="customerType" value="3" name="customerType" (change)="onCustomerTypeChanged()">
                  <span class="checkmark"></span>
                </label>
              </div>
              <div>
                <label class="input-radio">
                  Individual
                  <input id="customerType" type="radio" formControlName="customerType" value="0" name="customerType" (change)="onCustomerTypeChanged()">
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>

          </div>
        </div>

        <div *ngIf="myForm.customerType.value !='0' && myForm.isInternationalClaimant?.value =='false'">
          <div formGroupName="sender" *ngIf="claimantRole.value.value =='Sender'">
            <div>
              <div class="form-row mt-4">
                <div class="col-md-4 col-sm-12 text-right">
                  <label class="control-label">What is your ABN?
                  <span *ngIf="myForm.sender.get('abnNumber')?.errors?.required">*</span>
                  </label>
                </div>
                <div class="col-md-4 col-sm-12">
                  <input type="text" class="form-control" name="abnNumber" formControlName="abnNumber" numbers-only />
                </div>
                <div class="col-md-4 col-sm-12">
                  <div [ngClass]="{'help-block error' :(myForm.sender.get('abnNumber')?.errors?.required && myForm.sender.get('abnNumber')?.touched)
                 || (myForm.sender.get('abnNumber')?.errors && myForm.sender.get('abnNumber')?.value)}">
                    <span *ngIf="myForm.sender.get('abnNumber')?.touched && myForm.sender.get('abnNumber')?.errors?.required">Required<br /></span>
                    <span *ngIf="myForm.sender.get('abnNumber')?.errors?.minlength">Too Short<br /></span>
                    <span *ngIf="myForm.sender.get('abnNumber')?.errors?.maxlength">Too Long<br /></span>
                    <span *ngIf="myForm.sender.get('abnNumber')?.errors?.invalidNumber ">The ABN number should be numeric<br /></span>
                  </div>
                </div>
              </div>
              <div class="form-row mt-4">
                <div class="col-md-4 col-sm-12 text-right">
                  <label class="control-label">Are you registered for GST</label>
                </div>
                <div class="col-md-4 col-sm-12">
                  <div>
                      <label class="input-radio mr-3">
                        Yes
                        <input id="yes" type="radio" value="1" formControlName="isRegisteredForGst" name="isRegisteredForGst">
                        <span class="checkmark"></span>
                      </label>
                      <label class="input-radio">
                        No
                        <input id="no" type="radio" value="0" formControlName="isRegisteredForGst" name="isRegisteredForGst">
                        <span class="checkmark"></span>
                      </label>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div formGroupName="receiver" *ngIf="claimantRole.value.value == 'Receiver'">
            <div>
              <div class="form-row mt-4">
                <div class="col-md-4 col-sm-12 text-right">
                  <label class="control-label">
                    What is your ABN?
                    <span *ngIf="myForm.receiver.get('abnNumber')?.errors?.required">*</span>
                  </label>
                </div>
                <div class="col-md-4 col-sm-12">
                  <input type="text" class="form-control" name="abnNumber" formControlName="abnNumber" numbers-only />
                </div>
                <div class="col-md-4 col-sm-12">
                  <div [ngClass]="{'help-block error' : (myForm.receiver.get('abnNumber')?.errors?.required && myForm.receiver.get('abnNumber')?.touched)
                 || (myForm.receiver.get('abnNumber')?.errors && myForm.receiver.get('abnNumber')?.value)}">
                    <span *ngIf="myForm.receiver.get('abnNumber')?.touched && myForm.receiver.get('abnNumber')?.errors?.required">Required<br /></span>
                    <span *ngIf="myForm.receiver.get('abnNumber')?.errors?.minlength">Too Short<br /></span>
                    <span *ngIf="myForm.receiver.get('abnNumber')?.errors?.maxlength">Too Long<br /></span>
                    <span *ngIf="myForm.receiver.get('abnNumber')?.errors?.invalidNumber ">The ABN number should be numeric<br /></span>
                  </div>
                </div>
              </div>
              <div class="form-row mt-4">
                <div class="col-md-4 col-sm-12 text-right">
                  <label class="control-label">Are you registered for GST</label>
                </div>
                <div class="col-md-4 col-sm-12">
                  <label class="input-radio mr-3">
                    Yes
                    <input id="yes" type="radio" formControlName="isRegisteredForGst" value="1">
                    <span class="checkmark"></span>
                  </label>
                  <label class="input-radio">
                    No
                    <input id="no" type="radio" formControlName="isRegisteredForGst" value="0">
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div formGroupName="purchasingCustomer" *ngIf="claimantRole.value.value == 'Third Party'">
            <div>
              <div class="form-row mt-4">
                <div class="col-md-4 col-sm-12 text-right">
                  <label class="control-label">
                    What is your ABN?
                    <span *ngIf="myForm.purchasingCustomer.get('abnNumber')?.errors?.required">*</span>
                  </label>
                </div>
                <div class="col-md-4 col-sm-12">
                  <input type="text" class="form-control" name="abnNumber" formControlName="abnNumber" numbers-only />
                </div>
                <div class="col-md-4 col-sm-12">
                  <div [ngClass]="{'help-block error' :(myForm.purchasingCustomer.get('abnNumber')?.errors?.required && myForm.purchasingCustomer.get('abnNumber')?.touched)
                 || (myForm.purchasingCustomer.get('abnNumber')?.errors && myForm.purchasingCustomer.get('abnNumber')?.value)}">
                    <span *ngIf="myForm.purchasingCustomer.get('abnNumber')?.touched && myForm.purchasingCustomer.get('abnNumber')?.errors?.required">Required<br /></span>
                    <span *ngIf="myForm.purchasingCustomer.get('abnNumber')?.errors?.minlength">Too Short<br /></span>
                    <span *ngIf="myForm.purchasingCustomer.get('abnNumber')?.errors?.maxlength">Too Long<br /></span>
                    <span *ngIf="myForm.purchasingCustomer.get('abnNumber')?.errors?.invalidNumber ">The ABN number should be numeric<br /></span>
                  </div>
                </div>
              </div>
              <div class="form-row mt-4">
                <div class="col-md-4 col-sm-12 text-right">
                  <label class="control-label">Are you registered for GST</label>
                </div>
                <div class="col-md-4 col-sm-12">
                  <label class="input-radio mr-3">
                    Yes
                    <input id="yes" type="radio" formControlName="isRegisteredForGst" value="1">
                    <span class="checkmark"></span>
                  </label>
                  <label class="input-radio">
                    No
                    <input id="no" type="radio" formControlName="isRegisteredForGst" value="0">
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="col-12 col-md-9 m-auto">
          <div class="form-row mt-4" *ngIf="myForm.customerType.value !='0'">
            <div class="font-3 text-justify">
              <p>As a {{getCustomerTypeName(myForm.customerType.value)}}, <b>both</b> of the following invoices are required to process your claim:</p>
              <ol class="ml-3">
                <li *ngIf="myForm.customerType.value =='1'">Proof of manufacturer's cost by way of either an manufacturing statement on company letterhead or a screenshot of your internal accounting system.</li>
                <li *ngIf="myForm.customerType.value =='2' || myForm.customerType.value =='3'">The supplier's invoice showing the cost price of the goods.</li>
                <li *ngIf="myForm.customerType.value !='0'">The sales invoice for the goods shipped in this consignment.</li>
              </ol>
            </div>
          </div>

          <div class="form-row mt-4" *ngIf="myForm.customerType.value =='0'">
            <div class="font-3 text-justify">
              <p>As an individual, not selling or trading as a registered business, the following is required to process your claim:</p>
              <div>
                <p>* Your purchase receipt / retail tax invoice / eBay sales invoice, or similar, for the goods shipped in this consignment.</p>
                <div>
                  <P>* If you did not purchase the goods in the consignment, please provide a detailed account of what the goods were and where they came from (such as homemade items, old clothing being sent to family or similar) in the explanatory notes below, and attach a detailed list of these goods on the following page.</P>
                  <textarea rows="4" class="form-control" name="comment" formControlName="claimantComment" placeholder="Add explanatary notes, if any, here:"></textarea>
                </div>
              </div>
              <div class="col-12 important-notice mt-4">
                <p class="mb-0"><b class="font-weight-bold">IMPORTANT:</b></p>
                <ul>
                  <li><div>Please be aware if you are deemed to be operating as a business during assessment of the claim then payment is capped to 35% of the sales value of the item, exclusive of GST. </div></li>
                  <li><div>Suppliers invoices are payable up to 100% of the cost of goods, excluding GST (and subject to the Aramex Standard Warranty). </div></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </fieldset>
</div>
<modal #modal></modal>
