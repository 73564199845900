import { Injectable } from '@angular/core';
import { NavigationStart, Router, Event as NavigationEvent } from '@angular/router';
import { cwd } from 'process';
import { pipe, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';


import { NavigationStatus } from '../../enums/NavigationStatus';
import { ConfigurationModel } from '../../models/ConfigurationModel';
import { NavigationModel } from '../../models/NavigationModel';
import { Constant } from '../../utilities/Constant';
import { FormRegistry } from '../../utilities/FormRegistry';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  //Updates the progress bar when action buttons are clicked.
  nextStep$ = new Subject<NavigationModel>();
  //Updates the action buttons when the progress bar is clicked.
  activeStep$ = new Subject<NavigationModel>();


  constructor(private commonService: CommonService, private route: Router,
    private formRegistry: FormRegistry) {

  }

  /**
   * Navigate toward the given direction.
   * Sets the status of each navigation step before navigating.
   * @param direction
   * @param carrierConfig
   */
  public navigate(direction: string, carrierConfig: ConfigurationModel): NavigationModel | undefined {

    let nextNavigation: NavigationModel | undefined;
    let previousNavigation: NavigationModel | undefined;
    let activeNavigation = carrierConfig.navigation?.filter(nav => nav.status == NavigationStatus.Active)[0];

    if (direction == Constant.navigationDirection.forward) {
      if (activeNavigation && activeNavigation.next) {
        activeNavigation.status = this.updateNavigationStatus(activeNavigation.componentName);

        nextNavigation = this.getInactiveNavigationByPath(activeNavigation.next, carrierConfig);
        if (nextNavigation) {
          // activeNavigation.status = NavigationStatus.Inactive;
          nextNavigation.status = NavigationStatus.Active;
          nextNavigation.progressBarStatus = NavigationStatus.Inactive;
          this.nextStep$.next(nextNavigation);
          this.commonService.updateConfigObject(carrierConfig);
          this.route.navigate([`${carrierConfig.prefix}/${nextNavigation.navigationPath}`]);

          return nextNavigation;
          /*Setting up the preview button.*/
          //this.isNextNavigationPathAvailable = nextNavigation.showPreview ? false : true;
          //this.isShowSubmit = nextNavigation.showSubmit ? true : false;
        }
      }
    } else {
      if (activeNavigation && activeNavigation.previous != undefined) {
        activeNavigation.status = this.updateNavigationStatus(activeNavigation.componentName);
        previousNavigation = this.getInactiveNavigationByPath(activeNavigation.previous, carrierConfig);
        if (previousNavigation) {
          //activeNavigation.status = NavigationStatus.Inactive;
          previousNavigation.status = NavigationStatus.Active;
          previousNavigation.progressBarStatus = NavigationStatus.Active;
          this.nextStep$.next(previousNavigation);
          this.commonService.updateConfigObject(carrierConfig);
          this.route.navigate([`${carrierConfig.prefix}/${previousNavigation.navigationPath}`]);

          return previousNavigation;
          /*Setting up the preview button.*/
          //this.isNextNavigationPathAvailable = previousNavigation.showPreview ? false : true;
          //this.isShowSubmit = previousNavigation.showSubmit ? true : false;
        }
      }
    }

    return undefined;
  }

  /**
   * returns the status of a given component.
   * The status is taken from the relevant form.
   * @param componentName the name of the component to return the status of.
   */
  public updateNavigationStatus(componentName: string): NavigationStatus {
    let form = this.formRegistry.registry[componentName]
    if (!form?.valid) {
      return NavigationStatus.Error;
    } else if (form?.valid) {
      return NavigationStatus.Completed;
    } else {
      return NavigationStatus.Inactive;
    }
  }

  private getInactiveNavigationByPath(navPath: string, carrierConfig: ConfigurationModel): NavigationModel | undefined {
    let nextNavigation: NavigationModel | undefined;
    nextNavigation = carrierConfig.navigation?.filter(nav => nav.navigationPath == navPath)[0];
    if (nextNavigation?.status == NavigationStatus.Inactive || nextNavigation?.status == NavigationStatus.Error || nextNavigation?.status == NavigationStatus.Completed) {
      return nextNavigation;
    } else {
      if (nextNavigation && nextNavigation.next) {
        if (nextNavigation.next == "") {
          return nextNavigation;
        }
        this.getInactiveNavigationByPath(nextNavigation.next, carrierConfig);
      }
    }
    return;
  }
  /**
   * Navigates to the last element in the Navigation array.
   * @param carrierConfig
   */
  public navigateToLastNavigationElement(carrierConfig: ConfigurationModel): NavigationModel | undefined {
    if (carrierConfig && carrierConfig.navigation) {
      let activeNavigation = carrierConfig.navigation?.filter(nav => nav.status == NavigationStatus.Active)[0];
      let filterIsolatedNavigation = carrierConfig.navigation.filter(fil => !fil.isIsolated);
      let lastNavigation = filterIsolatedNavigation[filterIsolatedNavigation?.length - 1];
      activeNavigation.status = this.updateNavigationStatus(activeNavigation.componentName);
      lastNavigation.status = NavigationStatus.Active;
      lastNavigation.progressBarStatus = NavigationStatus.Inactive;
      this.nextStep$.next(lastNavigation);
      this.route.navigate([`${carrierConfig.prefix}/${lastNavigation.navigationPath}`]);
      this.commonService.updateConfigObject(carrierConfig);
      return lastNavigation;
    }
    return undefined;
  }
  /**
   * resets the navigation to the first element of the Navigation array.
   * resets the progres bar and the navigation buttons
   * @param carrierConfig
   */
  public resetNavigation(carrierConfig: ConfigurationModel, currentActiveStep: NavigationModel): NavigationModel | undefined {
    //set the status of the current active navigation step.
    currentActiveStep.status = this.updateNavigationStatus(currentActiveStep.componentName);
    //reset by moving to the first elemant of the array.
    if (carrierConfig.navigation) {
      carrierConfig.navigation[0].status = NavigationStatus.Active;
      this.commonService.updateConfigObject(carrierConfig);
      this.nextStep$.next(carrierConfig.navigation[0])
      this.route.navigate([`${carrierConfig.prefix}/${carrierConfig.navigation[0].navigationPath}`]);
      return carrierConfig.navigation[0];
    } return undefined

  }
  /**
   * This method is used when a browser button is clicked.
   * It is invoked by the browser when an event is triggered.
   * @param carrierConfig
   */
  public browserButtonEventListner(carrierConfig: ConfigurationModel) {
    this.route.events
      .pipe(
        // The "events" stream contains all the navigation events.
        // we only care about the NavigationStart event as it contains
        // information about what initiated the navigation sequence.
        filter(
          (event: NavigationEvent) => {

            return (event instanceof NavigationStart);

          }
        )
      )
      .subscribe(
        (event: NavigationEvent) => {

          if (event instanceof NavigationStart) {
            // Popstate Trigger is when the back or forward buttons of the broser are clicked.
            // The code checks for the popstate trigger and updates the navigation array accordingly.
            if (event.navigationTrigger == 'popstate') {
              let removedUnwatedCharacter = event.url.substr(1);
              let urlArray = removedUnwatedCharacter.split("/");
              if (urlArray.length > 1) {
                this.navigateToComponent(carrierConfig, urlArray);
              } else {
                urlArray = [""];
                this.navigateToComponent(carrierConfig, urlArray);
              }
            }
            if (event.restoredState) {
              console.warn(
                "restoring navigation id:",
                event.restoredState.navigationId
              );

            }
            console.groupEnd();
          }
        }
      );
  }
  /**
 * Navigates to a given Navigation path.
 * @param carrierConfig
 * @param urlArray
 * @param navigationPathName
 */
  public navigateToComponent(carrierConfig: ConfigurationModel, urlArray?: string[], navigationPathName?: string) {
    let navigation: NavigationModel | undefined;
    if (urlArray) {
      for (let i = 0; i < urlArray.length; i++) {
        navigation = carrierConfig.navigation?.find(nav => nav.navigationPath == urlArray[i])
        if (navigation) {
          this.navigateFunction(carrierConfig, navigation);
          this.setIsolatedNavigation(navigation);
          break;
        }
      }
    }
    if (navigationPathName) {
      navigation = carrierConfig.navigation?.find(nav => nav.navigationPath == navigationPathName)
      if (navigation) {
        this.navigateFunction(carrierConfig, navigation);
        this.setIsolatedNavigation(navigation);
      }
    }
    if (navigation) {
      this.route.navigate([`${carrierConfig.prefix}/${navigation?.navigationPath}`]);
    }

  }

  private setIsolatedNavigation(navigation: NavigationModel) {
    if (navigation.isIsolated) {
      this.commonService.isIsolatedComponet$.next(true);
    }
  }

  private navigateFunction(carrierConfig: ConfigurationModel, navigation: NavigationModel) {
    //carrierConfig.navigation?.map(nav => nav.status = NavigationStatus.Inactive);
    this.setProgresBarStyleOrStatus(carrierConfig, navigation, true, false);
    navigation.status = NavigationStatus.Active;
    this.nextStep$.next(navigation);
    this.activeStep$.next(navigation);
    this.commonService.updateConfigObject(carrierConfig);
  }

  /**
   * updates the style of the progress bar, with the use of the navigation status.
   * @param carrierConfig
   * @param step - Navigation step which is active.
   * @param setStatus - Used to set the status of the elements of the Navigation array.
   * @param setStyle - Used to set the progress bar style.
   */
  public setProgresBarStyleOrStatus(carrierConfig: ConfigurationModel, step: NavigationModel, setStatus: boolean, setStyle: boolean) {
    let clickedIndex = carrierConfig.navigation?.findIndex(nav => nav.componentName == step.componentName);
    let lastIndexOfError = carrierConfig.navigation?.map(n => n.status).lastIndexOf(NavigationStatus.Error);
    let lastIndexOfComplete = carrierConfig.navigation?.map(n => n.status).lastIndexOf(NavigationStatus.Completed);
    let lastIndexOfActive = carrierConfig.navigation?.map(n => n.status).lastIndexOf(NavigationStatus.Active);

    lastIndexOfError = lastIndexOfError != undefined ? lastIndexOfError : -1;
    lastIndexOfComplete = lastIndexOfComplete != undefined ? lastIndexOfComplete : -1;
    lastIndexOfActive = lastIndexOfActive != undefined ? lastIndexOfActive : -1;


    if (carrierConfig?.navigation) {
      /* Update status and style from the zeroth index to the clicked index */
      if (clickedIndex != undefined && clickedIndex > -1) {
        for (let i = 0; i <= clickedIndex; i++) {
          if (setStatus) {
            if (carrierConfig.navigation[i].componentName != step.componentName) {
              carrierConfig.navigation[i].status = this.updateNavigationStatus(carrierConfig.navigation[i].componentName);
            }
          }
          if (setStyle) {
            carrierConfig.navigation[i].progressBarCssClass = this.selectProgressBarStyle(carrierConfig.navigation[i].status);
          }
        }
        /* Update status and style from the clicked index to the last Error or completed status */
        let length = 0;
        length = Math.max(lastIndexOfComplete, lastIndexOfError, lastIndexOfActive);
        for (let j = clickedIndex; j <= length; j++) {
          if (setStatus) {
            if (carrierConfig.navigation[j].componentName != step.componentName) {
              carrierConfig.navigation[j].status = this.updateNavigationStatus(carrierConfig.navigation[j].componentName);
            }
          }
          if (setStyle) {
            carrierConfig.navigation[j].progressBarCssClass = this.selectProgressBarStyle(carrierConfig.navigation[j].status);
          }

        }
      }
    }
  }

  private selectProgressBarStyle(status: NavigationStatus) {
    let result: string;
    switch (status) {
      case NavigationStatus.Inactive: {
        result = Constant.progressBarInActiveClass
        break;
      }
      case NavigationStatus.Active: {
        result = Constant.progressBarActiveClass
        break;
      }
      case NavigationStatus.Error: {
        result = Constant.progressBarErrorClass
        break;
      }
      case NavigationStatus.Completed: {
        result = Constant.progressBarCompletedClass;
        break;
      }
    }
    return result;
  }

  public navigateToIsolatedComponent(carrierConfig: ConfigurationModel, urlArray?: string[], navigationPathName?: string, claim_number?: string, consignment_note?: string) {

    let navigation: NavigationModel | undefined;

    if (urlArray) {
      for (let i = 0; i < urlArray.length; i++) {
        navigation = carrierConfig.navigation?.find(nav => nav.navigationPath == urlArray[i]);
        if (navigation) {
          break;
        }
      }
    }
    if (navigationPathName) {
      navigation = carrierConfig.navigation?.find(nav => nav.navigationPath == navigationPathName);
    }
    if (navigation) {
      if (claim_number) {
        this.route.navigate([`${carrierConfig.prefix}/${navigation?.navigationPath}`], { queryParams: { claim_number: claim_number } });
      }
      if (consignment_note) {
        this.route.navigate([`${carrierConfig.prefix}/${navigation?.navigationPath}`], { queryParams: { consignment_note: consignment_note } });
      }
      this.setIsolatedNavigation(navigation);
    }


  }

  public adjustNavigationByComponentsVisibility(carrierConfig: ConfigurationModel) {
    let hiddenComponents = carrierConfig?.navigation?.filter(nav => nav.isVisible === false);
    hiddenComponents?.forEach(component => {
      this.resetNavigationComponent(component, carrierConfig);
    });
  }

  public setComponentVisibility(componentName: string, carrierConfig: ConfigurationModel, visbility: boolean) {

    let component = carrierConfig.navigation?.filter(nav => nav.componentName.toLowerCase() === componentName.toLowerCase())[0];
    if (component) {
      if (visbility) {
        component.isVisible = visbility;
        /*get the previous and next components*/
        if (component.previous) {
          let previouseComponent = carrierConfig.navigation?.filter(nav => nav.navigationPath.toLowerCase() === component?.previous?.toLowerCase())[0];
          if (previouseComponent) {
            previouseComponent.next = component.navigationPath;
          }
        }
        if (component.next)
        {
          let nextComponent = carrierConfig.navigation?.filter(nav => nav.navigationPath.toLowerCase() === component?.next?.toLowerCase())[0];
          if (nextComponent) {
            nextComponent.previous = component.navigationPath;
          }
        }

      } else {
        component.isVisible = visbility;
        this.resetNavigationComponent(component, carrierConfig);

      }
      this.commonService.isVisibilityUpdated$.next(true);
    }

  }

  private resetNavigationComponent(component: NavigationModel, carrierConfig: ConfigurationModel) {

    if (component.next) {
      let nextComponent = carrierConfig.navigation?.filter(nav => nav.navigationPath === component?.next)[0];
      if (nextComponent) {
        nextComponent.previous = component.previous;
      }
    }
    if (component.previous) {
      let previousComponent = carrierConfig.navigation?.filter(nav => nav.navigationPath === component?.previous)[0];
      if (previousComponent) {
        previousComponent.next = component.next;
      }
    }

  }

  

}
