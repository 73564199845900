<div class="form-row mt-4">
  <div class="col-12 col-md-7 m-auto">
    <p class="font-1 text-center">Aramex Claim Form</p>
    <p class="font-2 text-center">Please Help Us to Arrange Your Claim Faster!</p>
    <p class="text-justify font-3 thin">All claims are assessed in line with Aramex Standard Warranty Conditions, available to view online <a href="https://www.aramex.com.au/terms-and-conditions/standard-warranty" target="_blank">here</a>.</p>
    <div class="important-notice">
      <p class="mb-0"><b>IMPORTANT:</b> Unless specified all fields are required </p>
    </div>
    <fieldset style="margin-top:10px; margin-bottom:10px">
      <form [formGroup]="welcomeInformationForm">
        <ng-container *ngIf="!displayOnlyDraftClaims">
          <div class="col-sm-12 pl-0 font-3 font-weight-bold mt-2">
            <span class="pr-3">Have you lodged a claim with FreightSafe before?</span><br class="hidden-md" />
            <label class="input-radio mr-3">
              Yes
              <input type="radio" formControlName="isPreviousClaimant" value="1">
              <span class="checkmark"></span>
            </label>
            <label class="input-radio mr-0">
              No
              <input type="radio" formControlName="isPreviousClaimant" value="0">
              <span class="checkmark"></span>
            </label>
          </div>
        </ng-container>
      </form>
      <!-- Previous claim panel -->
      <form [formGroup]="previousClaimForm">
        <ng-container *ngIf="!displayOnlyDraftClaims">
          <div *ngIf="myForm.isPreviousClaimant.value =='1' " class="col-12 mt-2 additional-panel">
            <label class="font-3"> If you enter the claim and consignment number associated with a previous claim, we can assist you by pre-populating some of the fields below</label>
            <div>

              <div class="form-row mt-4 w-100">
                <div class="row w-100">
                  <div class="col-md-6 col-sm-12 text-right">
                    <label class="control-label" for="inputPreviousClaimNumber">Previous Claim Number</label>
                  </div>
                  <div class="col-md-6 col-sm-12 pr-0">
                    <input type="text" class="form-control" formControlName="previousClaimNumber" id="inputPreviousClaimNumber" name="inputPreviousClaimNumber" [placeholder]="claimNumberFormat">
                  </div>
                  <div class="text-right col-sm-12 pr-0">
                    <span *ngIf="previousClaimFormDetails.previousClaimNumber.errors?.invalidClaimNumber" class="help-block error">Invalid claim number format<br /></span>
                  </div>
                </div>
              </div>


              <div class="form-row mt-4 w-100">
                <div class="row w-100">
                  <div class="col-md-6 col-sm-12 text-right">
                    <label class="control-label" for="inputPreviousClaimConsignmentNumber">Previous Label Number</label>
                  </div>
                  <div class="col-md-6 col-sm-12 pr-0">
                    <input type="text" class="form-control" formControlName="previousClaimLabelNumber" id="inputPreviousClaimConsignmentNumber" name="inputPreviousClaimConsignmentNumber" placeholder="Consignment number">
                  </div>
                </div>
              </div>

              <div class="col-md-12 text-right mt-3">
                <input type="button"
                       value="Reset"
                       class="btn btn-primary pull-right button"
                       style="margin-right:20px"
                       (click)="resetPreviousClaim()" />

                <input type="button"
                       value="Submit"
                       class="btn btn-md btn-primary"
                       style="background-color:#AD002B"
                       [disabled]="(!previousClaimFormDetails.previousClaimNumber.value || !previousClaimFormDetails.previousClaimLabelNumber.value || !previousClaimForm.valid) "
                       (click)="getPreviousClaimDetails(previousClaimFormDetails.previousClaimNumber.value,previousClaimFormDetails.previousClaimLabelNumber.value)" />
                <!--<i *ngIf="previousClaimDataLoaded" title="click to see more information" class="bsb-status icon- completed" aria-hidden="true"></i>--><br />
                <span *ngIf="previousClaimDataLoaded"
                      class="col-md-12 col-sm-12 pr-0 text-right font-weight-bold font-5">
                  Successfully loaded the previous claim data, please proceed
                </span>
                <br />
              </div>
            </div>
          </div>
        </ng-container>
      </form>
      <!--
    <div class="col-sm-12 pl-0 font-3 font-weight-bold mt-2">
      <span class="pr-3">Do you wish to continue with a saved claim?</span><br class="hidden-md" />
      <label class="input-radio mr-3">
        Yes
        <input type="radio" formControlName="isDraftClaim" value="1">
        <span class="checkmark"></span>
      </label>
      <label class="input-radio mr-0">
        No
        <input type="radio" formControlName="isDraftClaim" value="0">
        <span class="checkmark"></span>
      </label>
    </div>-->
      <form [formGroup]="welcomeInformationForm">
        <!--Draft claim panel-->
        <ng-container *ngIf="displayOnlyDraftClaims">
          <div class="col-sm-12 pl-0 font-3 font-weight-bold mt-2">
            <span class="pr-3">Do you wish to continue with a saved claim?</span><br class="hidden-md" />
            <label class="input-radio mr-3">
              Yes
              <input type="radio" formControlName="isDraftClaim" value="1">
              <span class="checkmark"></span>
            </label>
            <label class="input-radio mr-0">
              No
              <input type="radio" formControlName="isDraftClaim" value="0">
              <span class="checkmark"></span>
            </label>
          </div>
          <!--Draft claim panel-->
          <div *ngIf="myForm.isDraftClaim.value =='1'" class="col-12 mt-2 additional-panel">
            <label class="font-3"> If you enter the label number associated with a saved claim, we can assist you by repopulating the fields</label>
            <div class="row">

              <!--<div class="form-row mt-4 w-100">
              <div class="row w-100">
                <div class="col-md-6 col-sm-12 text-right">
                  <label class="control-label" for="inputPreviousClaimNumber">Previous Claim Number</label>
                </div>
                <div class="col-md-6 col-sm-12 pr-0">
                  <input type="text" class="form-control" formControlName="previousClaimNumber" id="inputPreviousClaimNumber" name="inputPreviousClaimNumber" [placeholder]="claimNumberFormat">
                </div>
                <div class="text-right col-sm-12 pr-0">
                  <span *ngIf="myForm.previousClaimNumber.errors?.invalidClaimNumber" class="help-block error">Invalid claim number format<br /></span>
                </div>
              </div>
            </div>-->


              <div class="form-row mt-4 w-100">
                <div class="row w-100 pl-4">
                  <div class="col-md-6 col-sm-12 text-right">
                    <label class="control-label" for="inputDraftClaimLabelNumber">Label Number</label>
                  </div>
                  <div class="col-md-6 col-sm-12">
                    <input type="text" class="form-control" formControlName="draftClaimLabelNumber" id="inputPreviousClaimConsignmentNumber" name="inputPreviousClaimConsignmentNumber" placeholder="Consignment number">
                  </div>
                </div>
              </div>

              <div class="col-md-12 text-right mt-3">
                <!--<input type="button"
              value="Reset"
              class="btn btn-primary pull-right button"
              style="margin-right:20px"
              (click)="resetPreviousClaim()" />-->

                <input type="button"
                       value="Submit"
                       class="btn btn-md btn-primary"
                       style="background-color:#AD002B"
                       [disabled]="(!myForm.draftClaimLabelNumber.value)"
                       (click)="getDraftClaimDetails(myForm.draftClaimLabelNumber.value)" /><br /><br />
              </div>

            </div>
          </div>

        </ng-container>
        <!--End of draft claim panel-->

        <div class="form-row mt-4">
          <div class="form-group row" *ngIf="!displayOnlyDraftClaims">
            <div class="col-md-6 col-sm-12 text-right">
              <label class="control-label text-left-override" for="inputClaimantRole">Claimant Role <span *ngIf="myForm.claimantRole?.errors?.required">*</span></label>
            </div>
            <div class="col-md-4 col-sm-12">
              <select class="form-control" id="claimantRole" name="claimantRole" formControlName="claimantRole" (change)="onClaimantRoleChanged()">
                <option selected disabled value="">Please Choose</option>
                <option *ngFor="let role of claimantRoles" [ngValue]="role">{{role.value}}</option>
              </select>
            </div>
            <div class="col-md-2 col-sm-12">
              <span *ngIf="myForm.claimantRole?.touched && myForm.claimantRole?.errors?.required" class="help-block error">Claimant role is mandatory.</span>
            </div>
          </div>
        </div>
      </form>
    </fieldset>
    <span class="font-3 text-justify thin">If you require any assistance completing the claim form, please refer to our <a [href]="userGuide" [target]="target">user guide</a></span>
  </div>
</div>
<modal #modal></modal>
