import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { environment } from '../environments/environment';
import { ConsignmentModalComponent } from './components/common/modal/consignment-modal/consignment-modal.component';
import { ModalComponent } from './components/common/modal/modal.component';

import { NavigationStatus } from './enums/NavigationStatus';
import { ConfigurationModel } from './models/ConfigurationModel';
import { ModalOptions } from './models/ModalOptions';
import { AppService } from './services/common/app.service';
import { CommonService } from './services/common/common.service';
import { FormService } from './services/common/form.service';
import { NavigationService } from './services/common/navigation-service';
import { RouteService } from './services/common/route.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {

  @HostListener("window:beforeunload", ["$event"])
  private unloadNotification($event: Event): void {
    if (this.filesUpdated) {
      $event.returnValue = true;
    }
  }

  title = "Claim Form";
  carrierConfig!: ConfigurationModel;
  carrierLogo!: string;
  show = false;
  year!: number;
  isLoading: boolean = false;
  isIE: boolean = false;
  filesUpdated: boolean = false

  isLoadingSub!: Subscription;
  documentLoadSub!: Subscription;
  triggerModalSub!: Subscription;


  constructor(private commonService: CommonService,
    private routeService: RouteService, private formService: FormService,
    private navigationService: NavigationService,
    private ngbCalendarService: NgbCalendar,
    private route: Router,
    private cd: ChangeDetectorRef,
    private appService: AppService) { }
   
  // modal properties
  @ViewChild('mainmodal', { read: ModalComponent }) private modal!: ModalComponent;
  
  // modal options
  private options: ModalOptions = {
    size: 'xl',
    centered: true,
    isHtmlContent: true
  }

  modalHeaderContent!: string;
  modalbodyContent!: string;
  showOkayButton!: boolean;
  showModalConfirmationButtons!: boolean;
  isHtmlContent: boolean = false;
  metaContent!: string;

  ngOnInit(): void {
    /**
     * Loads All Routes.
     * */
    this.routeService.loadRoutes();
    this.year = this.ngbCalendarService.getToday().year;
    this.isIE = /msie\s|trident\/|edge\//i.test(navigator.userAgent);
    if (environment.showConsoleMessages) {
      console.log(this.isIE);
    }
    /*Clear application cache automatically if version has changed.*/
    if (environment.appVersion) {
      const version = this.commonService.getCacheObject('appVersion');
      if (version) {
        if (Number(version) != environment.appVersion) {
          this.commonService.clearCacheObjects();
          this.commonService.addKeyToSessionStorage('appVersion', environment.appVersion.toString());
        }
      } else {
        this.commonService.addKeyToSessionStorage('appVersion', environment.appVersion.toString());
      }
    }


    /* Subscribes to the isLoading Subject to load or stop the loading screen.*/
    this.isLoadingSub = this.commonService.isLoading$.subscribe(next => { this.isLoading = next; this.cd.detectChanges(); });


    /*Set modal instance which will be used by POST methods*/
    this.formService.modalCallBackMethod = (header: string, body: string, options: ModalOptions | null = null) => { return this.modal.modalOpen(header, body, options == null ? this.options : options); };

    this.commonService.getCarrierConfig().then(config => {
      this.carrierConfig = config;
      this.commonService.loadStyle();
      this.carrierLogo = this.carrierConfig?.logourl;
      if (this.carrierLogo) {
        this.show = true;
      }

      this.metaContent = `FreightSafe online claim form can be used to lodge claims for ${this.carrierConfig?.carrierName} carrier.`;
      if (this.formService.supportingDocumentTypes) {
        this.formService.setSupportingDocumentControls(this.carrierConfig?.prefix, this.formService.supportingDocumentTypes);
        this.formService.addCarrierSpecificValidation(this.carrierConfig);
        this.formService.setInitialCopyOfDocumentTypes(this.carrierConfig?.prefix);
        this.formService.getClaimFormDataFromSessionStorage(this.carrierConfig);

      } else {
        this.documentLoadSub = this.appService.getAllSupportingDocumentTypes()
          .subscribe(value => {
            this.formService.supportingDocumentTypes = value;
            this.formService.setSupportingDocumentControls(this.carrierConfig?.prefix, value);
            this.formService.addCarrierSpecificValidation(this.carrierConfig);
            this.formService.setInitialCopyOfDocumentTypes(this.carrierConfig?.prefix);
            this.formService.getClaimFormDataFromSessionStorage(this.carrierConfig);
          }, (errorResponse) => {

            this.commonService.isLoading$.next(false);
            // modal options
            let options: ModalOptions = {
              size: 'md',
              centered: true,
              isHtmlContent: true
            }
            this.modalHeaderContent = 'Error'
            this.modalbodyContent = '';

            let errorMessage = '';
            if (errorResponse) {
              if (errorResponse.error) {
                if (errorResponse.error.Message) {
                  errorMessage = errorResponse.error.Message;
                }
                if (errorResponse.error.ExceptionMessage) {
                  errorMessage = `${errorMessage} <br/> ${errorResponse.error.ExceptionMessage}`;

                }
                if (!errorResponse.error.Message && !errorResponse.error.ExceptionMessage) {
                  errorMessage = errorResponse.error;
                }
              }
              if (errorResponse.message) {
                errorMessage = `${errorMessage} <br/>  ${errorResponse.message}`;
              }

            } else {
              errorMessage = "No error response";
            }

            this.modalbodyContent = errorMessage;

            this.modal.modalOpen(this.modalHeaderContent, this.modalbodyContent, options)
          });
      }
      if (this.formService.consignmetInformationForm.get('consignmentNoteNo')?.value) {
        this.commonService.isDraftClaim$.next(true);
      }

      this.navigationService.browserButtonEventListner(this.carrierConfig);

      /* Check if Isolated component */
      if (this.commonService.isolatedComponent) {
        let isolatedStep = this.carrierConfig?.navigation?.filter(nav => nav.isIsolated &&
          nav.componentName.toLowerCase() == this.commonService.isolatedComponent?.toLowerCase())[0];
        if (isolatedStep) {
          if (this.commonService.consignmentNoteNumber) {
            this.navigationService
              .navigateToIsolatedComponent(this.carrierConfig, undefined,
                isolatedStep.navigationPath, undefined, this.commonService.consignmentNoteNumber);
          } else if (this.commonService.claimNumber) {
            this.navigationService
              .navigateToIsolatedComponent(this.carrierConfig, undefined,
                isolatedStep.navigationPath, this.commonService.claimNumber);
          }
        }
      }

      /* Bypass hidden components form navigation array */
      this.navigationService.adjustNavigationByComponentsVisibility(this.carrierConfig);


      let currentAtiveStep = this.carrierConfig?.navigation?.filter(nav => nav.status == NavigationStatus.Active)[0];
      if (currentAtiveStep) {
        if (currentAtiveStep.navigationPath != "") {
          /*Check if the url has changed and is not the active path.*/
          let index = this.route.url.indexOf(currentAtiveStep.navigationPath);
          if (index < 0) {
            let resetStep = this.navigationService.resetNavigation(this.carrierConfig, currentAtiveStep)
            if (resetStep) {
              currentAtiveStep = resetStep;
            }
          }
        } else {
          let urlComponent = this.carrierConfig?.navigation?.filter(
            nav => nav.navigationPath.toLowerCase() == this.commonService.component?.toLowerCase())[0];
          if (urlComponent) {
            if (!urlComponent.isIsolated && currentAtiveStep.navigationPath != urlComponent?.navigationPath) {
              let resetStep = this.navigationService.resetNavigation(this.carrierConfig, currentAtiveStep)
              if (resetStep) {
                currentAtiveStep = resetStep;
              }
            }
          } else {
            let resetStep = this.navigationService.resetNavigation(this.carrierConfig, currentAtiveStep)
            if (resetStep) {
              currentAtiveStep = resetStep;
            }
          }

        }
        this.navigationService.setProgresBarStyleOrStatus(this.carrierConfig, currentAtiveStep, true, true);
      }
      this.formService.welcomeForm.get('carrier')?.setValue({ code: this.carrierConfig?.prefix })

      if (this.commonService.carrierConfirmMessageEnabled && !this.commonService.isolatedComponent) {
        /* Confirmation modal to pop up when claim form is loaded to confrim carrier */
        let options: ModalOptions = {
          size: 'md',
          centered: true,
          isHtmlContent: true,
          showConfirmationButtons: true,
          showOkayButton: false
        }
        this.modalHeaderContent = 'Confirm'
        if (this.commonService.carrierConfirmMessageText) {
          this.modalbodyContent = this.commonService.carrierConfirmMessageText;
        } else {
          this.modalbodyContent = `You are about to complete and submit a claim form for ${this.carrierConfig?.carrierName}`;
        }
        
        this.modal.modalOpen(this.modalHeaderContent, this.modalbodyContent, options).result.then(value => {
          if (value.toLowerCase() == 'cancel') {
            window.location.href = 'https://freightsafe.com/contact/';
          }

        });
      } 
    });

    this.triggerModalSub = this.commonService.isUploaded$.subscribe(value => {
      this.filesUpdated = value;
    });

  }

 
  ngOnDestroy() {
    this.isLoadingSub?.unsubscribe();
    this.documentLoadSub?.unsubscribe();
    this.triggerModalSub?.unsubscribe();
  }


}
